import { createContext, useReducer } from "react";
import defaultTheme from '../themes/defaultTheme'
import blueTheme from '../themes/blueTheme'

export const ThemeContext = createContext<any>(defaultTheme);

  //define theme values
  const setCSSVariables = (theme:any) => {
    for (const value in theme) {
      document.documentElement.style.setProperty(`--${value}`, theme[value]);
    }
  };

const themes = {
  default:defaultTheme,
  blue: blueTheme,
};

export const themeReducer = (state: any, action: any) => {
  let link:any = document.querySelector("link[rel~='icon']");
  if (!link) {
    link = document.createElement('link');
    link.rel = 'icon';
    document.getElementsByTagName('head')[0].appendChild(link);
  }
  switch (action.type) {
    case "BLUE":
      setCSSVariables(themes.blue)
      link.href = "https://np-web-uploads.s3.eu-north-1.amazonaws.com/favicon-128x128.png"
      return { theme: themes.blue };
    case "GREEN":
      setCSSVariables(themes.default);
      link.href = "https://np-web-uploads.s3.eu-north-1.amazonaws.com/favicon.ico"
      return { theme: themes.default };
    default:
      link.href = "https://np-web-uploads.s3.eu-north-1.amazonaws.com/favicon.ico"
      setCSSVariables(state)
      return state
  }
};

export const ThemeContextProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(themeReducer, {
    theme: themes.default,
  });

  return (
    <ThemeContext.Provider value={{ ...state, dispatch }}>
      {children}
    </ThemeContext.Provider>
  );
};
