import { useCallback, useEffect, useMemo, useState } from "react";
import { getNodesBounds, useStore, useReactFlow } from "reactflow";
import { SubmitHandler, useForm } from "react-hook-form";
import { Dimmer, Grid, Loader, Segment, Table } from "semantic-ui-react";
import { InputText, TitleView } from "../../../components";
import "./CultivarForm.scss";
import {
  useGetCountries,
  useGetProduceTypes,
  useGetFertilizerList,
  useGetPesticideList,
  useGetIrrigationMethod,
  useGetPowerSource,
  useGetEnergySource,
  useGetLandUsage,
} from "../../../api/static-data";
import {
  HarvestSeasonData,
  PesticideTableHeader,
  FertilizerTableHeader,
  ENERGY_UNIT_TYPE,
  IrrigationTableHeader,
  EnergySourceData,
  LandUsageTableHeader,
} from "../../../config/constants";
import {
  useCultivationFormData,
  useCultivationFormFieldData,
  useUpdateCultivationFormData,
  useGetCultivationFormData,
  useDeleteFarmGate,
} from "../../../api/farmGate";
import { InputNumber } from "../../../components/InputText/InputNumber";
import { errorView, successMessage } from "../../../helpers/ErrorHandler";
import { MdDeleteOutline, MdModeEditOutline } from "react-icons/md";
import ConfirmModal from "../../../components/confirmViewModal/ConfirmModal";
import MainBottomButtonView from "../../../components/mainBottomButtonView/MainBottomButtonView";
import useWindowDimensions from "../../../hooks/windowsSize";
import { isEmpty, isNumber, set } from "lodash";
import {
  getLastTwentyYears,
  isCheckPercentageIrrigation,
  valueIsZero,
} from "../../../utils/utils";
import Fields from "./Fields";
import Fertilizer from "./Fertilizer";
import Pesticides from "./Pesticides";
import Irrigation from "./Irrigation";
import FuelUse from "./FuelUse";
import CultivarField from "./CultivarField";
import LandUse from "./LandUse";
import { nodeType } from "../../../config/drawingConstants";

interface IFormInput {
  farmGateName: string;
  produceType: string;
  farmingType: string;
  farmingField: string;
  produceCultivar: string;
  originCountry: string;
  harvestSeason: string;
  harvestYear: string;
  userCompletedStage?: string;
  ingredientId?: string;
  supplierId?: string;
  processing?: [];
  packaging?: [];
  internalTransportation?: [];
  dataType: string;
  pesticideQuantity?: string;
  fertilizerQuantity?: string;
  landUsageFrom?: string;
  landUsageTo?: string;
  landUseYearOfChange?: string;
  landUsageAllocation?: string;
  landUsageChange?: string;
}

interface CultivarFormProps {
  supplierId: string;
  ingredientId: string;
  farmGateId: string;
  productId: any;
  salesUnitId: any;
  nodeItem: any;
  updateDrawingNode: (node: any, farmGateName?: string) => void;
  setNodeItem: any;
}

const CULTIVAR_INIT_STEP = "CULTIVAR";
const padding = 25;

const CultivarForm: React.FC<CultivarFormProps> = ({
  supplierId,
  ingredientId,
  farmGateId,
  nodeItem,
  productId,
  salesUnitId,
  setNodeItem,
  updateDrawingNode,
}) => {
  const [harvestSeason, setHarvestSeason] = useState<string>("Unknown");
  const [farmingType, setFarmingType] = useState<string>("Unknown");
  const [fieldType, setFieldType] = useState<string>("Unknown");
  const [cultivationType, setCultivationType] = useState<string>("Supplier");
  const [country, setCountry] = useState<any>();
  const [produceType, setProduceType] = useState<any>();
  const [errorMessage, setErrorMessage] = useState("");
  const [errorStatus, setErrorStatus] = useState(false);
  const [onEdit, setOnEdit] = useState(false);
  const [onLandUsageEdit, setOnLandUsageEdit] = useState(false);
  const [onIrrigationEdit, setOnIrrigationEdit] = useState(false);
  const [onFuelUseEdit, setOnFuelUseEdit] = useState(false);
  const [editIndex, setEditIndex] = useState<any>(null);
  const [landUsageChange, setLandUsageChange] = useState("no");
  const [landUsageFrom, setLandUSageFrom] = useState<any>("");
  const [landUsageTo, setLandUSageTo] = useState<any>("");
  const [landUseYearOfChange, setLandUseYearOfChange] = useState<any>("");
  const [pesticide, setPesticide] = useState<any>("");
  const [pesticideDropdownList, setPesticideDropdownList] = useState<any>([]);
  const [pesticideTableList, setPesticideTableList] = useState<any>([]);
  const [irrigation, setIrrigation] = useState<any>("");
  const [irrigationListData, setIrrigationListData] = useState<any>([]);
  const [fuelUseData, setFuelUseData] = useState<any>([]);
  const [landUsagesData, setLandUsagesData] = useState<any>([]);
  const [powerSource, setPowerSource] = useState<any>("");
  const [energySource, setEnergySource] = useState<any>("");
  const [energyUnitType, setEnergyUnitType] = useState<any>("");
  const [fertilizer, setFertilizer] = useState<any>("");
  const [fertilizerDropdownList, setFertilizerDropdownList] = useState<any>([]);
  const [fertilizerTableList, setFertilizerTableList] = useState<any>([]);
  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false);
  const [deleteValue, setDeleteValue] = useState<any>({
    item: "",
    type: "",
  });
  const { deleteElements } = useReactFlow();

  const { height } = useWindowDimensions();

  const {
    data: countriesData,
    isSuccess: isCountriesDataSuccess,
    isLoading: isCountriesDataLoading,
  } = useGetCountries();
  const {
    data: produceTypesData,
    isSuccess: isProduceTypesSuccess,
    isLoading: isProduceTypesLoading,
  } = useGetProduceTypes();

  const {
    data: irrigationData,
    isSuccess: isIrrigationSuccess,
    isLoading: isIrrigationIsLoading,
  } = useGetIrrigationMethod();

  const {
    data: powerSourceData,
    isSuccess: isPowerSourceSuccess,
    isLoading: isPowerSourceLoading,
  } = useGetPowerSource();

  const {
    data: energySourceData,
    isSuccess: isEnergySourceSuccess,
    isLoading: isEnergySourceLoading,
  } = useGetEnergySource();

  const {
    data: landUsageData,
    isSuccess: isLandUsageSuccess,
    isLoading: isLandUsageLoading,
  } = useGetLandUsage();

  const landUsage = useMemo(() => {
    if (isLandUsageSuccess) {
      let landUsage = landUsageData;
      return landUsage;
    }
  }, [landUsageData, isLandUsageSuccess]);

  const countries = useMemo(() => {
    if (isCountriesDataSuccess) {
      const getCountryData = countriesData?.map((c: any) => ({
        key: c.id,
        text: c.country,
        value: c.id,
      }));
      return getCountryData;
    }
  }, [countriesData, isCountriesDataSuccess]);

  const produceTypes = useMemo(() => {
    if (isProduceTypesSuccess) {
      let getProduceTypesData = produceTypesData?.map((p: any) => ({
        text: p.produce_name,
        key: p.id,
        value: p.id,
      }));
      setProduceType(getProduceTypesData?.[0]?.value || "");
      return getProduceTypesData;
    }
  }, [produceTypesData, isProduceTypesSuccess]);

  const {
    data: fertilizerListData,
    isSuccess: isFertilizerListDataSuccess,
    isLoading: isFertilizerListDataLoading,
  } = useGetFertilizerList();

  const fertilizerList = useMemo(() => {
    if (isFertilizerListDataSuccess) {
      return fertilizerListData?.map((f: any) => ({
        ...f,
        key: f.id,
        text: f.fertilizer_name,
        value: f.id,
      }));
    }
  }, [fertilizerListData, isFertilizerListDataSuccess]);

  const {
    data: pesticideListData,
    isSuccess: isPesticideListDataSuccess,
    isLoading: isPesticideListDataLoading,
  } = useGetPesticideList();

  const pesticideList = useMemo(() => {
    if (isPesticideListDataSuccess) {
      return pesticideListData?.map((p: any) => ({
        ...p,
        id: parseInt(p.id),
        key: p.id,
        text: p.pesticide_name,
        value: p.id,
      }));
    }
  }, [pesticideListData, isPesticideListDataSuccess]);

  const initFormData: IFormInput = {
    farmGateName: "",
    ingredientId,
    supplierId,
    produceType: "",
    farmingType: "",
    farmingField: "",
    originCountry: "",
    harvestSeason: "",
    processing: [],
    packaging: [],
    internalTransportation: [],
    userCompletedStage: CULTIVAR_INIT_STEP,
    harvestYear: "",
    produceCultivar: "",
    dataType: "",
    pesticideQuantity: "",
    fertilizerQuantity: "",
    landUsageFrom: "",
    landUsageTo: "",
    landUseYearOfChange: "",
    landUsageAllocation: "",
    landUsageChange: "no",
  };

  const [formData, setFormData] = useState<any>(initFormData);

  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    setValue,
    getValues,
    setError,
  } = useForm({ defaultValues: initFormData, values: formData });

  // Validate Harvest Year
  const checkDateValidation = (data: any) => {
    let currentYear = new Date().getFullYear();
    if (data.length < 4) {
      setErrorMessage("Please insert valid year");
      setErrorStatus(true);
      return data;
    } else if (data.length === 4) {
      if (currentYear >= data) {
        setErrorMessage("");
        setErrorStatus(false);
      } else {
        setErrorMessage("Please insert valid year");
        setErrorStatus(true);
      }
      return data;
    } else {
      let subString = data.substring(0, 4);
      return subString;
    }
  };

  const {
    mutate: cultivationFormDataMutate,
    isLoading: isCultivarFormDataMutateLoading,
    isSuccess: isCultivarFormDataMutateSuccess,
    isError: isCultivarFormDataMutateError,
    data: cultivationFormData,
  } = useCultivationFormData();

  const {
    mutate: cultivationFormFieldDataMutate,
    isLoading: isCultivationFormFieldDataMutateLoading,
    isSuccess: isCultivationFormFieldDataMutateSuccess,
    isError: isCultivationFormFieldDataMutateError,
  } = useCultivationFormFieldData();

  const { data: currentFormData } = useGetCultivationFormData(farmGateId);

  const {
    mutate: updateCultivationDataMutate,
    isLoading: isUpdateCultivationDataMutateLoading,
    isSuccess: isUpdateCultivationDataMutateSuccess,
    isError: isUpdateCultivationDataMutateError,
  } = useUpdateCultivationFormData();

  const { mutate: deleteFarmGate, isLoading: loadingFarmGateDelete } =
    useDeleteFarmGate();

  // Update drawing node Item
  const updateNodeItem = useCallback(
    (nodeItem: any, farmGateId: string) => {
      return {
        ...nodeItem,
        data: {
          ...nodeItem?.data,
          reference: {
            farmGateId,
            supplierId,
            ingredientId,
          },
        },
      };
    },
    [supplierId, ingredientId]
  );

  useEffect(() => {
    if (fertilizerList) {
      setFertilizerDropdownList(fertilizerList);
    }

    if (pesticideList) {
      setPesticideDropdownList(pesticideList);
    }
  }, [fertilizerList, pesticideList]);

  useEffect(() => {
    if (currentFormData) {
      let pesticideTableList: any = [];
      let fertilizerTableList: any = [];
      let irrigationTableData: any = [];
      let fuelUseData: any = [];

      if (isPesticideListDataSuccess) {
        pesticideTableList =
          currentFormData?.cultivationField?.pesticideList.map((item: any) => {
            const pesticide = pesticideList?.find((p: any) => {
              return parseInt(p.id) === parseInt(item.id);
            });

            return {
              ...pesticide,
              ...item,
              id: parseInt(item.id),
            };
          });
      }
      fertilizerTableList =
        currentFormData?.cultivationField?.fertilizerList.map((item: any) => {
          const fertilizer = fertilizerList?.find((f: any) => {
            return parseInt(f.id) === parseInt(item.id);
          });
          return {
            ...fertilizer,
            ...item,
            id: parseInt(item.id),
          };
        });

      irrigationTableData = currentFormData?.cultivationField?.irrigations?.map(
        (item: any) => {
          return {
            ...item,
            id: parseInt(item.id),
          };
        }
      );

      fuelUseData = currentFormData?.cultivationField?.fuelUse?.map(
        (item: any) => {
          return {
            ...item,
            id: parseInt(item.id),
          };
        }
      );

      const formData = {
        farmGateName: currentFormData?.farmGateName ?? "",
        farmingType: currentFormData?.farmingType ?? "",
        farmingField: currentFormData?.farmingField ?? "",
        originCountry: currentFormData?.originCountry ?? "",
        produceType: currentFormData?.produceType ?? "",
        dataType: currentFormData?.dataType ?? "Supplier",
        produceCultivar: currentFormData?.produceCultivar,
        harvestSeason: currentFormData?.cultivationField?.harvestSeason ?? "",
        yield: currentFormData?.cultivationField?.yield,
        growthArea: currentFormData?.cultivationField?.growthArea,
        harvestYear: currentFormData?.cultivationField?.harvestYear,
        landUsageChange: currentFormData?.landUsageChange || "no",
        ...pesticideTableList,
        ...fertilizerTableList,
        ...irrigationTableData,
        ...fuelUseData,
        landUsage: currentFormData?.cultivationField?.landUsage || [],
      };
      setFormData(formData);
      setFieldType(formData.farmingField);
      setFarmingType(formData.farmingType);
      setHarvestSeason(formData.harvestSeason);
      setCultivationType(formData?.dataType || "Supplier");
      setPesticideTableList(pesticideTableList);
      setFertilizerTableList(fertilizerTableList);
      setIrrigationListData(irrigationTableData ? irrigationTableData : []);
      setFuelUseData(fuelUseData ? fuelUseData : []);
      setLandUsageChange(formData.landUsageChange);
      setLandUsagesData(formData.landUsage ? formData.landUsage : []);
      if (isProduceTypesSuccess) {
        const option = produceTypes?.find(
          (p) => parseInt(p.value) === parseInt(formData.produceType)
        );
        setProduceType(option?.value);
      }

      if (isCountriesDataSuccess) {
        const option = countries?.find(
          (c) => parseInt(c.value) === parseInt(formData.originCountry)
        );
        setCountry(option?.value);
      }
    }
  }, [
    currentFormData,
    isProduceTypesSuccess,
    isCountriesDataSuccess,
    isPesticideListDataSuccess,
    isFertilizerListDataSuccess,
  ]);

  useEffect(() => {
    if (
      isCultivationFormFieldDataMutateSuccess ||
      isUpdateCultivationDataMutateSuccess
    ) {
      successMessage("Cultivation updated successfully");
    }
    if (isCultivarFormDataMutateError) {
      errorView("Cultivar not saved successfully");
    }

    if (isCultivarFormDataMutateSuccess) {
      const farmGateId = (cultivationFormData as any)?.data._id.toString();
      const farmGateName = (cultivationFormData as any)?.data.farmGateName;
      if (farmGateId) {
        const cultivationFieldFormValues = setFormDataForField(
          farmGateId,
          formData
        );
        cultivationFormFieldDataMutate(cultivationFieldFormValues);
        const updatedNodeItem = updateNodeItem(nodeItem, farmGateId);
        updateDrawingNode(updatedNodeItem, farmGateName);
        successMessage("Cultivation saved successfully");
      }
    }

    if (isCultivationFormFieldDataMutateError) {
      errorView("Cultivar Field not updated successfully");
    }
  }, [
    isCultivationFormFieldDataMutateSuccess,
    isCultivarFormDataMutateError,
    isCultivarFormDataMutateSuccess,
    isCultivationFormFieldDataMutateError,
    isUpdateCultivationDataMutateError,
    isUpdateCultivationDataMutateSuccess,
    cultivationFormData,
    updateNodeItem,
    nodeItem,
    updateDrawingNode,
  ]);

  // set Cultivar form field data
  const setCultivarFormData = useCallback(
    (values: any) => {
      return {
        farmGateName: values.farmGateName,
        supplierId,
        ingredientId,
        produceType,
        originCountry: country,
        produceCultivar: values.produceCultivar,
        farmingType,
        farmingField: fieldType,
        processing: currentFormData?.processing || [],
        packaging: currentFormData?.packaging || [],
        internalTransportation: currentFormData?.internalTransportation || [],
        userCompletedStage: CULTIVAR_INIT_STEP,
        dataType: cultivationType,
        landUsageChange,
        landUsage: currentFormData?.landUsage || [],
      };
    },
    [
      country,
      farmingType,
      fieldType,
      produceType,
      ingredientId,
      supplierId,
      cultivationType,
      landUsageChange,
    ]
  );

  // Set farm gate form field data
  const setFormDataForField = useCallback(
    (farmGateId: string, values: any) => {
      const fertilizerList = fertilizerTableList.map((item: any) => {
        return { id: item.id, fertilizerQuantity: item.fertilizerQuantity };
      });
      const pesticideList = pesticideTableList.map((item: any) => {
        return { id: item.id, pesticideQuantity: item.pesticideQuantity };
      });
      const irrigations = irrigationListData;
      const fuelUse = fuelUseData;
      const landUsage = landUsagesData;
      return {
        farmGateId,
        yield: values.yield,
        growthArea: values.growthArea,
        harvestYear: values.harvestYear,
        harvestSeason,
        fertilizerList,
        pesticideList,
        irrigations,
        fuelUse,
        landUsage,
      };
    },
    [
      harvestSeason,
      pesticideTableList,
      fertilizerTableList,
      irrigationListData,
      fuelUseData,
      landUsagesData,
    ]
  );

  const onSubmitHandler: SubmitHandler<IFormInput> = useCallback(
    (values) => {
      setFormData(values);
      if (!farmGateId) {
        // new farm gate
        const cFormData = setCultivarFormData(values);
        cultivationFormDataMutate({ productId, salesUnitId, ...cFormData });
      } else {
        // update farm gate
        const cFormData = setCultivarFormData(values);
        updateCultivationDataMutate({
          farmGateId,
          productId,
          salesUnitId,
          ...cFormData,
        });
        const cultivationFieldFormValues = setFormDataForField(
          farmGateId,
          values
        );
        cultivationFormFieldDataMutate(cultivationFieldFormValues);
        const updatedNodeItem = updateNodeItem(nodeItem, farmGateId);
        updateDrawingNode(updatedNodeItem, cFormData.farmGateName);
        successMessage("Cultivation updated successfully");
      }
    },
    [
      farmGateId,
      setCultivarFormData,
      cultivationFormDataMutate,
      setFormDataForField,
      cultivationFormFieldDataMutate,
      updateCultivationDataMutate,
    ]
  );

  const resetIrrigation = () => {
    setIrrigation("");
    setPowerSource("");
    setValue("waterAmount", "");
    setValue("appliedPortion", "");
  };

  const resetLandUsage = () => {
    setLandUSageFrom("");
    setLandUSageTo("");
    setLandUseYearOfChange("");
    setValue("landUsageAllocation", "");
  };

  const resetFuelUsed = () => {
    setEnergySource("");
    setEnergyUnitType("");
    setValue("energyUsed", "");
  };

  const onAddClickIrrigationHandler = handleSubmit((values) => {
    if (
      isEmpty(values?.waterAmount?.toString()) ||
      values.waterAmount == undefined ||
      isEmpty(values?.appliedPortion?.toString()) ||
      values.appliedPortion == undefined
    ) {
      if (
        isEmpty(values?.waterAmount?.toString()) ||
        values.waterAmount == undefined
      ) {
        errorView("Water Amount is required");
        return;
      }
      errorView("Applied Portion is required");
      return;
    }
    let irrigationTableData = [...irrigationListData];
    if (onIrrigationEdit) {
      const updatedTodo = {
        ...irrigationTableData[editIndex],
        irrigationMethodId: Number(irrigation),
        powerSourceId: Number(powerSource),
        waterAmount: Number(values.waterAmount),
        appliedPortion: Number(values.appliedPortion),
      };
      const updateIrrigationData = [
        ...irrigationTableData.slice(0, editIndex),
        updatedTodo,
        ...irrigationTableData.slice(editIndex + 1),
      ];
      setIrrigationListData(updateIrrigationData);
      setOnIrrigationEdit(false);
    } else {
      const newIrrigationData = {
        irrigationMethodId: Number(irrigation),
        powerSourceId: Number(powerSource),
        waterAmount: Number(values.waterAmount),
        appliedPortion: Number(values.appliedPortion),
      };
      irrigationTableData.push(newIrrigationData);
      setIrrigationListData(irrigationTableData);
    }
    resetIrrigation();
  });

  const onAddClickFuelUseDetails = handleSubmit((values) => {
    if (
      isEmpty(values?.energyUsed?.toString()) ||
      values.energyUsed == undefined
    ) {
      errorView("Energy used is required");
      return;
    }
    let fuelUsedTableData = [...fuelUseData];
    if (onFuelUseEdit) {
      const updatedTodo = {
        ...fuelUsedTableData[editIndex],
        energySource: Number(energySource),
        energyUsed: Number(values.energyUsed),
        energyUnit: Number(energyUnitType),
      };
      const updateIrrigationData = [
        ...fuelUsedTableData.slice(0, editIndex),
        updatedTodo,
        ...fuelUsedTableData.slice(editIndex + 1),
      ];
      setFuelUseData(updateIrrigationData);
      setOnFuelUseEdit(false);
    } else {
      const newFuelUsed = {
        energySource: Number(energySource),
        energyUsed: Number(values.energyUsed),
        energyUnit: Number(energyUnitType),
      };
      fuelUsedTableData.push(newFuelUsed);
      setFuelUseData(fuelUsedTableData);
    }
    resetFuelUsed();
  });

  const onAddClickLandUsageHandler = handleSubmit((values) => {
    if (
      landUsageFrom === "" ||
      landUsageTo === "" ||
      landUseYearOfChange === ""
    ) {
      errorView("Please fill land usage the fields");
      return;
    }
    const value = parseInt(values.landUsageAllocation);
    if (!(value >= 0 && value <= 100)) {
      errorView(
        "Land usage allocation should be greater than 0 and less than 100"
      );
      return;
    }
    let landUsagesTableData = landUsagesData ? [...landUsagesData] : [];
    if (onLandUsageEdit) {
      const updatedTodo = {
        ...landUsagesTableData[editIndex],
        landUsageFrom,
        landUsageTo,
        landUseYearOfChange,
        landUsageAllocation: values.landUsageAllocation,
      };
      const updatedLandUsageData = [
        ...landUsagesTableData.slice(0, editIndex),
        updatedTodo,
        ...landUsagesTableData.slice(editIndex + 1),
      ];
      setLandUsagesData(updatedLandUsageData);
      setOnLandUsageEdit(false);
    } else {
      const newLandUsage = {
        landUsageFrom,
        landUsageTo,
        landUseYearOfChange,
        landUsageAllocation: values.landUsageAllocation,
      };
      landUsagesTableData.push(newLandUsage);
      setLandUsagesData(landUsagesTableData);
    }
    resetLandUsage();
  });

  const onAddClickPesticideHandler = handleSubmit((values) => {
    let updatedPesticideDropdownList = [...pesticideDropdownList];
    let updatedPesticideTableList = [...pesticideTableList];
    if (
      updatedPesticideDropdownList.length === 0 ||
      !pesticide ||
      !values.pesticideQuantity
    ) {
      errorView("Select pesticide name or quantity");
      return;
    }
    const doesPesticideExists = updatedPesticideTableList.find(
      (item) => parseInt(item.id) === parseInt(pesticide)
    );
    if (doesPesticideExists) {
      updatedPesticideTableList = updatedPesticideTableList.map((p) => {
        if (parseInt(p.id) === parseInt(pesticide)) {
          p.pesticideQuantity = values.pesticideQuantity;
        }
        return p;
      });
    } else {
      const pesticideFromList = updatedPesticideDropdownList.find(
        (item) => item.value === pesticide
      );
      if (pesticideFromList) {
        updatedPesticideTableList.push({
          ...pesticideFromList,
          pesticideQuantity: values.pesticideQuantity,
        });
        updatedPesticideDropdownList = updatedPesticideDropdownList.filter(
          (item) => {
            return item.id !== pesticide;
          }
        );
      }
    }
    setPesticideTableList(updatedPesticideTableList);
    setPesticideDropdownList(updatedPesticideDropdownList);
    setValue("pesticideQuantity", "");
    setPesticide("");
  });

  const onAddClickFertilizerHandler = handleSubmit((values) => {
    let updatedFertilizerDropdownList = [...fertilizerDropdownList];
    let updatedFertilizerTableList = [...fertilizerTableList];
    if (
      updatedFertilizerDropdownList.length === 0 ||
      !fertilizer ||
      !values.fertilizerQuantity
    ) {
      errorView("Select fertilizer name or quantity");
      return;
    }

    const doesFertilizerExists = updatedFertilizerTableList.find(
      (item) => parseInt(item.id) === parseInt(fertilizer)
    );
    if (doesFertilizerExists) {
      updatedFertilizerTableList = updatedFertilizerTableList.map((p) => {
        if (parseInt(p.id) === parseInt(fertilizer)) {
          p.fertilizerQuantity = values.fertilizerQuantity;
        }
        return p;
      });
    } else {
      const fertilizerFromList = updatedFertilizerDropdownList.find(
        (item) => item.id === fertilizer
      );
      if (fertilizerFromList) {
        updatedFertilizerTableList.push({
          ...fertilizerFromList,
          fertilizerQuantity: values.fertilizerQuantity,
        });
        updatedFertilizerDropdownList = updatedFertilizerDropdownList.filter(
          (item) => {
            return item.id !== fertilizer;
          }
        );
      }
    }
    setFertilizerTableList(updatedFertilizerTableList);
    setFertilizerDropdownList(updatedFertilizerDropdownList);
    setValue("fertilizerQuantity", "");
    setFertilizer("");
  });

  const { childNodes } = useStore((store) => {
    const childNodes = Array.from(store.nodeInternals.values()).filter(
      (n) => n.parentNode === nodeItem?.parentNode
    );
    const rect = getNodesBounds(childNodes);
    return {
      minWidth: rect.width + padding * 2,
      minHeight: rect.height + padding * 2,
      hasChildNodes: childNodes.length > 0,
      childNodes,
    };
  }, isEqual);

  const deleteFarmGateGroup = useCallback(() => {
    const farmGateNodeId = nodeItem?.parentNode;

    const isHaveCultivar = childNodes.some(
      (cn) => cn?.data.icon !== nodeType.Cultivar
    );
    if (isHaveCultivar) {
      setVisibleDeleteModal(false);
      errorView("Please delete all items inside the farm gate except cultivar");
      return;
    }
    if (farmGateId) {
      deleteFarmGate(farmGateId, {
        onSuccess: () => {
          deleteElements({ nodes: [{ id: farmGateNodeId }] });
        },
      });
    } else {
      deleteElements({ nodes: [{ id: farmGateNodeId }] });
    }
  }, [childNodes]);

  const editTableData = (type: any) => {
    if (type == "Irrigation") {
      onAddClickIrrigationHandler();
    } else if (type == "FuelUsed") {
      onAddClickFuelUseDetails();
    } else if (type == "LandUsage") {
      onAddClickLandUsageHandler();
    }
  };

  const cancelEdit = (type: any) => {
    setOnEdit(false);
    setOnLandUsageEdit(false);
    setOnIrrigationEdit(false);
    setOnFuelUseEdit(false);
    if (type === "Irrigation") {
      resetIrrigation();
    } else if (type === "FuelUsed") {
      resetFuelUsed();
    } else if (type === "LandUsage") {
      resetLandUsage();
    }
  };

  const onEditHandler = (item: any, type: string, index?: any) => {
    if (type === "Pesticide") {
      setValue("pesticideQuantity", item?.pesticideQuantity ?? "");
      setPesticideDropdownList((pesticideList: any) => [
        ...pesticideList,
        item,
      ]);
      setPesticide(item.id.toString());
    } else if (type === "Fertilizer") {
      setValue("fertilizerQuantity", item?.fertilizerQuantity ?? "");
      setFertilizerDropdownList((fertilizerDropdownList: any) => [
        ...fertilizerDropdownList,
        item,
      ]);
      setFertilizer(item.id.toString());
    } else if (type === "Irrigation") {
      setOnIrrigationEdit(true);
      setEditIndex(index);
      setValue("waterAmount", item?.waterAmount ?? "");
      setValue("appliedPortion", item?.appliedPortion ?? "");
      setIrrigation(item.irrigationMethodId.toString());
      setPowerSource(item.powerSourceId.toString());
    } else if (type === "FuelUsed") {
      setOnFuelUseEdit(true);
      setEditIndex(index);
      setValue("energyUsed", item?.energyUsed ?? "");
      setEnergySource(item.energySource.toString());
      setEnergyUnitType(item.energyUnit);
    } else if (type === "LandUsage") {
      setOnLandUsageEdit(true);
      setEditIndex(index);
      setLandUSageFrom(item.landUsageFrom);
      setLandUSageTo(item.landUsageTo);
      setLandUseYearOfChange(item.landUseYearOfChange);
      setValue("landUsageAllocation", item.landUsageAllocation);
    }
  };

  const isLoading =
    isCultivarFormDataMutateLoading ||
    isCultivationFormFieldDataMutateLoading ||
    isUpdateCultivationDataMutateLoading ||
    isCountriesDataLoading ||
    isProduceTypesLoading ||
    isFertilizerListDataLoading ||
    isPesticideListDataLoading ||
    loadingFarmGateDelete;

  return (
    <Segment basic className="empty-padding">
      <Dimmer active={isLoading} inverted>
        <Loader />
      </Dimmer>
      <div className="cultivarForm">
        <div className="custom-card">
          <form id="cultivarForm" onSubmit={handleSubmit(onSubmitHandler)}>
            <div
              style={{
                height: height * 0.9 - 120,
                overflowX: "hidden",
              }}
            >
              <TitleView
                CustomTextTitle="customT"
                CustomTitleViewMain="Farm Gate"
                title="Farm Gate"
              />
              <div>
                <Grid columns={2}>
                  <Grid.Row>
                    <Grid.Column>
                      <InputText
                        labelName={"Farm Gate Name*"}
                        name="farmGateName"
                        register={register}
                        errorMessage="Farm Gate Name is required"
                        errors={errors.farmGateName}
                        required
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </div>
              <CultivarField
                cultivationType={cultivationType}
                setCultivationType={setCultivationType}
                produceType={produceType}
                produceTypes={produceTypes}
                setProduceType={setProduceType}
                isProduceTypesLoading={isProduceTypesLoading}
                country={country}
                countries={countries}
                setCountry={setCountry}
                setValue={setValue}
                isCountriesDataLoading={isCountriesDataLoading}
                register={register}
                farmingType={farmingType}
                setFarmingType={setFarmingType}
                fieldType={fieldType}
                setFieldType={setFieldType}
              />
              <div className="">
                <Fields
                  cultivationType={cultivationType}
                  control={control}
                  checkDateValidation={checkDateValidation}
                  errors={errors}
                  errorStatus={errorStatus}
                  errorMessage={errorMessage}
                  HarvestSeasonData={HarvestSeasonData}
                  harvestSeason={harvestSeason}
                  setHarvestSeason={setHarvestSeason}
                />
                <Fertilizer
                  fertilizerTableList={fertilizerTableList}
                  FertilizerTableHeader={FertilizerTableHeader}
                  onEditHandler={onEditHandler}
                  control={control}
                  setVisibleDeleteModal={setVisibleDeleteModal}
                  setDeleteValue={setDeleteValue}
                  fertilizer={fertilizer}
                  fertilizerDropdownList={fertilizerDropdownList}
                  setFertilizer={setFertilizer}
                  onAddClickFertilizerHandler={onAddClickFertilizerHandler}
                  cultivationType={cultivationType}
                />
                <Pesticides
                  pesticide={pesticide}
                  pesticideDropdownList={pesticideDropdownList}
                  onEditHandler={onEditHandler}
                  control={control}
                  setVisibleDeleteModal={setVisibleDeleteModal}
                  setDeleteValue={setDeleteValue}
                  setPesticide={setPesticide}
                  onAddClickPesticideHandler={onAddClickPesticideHandler}
                  pesticideTableList={pesticideTableList}
                  PesticideTableHeader={PesticideTableHeader}
                  cultivationType={cultivationType}
                />
                <Irrigation
                  irrigation={irrigation}
                  irrigationData={irrigationData}
                  onEditHandler={onEditHandler}
                  control={control}
                  setVisibleDeleteModal={setVisibleDeleteModal}
                  setDeleteValue={setDeleteValue}
                  setIrrigation={setIrrigation}
                  powerSource={powerSource}
                  powerSourceData={powerSourceData}
                  errors={errors}
                  cultivationType={cultivationType}
                  setPowerSource={setPowerSource}
                  isCheckPercentageIrrigation={isCheckPercentageIrrigation}
                  onEdit={onEdit}
                  editTableData={editTableData}
                  cancelEdit={cancelEdit}
                  onAddClickIrrigationHandler={onAddClickIrrigationHandler}
                  irrigationListData={irrigationListData}
                  IrrigationTableHeader={IrrigationTableHeader}
                />
                <FuelUse
                  energySource={energySource}
                  energySourceData={energySourceData}
                  onEditHandler={onEditHandler}
                  control={control}
                  setVisibleDeleteModal={setVisibleDeleteModal}
                  setDeleteValue={setDeleteValue}
                  setEnergySource={setEnergySource}
                  energyUnitType={energyUnitType}
                  ENERGY_UNIT_TYPE={ENERGY_UNIT_TYPE}
                  errors={errors}
                  cultivationType={cultivationType}
                  setEnergyUnitType={setEnergyUnitType}
                  EnergySourceData={EnergySourceData}
                  onEdit={onEdit}
                  editTableData={editTableData}
                  cancelEdit={cancelEdit}
                  onAddClickFuelUseDetails={onAddClickFuelUseDetails}
                  fuelUseData={fuelUseData}
                />
                <LandUse
                  setLandUsageChange={setLandUsageChange}
                  landUsageChange={landUsageChange}
                  onEditHandler={onEditHandler}
                  control={control}
                  setVisibleDeleteModal={setVisibleDeleteModal}
                  setDeleteValue={setDeleteValue}
                  landUseYearOfChange={landUseYearOfChange}
                  getLastTwentyYears={getLastTwentyYears}
                  setLandUseYearOfChange={setLandUseYearOfChange}
                  setValue={setValue}
                  cultivationType={cultivationType}
                  landUsageFrom={landUsageFrom}
                  landUsage={landUsage}
                  setLandUSageFrom={setLandUSageFrom}
                  landUsageTo={landUsageTo}
                  setLandUSageTo={setLandUSageTo}
                  onEdit={onEdit}
                  editTableData={editTableData}
                  cancelEdit={cancelEdit}
                  onAddClickLandUsageHandler={onAddClickLandUsageHandler}
                  landUsagesData={landUsagesData}
                  LandUsageTableHeader={LandUsageTableHeader}
                />
              </div>
            </div>
            <MainBottomButtonView
              saveButtonStatus={true}
              deleteStatus={true}
              deleteButton={() => setVisibleDeleteModal(true)}
              saveTitle={"Update"}
              type="submit"
            />
          </form>
          <ConfirmModal
            viewModal={visibleDeleteModal}
            closeModal={() => setVisibleDeleteModal(false)}
            cancel={() => {
              setVisibleDeleteModal(false);
              setDeleteValue({});
            }}
            approve={deleteFarmGateGroup}
            title={`Delete ${deleteValue.type}`}
            subTitle={`Are you sure you want to delete Cultivation data?`}
          />
        </div>
      </div>
    </Segment>
  );
};

type IsEqualCompareObj = {
  minWidth: number;
  minHeight: number;
  hasChildNodes: boolean;
};

function isEqual(prev: IsEqualCompareObj, next: IsEqualCompareObj): boolean {
  return (
    prev.minWidth === next.minWidth &&
    prev.minHeight === next.minHeight &&
    prev.hasChildNodes === next.hasChildNodes
  );
}

export default CultivarForm;
