import { useQueries } from "@tanstack/react-query";
import { axiosApi } from "../apiService";


export const useGetSimulationSuppliersByIds = (supplierIds: string[]) => {
  return useQueries({
    queries: supplierIds.map(
      (id) => {
        return {
          queryKey: ["getSupplierId", id],
          queryFn: async () => {
            const response = await axiosApi.get(`/supplier/${id}`);
            return response.data;
          },
        };
      },
      { enabled: !!supplierIds }
    ),
  });
};
