import { Grid } from "semantic-ui-react";
import "./TitleActionBar.scss";
import { DropDown, DropDownProps } from "../selectDropDown/DropDown";

export const TitleActionBar: React.FC<DropDownProps | any> = (props) => {
  const { title } = props;
  return (
    <div className={`product-drop-down-div`}>
      <label className={`dropdown-title`}>{title}</label>
      <DropDown
        key="action_dropdown"
        customGridMain="action-grid"
        customGridColumn={"action-grid-column"}
        {...props}
      />
    </div>
  );
};
