import React, { useEffect, useState } from "react";
import { Table, Input, Grid } from "semantic-ui-react";
import Icon from "@mdi/react";
import { mdiPencil, mdiDeleteOutline, mdiContentSave } from "@mdi/js";

import "./packagingTable.scss";
import { calculateTotalWeight } from "../../utils/calculateTotalWeight";
import { CustomButton } from "../../../../components/button/Button";
import { DropDown } from "../../../../components/selectDropDown/DropDown";

interface PackagingMachineryTableProps {
  tableData: PackagingMachineryTableData[];
  editable?: boolean;
  originalData?: PackagingMachineryTableData[];
  setNewPackagingData?: React.Dispatch<
    React.SetStateAction<PackagingData | undefined>
  >;
  packagingLevels: PackagingLevels[];
  wasteManagements: WasteManagements[];
  isView?: boolean;
  createPackagingMethod?: (data: any) => void;
  updatePackagingMethod?: (data: any) => void;
  deletePackagingMethod?: (data: any) => void;
}

const PackagingMachineryTable: React.FC<PackagingMachineryTableProps> = ({
  tableData,
  editable,
  setNewPackagingData,
  originalData,
  packagingLevels,
  wasteManagements,
  isView,
  createPackagingMethod,
  updatePackagingMethod,
  deletePackagingMethod,
}) => {
  const [editIndex, setEditIndex] = useState<number | undefined>(undefined);
  const [editRow, setEditRow] = useState<
    PackagingMachineryTableData | undefined
  >(undefined);
  const [newRow, setNewRow] = useState<
    PackagingMachineryTableData | undefined
  >();

  useEffect(() => {
    if (editIndex !== undefined) {
      setEditRow(tableData[editIndex]);
    }
  }, [editIndex]);

  const field = "packagingMethod";

  const renderNewRow = () => {
    if (!newRow) return;
    return (
      <Table.Row>
        <Table.Cell>
          <Input
            fluid
            value={newRow.packagingMethodName}
            onChange={(e) => {
              setNewRow({ ...newRow, packagingMethodName: e.target.value });
            }}
          />
        </Table.Cell>
        <Table.Cell>
          <Input
            fluid
            value={newRow.packagingMachine}
            onChange={(e) => {
              setNewRow({ ...newRow, packagingMachine: e.target.value });
            }}
          />
        </Table.Cell>
        <Table.Cell>
          <DropDown
            customGridMain="custom-grid-main"
            placeholder="Packaging Level"
            currentData={packagingLevels}
            defaultValue={
              packagingLevels.find((packagingLevel) => {
                return (
                  packagingLevel.value === newRow.packagingLevel
                );
              })?.value || null
            }
            customGridColumn={"customGridColomnType"}
            handleChangeState={(e: any, { value }: any) => {
              if (newRow) {
                setNewRow({
                  ...newRow,
                  packagingLevel: value,
                });
              }
            }}
          />
        </Table.Cell>
        <Table.Cell>
          <Input
            fluid
            type="number"
            value={newRow.energy}
            onChange={(e) => {
              setNewRow({ ...newRow, energy: parseFloat(e.target.value) });
            }}
          />
        </Table.Cell>
        <Table.Cell>
          <Input
            fluid
            type="number"
            value={newRow.capacity}
            onChange={(e) => {
              setNewRow({ ...newRow, capacity: parseFloat(e.target.value) });
            }}
          />
        </Table.Cell>
        <Table.Cell>
          <Input
            fluid
            type="number"
            value={newRow.waste}
            onChange={(e) => {
              setNewRow({ ...newRow, waste: parseFloat(e.target.value) });
            }}
          />
        </Table.Cell>
        <Table.Cell>
          <DropDown
            customGridMain="custom-grid-main"
            placeholder="Waist Management"
            currentData={wasteManagements}
            defaultValue={
              wasteManagements.find((wasteManagement) => {
                return (
                  Number(wasteManagement.value) === Number(newRow.wasteManagementMethod)
                );
              })?.value || null
            }
            customGridColumn={"customGridColomnType"}
            handleChangeState={(e: any, { value }: any) => {
              if (newRow) {
                setNewRow({
                  ...newRow,
                  wasteManagementMethod: value,
                });
              }
            }}
          />
        </Table.Cell>
        <Table.Cell>
          <Grid>
            <Grid.Column width={8}>
              <div
                onClick={() => {
                  setNewPackagingData &&
                    setNewPackagingData((prev: any) => {
                      if (prev && field) {
                        const newData = [...prev[field]];
                        newData.push(newRow);
                        return calculateTotalWeight({
                          ...prev,
                          [field]: newData,
                        });
                      }
                      return prev;
                    });
                    createPackagingMethod && createPackagingMethod(newRow);
                  setNewRow(undefined);
                }}
              >
                <Icon
                  className="table-icon"
                  color="var(--tableEditIcon)"
                  path={mdiContentSave}
                  size={1.2}
                />
              </div>
            </Grid.Column>
            <Grid.Column width={8}>
              <div onClick={() => setNewRow(undefined)}>
                <Icon
                  className="table-icon"
                  color="var(--tableEditIcon)"
                  path={mdiDeleteOutline}
                  size={1.2}
                />
              </div>
            </Grid.Column>
          </Grid>
        </Table.Cell>
      </Table.Row>
    );
  };

  return (
    <>
      {editable && (
        <CustomButton
          customColumnStyle="p-0 m-0 packaging-add-button"
          theme="green"
          title="Add New"
          disabled={isView}
          onClick={() => {
            setNewRow({
              packagingMethodName: "",
              packagingMachine: "",
              packagingLevel: "",
              energy: 0,
              capacity: 0,
              waste: 0,
              wasteManagementMethod: 1
            });
          }}
        />
      )}
      <Table
        singleLine
        className={editable ? "editable-table" : "non-editable-table"}
      >
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Packaging Step</Table.HeaderCell>
            <Table.HeaderCell>Packaging Machinary</Table.HeaderCell>
            <Table.HeaderCell>Packaging Level</Table.HeaderCell>
            <Table.HeaderCell>Energy</Table.HeaderCell>
            <Table.HeaderCell>Capacity(pk/hr)</Table.HeaderCell>
            <Table.HeaderCell>Waste(pk/hr)</Table.HeaderCell>
            <Table.HeaderCell>Waste Management</Table.HeaderCell>
            {editable && !isView && <Table.HeaderCell>Actions</Table.HeaderCell>}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {tableData.map((item, index) => (
            <Table.Row key={index}>
              <Table.Cell>
                {editIndex === index ? (
                  <Input
                    fluid
                    value={editRow?.packagingMethodName}
                    onChange={(e) => {
                      if (editRow) {
                        setEditRow({
                          ...editRow,
                          packagingMethodName: e.target.value,
                        });
                      }
                    }}
                  />
                ) : (
                  <p
                    className={
                      originalData &&
                      originalData?.[index]?.packagingMethodName !==
                        item.packagingMethodName
                        ? "edited"
                        : ""
                    }
                  >
                    {item.packagingMethodName}
                  </p>
                )}
              </Table.Cell>
              <Table.Cell>
                {editIndex === index ? (
                  <Input
                    fluid
                    value={editRow?.packagingMachine}
                    onChange={(e) => {
                      if (editRow) {
                        setEditRow({
                          ...editRow,
                          packagingMachine: e.target.value,
                        });
                      }
                    }}
                  />
                ) : (
                  <p
                    className={
                      originalData &&
                      originalData?.[index]?.packagingMachine !==
                        item.packagingMachine
                        ? "edited"
                        : ""
                    }
                  >
                    {item.packagingMachine}
                  </p>
                )}
              </Table.Cell>
              <Table.Cell>
                {editIndex === index ? (
                  <DropDown
                    customGridMain="custom-grid-main"
                    placeholder="Packaging Level"
                    currentData={packagingLevels}
                    defaultValue={
                      packagingLevels.find((packagingLevel) => {
                        return (
                          packagingLevel.value ===
                          editRow?.packagingLevel
                        );
                      })?.value || null
                    }
                    customGridColumn={"customGridColomnType"}
                    handleChangeState={(e: any, { value }: any) => {
                      if (editRow) {
                        setEditRow({
                          ...editRow,
                          packagingLevel: value,
                        });
                      }
                    }}
                  />
                ) : (
                  <p
                    className={
                      originalData &&
                      originalData?.[index]?.packagingLevel !==
                        item.packagingLevel
                        ? "edited"
                        : ""
                    }
                  >
                    {packagingLevels.find((packagingLevel) => {
                      return (
                        Number(packagingLevel.value) ===
                        Number(item.packagingLevel)
                      );
                    })?.text || item.packagingLevel}
                  </p>
                )}
              </Table.Cell>
              <Table.Cell>
                {editIndex === index ? (
                  <Input
                    fluid
                    type="number"
                    value={editRow?.energy}
                    onChange={(e) => {
                      if (editRow) {
                        setEditRow({
                          ...editRow,
                          energy: parseFloat(e.target.value),
                        });
                      }
                    }}
                  />
                ) : (
                  <p
                    className={
                      originalData &&
                      originalData?.[index]?.energy !== item.energy
                        ? "edited"
                        : ""
                    }
                  >
                    {item.energy}
                  </p>
                )}
              </Table.Cell>
              <Table.Cell>
                {editIndex === index ? (
                  <Input
                    fluid
                    type="number"
                    value={editRow?.capacity}
                    onChange={(e) => {
                      if (editRow) {
                        setEditRow({
                          ...editRow,
                          capacity: parseFloat(e.target.value),
                        });
                      }
                    }}
                  />
                ) : (
                  <p
                    className={
                      originalData &&
                      originalData?.[index]?.capacity !== item.capacity
                        ? "edited"
                        : ""
                    }
                  >
                    {item.capacity}
                  </p>
                )}
              </Table.Cell>
              <Table.Cell>
                {editIndex === index ? (
                  <Input
                    fluid
                    type="number"
                    value={editRow?.waste}
                    onChange={(e) => {
                      if (editRow) {
                        setEditRow({
                          ...editRow,
                          waste: parseFloat(e.target.value),
                        });
                      }
                    }}
                  />
                ) : (
                  <p
                    className={
                      originalData &&
                      originalData?.[index]?.waste !== item.waste
                        ? "edited"
                        : ""
                    }
                  >
                    {item.waste}
                  </p>
                )}
              </Table.Cell>
              <Table.Cell>
                {editIndex === index ? (
                  <DropDown
                    customGridMain="custom-grid-main"
                    placeholder="Waste Management"
                    currentData={wasteManagements}
                    defaultValue={
                      wasteManagements.find((wasteManagement) => {
                        return (
                          Number(wasteManagement.value) ===
                          Number(editRow?.wasteManagementMethod)
                        );
                      })?.value || null
                    }
                    customGridColumn={"customGridColomnType"}
                    handleChangeState={(e: any, { value }: any) => {
                      if (editRow) {
                        setEditRow({
                          ...editRow,
                          wasteManagementMethod: value,
                        });
                      }
                    }}
                  />
                ) : (
                  <p
                    className={
                      originalData &&
                      originalData?.[index]?.wasteManagementMethod !==
                        item.wasteManagementMethod
                        ? "edited"
                        : ""
                    }
                  >
                    {wasteManagements.find((wasteManagement) => {
                        return (
                          Number(wasteManagement?.value) ===
                          Number(item?.wasteManagementMethod)
                        );
                      })?.text || null}
                  </p>
                )}
              </Table.Cell>
              {editable && !isView && (
                <Table.Cell>
                  {editIndex === index ? (
                    <div
                      onClick={() => {
                        setNewPackagingData &&
                          setNewPackagingData((prev: any) => {
                            if (prev && field) {
                              const newData = [...prev[field]];
                              newData[index] = editRow;
                              return calculateTotalWeight({
                                ...prev,
                                [field]: newData,
                              });
                            }
                            return prev;
                          });
                          updatePackagingMethod && updatePackagingMethod(editRow);
                        setEditIndex(undefined);
                      }}
                    >
                      <Icon
                        className="table-icon"
                        color="var(--tableEditIcon)"
                        path={mdiContentSave}
                        size={1.2}
                      />
                    </div>
                  ) : (
                    <Grid>
                      <Grid.Column width={8}>
                        <div onClick={() => setEditIndex(index)}>
                          <Icon
                            className="table-icon"
                            color="var(--tableEditIcon)"
                            path={mdiPencil}
                            size={1.2}
                          />
                        </div>
                      </Grid.Column>
                      <Grid.Column width={8}>
                        <div
                          onClick={() => {
                            setNewPackagingData &&
                              setNewPackagingData((prev) => {
                                if (prev && field) {
                                  const newData = [...prev[field]];
                                  const deleted = newData.splice(index, 1);
                                  deletePackagingMethod && deletePackagingMethod({
                                    ...deleted[0],
                                    packagingMethodId: deleted[0]._id,
                                  });
                                  return calculateTotalWeight({
                                    ...prev,
                                    [field]: newData,
                                  });
                                }
                                return prev;
                              });
                          }}
                        >
                          <Icon
                            className="table-icon"
                            color="var(--tableEditIcon)"
                            path={mdiDeleteOutline}
                            size={1.2}
                          />
                        </div>
                      </Grid.Column>
                    </Grid>
                  )}
                </Table.Cell>
              )}
            </Table.Row>
          ))}
          {renderNewRow()}
        </Table.Body>
      </Table>
    </>
  );
};

export default PackagingMachineryTable;
