import { useMutation, useQuery } from "@tanstack/react-query";
import { ApiServiceErr, axiosApi } from "./apiService";

export const useGetEnergyType = () =>
  useQuery<any, ApiServiceErr>(
    ["getEnergyTypeData"],
    async () => {
      const response = await axiosApi.get(`/static-data/energy-types`);
      let createEnergyType = response?.data.reduce((currentType: any, data: any) => {
        let energyType = {
          key: data?.id,
          text: data?.energy_name,
          value: data?.id
        };
        return [...currentType, energyType]
      }, [])
      return createEnergyType
    },
    {
      cacheTime: 0,
    }
  );


export const useUpdateSupplierStaticCOntent = (content: string) =>
  useMutation<any, ApiServiceErr, any>(async (content) => {
    const response = await axiosApi.post(`/tenant/supplier-content`, { supplierTermsNCondition: content });
    return response?.data;
  });

export const useGetSupplierStaticCOntent = () =>
  useQuery<any, ApiServiceErr>(
    ["getSupplierStaticCOntent"],
    async () => {
      const response = await axiosApi.get(`/tenant/supplier-content`);
      return response?.data;
    },
    {
      cacheTime: 0,
    }
  );
