import React from "react";
import { Grid } from "semantic-ui-react";

import { CustomButton, DropDown, InputText } from "../../../components";

import "./userList.scss";

const ProductFilter = ({ currentData, defaultValue, status }: any) => {
  return (
    <div className="customProductFilterMainView">
      {status ? (
        <Grid>
          <Grid.Column computer={4} tablet={8} mobile={16}>
            <DropDown
              labelName={"Select Column Name"}
              placeholder="Select Column Name"
              currentData={currentData}
              defaultValue={defaultValue}
            />
          </Grid.Column>
          <Grid.Column computer={4} tablet={8} mobile={16}>
            <InputText
              labelName={"Search value"}
              customGridColumn={"customLabelFilter"}
            />
          </Grid.Column>
          <Grid.Column computer={3} tablet={4} mobile={8}>
            <CustomButton
              title="Search"
              customColumnStyle={"customButtonSearch"}
            />
          </Grid.Column>
        </Grid>
      ) : null}
    </div>
  );
};

export default ProductFilter;
