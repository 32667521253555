import * as React from "react";
const IngredientIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <circle cx={24} cy={24} r={24} fill="var(--mappingImageBackground)" />
    <path
      fill="var(--mappingIconColor)"
      d="M19.66 13.895a2.712 2.712 0 1 1-5.424 0 2.712 2.712 0 0 1 5.424 0Zm-4.068 0a1.356 1.356 0 1 0 2.712 0 1.356 1.356 0 0 0-2.712 0Z"
    />
    <circle cx={23.457} cy={12.811} r={1.627} fill="var(--mappingIconColor)" />
    <circle cx={20.745} cy={17.692} r={1.085} fill="var(--mappingIconColor)" />
    <path
      stroke="var(--mappingIconColor)"
      strokeLinecap="round"
      strokeWidth={2}
      d="M19.525 34.54c1.158.347 4.47.833 8.45 0M14.55 21h18.396c.829 0 2.387.52 1.99 2.604-.498 2.604-.996 7.811-6.962 8.332-4.774.417-8.95-.174-10.442-.52-3.48-1.563-6.46-9.895-2.982-10.416ZM24.542 20.946l3.797-5.967c-.181-1.627.458-4.767 4.34-3.796"
    />
    <path
      stroke="var(--mappingIconColor)"
      strokeLinecap="round"
      strokeWidth={2}
      d="m29.966 20.947 2.17-3.797c1.988-.723 4.339-3.797.542-5.967"
    />
    <path
      stroke="var(--mappingIconColor)"
      strokeLinecap="round"
      d="M31.678 23.116c.18.543.217 2.17-1.085 4.34"
    />
  </svg>
);
export default IngredientIcon;
