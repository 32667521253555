import FacilityList from "../screens/facility/facilityList/FacilityList";
import { adminAccess, userAccess, supplierAccess } from "./useRoleAccess";

export const FacilityRoutes = [
  {
    path: "/facility",
    route: <FacilityList />,
    protectRoutes: true,
    roleGrantAccess: [...adminAccess, ...userAccess, ...supplierAccess],
  },
];
