import { isEmpty } from "lodash";
import { useLocation } from "react-router-dom";

export function deCamelize(
  text: string,
  options: {
    separator?: string;
    hasPreserveConsecutiveUppercase?: boolean;
  } = {}
): string {
  const { separator = "_", hasPreserveConsecutiveUppercase = false } = options;

  if (typeof text !== "string" || typeof separator !== "string") {
    throw new TypeError(
      "The `text` and `separator` arguments should be of type `string`"
    );
  }

  if (text.length < 2) {
    return hasPreserveConsecutiveUppercase ? text : text.toLowerCase();
  }

  const camelToSeparator = hasPreserveConsecutiveUppercase
    ? handlePreserveConsecutiveUppercase
    : regularDeCamelize;

  return camelToSeparator(text, separator);
}

export const getStringInsideBrackets = (input: any) => {
  const regex = /\(([^)]+)\)/;
  const result = input.match(regex);
  return result ? result[1] : null;
}

export function handlePreserveConsecutiveUppercase(
  deCamelize: string,
  separator: string
): string {
  const firstPass = deCamelize.replace(
    /((?<![\p{Uppercase_Letter}\d])[\p{Uppercase_Letter}\d](?![\p{Uppercase_Letter}\d]))/gu,
    ($0) => $0.toLowerCase()
  );

  return firstPass.replace(
    /(\p{Uppercase_Letter}+)(\p{Uppercase_Letter}\p{Lowercase_Letter}+)/gu,
    (_, $1, $2) => $1 + separator + $2.toLowerCase()
  );
}

function regularDeCamelize(deCamelize: string, separator: string): string {
  return deCamelize.replace(
    /([\p{Lowercase_Letter}\d])(\p{Uppercase_Letter})/gu,
    `$1${separator}$2`
  );
}

export function humanize(string: string): string {
  string = deCamelize(string);
  string = string
    .toLowerCase()
    .replace(/[_-]+/g, " ")
    .replace(/\s{2,}/g, " ")
    .trim();
  string = string.charAt(0).toUpperCase() + string.slice(1);

  return string;
}

export const checkAvailableUserCompanyId = (company: any, userData: any) => {
  let getCompanyData = company.filter(
    (e: any) => e.tenantId == userData?.tenantId
  );

  if (getCompanyData.length > 0) {
    return {
      id: getCompanyData[0].id,
      name: getCompanyData[0].title,
      status: true,
    };
  } else {
    return {
      id: null,
      name: null,
      status: false,
    };
  }
};

export const getCompanyLogo = (theme: any) => {
  switch (theme) {
    case "BLUE":
      return require("../assets/images/PoweredbyNature.png");
    default:
      return require("../assets/images/logo.png");
  }
};

export const hideSearchPath = (path: any) => {
  switch (path) {
    case "/auth":
      return false;
    case "/password-reset":
      return false;
    case "/password-forgot":
      return false;
    default:
      return true;
  }
};

export const valueGraterThanZero = (value: any) => {
  if (value > 0) {
    return true;
  } else {
    return false;
  }
};

export const valueIsZero = (value: any) => {
  if (value !== 0) {
    return true;
  } else {
    return false;
  }
};

export const isCheckPercentageIrrigation = (value: any) => {
  if (value > 100) {
    return false;
  } else {
    return true;
  }
};

export const isValueValid = (value: any) => {
  if (value >= 0.1 && value <= 100) {
    return true;
  } else {
    return false;
  }
};

export const isAdminAuthorized = (user: any) => {
  if (["Root Admin", "Group Admin"].includes(user?.userRole[0])) {
    return true;
  }
  return false;
}

export const concatToNewArray = (values: Array<any>, propertyName: string) => {
  return [].concat.apply(
    [],
    values.map((f) => f[propertyName])
  );
};

export const checkAuthorization = (rolePermissions: { [role: string]: string[] }, userRole: string, userPermissions: string[]) => {
  const allowedPermissions = rolePermissions[userRole];
  return allowedPermissions && allowedPermissions.some(permission => userPermissions.includes(permission));
};


export const getCodeFromString = (str: string) => {
  return str.replace(/\s/g, "_").toLowerCase();
}

export function getLastTwentyYears(): any[] {
  const currentYear = new Date().getFullYear();
  const years = [];
  for (let i = 0; i < 21; i++) {
    years.push({
      key: i,
      text: (currentYear - i).toString(),
      value: (currentYear - i).toString()
    });
  }
  return years;
}

export const checkYearValidation = (data: any) => {
  let currentYear = new Date().getFullYear();
  if (data?.length < 4) {
    return false;
  } else if (data?.length == 4) {
    if (currentYear < data) {
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }
};

export const customEmailValidation = (value:any) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+(\.[^\s@]+)*$/;
  if (!emailRegex.test(value)) {
    return "Please enter the valid email";
  }
  return true;
};

export const getPathName = () =>{
    var currentPath = window.location.pathname;
    const pathSegments = currentPath.split("/").filter(Boolean);
    const pathName = pathSegments[pathSegments.length - 1];
    return pathName;
}