import { useState, useEffect } from "react";
import { Button } from "semantic-ui-react";

import "./Switch.scss";

enum switchSize {
  mini = "mini",
  tiny = "tiny",
  small = "small",
  medium = "medium",
  large = "large",
  big = "big",
  huge = "huge",
  massive = "massive",
}

interface switchProps {
  titleOne: string;
  titleTwo: string;
  size?: switchSize;
  onClick: any;
  computer?: number;
  tablet?: number;
  mobile?: number;
  label?: string;
  defaultValue: any;
  customSwitchMainCss?: any;
  titleOneValue?: string;
  titleTwoValue?: string;
  disabled?: any;
}

const active = {
  backgroundColor: "var(--switchActiveColor)",
  color: "#FFFF",
  fontFamily: "ProximaNova",
  fontSize: "12px",
  lineHeight: "12px",
  fontWeight: 600,
  border: "1px solid #267C38",
  height: "42px",
};

const inactive = {
  backgroundColor: "#FFFF",
  color: "#C1C7D0",
  fontFamily: "ProximaNova",
  fontSize: "12px",
  lineHeight: "12px",
  fontWeight: 600,
  border: "1px solid #267C38",
  height: "42px",
};

export const Switch = ({
  label,
  titleOne,
  titleTwo,
  titleOneValue = "",
  titleTwoValue = "",
  size = switchSize.tiny,
  onClick,
  defaultValue,
  customSwitchMainCss,
  disabled = false,
}: switchProps) => {
  const [activeTitle, setActiveTitle] = useState(defaultValue);

  useEffect(() => {
    setActiveTitle(defaultValue);
  }, [defaultValue]);

  const handleClick = (value: string) => {
    setActiveTitle(value);
    onClick(value);
  };

  return (
    <div className={`switchMain ${customSwitchMainCss}`}>
      <label className="label">{label}</label>
      <Button.Group size={size}>
        <Button
          style={activeTitle == titleOneValue ? active : inactive}
          onClick={() => handleClick(titleOneValue)}
          className="buttonCustomSwitch"
          type="button"
          disabled={disabled}
        >
          {titleOne}
        </Button>
        <Button
          style={
            activeTitle == titleTwoValue
              ? { ...active, borderLeft: "#FFFF" }
              : { ...inactive, borderLeft: "#FFFF" }
          }
          onClick={() => handleClick(titleTwoValue)}
          className="buttonCustomSwitch"
          type="button"
          disabled={disabled}
        >
          {titleTwo}
        </Button>
      </Button.Group>
    </div>
  );
};
