import { Grid, Table } from "semantic-ui-react";
import {
  AccordionTitleView,
  CommonTable,
  CustomButton,
  DropDown,
} from "../../../../components";
import "./CultivarForm.scss";
import { InputNumber } from "../../../../components/InputText/InputNumber";
import { MdDeleteOutline, MdModeEditOutline } from "react-icons/md";
import { isEmpty } from "lodash";

const FuelUse = ({
  energySource,
  energySourceData,
  onEditHandler,
  control,
  setVisibleDeleteModal,
  setDeleteValue,
  setEnergySource,
  energyUnitType,
  ENERGY_UNIT_TYPE,
  errors,
  cultivationType,
  setEnergyUnitType,
  EnergySourceData,
  onEdit,
  editTableData,
  cancelEdit,
  onAddClickFuelUseDetails,
  fuelUseData,
  currentOriginalFormData,
}: any) => {

  const fuelUse = currentOriginalFormData?.cultivationField?.fuelUse || [];

  return cultivationType === "Supplier" ? (
    <div>
      <AccordionTitleView
        accordionEnabled={false}
        title={"Fuel Use Details"}
        plusIcon={false}
      />
      <div>
        <Grid>
          <Grid.Row divided>
            <Grid.Column computer={8} tablet={16} mobile={16}>
              {fuelUse.length > 0 && (
                <Grid.Column
                  computer={16}
                  tablet={16}
                  mobile={16}
                  className="pesticideTable"
                >
                  <CommonTable
                    tableHeaderData={EnergySourceData.filter(
                      (e: any) => e.name !== ""
                    )}
                    className="pesticideTable"
                    isSimulateView
                  >
                    {fuelUse?.map((item: any, index: any) => {
                      return (
                        <Table.Row key={`pesticide_${item?.id}`}>
                          <Table.Cell>
                            <p>
                              {energySourceData?.find(
                                (e: any) => e?.value == item?.energySource
                              )?.text ?? ""}
                            </p>
                          </Table.Cell>
                          <Table.Cell>
                            <p>{item?.energyUsed ?? ""}</p>
                          </Table.Cell>
                          <Table.Cell>
                            <p>
                              {ENERGY_UNIT_TYPE?.find(
                                (e: any) => e?.key == item?.energyUnit
                              )?.text ?? ""}
                            </p>
                          </Table.Cell>
                        </Table.Row>
                      );
                    })}
                  </CommonTable>
                </Grid.Column>
              )}
            </Grid.Column>
            <Grid.Column computer={8} tablet={16} mobile={16}>
              <Grid>
                <Grid.Column computer={4} tablet={8} mobile={16}>
                  <DropDown
                    labelName={"Energy Source"}
                    placeholder="Energy Source"
                    customGridColumn={"customDropDownGrid"}
                    defaultValue={energySource}
                    currentData={energySourceData}
                    handleChangeState={(e: any, { value }: any) => {
                      setEnergySource(value);
                    }}
                    search
                    key={"energy_source"}
                  />
                </Grid.Column>
                <Grid.Column computer={4} tablet={6} mobile={16}>
                  <InputNumber
                    transform={{
                      input: (value: any) =>
                        isNaN(value) || value === 0 ? "" : value.toString(),
                      output: (e: any) => {
                        return e;
                      },
                    }}
                    control={control}
                    labelName={"Energy Used"}
                    placeholder="Energy Used"
                    name="energyUsed"
                    errors={errors?.energyUsed}
                  />
                </Grid.Column>
                <Grid.Column computer={5} tablet={8} mobile={16}>
                  <DropDown
                    labelName={"Energy Unit type"}
                    placeholder="Energy Unit type"
                    customGridColumn={"customDropDownGrid"}
                    defaultValue={energyUnitType}
                    currentData={ENERGY_UNIT_TYPE}
                    handleChangeState={(e: any, { value }: any) => {
                      setEnergyUnitType(value);
                    }}
                    search
                    key={"energy_unit"}
                  />
                </Grid.Column>
                <Grid.Column computer={2} tablet={2} mobile={16}>
                  {onEdit ? (
                    <>
                      <Grid>
                        <Grid.Column computer={8}>
                          <CustomButton
                            theme="green"
                            title="edit"
                            customColumnStyle={"plusButton"}
                            onClick={() => editTableData("FuelUsed")}
                          />
                        </Grid.Column>

                        <Grid.Column computer={8}>
                          <CustomButton
                            theme="green"
                            title="cancel"
                            customColumnStyle={"plusButton"}
                            onClick={() => cancelEdit("FuelUsed")}
                          />
                        </Grid.Column>
                      </Grid>
                    </>
                  ) : (
                    <CustomButton
                      theme="green"
                      icon="plus"
                      disabled={
                        isEmpty(energySource.toString()) ||
                        isEmpty(energyUnitType.toString())
                      }
                      customColumnStyle={"plusButton"}
                      onClick={onAddClickFuelUseDetails}
                    />
                  )}
                </Grid.Column>
              </Grid>

              {fuelUseData?.length > 0 && (
                <Grid.Column
                  computer={16}
                  tablet={16}
                  mobile={16}
                  className="pesticideTable"
                >
                  <CommonTable
                    tableHeaderData={EnergySourceData}
                    className="pesticideTable"
                  >
                    {fuelUseData?.map((item: any, index: any) => {
                      return (
                        <Table.Row key={`pesticide_${item?.id}`}>
                          <Table.Cell>
                            <p>
                              {energySourceData?.find(
                                (e: any) => e?.value == item?.energySource
                              )?.text ?? ""}
                            </p>
                          </Table.Cell>
                          <Table.Cell>
                            <p>{item?.energyUsed ?? ""}</p>
                          </Table.Cell>
                          <Table.Cell>
                            <p>
                              {ENERGY_UNIT_TYPE?.find(
                                (e: any) => e?.key == item?.energyUnit
                              )?.text ?? ""}
                            </p>
                          </Table.Cell>
                          <Table.Cell>
                            <Grid>
                              <Grid.Column computer={8} tablet={16} mobile={16}>
                                <MdModeEditOutline
                                  cursor={"pointer"}
                                  size={24}
                                  color="var(--tableEditIcon)"
                                  onClick={() =>
                                    onEditHandler(item, "FuelUsed", index)
                                  }
                                />
                              </Grid.Column>
                              <Grid.Column computer={8} tablet={16} mobile={16}>
                                <MdDeleteOutline
                                  cursor={"pointer"}
                                  size={24}
                                  color="var(--tableEditIcon)"
                                  onClick={() => {
                                    setVisibleDeleteModal(true);
                                    setDeleteValue({
                                      type: "FuelUsed",
                                      item,
                                      index,
                                    });
                                  }}
                                />
                              </Grid.Column>
                            </Grid>
                          </Table.Cell>
                        </Table.Row>
                      );
                    })}
                  </CommonTable>
                </Grid.Column>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    </div>
  ) : null;
};

export default FuelUse;
