import { Position, MarkerType } from "reactflow";
import type { Node } from "reactflow";

import { nodeCatagories, nodeType } from "../../config/drawingConstants";

// this helper function returns the intersection point
// of the line between the center of the intersectionNode and the target node
export function getNodeIntersection(intersectionNode: any, targetNode: any) {
  // https://math.stackexchange.com/questions/1724792/an-algorithm-for-finding-the-intersection-point-between-a-center-of-vision-and-a
  const {
    width: intersectionNodeWidth,
    height: intersectionNodeHeight,
    positionAbsolute: intersectionNodePosition,
  } = intersectionNode;
  const targetPosition = targetNode.positionAbsolute;

  const w = intersectionNodeWidth / 2;
  const h = intersectionNodeHeight / 2;

  const x2 = intersectionNodePosition.x + w;
  const y2 = intersectionNodePosition.y + h;
  const x1 = targetPosition.x + w;
  const y1 = targetPosition.y + h;

  const xx1 = (x1 - x2) / (2 * w) - (y1 - y2) / (2 * h);
  const yy1 = (x1 - x2) / (2 * w) + (y1 - y2) / (2 * h);
  const a = 1 / (Math.abs(xx1) + Math.abs(yy1));
  const xx3 = a * xx1;
  const yy3 = a * yy1;
  const x = w * (xx3 + yy3) + x2;
  const y = h * (-xx3 + yy3) + y2;

  return { x, y };
}

// returns the position (top,right,bottom or right) passed node compared to the intersection point
function getEdgePosition(node: any, intersectionPoint: any) {
  const n = { ...node.positionAbsolute, ...node };
  const nx = Math.round(n.x);
  const ny = Math.round(n.y);
  const px = Math.round(intersectionPoint.x);
  const py = Math.round(intersectionPoint.y);

  if (px <= nx + 1) {
    return Position.Left;
  }
  if (px >= nx + n.width - 1) {
    return Position.Right;
  }
  if (py <= ny + 1) {
    return Position.Top;
  }
  if (py >= n.y + n.height - 1) {
    return Position.Bottom;
  }

  return Position.Top;
}

// returns the parameters (sx, sy, tx, ty, sourcePos, targetPos) you need to create an edge
export function getEdgeParams(source: any, target: any) {
  const sourceIntersectionPoint = getNodeIntersection(source, target);
  const targetIntersectionPoint = getNodeIntersection(target, source);

  const sourcePos = getEdgePosition(source, sourceIntersectionPoint);
  const targetPos = getEdgePosition(target, targetIntersectionPoint);

  return {
    sx: sourceIntersectionPoint.x,
    sy: sourceIntersectionPoint.y,
    tx: targetIntersectionPoint.x,
    ty: targetIntersectionPoint.y,
    sourcePos,
    targetPos,
  };
}

export function createNodesAndEdges() {
  const nodes = [];
  const edges = [];
  const center = { x: window.innerWidth / 2, y: window.innerHeight / 2 };

  nodes.push({ id: "target", data: { label: "Target" }, position: center });

  for (let i = 0; i < 8; i++) {
    const degrees = i * (360 / 8);
    const radians = degrees * (Math.PI / 180);
    const x = 250 * Math.cos(radians) + center.x;
    const y = 250 * Math.sin(radians) + center.y;

    nodes.push({ id: `${i}`, data: { label: "Source" }, position: { x, y } });

    edges.push({
      id: `edge-${i}`,
      target: "target",
      source: `${i}`,
      type: "special",
      markerEnd: {
        type: MarkerType.Arrow,
      },
    });
  }

  return { nodes, edges };
}

// we have to make sure that parent nodes are rendered before their children
export const sortNodes = (a: Node, b: Node): number => {
  if (a.type === b.type) {
    return 0;
  }

  return a.type !== nodeCatagories.special && b.type === nodeCatagories.special
    ? -1
    : 1;
};

export const getNodePositionInsideParent = (
  node: Partial<Node>,
  groupNode: Node
) => {
  const position = node.position ?? { x: 0, y: 0 };
  const nodeWidth = node.width ?? 0;
  const nodeHeight = node.height ?? 0;
  const groupWidth = groupNode.width ?? 0;
  const groupHeight = groupNode.height ?? 0;

  if (position.x < groupNode.position.x) {
    position.x = 0;
  } else if (position.x + nodeWidth > groupNode.position.x + groupWidth) {
    position.x = groupWidth - nodeWidth;
  } else {
    position.x = position.x - groupNode.position.x;
  }

  if (position.y < groupNode.position.y) {
    position.y = 0;
  } else if (position.y + nodeHeight > groupNode.position.y + groupHeight) {
    position.y = groupHeight - nodeHeight;
  } else {
    position.y = position.y - groupNode.position.y;
  }
  return position;
};

//get cultivation data
const getCultivationData = (
  chartNodes: any,
  chartEdges: any,
  nodeItem: any
) => {
  const getEdgesData = chartEdges.filter((e: any) => e.target === nodeItem.id);
  const getNodesItems = chartNodes.filter(
    (e: any) => e.id === getEdgesData[0]?.source
  );
  return getNodesItems;
};

const farmGateStorageAndTransportDataModalValidation = (
  data: any,
  checkHaveEdges: any
) => {
  return {
    status: checkHaveEdges && data.farmGateId ? true : false,
    data: {
      packagingId: data.packagingId,
      farmGateId: data.farmGateId,
      nodeItem: data.nodeItem,
    },
    errorMessage: !checkHaveEdges
      ? "Please create a connection"
      : `Please create cultivation before create ${
          data.nodeItem?.data.icon
            ? "internal transportation"
            : "internal storage"
        }`,
  };
};

const farmGatePackagingModalValidation = (data: any, checkHaveEdges: any) => {
  return {
    status: checkHaveEdges && data.farmGateId ? true : false,
    data: {
      packagingId: data.packagingId,
      farmGateId: data.farmGateId,
      nodeItem: data.nodeItem,
    },
    errorMessage: !checkHaveEdges
      ? "Please create a connection"
      : "Please create cultivation before create packaging",
  };
};

const farmGateProcessingModalValidation = (
  data: any,
  checkHaveEdges: any,
  chartNodes: any,
  chartEdges: any,
  nodeItem: any,
  customFarmGateId: any
) => {
  let cultivationData = getCultivationData(chartNodes, chartEdges, nodeItem);
  let rawMaterialId =
    cultivationData?.[0]?.data?.reference?.supplierRawMaterialId;
  return {
    status: checkHaveEdges && data.farmGateId ? true : false,
    data: {
      rawMaterialId: rawMaterialId,
      farmGateId: data.farmGateId,
      supplierId: data.supplierId,
      nodeItem: data.nodeItem,
    },
    errorMessage: !checkHaveEdges
      ? "Please create a connection"
      : "Please create cultivation before create Processing",
  };
};

const generalClientModalValidation = (data: any, checkHaveEdges: any) => {
  return {
    status: checkHaveEdges ? true : false,
    data: {
      clientId: data.clientId,
      salesUnitId: data.salesUnitId,
      productId: data.productId,
      nodeItem: data.nodeItem,
    },
    errorMessage: "Please create a connection",
  };
};

const processGateProcessingModalValidation = (
  data: any,
  checkHaveEdges: any,
  chartNodes: any,
  chartEdges: any,
  nodeItem: any,
) => {
  let cultivationData = getCultivationData(chartNodes, chartEdges, nodeItem);
  let rawMaterialId =
    cultivationData?.[0]?.data?.reference?.supplierRawMaterialId;
  return {
    status: data.processGateId && checkHaveEdges ? true : false,
    data: {
      ingredientId: data.ingredientId,
      processGateId: data.processGateId,
      supplierId: data.supplierId,
      nodeItem: data.nodeItem,
      productId: data.productId,
      salesUnitId: data.salesUnitId,
      rawMaterialId:rawMaterialId
    },
    errorMessage: !checkHaveEdges
      ? "Please create a connection"
      : "Please create Process Gate before create a process",
  };
};

const processGateStorageAndTransportDataModalValidation = (
  data: any,
  checkHaveEdges: any
) => {
  return {
    status: data.processGateId && checkHaveEdges ? true : false,
    data: {
      processGateId: data.processGateId,
      nodeItem: data.nodeItem,
    },
    errorMessage: !checkHaveEdges
      ? "Please create a connection"
      : `Please create Process Gate before create ${
          data.nodeItem?.data.icon
            ? "internal transportation"
            : "internal storage"
        }`,
  };
};

const farmGateCultivationModalValidation = (
  data: any,
  checkHaveEdges: any,
  chartNodes: any,
  chartEdges: any,
  nodeItem: any,
  customFarmGateId: any
) => {
  let cultivationData = getCultivationData(chartNodes, chartEdges, nodeItem);
  let rawMaterialId =
    cultivationData?.[0]?.data?.reference?.supplierRawMaterialId;
  return {
    status: checkHaveEdges && rawMaterialId ? true : false,
    data: {
      rawMaterialId: rawMaterialId,
      farmGateId: customFarmGateId,
      nodeItem: nodeItem,
    },
    errorMessage: !checkHaveEdges
      ? "Please create a connection"
      : "Please create Ingredient before create Cultivation",
  };
};

const processGatePackagingModalValidation = (
  data: any,
  checkHaveEdges: any
) => {
  return {
    status: data.processGateId && checkHaveEdges ? true : false,
    data: {
      packagingId: data.packagingId,
      processGateId: data.processGateId,
      nodeItem: data.nodeItem,
    },
    errorMessage: !checkHaveEdges
      ? "Please create a connection"
      : "Please create Farm Gate before create packaging",
  };
};

const externalTransportModalValidation = (
    data: any, 
    checkHaveEdges: any,
  ) => {
  
  return {
    status: checkHaveEdges ? true : false,
    data: {
      nodeItem: data.nodeItem,
    },
    errorMessage: "Please create a connection"
  };
};

//check node modal validation and return data
export const checkNodeModalValidation = (
  nodeItem: any,
  chartNodes: any,
  chartEdges: any,
  setSupplierIngredientId: any
) => {
  const getParentId = nodeItem?.parentNode || null;
  const getNodeId = nodeItem?.id || null;
  const packagingId = nodeItem?.data?.reference?.packagingId || null;
  const getParentData = chartNodes.filter((e: any) => e.id === getParentId);
  const farmGateId = getParentData?.[0]?.data?.reference?.farmGateId || null;
  const ingredientId =
    getParentData?.[0]?.data?.reference?.ingredientId || null;
  const supplierId = getParentData?.[0]?.data?.reference?.supplierId || null;
  const customFarmGateId = nodeItem?.data?.reference?.farmGateId || null;
  const processGateId =
    getParentData?.[0]?.data?.reference?.processGateId || null;
  const checkHaveEdges = chartEdges.some((e: any) => e.target === getNodeId);
  const externalTransportId =
    nodeItem?.data?.reference?.externalTransportId || null;
  const clientId = nodeItem?.data?.reference?.clientId || null;

  let commonPassData = {
    packagingId: packagingId,
    farmGateId: farmGateId,
    nodeItem: nodeItem,
    supplierId: supplierId,
    ingredientId: ingredientId,
    processGateId: processGateId,
    externalTransportId: externalTransportId,
    setSupplierIngredientId: setSupplierIngredientId,
    clientId: clientId,
  };

  switch (nodeItem?.data.icon) {
    case "2":
      return externalTransportModalValidation(
        commonPassData, 
        checkHaveEdges
        );
    case "7":
    case "9":
      return farmGateStorageAndTransportDataModalValidation(
        commonPassData,
        checkHaveEdges
      );
    case "8":
      return farmGatePackagingModalValidation(commonPassData, checkHaveEdges);
    case "6":
      return farmGateProcessingModalValidation(
        commonPassData,
        checkHaveEdges,
        chartNodes,
        chartEdges,
        nodeItem,
        customFarmGateId
      );
    case "5":
      return farmGateCultivationModalValidation(
        commonPassData,
        checkHaveEdges,
        chartNodes,
        chartEdges,
        nodeItem,
        customFarmGateId
      );
    case "4":
      return generalClientModalValidation(commonPassData, checkHaveEdges);
    case "11":
      return processGatePackagingModalValidation(
        commonPassData,
        checkHaveEdges
      );
    case "10":
      return processGateProcessingModalValidation(
        commonPassData,
        checkHaveEdges,
        chartNodes,
        chartEdges,
        nodeItem,
      );
    case "12":
    case "13":
      return processGateStorageAndTransportDataModalValidation(
        commonPassData,
        checkHaveEdges
      );
    default:
      return {
        status: true,
        data: {},
        errorMessage: "",
      };
  }
};

//check chart node edges validation
export const checkEdgeValidation = (
  connection: any,
  chartEdges: any,
  chartNodes: any
) => {
  let checkSourceHaveEdge = false;
  let getConnectionSourceId = connection.source;
  let getSourceData = chartNodes.find(
    (e: any) => e.id === getConnectionSourceId
  );

  if (getSourceData?.data.icon === "1") {
    checkSourceHaveEdge = true;
  } else {
    checkSourceHaveEdge = chartEdges.some(
      (e: any) => e.target === getConnectionSourceId
    );
  }
  return checkSourceHaveEdge;
};

const cultivarValidation = (
  node: Node,
  group: Node | undefined,
  sameNode: Node[]
) => {
  let valid = true;
  let message = "";
  if (!group) {
    valid = false;
    message = "Cultivation can only be use inside a Farm gate";
  }
  if (
    node.parentNode &&
    node.parentNode === group?.id &&
    group?.type !== nodeCatagories.farmGate
  ) {
    valid = false;
    message =
      "Cultivar node must be use in farmGate group can not use in other groups!";
  }
  if (
    sameNode.some((sn) => sn.id !== node.id) &&
    group?.type === nodeCatagories.farmGate
  ) {
    valid = false;
    message = "One Farm Gate can have only one cultivation";
  }
  return {
    valid: valid,
    message: message,
  };
};

const ingredientValidation = (node: Node, group: Node | undefined) => {
  let valid = true;
  let message = "";
  if (group) {
    valid = false;
    message = "Ingredient items can not use inside any groups!";
  }
  return {
    valid: valid,
    message: message,
  };
};

const externalTransportValidation = (node: Node, group: Node | undefined) => {
  let valid = true;
  let message = "";
  if (group) {
    valid = false;
    message = "External Transport items can not use inside any groups!";
  }
  return {
    valid: valid,
    message: message,
  };
};

const warehouseValidation = (node: Node, group: Node | undefined) => {
  let valid = true;
  let message = "";
  if (group) {
    valid = false;
    message = "External Warehouse items can not use inside any groups!";
  }
  return {
    valid: valid,
    message: message,
  };
};

const clientValidation = (node: Node, group: Node | undefined) => {
  let valid = true;
  let message = "";
  if (group) {
    valid = false;
    message = "Client items can not use inside any groups!";
  }
  return {
    valid: valid,
    message: message,
  };
};

const farmGateInternalTransportValidation = (
  node: Node,
  group: Node | undefined
) => {
  let valid = true;
  let message = "";
  if (group?.type !== nodeCatagories.farmGate) {
    valid = false;
    message = "Farm gate Internal Transport must only use in Farm gate group!";
  }
  return {
    valid: valid,
    message: message,
  };
};

const farmGateInternalStorageValidation = (
  node: Node,
  group: Node | undefined
) => {
  let valid = true;
  let message = "";
  if (group?.type !== nodeCatagories.farmGate) {
    valid = false;
    message = "Farm gate Internal Storage must only use in Farm gate group!";
  }
  return {
    valid: valid,
    message: message,
  };
};

const farmGateProcessValidation = (node: Node, group: Node | undefined) => {
  let valid = true;
  let message = "";
  if (group?.type !== nodeCatagories.farmGate) {
    valid = false;
    message = "Farm gate Process must only use in Farm gate group!";
  }
  return {
    valid: valid,
    message: message,
  };
};

const farmGatePackingValidation = (node: Node, group: Node | undefined) => {
  let valid = true;
  let message = "";
  if (group?.type !== nodeCatagories.farmGate) {
    valid = false;
    message = "Farm gate packaging must only use in Farm gate group!";
  }
  return {
    valid: valid,
    message: message,
  };
};

const processGateInternalTransportValidation = (
  node: Node,
  group: Node | undefined
) => {
  let valid = true;
  let message = "";
  if (group?.type !== nodeCatagories.processGate) {
    valid = false;
    message =
      "Process Gate Internal Transport must only use in Process Gate group!";
  }
  return {
    valid: valid,
    message: message,
  };
};

const processGateInternalStorageValidation = (
  node: Node,
  group: Node | undefined
) => {
  let valid = true;
  let message = "";
  if (group?.type !== nodeCatagories.processGate) {
    valid = false;
    message =
      "Process Gate Internal Storage must only use in Process Gate group!";
  }
  return {
    valid: valid,
    message: message,
  };
};

const processGatePackingValidation = (node: Node, group: Node | undefined) => {
  let valid = true;
  let message = "";
  if (group?.type !== nodeCatagories.processGate) {
    valid = false;
    message = "Process Gate Packing must only use in Process Gate group!";
  }
  return {
    valid: valid,
    message: message,
  };
};

const processGateProcessingValidation = (
  node: Node,
  group: Node | undefined
) => {
  let valid = true;
  let message = "";
  if (group?.type !== nodeCatagories.processGate) {
    valid = false;
    message = "Process Gate Processing must only use in Process Gate group!";
  }
  return {
    valid: valid,
    message: message,
  };
};

export const nodeValidationChecker = (
  node: Node,
  group: Node | undefined,
  chartNodes: Node[]
) => {
  let isValid = true;
  let validationMessage = "";
  switch (node?.data.icon) {
    case nodeType.Ingredients:
      const ingredientResult = ingredientValidation(node, group);
      isValid = ingredientResult.valid;
      validationMessage = ingredientResult.message;
      break;
    case nodeType.FarmGateInternalTransport:
      const fgITransportResult = farmGateInternalTransportValidation(
        node,
        group
      );
      isValid = fgITransportResult.valid;
      validationMessage = fgITransportResult.message;
      break;
    case nodeType.Transportation:
      const externalTransportResult = externalTransportValidation(node, group);
      isValid = externalTransportResult.valid;
      validationMessage = externalTransportResult.message;
      break;
    case nodeType.FarmGateInternalStorage:
      const fgIStorageResult = farmGateInternalStorageValidation(node, group);
      isValid = fgIStorageResult.valid;
      validationMessage = fgIStorageResult.message;
      break;
    case nodeType.FarmGateProcess:
      const fgProcessResult = farmGateProcessValidation(node, group);
      isValid = fgProcessResult.valid;
      validationMessage = fgProcessResult.message;
      break;
    case nodeType.FarmGatePacking:
      const fgPackingResult = farmGatePackingValidation(node, group);
      isValid = fgPackingResult.valid;
      validationMessage = fgPackingResult.message;
      break;
    case nodeType.Client:
      const clientResult = clientValidation(node, group);
      isValid = clientResult.valid;
      validationMessage = clientResult.message;
      break;
    case nodeType.Warehouse:
      const warehouseResult = warehouseValidation(node, group);
      isValid = warehouseResult.valid;
      validationMessage = warehouseResult.message;
      break;
    case nodeType.Cultivar:
      const sameNodes = chartNodes.filter(
        (cn) =>
          cn.parentNode &&
          cn.parentNode === group?.id &&
          cn?.data.icon === nodeType.Cultivar
      );
      const cultivarValidationResult = cultivarValidation(
        node,
        group,
        sameNodes
      );
      isValid = cultivarValidationResult.valid;
      validationMessage = cultivarValidationResult.message;
      break;
    case nodeType.ProcessGateInternalTransport:
      const pgITransportResult = processGateInternalTransportValidation(
        node,
        group
      );
      isValid = pgITransportResult.valid;
      validationMessage = pgITransportResult.message;
      break;
    case nodeType.ProcessGateInternalStorage:
      const pgIStorageResult = processGateInternalStorageValidation(
        node,
        group
      );
      isValid = pgIStorageResult.valid;
      validationMessage = pgIStorageResult.message;
      break;
    case nodeType.ProcessGatePacking:
      const pgPackingResult = processGatePackingValidation(node, group);
      isValid = pgPackingResult.valid;
      validationMessage = pgPackingResult.message;
      break;
    case nodeType.ProcessGateProcessing:
      const pgProcessingResult = processGateProcessingValidation(node, group);
      isValid = pgProcessingResult.valid;
      validationMessage = pgProcessingResult.message;
      break;
  }
  return {
    isValid: isValid,
    validationMessage: validationMessage,
  };
};
