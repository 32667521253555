import { memo, useEffect } from "react";
import { Grid, Dropdown } from "semantic-ui-react";
import { useLocation } from "react-router-dom";

import logo from "../../assets/images/logo.png";
import MenuIcon from "../../assets/images/menuView.png";
import { SearchValue } from "../SearchValue/SearchValue";

import "./Header.scss";
import { COMPANY_LOGO } from "../../config/constants";
import { hideSearchPath } from "../../utils/utils";

export const HeaderMobileView = memo(
  ({
    options,
    userImage,
    navigate,
    modalStatue,
    company,
    getSearchValue,
    loadCompanyData,
    loadCompanyToken,
    searchCompany,
  }: any) => {
    const pathname = window.location.pathname;

    return (
      <div className="mainContentMobile">
        <Grid centered>
          <Grid.Column
            mobile={8}
            tablet={8}
            only="tablet mobile"
            className="rightContent "
            onClick={() => navigate("/")}
          >
            <img src={COMPANY_LOGO} alt="logo" className="logo" />
          </Grid.Column>
          <Grid.Column
            mobile={8}
            tablet={8}
            only="tablet mobile"
            textAlign="right"
            className="removePadding"
          >
            {hideSearchPath(pathname) ? (
              <div className="searchDiv">
                <SearchValue
                  searchDataValues={company}
                  checkOther={() => {}}
                  searchByApi={true}
                  defaultValue={localStorage.getItem("companyName")}
                  selectDetails={getSearchValue}
                  disable={loadCompanyData || loadCompanyToken}
                  searchValueData={(key: any) => searchCompany(key)}
                />
              </div>
            ) : null}
            <div className="userDiv">
              <Dropdown
                trigger={userImage}
                options={options}
                pointing="top left"
                icon={null}
              />
            </div>
            <div onClick={() => modalStatue()}>
              <img src={MenuIcon} alt="logo" className="logoMenu" />
            </div>
          </Grid.Column>
        </Grid>
      </div>
    );
  }
);
