const TOKEN = "IdToken";
const COMPANY_TOKEN = "CompanyToken";
const TENANT_ID = "tenantId";
const USER_TENANT_ID = "userTenantId";
const REFRESH_TOKEN = "refreshToken";
const TOKEN_EXPIRED_IN = "tokenExpiredIn";
const USER_ROLES = "userRoles";
const NEW_DRAWING_ITEM = "newDrawingItem";
const USER_PERMISSIONS = "userPermissions";
const USER_ID = "userId";

// Original token
export const getTokenInLocal = () =>
  localStorage.getItem(COMPANY_TOKEN)
    ? localStorage.getItem(COMPANY_TOKEN)
    : localStorage.getItem(TOKEN);

// Original token
export const getRefreshTokenInLocal = () => {
  return localStorage.getItem(REFRESH_TOKEN);
};

export const getNewDaringItemStatus = () => {
  return localStorage.getItem(NEW_DRAWING_ITEM);
};

export const removeNewDrawingItemStatus = () => {
  return localStorage.removeItem(NEW_DRAWING_ITEM);
};

export const saveNewDrawingItem = (status: string) =>
  localStorage.setItem(NEW_DRAWING_ITEM, status);

// Original token company
export const getTokenInForCompanyLocal = () => localStorage.getItem(TOKEN);

export const getUserPermissionsInLocal = () => {
  const userRoles = localStorage.getItem(USER_PERMISSIONS) || "[]";
  return JSON.parse(userRoles);
};

export const saveUserPermissionsInLocal = (permissions: [any]) =>
  localStorage.setItem(USER_ROLES, JSON.stringify(permissions));


export const getUserRolesInLocal = () => {
  const userRoles = localStorage.getItem(USER_ROLES) || "[]";
  return JSON.parse(userRoles);
};
export const saveTokenInLocal = (userToken: string) =>
  localStorage.setItem(TOKEN, userToken);

export const saveUserRoleInLocal = (userToken: string) =>
  localStorage.setItem(USER_ROLES, userToken);

export const getUserIdInLocal = () => {
  return localStorage.getItem(USER_ID);
};
export const saveUserIdInLocal = (userId: string) =>
  localStorage.setItem(USER_ID, userId);

export const saveRefreshTokenInLocal = (refreshToken: string) =>
  localStorage.setItem(REFRESH_TOKEN, refreshToken);

export const saveTokenExpiration = (expiresIn: number) => {
  localStorage.setItem(TOKEN_EXPIRED_IN, expiresIn.toString());
};
export const geTokenExpiration = () => {
  return localStorage.getItem(TOKEN_EXPIRED_IN);
};

export const saveCompanyTokenInLocal = (companyToken: string) =>
  localStorage.setItem(COMPANY_TOKEN, companyToken);

export const saveCompanyTenantId = (tenantId: string) =>
  localStorage.setItem(TENANT_ID, tenantId);

export const saveUserTenantId = (tenantId: string) =>
  localStorage.setItem(USER_TENANT_ID, tenantId);

export const getUserTenantId = () => localStorage.getItem(USER_TENANT_ID);

//check token is available
export const checkAuthTokenAvailable = () => {
  return localStorage.getItem(TOKEN);
};

//remove all tokens
export const removeTokens = async () => {
  localStorage.removeItem(TOKEN);
  localStorage.removeItem(COMPANY_TOKEN);
  localStorage.removeItem("companyName");
  localStorage.removeItem(REFRESH_TOKEN);
  localStorage.removeItem(TENANT_ID);
  localStorage.removeItem(TOKEN_EXPIRED_IN);
  localStorage.removeItem(USER_TENANT_ID);
  localStorage.removeItem(USER_ROLES);
  localStorage.removeItem(NEW_DRAWING_ITEM);
};

// Tenant ID
export const getTenantId = () => localStorage.getItem(TENANT_ID);
export const saveTenantId = (userToken: string) =>
  localStorage.setItem(TENANT_ID, userToken);
