import { useMutation, useQuery } from "@tanstack/react-query";
import { ApiServiceErr, axiosApi, MutOptions } from "../apiService";

import { successMessage } from "../../helpers/ErrorHandler";

export const usePostSimulationProcessGateRecipeData = () => {
  return useMutation<string, ApiServiceErr, any>(async (data) => {
    const response = await axiosApi.post(
      `/${data.type}/recipe/${data?.processId}`,
      data
    );
    return response.data;
  });
};
export const useProcessCalculateData = (opt?: MutOptions<string>) =>
  useMutation<string, ApiServiceErr, any>(async (id) => {
    const response = await axiosApi.put(`/process-module/queue/${id}`);
    successMessage(response.data);
    return response.data;
  }, opt);

export const usePostSimulationProcessGateInternalTransportData = (
  data?: any
) => {
  return useMutation<string, ApiServiceErr, any>(async (data) => {
    const response = await axiosApi.post(
      `/process-gate-simulation/internal-transport?processGateId=${data?.processGateId}`,
      data?.transportData
    );
    return response.data;
  });
};

export const usePutSimulationProcessGateInternalTransportData = (
  data?: any
) => {
  return useMutation<string, ApiServiceErr, any>(async (data) => {
    const response = await axiosApi.put(
      `/process-gate-simulation/internal-transport/${data?.internalTransportId}?processGateId=${data?.processGateId}`,
      data?.transportData
    );
    return response.data;
  });
};

export const useDeleteSimulationProcessGateInternalTransportData = (
  data?: any
) => {
  return useMutation<string, ApiServiceErr, any>(async (data) => {
    const response = await axiosApi.delete(
      `/process-gate-simulation/internal-transport/${data?.internalTransportId}?processGateId=${data?.processGateId}`
    );
    return response.data;
  });
};

export const useGetSimulationProcessGateInternalTransportById = (
  processGateInternalTransportId: any
) =>
  useQuery(
    ["getSimulationProcessGateInternalTransportById"],
    async () => {
      const response = await axiosApi.get(
        `/process-gate-simulation/internal-transport/${processGateInternalTransportId}`
      );
      return response?.data;
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!processGateInternalTransportId,
    }
  );
