import * as React from "react";
import { getPathName } from "../../utils/utils";
const ProductIcon = (props: any) => {
  const pathName = getPathName();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={36}
      height={36}
      fill="none"
      {...props}
    >
      <path
        fill={
          pathName == "product"
            ? "var(--menuBarIconActive)"
            : "var(--menuBarIconDeactive)"
        }
        d="M23.625.035 36 6.223V20.02l-2.25-1.125V8.736l-9 4.5v4.535l-2.25 1.125v-5.66l-9-4.5v3.99l-2.25-1.124v-5.38L23.625.036Zm0 11.25 3.111-1.564-8.138-4.659-3.71 1.864 8.737 4.36Zm5.537-2.76 3.2-1.6-8.737-4.376-2.62 1.318 8.157 4.658ZM20.25 20.021 18 21.146v-.017l-6.75 3.375v7.998L18 29.109v2.532l-7.875 3.937L0 30.498V18.615l10.125-5.062 10.125 5.062v1.407ZM9 32.502v-7.998l-6.75-3.375v7.98L9 32.502Zm1.125-9.95 6.486-3.234-6.486-3.252-6.486 3.252 6.486 3.235Zm10.125-.017 7.875-3.937L36 22.535V31.8l-7.875 3.937L20.25 31.8v-9.264ZM27 32.66V27.3l-4.5-2.25v5.361l4.5 2.25Zm6.75-2.25V25.05l-4.5 2.25v5.361l4.5-2.25Zm-5.625-5.062 4.236-2.127-4.236-2.11-4.236 2.11 4.236 2.127Z"
      />
    </svg>
  );
};
export default ProductIcon;
