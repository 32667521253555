import React, {
  useState,
  useCallback,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { Search } from "semantic-ui-react";
import _, { isEmpty } from "lodash";

import "./searchValue.scss";
import { useOnOutsideClick } from "../../hooks/outSideClick";

interface HandleValidation {
  checkValidation(): void;
}

interface OtherProps extends React.ComponentPropsWithoutRef<"button"> {
  placeholder?: string;
  title?: string;
  checkOther?: any;
  customLabelText?: any;
  selectDetails?: any;
  defaultValue?: any;
  searchDataValues?: any;
  icon?: any;
  validationMessage?: any;
  disable?: any;
  addCustomObject?: any;
  customData?: any;
  searchByApi?: any;
  searchValueData?: any;
  customMainView?:any
}

export const SearchValue = forwardRef<HandleValidation, OtherProps>(
  (props, ref) => {
    const {
      placeholder,
      title,
      customLabelText,
      selectDetails,
      defaultValue,
      searchDataValues,
      icon = "triangle down",
      validationMessage,
      checkOther,
      disable = false,
      addCustomObject = false,
      customData = {},
      searchByApi = false,
      searchValueData = () => {},
      customMainView
    } = props;

    useImperativeHandle(ref, () => ({
      checkValidation() {
        if (_.isEmpty(searchValue)) {
          setValidateData(true);
          checkOther(true);
        } else {
          checkOther(false);
          setValidateData(false);
        }
      },
    }));

    const [searchData, setSearchData] = useState(searchDataValues);
    const [openState, setOpenState] = useState(false);

    const [validateData, setValidateData] = useState(false);
    const [searchValue, setSearchValue] = useState(
      !isEmpty(defaultValue) ? defaultValue : ""
    );
    const [loadingSearch, setLoading] = useState(false);
    const { innerBorderRef } = useOnOutsideClick(() => setOpenState(false));

    useEffect(() => {
      defaultValueGetter(defaultValue);
    }, [defaultValue]);

    useEffect(() => {
      setSearchData(searchDataValues);
    }, [searchDataValues]);

    const defaultValueGetter = (defValue: string) => {
      setSearchValue(defValue);
    };

    const groupData = (data: any) => {
      return data.reduce(function (acc: any, obj: any) {
        acc.push({
          title: obj.title,
          id: obj?.id,
          other: obj,
        });
        return acc;
      }, []);
    };
    

    const filterDataFromApi = (searchQuery: any) => {
      setLoading(true);
      if (searchByApi) {
        searchValueData(searchQuery);
      } else {
        const re = new RegExp(_.escapeRegExp(searchQuery), "i");
        let searchResult = _.filter(searchDataValues, (res: any) => {
          return re.test(res?.["title"]); //locality
        });
        let getData = groupData(searchResult);
        setSearchData(getData);
      }
      setLoading(false);
    };

    const getAllData = () => {
      setLoading(true);
      setOpenState(true);
      let getData = groupData(searchDataValues);
      setSearchData(getData);
      setLoading(false);
    };

    const handleSearchChange = useCallback((e: any, data: any) => {
      if (!disable) {
        setValidateData(true);
        checkOther(true);
        setOpenState(true);
        setSearchValue(data.value);
        if (data?.value?.split("").length > 1) {
          filterDataFromApi(data?.value);
        }
      }
    }, []);
    
    return (
      <div className={`dataSearchMain ${customMainView}`} ref={innerBorderRef}>
        <label className={`dateLabel ${customLabelText}`}>{title}</label>
        <Search
          loading={loadingSearch}
          onSearchChange={handleSearchChange}
          icon={icon}
          results={addCustomObject ? [customData, ...searchData] : searchData}
          value={searchValue}
          placeholder={placeholder}
          onResultSelect={(e, data) => {
            setSearchValue(data.result.title);
            selectDetails(data);
            setValidateData(false);
            checkOther(false);
            setOpenState(false);
          }}
          onFocus={() => getAllData()}
          open={disable ? false : openState}
        />
        {validateData && (
          <span className={`validationData`}>{validationMessage}</span>
        )}
      </div>
    );
  }
);
