import React, { forwardRef, useState, useImperativeHandle } from "react";
import { Grid } from "semantic-ui-react";
import isEmpty from "lodash/isEmpty";
import "./inputText.scss";
import _ from "lodash";

interface HandleValidation {
  checkCustomRequired(data: any): void;
}

interface OtherProps extends React.ComponentPropsWithoutRef<"button"> {
  computer?: any;
  tablet?: any;
  mobile?: any;
  name?: any;
  register?: any;
  errors?: any;
  required?: any;
  placeholder?: any;
  labelName?: any;
  errorMessage?: any;
  customLabelText?: any;
  customGridMain?: any;
  customGridColumn?: any;
  customText?: any;
  customErrorText?: any;
  emptyCheck?: any;
  availableValueCheck?: any;
  checkValue?: any;
  defaultValues?: any;
  validateHandle?: any;
  disabled?: any;
  pattern?: any;
  onChangeFunction?: any;
  onFocus?: any;
  onBlur?: any;
  customInputValidation?: any;
  type?: any;
  valueAsNumber?: any;
  minNumber?: any;
  step?: any;
  id?: any;
  lableName?: any;
  control?: any;
  customRequired?: any;
}

export const InputText = forwardRef<HandleValidation, OtherProps>(
  (props, ref) => {
    const {
      computer = undefined,
      tablet = undefined,
      mobile = undefined,
      name,
      register = () => {},
      errors,
      required,
      placeholder,
      labelName,
      errorMessage,
      customLabelText,
      customGridMain,
      customGridColumn,
      customText,
      customErrorText,
      emptyCheck,
      availableValueCheck,
      checkValue = false,
      defaultValues,
      validateHandle = () => {},
      disabled = false,
      pattern = null,
      onChangeFunction = () => {},
      onFocus = () => {},
      onBlur = () => {},
      customInputValidation = false,
      type = "text",
      valueAsNumber = false,
      minNumber = -1000,
      step,
      id,
      customRequired,
    } = props;

    const [customValidation, setCustomValidation] = useState(false);
    let errorViewStyle = "textInputFormError";
    let errorBottomStyle = "textInputError";

    const handleOnChangeFunction = (e: any) => {
      if (
        e.target.value.charAt(0) == 0 &&
        e.target.value.length > 1 &&
        [...e.target.value][1] != "." &&
        valueAsNumber
      ) {
        e.target.value = e.target.value.slice(1, e.target.value.length);
      }
      onChangeFunction(e);
    };

    useImperativeHandle(ref, () => ({
      checkCustomRequired(data: any) {
        if (_.isEmpty(data) && customRequired) {
          setCustomValidation(true);
        } else {
          setCustomValidation(false);
        }
      },
    }));

    //check error
    const checkError = (errors: any) => {
      if (errors) {
        errorViewStyle = "textInputFormError";
        errorBottomStyle = "textInputError";
        return true;
      } else if (customValidation) {
        errorViewStyle = "textInputFormCustomError";
        errorBottomStyle = "textInputCustomError";
        return true;
      } else {
        errorViewStyle = "textInputFormError";
        errorBottomStyle = "textInputError";
        return false;
      }
    };

    //check error message
    const checkErrorMessage = (errors: any) => {
      switch (errors.type) {
        case "required":
          return errorMessage;
        default:
          return errors.message;
      }
    };

    return (
      <>
        <label className={`inputTextLable ${customLabelText}`}>
          {labelName}
        </label>
        <Grid className={`${customGridMain}`}>
          <Grid.Column
            computer={computer}
            tablet={tablet}
            mobile={mobile}
            className={`inputTextContanier ${customGridColumn}`}
          >
            {customInputValidation ? (
              checkValue ? (
                (availableValueCheck || isEmpty(emptyCheck)) && (
                  <input
                    id={id}
                    className={
                      checkError(errors)
                        ? `${errorViewStyle} ${customText}`
                        : `textInputFormText ${customText}`
                    }
                    autoComplete="off"
                    placeholder={placeholder}
                    disabled={disabled}
                    defaultValue={defaultValues}
                    {...register(name, {
                      onChange: (e: any) => handleOnChangeFunction(e),
                      required: required,
                      validate: validateHandle,
                      valueAsNumber: valueAsNumber,
                    })}
                    onFocus={onFocus}
                    pattern={pattern}
                    onBlur={onBlur}
                    type={type}
                    min={minNumber}
                    step={step}
                  />
                )
              ) : (
                <input
                  id={id}
                  className={
                    checkError(errors)
                      ? `${errorViewStyle} ${customText}`
                      : `textInputFormText ${customText}`
                  }
                  autoComplete="off"
                  placeholder={placeholder}
                  defaultValue={defaultValues}
                  disabled={disabled}
                  {...register(name, {
                    onChange: (e: any) => handleOnChangeFunction(e),
                    required: required,
                    validate: validateHandle,
                    valueAsNumber: valueAsNumber,
                  })}
                  onFocus={onFocus}
                  pattern={pattern}
                  onBlur={onBlur}
                  type={type}
                  min={minNumber}
                  step={step}
                />
              )
            ) : checkValue ? (
              (availableValueCheck || isEmpty(emptyCheck)) && (
                <input
                  id={id}
                  className={
                    checkError(errors)
                      ? `${errorViewStyle} ${customText}`
                      : `textInputFormText ${customText}`
                  }
                  autoComplete="off"
                  placeholder={placeholder}
                  defaultValue={defaultValues}
                  {...register(name, {
                    required: required,
                    onChange: (e: any) => handleOnChangeFunction(e),
                    validate: validateHandle,
                    valueAsNumber: valueAsNumber,
                  })}
                  onFocus={onFocus}
                  onBlur={onBlur}
                  pattern={pattern}
                  type={type}
                  min={minNumber}
                  step={step}
                />
              )
            ) : (
              <input
                id={id}
                className={
                  checkError(errors)
                    ? `${errorViewStyle} ${customText}`
                    : `textInputFormText ${customText}`
                }
                autoComplete="off"
                placeholder={placeholder}
                defaultValue={defaultValues}
                disabled={disabled}
                {...register(name, {
                  required: required,
                  onChange: (e: any) => handleOnChangeFunction(e),
                  validate: validateHandle,
                  valueAsNumber: valueAsNumber,
                })}
                onFocus={onFocus}
                onBlur={onBlur}
                pattern={pattern}
                type={type}
                min={minNumber}
                step={step}
              />
            )}

            {checkError(errors) && (
              <span className={`${errorBottomStyle} ${customErrorText}`}>
                {customValidation && customRequired
                  ? errorMessage
                  : checkErrorMessage(errors)}
              </span>
            )}
          </Grid.Column>
        </Grid>
      </>
    );
  }
);
