import { List, ListItem, Message, Modal } from "semantic-ui-react";

interface DrawingErrorsModalProps {
  errors: string[];
  setPutDataToQueueErrors: (errors: string[]) => void;
}

// DrawingErrorsModal component
const DrawingErrorsModal = ({
  errors,
  setPutDataToQueueErrors,
}: DrawingErrorsModalProps) => {
  const onClose = () => {
    setPutDataToQueueErrors([]);
  };

  return (
    <Modal
      centered={false}
      size={"small"}
      dimmer="blurring"
      open={errors.length > 0}
      onClose={onClose}
      closeIcon
    >
      <Modal.Header>Mapping Errors!</Modal.Header>
      <Modal.Content>
        <List divided>
          {errors.map((error: string, index: number) => (
            <ListItem key={`error-${index}`}>
              <Message color="red">{error}</Message>
            </ListItem>
          ))}
        </List>
      </Modal.Content>
    </Modal>
  );
};

export default DrawingErrorsModal;
