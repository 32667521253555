import { useEffect, useState, useMemo } from "react";
import { Dimmer, Grid, Loader, Table } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";

import { CustomButton, CommonTable, TitleBar } from "../../../components";
import { CompanyTableCol, UserTableCol } from "../../../config/constants";
import { TbFileSearch } from "react-icons/tb";
import { MdModeEditOutline, MdDeleteOutline } from "react-icons/md";

import ConfirmModal from "../../../components/confirmViewModal/ConfirmModal";
import CompanyRegisterModal from "../companyRegister/CompanyRegisterModal";
import CompanyDetailsModal from "../companyDetails/CompanyDetailsModal";
import CompanyEditModal from "../companyEdit/CompanyEditModal";
import {
  useDeleteCompanyData,
  useGetCompanyDetails,
} from "../../../api/company";
import { successMessage } from "../../../helpers/ErrorHandler";

// pick from the local storage

const permissions = {
  name: "Company",
  isAuthorized: true,
  actions: [
    {
      name: "companyRegister",
      isAuthorized: true,
    },
    {
      name: "companyDetails",
      isAuthorized: true,
    },
    {
      name: "companyEdit",
      isAuthorized: true,
    },
    {
      name: "companyDelete",
      isAuthorized: true,
    },
  ],
};

const CompanyList = () => {
  const [visibleModal, setVisibleModal] = useState(false);
  const [visibleProductDetailsModal, setVisibleProductDetailsModal] =
    useState(false);
  const [visibleProductEditModal, setVisibleProductEditModal] = useState(false);
  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false);
  const [deleteData, setDeleteData] = useState<any>({});
  const [id, setId] = useState("");

  const {
    data: company,
    isLoading: loadCompanyData,
    refetch,
  } = useGetCompanyDetails();

  const { isLoading, isSuccess = false, mutate } = useDeleteCompanyData();

  //custom hook handle company delete
  useEffect(() => {
    if (isSuccess) {
      successMessage("Company inactive successfully");
      setVisibleModal(false);
      refetch();
    }
  }, [isSuccess]);

  //get company data
  const getCompanyDetails = (id: any, type: any) => {
    setId(id);
    if (type == "edit") {
      setVisibleProductEditModal(true);
    } else {
      setVisibleProductDetailsModal(true);
    }
  };

  //delete company data
  const deleteProductDetails = (data: any) => {
    mutate(data._id);
    setDeleteData({});
  };

  //load company data in table
  const loadTableData = () => {
    return company?.map((data: any, index: any) => {
      return (
        <Table.Row className="tbleR" key={index}>
          <Table.Cell>
            <p>{data.name}</p>
          </Table.Cell>
          <Table.Cell>
            <p>{data.companyEmail}</p>
          </Table.Cell>
          <Table.Cell>
            <p>{data.status}</p>
          </Table.Cell>
          <Table.Cell>
            {data.name !== "NP Admin Company" ? (
              <Grid>
                <Grid.Column computer={4} tablet={5} mobile={5}>
                  <TbFileSearch
                    cursor={"pointer"}
                    onClick={() => {
                      getCompanyDetails(data._id, "view");
                    }}
                    size={24}
                    color="var(--tableEditIcon)"
                  />
                </Grid.Column>
                <Grid.Column computer={4} tablet={5} mobile={5}>
                  <MdModeEditOutline
                    cursor={"pointer"}
                    onClick={() => {
                      getCompanyDetails(data._id, "edit");
                    }}
                    size={24}
                    color="var(--tableEditIcon)"
                  />
                </Grid.Column>
                <Grid.Column computer={4} tablet={5} mobile={5}>
                  <MdDeleteOutline
                    cursor={"pointer"}
                    size={24}
                    color="var(--tableEditIcon)"
                    onClick={() => {
                      setVisibleDeleteModal(true);
                      setDeleteData(data);
                    }}
                  />
                </Grid.Column>
              </Grid>
            ) : null}
          </Table.Cell>
        </Table.Row>
      );
    });
  };

  //handle loading
  if (loadCompanyData || isLoading) {
    return (
      <Dimmer active>
        <Loader content="Loading" />
      </Dimmer>
    );
  }

  return (
    <>
      <TitleBar enableTitle={true} titleName={"Register Company"} />
      <CustomButton
        theme="green"
        title="Add New company"
        onClick={() => setVisibleModal(true)}
        customColumnStyle="topButtonView"
        iconWithText={true}
        iconName="plus"
      />
      <Grid>
        <Grid.Column computer={16} tablet={16} mobile={16}>
          <CommonTable tableHeaderData={CompanyTableCol}>
            {loadTableData()}
          </CommonTable>
        </Grid.Column>
      </Grid>
      <CompanyRegisterModal
        visibleModal={visibleModal}
        setVisibleModal={() => {
          refetch();
          setVisibleModal(false);
        }}
      />
      <CompanyDetailsModal
        visibleModal={visibleProductDetailsModal}
        setVisibleModal={() => setVisibleProductDetailsModal(false)}
        id={id}
      />
      <CompanyEditModal
        visibleModal={visibleProductEditModal}
        setVisibleModal={() => {
          refetch();
          setVisibleProductEditModal(false);
        }}
        id={id}
      />
      <ConfirmModal
        viewModal={visibleDeleteModal}
        closeModal={() => setVisibleDeleteModal(false)}
        cancel={() => {
          setVisibleDeleteModal(false);
          setDeleteData({});
        }}
        approve={() => {
          setVisibleDeleteModal(false);
          deleteProductDetails(deleteData);
        }}
        title="Delete Company"
        subTitle="Are you sure you want to delete your company?"
      />
    </>
  );
};

export default CompanyList;
