import React from "react";

import Transport from "../screens/transport/transportList/Transport";
import { adminAccess, userAccess } from "./useRoleAccess";

export const TransportationRoutes = [
  {
    path: "/transportation",
    route: <Transport />,
    protectRoutes: true,
    roleGrantAccess: [...adminAccess, ...userAccess],
  },
];
