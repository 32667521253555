import { useMutation, useQuery } from "@tanstack/react-query";
import { ApiServiceErr, MutOptions, axiosApi } from "./apiService";
import { errorView, successMessage } from "../helpers/ErrorHandler";

export const useSupplierCultivationFormData = (opt?: MutOptions<string>) => {
  return useMutation<string, ApiServiceErr, any>(async (formData) => {
    return await axiosApi.post(`/supplier-farm-gate/`, formData);
  }, opt);
};

export const useUpdateSupplierCultivationFormData = (opt?: MutOptions<string>) => {
  return useMutation<string, ApiServiceErr, any>(async (formData: any) => {
    return await axiosApi.put(`/supplier-farm-gate/${formData.farmGateId}`, formData);
  }, opt);
};

export const useSupplierCultivationFormFieldData = (opt?: MutOptions<string>) => {
  return useMutation<string, ApiServiceErr, any>(async (formData) => {
    return await axiosApi.post(
      `/supplier-farm-gate/cultivation-field/${formData.farmGateId}`,
      formData
    );
  }, opt);
};

export const useGetSupplierCultivationFormData = (farmGateId: string) => {
  return useQuery(
    ["getSupplierFarmingData", farmGateId],
    async () => {
      const response = await axiosApi.get(`/supplier-farm-gate/${farmGateId}`);
      return response.data;
    },
    {
      refetchOnWindowFocus: false,
      cacheTime: 0,
      enabled: !!farmGateId,
      onError: () => {
        errorView("Something went wrong");
      },
    }
  );
};

export const useDeleteSupplierFarmGate = (opt?: MutOptions<string>) => {
  return useMutation<string, ApiServiceErr, any>(async (farmGateId) => {
    return await axiosApi.delete(`/supplier-farm-gate/${farmGateId}`);
  }, opt);
};


export const useGetFacilityWholeList = (facilityType: any) =>
  useQuery(
    ["getFacilityWholeList"],
    async () => {
      const response = await axiosApi.get(
        `/facility/list?facilityType=${facilityType}`
      );

      const newData = response?.data?.map((value: any, index: any) => {
        return {
          key: `facility_${index}`,
          text: value?.facilityName,
          value: value?._id,
        };
      });
      return newData;
    },
    {
      refetchOnWindowFocus: false,
    }
  );

export const useCalculateData = (opt?: MutOptions<string>) =>
  useMutation<string, ApiServiceErr, any>(async (id) => {
    const response = await axiosApi.put(`/farm-gate/queue/${id}`);
    successMessage(response.data);
    return response.data;
  }, opt);

export const usePostFarmGateInternalTransportData = (data?: any) => {
  return useMutation<string, ApiServiceErr, any>(async (data) => {
    const response = await axiosApi.post(
      `/supplier-farm-gate/internal-transport?farmGateId=${data?.farmGateId}`,
      data?.transportData
    );
    return response.data;
  });
};

export const usePutFarmGateInternalTransportData = (data?: any) => {
  return useMutation<string, ApiServiceErr, any>(async (data) => {
    const response = await axiosApi.put(
      `/supplier-farm-gate/internal-transport/${data?.internalTransportId}?farmGateId=${data?.farmGateId}`,
      data?.transportData
    );
    return response.data;
  });
};

export const useDeleteFarmGateInternalTransportData = (data?: any) => {
  return useMutation<string, ApiServiceErr, any>(async (data) => {
    const response = await axiosApi.delete(
      `/supplier-farm-gate/internal-transport/${data?.internalTransportId}?farmGateId=${data?.farmGateId}`
    );
    return response.data;
  });
};

export const useGetFarmGateInternalTransportById = (
  farmGateInternalTransportId: any
) =>
  useQuery(
    ["getFarmGateInternalTransportById"],
    async () => {
      const response = await axiosApi.get(
        `/supplier-farm-gate/internal-transport/${farmGateInternalTransportId}`
      );
      return response?.data;
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!farmGateInternalTransportId,
    }
  );

export const useGetTransportTypesList = () =>
  useQuery(
    ["getTransportTypesList"],
    async () => {
      const response = await axiosApi.get(`/static-data/transportation-types`);

      const newD = await response?.data?.map((type: any) => {
        const newIngredient: any = {
          key: type?.id,
          text: type?.transportation_name,
          value: type?.id,
        };
        return newIngredient;
      });
      return newD;
    },
    {
      refetchOnWindowFocus: false,
    }
  );

export const usePostFarmGateInternalStorageData = (data?: any) => {
  return useMutation<string, ApiServiceErr, any>(async (data) => {
    const response = await axiosApi.post(
      `/supplier-farm-gate/internal-storage?farmGateId=${data?.farmGateId}`,
      data?.storageData
    );
    return response.data;
  });
};

export const usePutFarmGateInternalStorageData = (data?: any) => {
  return useMutation<string, ApiServiceErr, any>(async (data) => {
    const response = await axiosApi.put(
      `/supplier-farm-gate/internal-storage/${data?.internalStorageId}?farmGateId=${data?.farmGateId}`,
      data?.storageData
    );
    return response.data;
  });
};

export const useDeleteFarmGateInternalStorageData = (data?: any) => {
  return useMutation<string, ApiServiceErr, any>(async (data) => {
    const response = await axiosApi.delete(
      `/supplier-farm-gate/internal-storage/${data?.internalStorageId}?farmGateId=${data?.farmGateId}`
    );
    return response.data;
  });
};

export const useGetFarmGateInternalStorageById = (
  farmGateInternalStorageId: any
) =>
  useQuery(
    ["getFarmGateInternalStorageById"],
    async () => {
      const response = await axiosApi.get(
        `/supplier-farm-gate/internal-storage/${farmGateInternalStorageId}`
      );
      return response?.data;
    },
    {
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );

export const useGetFacilityList = () =>
  useQuery(
    ["getFacilityList"],
    async () => {
      const response = await axiosApi.get(`/facility`);

      const newD = await response?.data?.result?.map((facility: any) => {
        return {
          key: facility?._id,
          text: facility?.facilityName,
          value: facility?._id,
        };
      });
      return newD;
    },
    {
      refetchOnWindowFocus: false,
    }
  );

export const usePostFarmGateProcessData = (data?: any) => {
  return useMutation<string, ApiServiceErr, any>(async (data) => {
    const response = await axiosApi.post(
      `/supplier-farm-gate/processing/${data?.farmGateId}`,
      data
    );
    return response.data;
  });
};

export const usePutFarmGateProcessData = (data?: any) => {
  return useMutation<string, ApiServiceErr, any>(async (data) => {
    const response = await axiosApi.put(
      `/supplier-farm-gate/processing/${data?.processId}`,
      data
    );
    return response.data;
  });
};

export const useDeleteFarmGateProcessData = (data?: any) => {
  return useMutation<string, ApiServiceErr, any>(async (data) => {
    const response = await axiosApi.delete(
      `/supplier-farm-gate/processing/${data?.processId}?farmGateId=${data?.farmGateId}`
    );
    return response.data;
  });
};

export const useGetFarmGateProcessDataById = (farmGateProcessId: any) =>
  useQuery(
    ["getFarmGateProcessDataById"],
    async () => {
      const response = await axiosApi.get(
        `/supplier-farm-gate/processing/${farmGateProcessId}`
      );
      return response?.data;
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!farmGateProcessId,
    }
  );

export const useGetProcessTypesWholeList = () =>
  useQuery(
    ["getProcessTypesWholeList"],
    async () => {
      const response = await axiosApi.get(`/static-data/process-types`);

      const newData = response?.data?.map((value: any, index: any) => {
        return {
          key: `processingType_${index}`,
          text: value?.process_name,
          value: value?.id,
        };
      });
      return newData;
    },
    {
      refetchOnWindowFocus: false,
    }
  );