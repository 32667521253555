import { useNavigate } from "react-router-dom";
import {
  Grid,
  Accordion,
  AccordionTitle,
  AccordionContent,
} from "semantic-ui-react";

import "./unauthorized.scss";
import useWindowDimensions from "../../hooks/windowsSize";
import { CustomButton } from "../../components";

interface UnauthorizedProps {}

const Unauthorized = (props: UnauthorizedProps) => {
  const navigate = useNavigate();
  const { height } = useWindowDimensions();

  const onClick = () => {
    navigate("/");
  };

  return (
    <Grid className="backgroundUnauthorized" style={{ height: height - 90 }}>
      <Accordion>
        <AccordionTitle>
          <p className="unauthorizedText">Unauthorized</p>
          <p className="subUnauthorized">Your are not authorized to access this page</p>
        </AccordionTitle>
        <AccordionContent active>
          <CustomButton
            onClick={onClick}
            title="Go Home"
            theme="green"
            buttonOutLine={true}
          />
        </AccordionContent>
      </Accordion>
    </Grid>
  );
};

export default Unauthorized;
