import { useMutation, useQuery } from "@tanstack/react-query";
import { ApiServiceErr, axiosApi, customConfig } from "./apiService";
import { errorView } from "../helpers/ErrorHandler";
import { removeTokens } from "../utils/cacheStorage";

interface companyData {
  data: [
    {
      _id: string;
      createdAt: string;
      updatedAt: string;
      name: string;
      isParent: boolean;
      companyEmail: string;
      status: string;
      contacts: any;
    }
  ];
}

export const useGetCompanyDetails = () =>
  useQuery<companyData[], ApiServiceErr>(
    ["getCompanyData"],
    async () => {
      const response = await axiosApi.get("/company");
      return response?.data?.data || [];
    },
    {
      cacheTime: 0,
    }
  );

export const useGetCompanyById = (id?: any) =>
  useMutation<any, ApiServiceErr, any>(async (id) => {
    if (!id) {
      return null;
    }
    const response = await axiosApi.get(`company/${id}`);
    return response?.data?.data;
  }, id);

export const useUpdateCompanyData = (companyData?: any) =>
  useMutation<string, ApiServiceErr, any>(async (companyData) => {
    if (!companyData.id) {
      return null;
    }
    const response = await axiosApi.put(
      `company/${companyData.id}`,
      companyData.data
    );
    return response.data;
  }, companyData);

export const usePostRegisterCompanyData = (companyData?: any) =>
  useMutation<string, ApiServiceErr, any>(async (companyData) => {
    const response = await axiosApi.post(`company`, companyData);
    return response.data;
  }, companyData);

export const useDeleteCompanyData = (id?: any) =>
  useMutation<string, ApiServiceErr, any>(async (id) => {
    const response = await axiosApi.delete(`company/${id}`);
    return response.data;
  }, id);

export const useGetCompanySearch = (searchText?: any) =>
  useMutation<any, ApiServiceErr, any>(async (searchText) => {
    let newCustomConfig = {...customConfig, params: { search: searchText }}
    const response = await axiosApi.get("/company", newCustomConfig);
    response?.data?.data?.sort((a: any, b: any) => {
      if (a.name < b.name) {
          return -1;
      }
      if (a.name > b.name) {
          return 1;
      }
      return 0;
  });
    
    const companyDetails = await response?.data?.data?.map((company: any) => {
      const newProduct: any = {
        id: company._id,
        title: company.name,
        ...company,
      };
      return newProduct;
    });

    return companyDetails || [];
  }, searchText);

export const useGetCompanyToken = (id?: any) =>
  useMutation<any, ApiServiceErr, any>(async (id) => {
    const response = await axiosApi.post(`/auth/change-tenant/${id}`);
    return response.data.data;
  }, {
    onError:()=>{
      removeTokens();
    }
  });


  export const useGetCompanyName = (token:any) =>
  useQuery<companyData[], ApiServiceErr>(
    ["getCompanyNames",token],
    async (token) => {
      const response = await axiosApi.get("/company",customConfig);
      const companyDetails = await response?.data?.data?.map((company: any) => {
        const newProduct: any = {
          id: company._id,
          title: company.name,
          tenantId: company.tenantId,
        };
        return newProduct;
      });
  
      return companyDetails || [];
    },
    {
      cacheTime: 0,
      enabled:!!token,
      onError:()=>{
        errorView("User hasn't any company");
        removeTokens();
      }
    }
  );
