import * as React from "react";
import { getPathName } from "../../utils/utils";

const ProductSummary = (props: any) => {
  const pathName = getPathName();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={36}
      height={36}
      fill="none"
      {...props}
    >
      <path
        fill={
          pathName == "product-summary"
            ? "var(--menuBarIconActive)"
            : "var(--menuBarIconDeactive)"
        }
        d="M29.55 19.35 21 27.9h-3.45v-3.45l8.55-8.55 3.45 3.45Zm5.1-1.2c0 .45-.45.9-.9 1.35L30 23.25l-1.35-1.35 3.9-3.9-.9-.9-1.05 1.05-3.45-3.45 3.3-3.15c.3-.3.9-.3 1.35 0l2.1 2.1c.3.3.3.9 0 1.35-.3.3-.6.6-.6.9 0 .3.3.6.6.9.45.45.9.9.75 1.35ZM4.5 30V6H15v7.5h7.5v2.25l3-3V12l-9-9h-12c-1.65 0-3 1.35-3 3v24c0 1.65 1.35 3 3 3h18c1.65 0 3-1.35 3-3h-21Zm12-4.35c-.3 0-.6.15-.75.15L15 22.5h-2.25L9.6 25.05l.9-4.05H8.25l-1.5 7.5H9l4.35-3.9.9 3.45h1.5l.75-.15v-2.25Z"
      />
    </svg>
  );
};
export default ProductSummary;
