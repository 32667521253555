import * as React from "react";
import { getPathName } from "../../utils/utils";
const SupplierIcon = (props: any) => {
  const pathName = getPathName();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={36}
      height={36}
      fill="none"
      {...props}
    >
      <path
        fill={
          pathName == "supplier"
            ? "var(--menuBarIconActive)"
            : "var(--menuBarIconDeactive)"
        }
        d="m33.66 18.686-3.375-7.875a1.125 1.125 0 0 0-1.035-.686h-3.375v-2.25A1.125 1.125 0 0 0 24.75 6.75H3.375A1.125 1.125 0 0 0 2.25 7.875V27a1.125 1.125 0 0 0 1.125 1.125h2.407a4.5 4.5 0 0 0 8.685 0h7.065a4.5 4.5 0 0 0 8.686 0h2.407A1.125 1.125 0 0 0 33.75 27v-7.875c0-.15-.03-.3-.09-.439Zm-7.785-6.311h2.633L30.915 18h-5.04v-5.625ZM10.125 29.25a2.25 2.25 0 1 1 0-4.5 2.25 2.25 0 0 1 0 4.5Zm11.407-3.375h-7.065a4.5 4.5 0 0 0-8.684 0H4.5V9h19.125v14.13a4.499 4.499 0 0 0-2.093 2.745Zm4.343 3.375a2.25 2.25 0 1 1 0-4.5 2.25 2.25 0 0 1 0 4.5Zm5.625-3.375h-1.282a4.5 4.5 0 0 0-4.343-3.375v-2.25H31.5v5.625Z"
      />
    </svg>
  );
};
export default SupplierIcon;
