import * as React from "react";
import { getPathName } from "../../utils/utils";

const CultivationIcon = (props: any) => {
  const pathName = getPathName();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={29}
      height={30}
      fill="none"
      {...props}
    >
      <path
        fill={
          pathName == "facility"
            ? "var(--menuBarIconActive)"
            : "var(--menuBarIconDeactive)"
        }
        d="M8.259 19.397a1.125 1.125 0 0 0 2.169-.6l-2.17.6Zm-6.46-15.81a1.125 1.125 0 1 0-.602 2.168L1.8 3.587Zm25.483 16.5a1.126 1.126 0 1 0-.567-2.176l.567 2.176Zm-14.987 2.751c.465 1.68-.555 3.455-2.36 3.924l.565 2.177c2.965-.77 4.777-3.753 3.964-6.7l-2.169.6Zm-2.36 3.924c-1.819.473-3.643-.583-4.11-2.278l-2.17.6c.81 2.932 3.891 4.623 6.845 3.855l-.566-2.177Zm-4.11-2.278c-.466-1.68.554-3.455 2.36-3.924l-.565-2.175c-2.966.77-4.78 3.751-3.965 6.699l2.17-.6Zm2.36-3.924c1.818-.473 3.642.583 4.11 2.278l2.17-.6c-.81-2.932-3.892-4.623-6.845-3.855l.565 2.177Zm2.243-1.763L7.2 7.112l-2.17.6L8.26 19.397l2.169-.6ZM4.356 4.295 1.8 3.587l-.603 2.168 2.557.71.602-2.17ZM7.2 7.112a4.06 4.06 0 0 0-2.844-2.817l-.599 2.17a1.811 1.811 0 0 1 1.274 1.247l2.169-.6Zm6.465 16.515 13.617-3.54-.566-2.176-13.618 3.54.567 2.176Z"
      />
      <path
        stroke={
          pathName == "facility"
            ? "var(--menuBarIconActive)"
            : "var(--menuBarIconDeactive)"
        }
        strokeWidth={1.5}
        d="M11.347 9.595c-.727-2.633-1.09-3.95-.472-4.986.616-1.038 1.974-1.39 4.689-2.097l2.88-.747c2.715-.705 4.072-1.06 5.142-.46 1.07.6 1.434 1.915 2.16 4.549l.772 2.793c.728 2.632 1.092 3.95.474 4.987-.618 1.037-1.975 1.39-4.69 2.096l-2.88.748c-2.715.705-4.073 1.058-5.142.46-1.07-.6-1.433-1.916-2.16-4.549l-.773-2.794Z"
      />
    </svg>
  );
};
export default CultivationIcon;