import * as React from "react";
import { useLocation } from "react-router-dom";
import { getPathName } from "../../utils/utils";

const DashboardIcon = (props: any) => {
  const pathName = getPathName();  
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={36}
      height={36}
      fill="none"
      {...props}
    >
      <path
        fill={
          pathName == undefined
            ? "var(--menuBarIconActive)"
            : "var(--menuBarIconDeactive)"
        }
        stroke={
          pathName == undefined
            ? "var(--menuBarIconActive)"
            : "var(--menuBarIconDeactive)"
        }
        d="m12.252 21.701-.002.025v.024A1.75 1.75 0 1 1 10.5 20h.207l.147-.146 4.5-4.5.146-.147V15a1.75 1.75 0 0 1 3.5 0v.205l.143.146 2.595 2.64.147.149H22.542l.147-.146 5.415-5.415.167-.168-.023-.235a.663.663 0 0 1 0-.127l.002-.025V12A1.75 1.75 0 1 1 30 13.75h-.208l-.147.148-5.46 5.505-.145.146V19.875a1.75 1.75 0 0 1-3.5 0V19.548l-.146-.147-2.415-2.415-.147-.146H16.728l-.147.146-4.185 4.185-.167.168.023.235a.663.663 0 0 1 0 .127ZM4.75 30v.5h26a1 1 0 0 1 0 2h-27a1 1 0 0 1-1-1v-27a1 1 0 0 1 2 0V30Z"
      />
    </svg>
  );
};

export default DashboardIcon;
