import { Navigate, useLocation } from "react-router-dom";
import isEmpty from "lodash/isEmpty";
import { getTokenInLocal, getUserRolesInLocal } from "./utils/cacheStorage";
import { useMemo } from "react";
import { userInitialRout, userRoles } from "./routes/useRoleAccess";

const ProtectRoute = ({
  children,
  redirectTo,
  protectRoutes,
  roleGrantAccess,
}: any) => {
  const location = useLocation();
  const allowedRoles = useMemo(
    () => getUserRolesInLocal(),
    [getUserRolesInLocal]
  );

  const isHasUserRoles = allowedRoles.some((role: string) =>
    roleGrantAccess?.includes(role)
  );

  if (isEmpty(getTokenInLocal()) && protectRoutes) {
    return <Navigate to={redirectTo} />;
  }

  if (location.pathname === "/" && protectRoutes && !isHasUserRoles) {
    if (allowedRoles?.includes(userRoles.supplier)) {
      return <Navigate to={userInitialRout.supplier} />;
    }
  }

  if (location.pathname !== "/" && !isHasUserRoles && protectRoutes) {
    return <Navigate to={"/unauthorized"} />;
  }

  return children;
};

export default ProtectRoute;
