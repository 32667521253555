import { useContext, useEffect, useMemo } from "react";
import { useReactFlow } from "reactflow";

import CultivarForm from "./CultivarForm";
import { DrawingForIngredientContext } from "../../DrawingToolForIngredient";
import useWindowDimensions from "../../../../hooks/windowsSize";
import CultivarSupplierForm from "../../../cultivation/cultivarSupplier/CultivarForm";

export const CultivarBase = ({ modalData }: any) => {
  const { farmGateId, nodeItem, rawMaterialId } = modalData;
  const {
    chartEdges,
    chartNodes,
    setNodeItem,
    setChartNodes,
    saveDrawing,
    supplierIngredientId,
  } = useContext(DrawingForIngredientContext);
  const { height } = useWindowDimensions();

  const getFarmGateParentId = nodeItem?.parentNode;

  //get ingredient and supplier data
  const getFarmGateParentData = useMemo(
    () => chartNodes.filter((e) => e.id == getFarmGateParentId),
    [getFarmGateParentId]
  );

  //update main farm date data
  const updateFarmGate = (currentNode: any, farmGateName?: string) => {
    const updatedFarmNode = {
      ...getFarmGateParentData[0],
      data: {
        ...getFarmGateParentData?.[0]?.data,
        ...(farmGateName && { label: `Farm Gate (${farmGateName})` }),
        reference: {
          ...currentNode.data.reference,
        },
      },
    };
    return updatedFarmNode;
  };

  // update drawing node
  const updateDrawingNode = (updatedNode: any, farmGateName?: string) => {
    const updateFarmGateData = updateFarmGate(updatedNode, farmGateName);
    const updatedChartNodes = chartNodes.map((n: any) => {
      if (n.id === nodeItem?.id) {
        return updatedNode;
      } else if (n.id === getFarmGateParentId) {
        return updateFarmGateData;
      }
      return n;
    });
    setChartNodes(updatedChartNodes);
    setNodeItem(updatedNode);
    saveDrawing(chartEdges, updatedChartNodes);
  };

  return (
    <div style={{ overflowY: "scroll", height: height - 80 }}>
      <CultivarSupplierForm
        ingredientId={rawMaterialId}
        farmGateId={farmGateId}
        nodeItem={nodeItem}
        supplierIngredientId={supplierIngredientId}
        updateDrawingNode={updateDrawingNode}
        setNodeItem={setNodeItem}
      />
    </div>
  );
};
