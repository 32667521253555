import { Checkbox, Dimmer, Grid, Loader } from "semantic-ui-react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";

import { InputText, CustomButton } from "../../components";

import "./passwordReset.scss";
import { useCheckUserByEmail, usePostUserReset } from "../../api/userDetails";
import { ReactElement, useEffect, useMemo, useState } from "react";
import { errorView, successMessage } from "../../helpers/ErrorHandler";
import { ResizableBox } from "react-resizable";
import useWindowDimensions from "../../hooks/windowsSize";

const PasswordReset = () => {
  const navigate = useNavigate();
  const location = useLocation();
  let passwordResetStatus = location.state.isPasswordResetRequired;
  let userEmail = location.state.email;
  const [email, setEmail] = useState(userEmail);
  const [isAgreeToTerms, setIsAgreeToTerms] = useState(false);
  const { width } = useWindowDimensions();
  const contentWidth = width * 0.45 - 225;

  const {
    data,
    isLoading: userLoading,
    mutate,
    isSuccess = false,
  } = usePostUserReset();

  //handle user password reset input
  const values = useMemo(() => {
    return {
      email: userEmail,
      oldPassword: "",
      newPassword: "",
      isAgreeToTerms: true,
    };
  }, [userEmail]);

  const {
    data: useCheckUserByEmailData,
    isLoading: loadCheckUserByEmail,
    refetch: refetchCheckUserByEmail,
  } = useCheckUserByEmail(email);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "all",
    defaultValues: values,
    values: values,
  });

  //user navigation when data change
  useEffect(() => {
    if (isSuccess) {
      successMessage("Password reset successfully");
      navigate("/auth");
    }
  }, [isSuccess]);

  //submit user password reset
  const onSubmit = async (data: any) => {
    if (useCheckUserByEmailData && useCheckUserByEmailData?.isSupplier) {
      data.isAgreeToTerms = isAgreeToTerms;
      if (!isAgreeToTerms) {
        errorView("Please agree to terms and conditions");
        return;
      }
    } else {
      data.isAgreeToTerms = true;
    }
    mutate(data);
  };

  //handle loading
  if (userLoading) {
    return (
      <Dimmer active>
        <Loader content="Loading" />
      </Dimmer>
    );
  }

  const checkUserHandler = (e: any) => {
    const email = e.target.value;
    setEmail(email);
    refetchCheckUserByEmail();
  }

  return (
    <>
      <form className="mainContaa" onSubmit={handleSubmit(onSubmit)}>
        <Grid className="backgroundLoginMain">
          <Grid.Column computer={16} tablet={16} mobile={16}>
            <span className="welcomeTxt">Reset Password</span>
          </Grid.Column>

          <Grid.Column
            style={{ paddingTop: "0px" }}
            computer={16}
            tablet={16}
            mobile={16}
          >
            <InputText
              register={register}
              errors={errors.email}
              required={true}
              labelName={"Email"}
              placeholder="Enter email"
              name={"email"}
              errorMessage="Please enter email"
              onBlur={(e: ReactElement) => checkUserHandler(e)}
            />
          </Grid.Column>
          <Grid.Column
            style={{ paddingTop: "0px" }}
            computer={16}
            tablet={16}
            mobile={16}
          >
            <InputText
              register={register}
              errors={errors.oldPassword}
              required={true}
              errorMessage="Please enter old password"
              labelName={
                passwordResetStatus ? "Temporary Password" : "Old Password"
              }
              placeholder={
                passwordResetStatus
                  ? "Enter Temporary Password"
                  : "Enter Old Password"
              }
              name="oldPassword"
              type={"password"}
            />
          </Grid.Column>
          <Grid.Column
            style={{ paddingTop: "0px" }}
            computer={16}
            tablet={16}
            mobile={16}
          >
            <InputText
              register={register}
              labelName={"New Password"}
              errors={errors.newPassword}
              required={true}
              errorMessage="Please enter new password"
              placeholder="Enter New Password"
              name="newPassword"
              type={"password"}
            />
          </Grid.Column>
          {useCheckUserByEmailData && useCheckUserByEmailData?.isSupplier && (
            <Grid.Column
              style={{ paddingTop: "0px" }}
              computer={16}
              tablet={16}
              mobile={16}
            >
              <ResizableBox
                width={contentWidth}
                height={400}
                minConstraints={[contentWidth, 400]}
                maxConstraints={[contentWidth, 1000]}
                className="mainResizeBoxView"
              >
                <div
                  className="resizeBoxContentView"
                  dangerouslySetInnerHTML={{
                    __html: useCheckUserByEmailData?.terms,
                  }}
                />
              </ResizableBox>

              <Checkbox
                register={register}
                label="Supplier Terms & Conditions"
                className="passwordResetAgreeCheckBox"
                style={{ color: "#000" }}
                checked={isAgreeToTerms}
                name="isAgreeToTerms"
                onChange={() => {
                  setIsAgreeToTerms(!isAgreeToTerms);
                }}
              />
            </Grid.Column>
          )}
          <Grid.Column
            style={{ paddingTop: "0px" }}
            computer={16}
            tablet={16}
            mobile={16}
          >
            <CustomButton
              theme="green"
              title={"Reset Password"}
              type="submit"
              customButtonStyle={"customBtnn"}
            />
          </Grid.Column>
          <Grid.Column
            style={{ paddingTop: "0px" }}
            computer={16}
            tablet={16}
            mobile={16}
          >
            <CustomButton
              theme="green"
              title={"Cancel"}
              customButtonStyle={"customBtnn"}
              buttonOutLine={true}
              onClick={() => navigate("/auth")}
            />
          </Grid.Column>
        </Grid>
      </form>
    </>
  );
};

export default PasswordReset;
