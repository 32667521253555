import { useState, useMemo, useEffect } from "react";
import { Dimmer, Grid, Loader } from "semantic-ui-react";
import { useForm } from "react-hook-form";
import { isEmpty } from "lodash";

import { CustomButton, CommonModal } from "../../../components";
import {
  FACILITY_OWNERSHIP,
  FACILITY_TYPES,
  UNIT_TYPES,
} from "../../../config/constants";
import useWindowDimensions from "../../../hooks/windowsSize";
import { errorView } from "../../../helpers/ErrorHandler";
import ConfirmModal from "../../../components/confirmViewModal/ConfirmModal";
import { useDeleteUploadProductImage } from "../../../api/product";
import { useEditFacility } from "../../../api/facility";
import { useGetEnergyType } from "../../../api/common";
import {
  useGetCountries,
  useGetHeatSourceType,
  useGetRefrigerantSourceType,
  useGetWaterSourceType,
} from "../../../api/static-data";
import FacilityInfo from "../facilityForms/FacilityInfo";
import FacilityEnergy from "../facilityForms/FacilityEnergy";
import FacilityHeatSource from "../facilityForms/FacilityHeatSource";

import "./facilityEdit.scss";
import FacilityWaterSource from "../facilityForms/FacilityWaterSource";
import FacilityRefrigerant from "../facilityForms/FacilityRefrigerant";

interface Energy {
  id: string;
  Year: number;
  Percentage: number;
  Price: number;
  kWhConsumed: number;
  EnergyType: string;
}

interface HeatSource {
  id: string;
  Year: number;
  Percentage: number;
  Price: number;
  Volumes: number;
  UnitType: string;
  HeatSource: string;
}

interface RefrigerantSource {
  id: string;
  Year: number;
  Percentage: number;
  Price: number;
  Volumes: number;
  UnitType: string;
  RefrigerantSource: string;
}

interface WaterSource {
  id: string;
  Year: number;
  Price: number;
  Volumes: number;
  WaterSource: string;
}

interface FacilityData {
  _id: string;
  facilityName: string;
  facilityAddress: string;
  facilityCity: string;
  facilityCountry: string;
  facilityType: string;
  facilityOwnership: string;
  energy: Energy[];
  heatSource: HeatSource[];
  refrigerantSource: RefrigerantSource[];
  waterSource: WaterSource[];
}

const FacilityEditModal = ({
  visibleModal,
  setVisibleModal,
  facilityData,
  updateFacilityData,
}: any) => {
  const [addFacilityEnergy, setFacilityEnergy] = useState<Energy[]>([]);
  const [visibleDeleteModal, setVisibleDeleteModal] = useState<boolean>(false);
  const [visibleHeatDeleteModal, setVisibleHeatDeleteModal] =
    useState<boolean>(false);
  const [visibleRefrigerantDeleteModal, setVisibleRefrigerantDeleteModal] =
    useState<boolean>(false);
  const [visibleWaterDeleteModal, setVisibleWaterDeleteModal] =
    useState<boolean>(false);

  const [defaultEnergyType, setDefaultEnergyType] = useState<number>(0);
  const [deleteData, setDeleteData] = useState<Energy | null>(null);
  const [deleteHeatData, setDeleteHeatData] = useState<HeatSource | null>(null);
  const [deleteRefrigerantData, setDeleteRefrigerantData] =
    useState<RefrigerantSource | null>(null);
  const [deleteWaterData, setDeleteWaterData] = useState<WaterSource | null>(
    null
  );

  const [defaultWaterType, setDefaultWaterType] = useState<number>(0);
  const [addFacilityWater, setFacilityWater] = useState<WaterSource[]>([]);
  const [defaultFacilityType, setDefaultType] = useState<string>(
    FACILITY_TYPES[0].value
  );
  const [defaultFacilityOwnerShip, setDefaultOwnerShip] = useState<string>(
    FACILITY_OWNERSHIP[0].value
  );
  const [getDefaultUnit, setUnit] = useState<string>(UNIT_TYPES[0].value);
  const [getDefaultRefrigerantUnit, setRefrigerantUnit] = useState<string>(
    UNIT_TYPES[0].value
  );
  const [defaultHeatType, setDefaultHeatType] = useState<number>(0);

  const [addFacilityHeat, setFacilityHeat] = useState<HeatSource[]>([]);
  const [addFacilityRefrigerant, setFacilityRefrigerant] = useState<
    RefrigerantSource[]
  >([]);

  const [country, setCountry] = useState<string>("");
  const { height } = useWindowDimensions();
  const [defaultRefrigerantType, setDefaultRefrigerantType] =
    useState<number>(0);

  //get energy type data
  const { data: energyType, status, fetchStatus } = useGetEnergyType();

  const { isLoading: editFacilityLoading, mutate: editFacilityData } =
    useEditFacility();

  const { isLoading: isLoadingProductImageDelete, mutate: deleteProductImage } =
    useDeleteUploadProductImage();

  const deleteFacilityEnergy = () => {
    if (visibleDeleteModal) {
      const indexToDelete = facilityData?.energy.findIndex(
        (item: any) => item === deleteData
      );
      if (indexToDelete !== -1) {
        const updatedEnergy = [
          ...facilityData.energy.slice(0, indexToDelete),
          ...facilityData.energy.slice(indexToDelete + 1),
        ];
        updateFacilityData((prev: any) => ({ ...prev, energy: updatedEnergy }));
        setDeleteData(null);
      }
    } else if (visibleHeatDeleteModal) {
      const indexToDelete = facilityData?.heatSource.findIndex(
        (item: any) => item === deleteHeatData
      );
      if (indexToDelete !== -1) {
        const updatedHeatSource = [
          ...facilityData.heatSource.slice(0, indexToDelete),
          ...facilityData.heatSource.slice(indexToDelete + 1),
        ];
        updateFacilityData((prev: any) => ({
          ...prev,
          heatSource: updatedHeatSource,
        }));
        setDeleteHeatData(null);
      }
    } else if (visibleRefrigerantDeleteModal) {
      const indexToDelete = facilityData?.refrigerantSource.findIndex(
        (item: any) => item === deleteRefrigerantData
      );
      if (indexToDelete !== -1) {
        const updateRefrigerant = [
          ...facilityData.refrigerantSource.slice(0, indexToDelete),
          ...facilityData.refrigerantSource.slice(indexToDelete + 1),
        ];
        updateFacilityData((prev: any) => ({
          ...prev,
          refrigerantSource: updateRefrigerant,
        }));
        setDeleteRefrigerantData(null);
      }
    } else {
      const indexToDelete = facilityData?.waterSource.findIndex(
        (item: any) => item === deleteWaterData
      );
      if (indexToDelete !== -1) {
        const updatedWaterSource = [
          ...facilityData.waterSource.slice(0, indexToDelete),
          ...facilityData.waterSource.slice(indexToDelete + 1),
        ];
        updateFacilityData((prev: any) => ({
          ...prev,
          waterSource: updatedWaterSource,
        }));
        setDeleteWaterData(null);
      }
    }
  };

  const {
    data: countriesData,
    isSuccess: isCountriesDataSuccess,
    isLoading: isCountriesDataLoading,
  } = useGetCountries();

  const {
    data: heatSource,
    status: heatSourceStatus,
    fetchStatus: fetchHeatSource,
    refetch: refetchHeatSource,
  } = useGetHeatSourceType();
  const {
    data: waterSource,
    status: waterSourceStatus,
    fetchStatus: fetchWaterSource,
    refetch: refetchWaterSource,
  } = useGetWaterSourceType();

  //handle company data input data
  const values = useMemo(() => {
    setFacilityEnergy(facilityData?.energy);
    setDefaultType(facilityData?.facilityType);
    setCountry(facilityData?.facilityCountry);
    setFacilityHeat(facilityData?.heatSource || []);
    setFacilityWater(facilityData?.waterSource || []);
    setFacilityRefrigerant(facilityData?.refrigerantSource || []);
    setDefaultOwnerShip(
      facilityData?.facilityOwnership || FACILITY_OWNERSHIP[0].value
    );
    return {
      facilityName: facilityData?.facilityName,
      facilityAddress: facilityData?.facilityAddress,
      facilityCity: facilityData?.facilityCity,
      facilityCountry: facilityData?.facilityCountry,
    };
  }, [facilityData, visibleModal]);

  const countries = useMemo(() => {
    if (isCountriesDataSuccess) {
      return countriesData?.map((c: any) => ({
        key: c.id,
        text: c.country,
        value: c.id,
      }));
    }
  }, [countriesData, isCountriesDataSuccess]);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    getValues,
    setValue: setFacilityValue,
  } = useForm({
    defaultValues: values,
    values: values,
  });

  const {
    formState: { errors: errorsFacilityWater },
    handleSubmit: handleSubmitFacilityWater,
    setValue: setWaterValue,
    reset: resetWater,
    control: controlWater,
  } = useForm();

  const {
    data: refrigerantSource,
    status: refrigerantStatus,
    fetchStatus: refrigerantFetchStatus,
    refetch: refrigerantFetch,
  } = useGetRefrigerantSourceType();

  const {
    formState: { errors: errorRefrigerant },
    handleSubmit: handleSubmitFacilityRefrigerant,
    setValue: setRefrigerantValue,
    reset: resetRefrigerant,
    control: controlRefrigerant,
  } = useForm();

  const {
    register: registerFacilityEnergy,
    formState: { errors: errorsFacilityEnergy },
    handleSubmit: handleSubmitFacilityEnergy,
    setValue: setEnergyValue,
    reset: resetUnitSize,
    setError,
    control,
  } = useForm();

  const {
    register: registerHeat,
    formState: { errors: errorsFacilityHeat },
    handleSubmit: handleSubmitFacilityHeat,
    setValue: setHeatValue,
    reset: resetHeat,
    control: controlHeat,
  } = useForm();

  useEffect(() => {
    resetUnitSize();
    resetHeat();
    resetWater();
  }, [visibleModal]);

  const addFacilityEnergyData = (data: any) => {
    const price = data?.Price ? Number(data?.Price) : 0;
    let updateData = {
      ...data,
      Price: price,
      kWhConsumed: Number(data?.kWhConsumed),
      Percentage: Number(data?.Percentage),
      EnergyType: energyType?.[defaultEnergyType]?.text || "",
    };
    facilityData.energy.push(updateData);
    setEnergyValue("Percentage", null);
    setEnergyValue("Price", 0.0);
    setEnergyValue("Year", null);
    setEnergyValue("kWhConsumed", null);
    setDefaultEnergyType(0);
  };

  //add product Heat data
  const addHeatData = (data: any) => {
    const price = data?.Price ? Number(data?.Price) : 0;
    let updateData = {
      ...data,
      Price: price,
      UnitType: getDefaultUnit,
      Volumes: Number(data?.Volumes),
      HeatSource: heatSource?.[defaultHeatType]?.text || "",
    };
    facilityData.heatSource.push(updateData);
    setHeatValue("Percentage", null);
    setHeatValue("Price", 0.0);
    setHeatValue("Year", null);
    setHeatValue("Volumes", null);
    setDefaultHeatType(0);
  };

  //add product Refrigerant data
  const addRefrigerant = (data: any) => {
    const price = data?.Price ? Number(data?.Price) : 0;
    let updateData = {
      ...data,
      Price: price,
      Volumes: Number(data?.Volumes),
      UnitType: getDefaultRefrigerantUnit,
      RefrigerantSource:
        refrigerantSource?.[defaultRefrigerantType]?.text || "",
    };
    facilityData.refrigerantSource.push(updateData);
    setRefrigerantValue("Percentage", null);
    setRefrigerantValue("Price", 0.0);
    setRefrigerantValue("Year", null);
    setRefrigerantValue("Volumes", null);
    setDefaultRefrigerantType(0);
  };

  //add product Water data
  const addWaterData = (data: any) => {
    const price = data?.Price ? Number(data?.Price) : 0;
    let updateData = {
      ...data,
      Price: price,
      Volumes: Number(data?.Volumes),
      WaterSource: waterSource?.[defaultWaterType]?.text || "",
    };
    facilityData.waterSource.push(updateData);
    setWaterValue("Price", 0.0);
    setWaterValue("Year", null);
    setWaterValue("Volumes", null);
    setDefaultWaterType(0);
  };

  //check product error view
  const checkFacilitySaveCondition = (data: any) => {
    if (isEmpty(data.facilityName.trim())) {
      errorView("Facility name is required");
      return true;
    } else if (!country) {
      errorView("Facility country is required");
      return true;
    } else if (addFacilityEnergy.length <= 0) {
      errorView("Please add facility energy");
      return true;
    } else {
      return false;
    }
  };

  // Edit facility data
  const onSubmit = async (data: any) => {
    if (!checkFacilitySaveCondition(data)) {
      let { facilityName, facilityAddress, facilityCity, facilityCountry } =
        data;
      let facilityDetails = {
        _id: facilityData?._id,
        facilityName,
        facilityAddress,
        facilityCity,
        facilityCountry: country,
        facilityType: defaultFacilityType,
        energy: facilityData.energy,
        heatSource: facilityData.heatSource,
        facilityOwnership: defaultFacilityOwnerShip,
        waterSource: facilityData.waterSource,
        refrigerantSource: facilityData.refrigerantSource,
      };
      editFacilityData(facilityDetails, {
        onSuccess: () => {
          setVisibleModal(true);
          reset();
          resetUnitSize();
          setDefaultEnergyType(0);
          resetHeat();
          resetRefrigerant();
        },
      });
      if (!data?.enableNavigate || false) {
        setVisibleModal(true);
      }
    }
  };

  if (
    editFacilityLoading ||
    isLoadingProductImageDelete ||
    (status == "loading" && fetchStatus == "fetching") ||
    (heatSourceStatus == "loading" && fetchHeatSource == "fetching") ||
    (waterSourceStatus == "loading" && fetchWaterSource == "fetching") ||
    (refrigerantStatus == "loading" && refrigerantFetchStatus == "fetching")
  ) {
    return (
      <Dimmer active>
        <Loader content="Loading" />
      </Dimmer>
    );
  }

  return (
    <>
      <CommonModal
        modalOpenState={visibleModal}
        modalCloseState={() => {
          setVisibleModal(true);
          reset();
          resetUnitSize();
          resetHeat();
          resetRefrigerant();
        }}
        centered={true}
        title="Edit Facility"
      >
        <Grid>
          <Grid.Column computer={16} tablet={16} mobile={16}>
            <>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div
                  style={{
                    height: height * 0.9 - 150,
                    overflowX: "hidden",
                  }}
                >
                  <FacilityInfo
                    register={register}
                    errors={errors}
                    country={country}
                    countries={countries}
                    setFacilityValue={setFacilityValue}
                    setCountry={setCountry}
                    isCountriesDataLoading={isCountriesDataLoading}
                    FACILITY_TYPES={FACILITY_TYPES}
                    defaultFacilityType={defaultFacilityType}
                    setDefaultType={setDefaultType}
                    defaultFacilityOwnerShip={defaultFacilityOwnerShip}
                    setDefaultOwnerShip={setDefaultOwnerShip}
                  />
                  <FacilityEnergy
                    energyType={energyType}
                    defaultEnergyType={defaultEnergyType}
                    setDefaultEnergyType={setDefaultEnergyType}
                    registerFacilityEnergy={registerFacilityEnergy}
                    errorsFacilityEnergy={errorsFacilityEnergy}
                    handleSubmitFacilityEnergy={handleSubmitFacilityEnergy}
                    addFacilityEnergyData={addFacilityEnergyData}
                    addFacilityEnergy={addFacilityEnergy}
                    currentFacilityData={facilityData?.energy || []}
                    setDeleteData={setDeleteData}
                    setVisibleDeleteModal={setVisibleDeleteModal}
                    control={control}
                    setError={setError}
                  />
                  <FacilityHeatSource
                    heatType={heatSource}
                    defaultHeatType={defaultHeatType}
                    setDefaultHeatType={setDefaultHeatType}
                    registerFacilityHeat={registerHeat}
                    errorsFacilityHeat={errorsFacilityHeat}
                    handleSubmitFacilityHeat={handleSubmitFacilityHeat}
                    addFacilityHeatData={addHeatData}
                    addFacilityHeat={addFacilityHeat}
                    currentFacilityHeatData={addFacilityHeat || []}
                    setDeleteHeatData={setDeleteHeatData}
                    setVisibleDeleteHeatModal={setVisibleHeatDeleteModal}
                    control={controlHeat}
                    setUnit={setUnit}
                    getDefaultUnit={getDefaultUnit}
                  />
                  <FacilityWaterSource
                    waterType={waterSource}
                    defaultWaterType={defaultWaterType}
                    setDefaultWaterType={setDefaultWaterType}
                    errorsFacilityWater={errorsFacilityWater}
                    handleSubmitFacilityWater={handleSubmitFacilityWater}
                    addFacilityWaterData={addWaterData}
                    addFacilityWater={addFacilityWater}
                    currentFacilityWaterData={addFacilityWater || []}
                    setDeleteWaterData={setDeleteWaterData}
                    setVisibleDeleteWaterModal={setVisibleWaterDeleteModal}
                    control={controlWater}
                  />
                  <FacilityRefrigerant
                    refrigerantType={refrigerantSource}
                    defaultRefrigerantType={defaultRefrigerantType}
                    setDefaultRefrigerantType={setDefaultRefrigerantType}
                    errorsFacilityRefrigerant={errorRefrigerant}
                    handleSubmitFacilityRefrigerant={
                      handleSubmitFacilityRefrigerant
                    }
                    addFacilityRefrigerantData={addRefrigerant}
                    addFacilityRefrigerant={addFacilityRefrigerant}
                    currentFacilityRefrigerantData={
                      addFacilityRefrigerant || []
                    }
                    setDeleteRefrigerantData={setDeleteRefrigerantData}
                    setVisibleDeleteRefrigerantModal={
                      setVisibleRefrigerantDeleteModal
                    }
                    control={controlRefrigerant}
                    setUnit={setRefrigerantUnit}
                    getDefaultUnit={getDefaultRefrigerantUnit}
                  />
                </div>
                <div
                  style={{
                    height: height * 0.1,
                  }}
                  className="modalRegisterButtonMain"
                >
                  <div className="modalRegisterFlex">
                    <CustomButton
                      theme="green"
                      title={"Cancel"}
                      customColumnStyle={"cardInsertSaveOther"}
                      buttonOutLine={true}
                      onClick={() => {
                        setVisibleModal(true);
                        reset();
                        setCountry("");
                        resetUnitSize();
                        resetHeat();
                        resetWater();
                        resetRefrigerant();
                      }}
                    />
                  </div>
                  <div className="modalRegisterButtonLeft">
                    <CustomButton
                      theme="green"
                      title={"Save Facility"}
                      customColumnStyle={"cardInsertSaveOther"}
                      buttonOutLine={true}
                      onClick={() => {
                        const values = getValues();
                        onSubmit(values);
                      }}
                    />
                  </div>
                </div>
              </form>
            </>
          </Grid.Column>
        </Grid>
      </CommonModal>
      <ConfirmModal
        viewModal={
          visibleDeleteModal ||
          visibleHeatDeleteModal ||
          visibleWaterDeleteModal ||
          visibleRefrigerantDeleteModal
        }
        closeModal={() => setVisibleDeleteModal(false)}
        cancel={() => {
          setVisibleDeleteModal(false);
          setDeleteData(null);
          setVisibleHeatDeleteModal(false);
          setDeleteHeatData(null);
          setVisibleWaterDeleteModal(false);
          setDeleteWaterData(null);
          setVisibleRefrigerantDeleteModal(false);
          setDeleteRefrigerantData(null);
        }}
        approve={() => {
          setVisibleDeleteModal(false);
          setVisibleHeatDeleteModal(false);
          setVisibleWaterDeleteModal(false);
          setVisibleRefrigerantDeleteModal(false);
          deleteFacilityEnergy();
        }}
        title="Delete Facility Energy"
        subTitle="Are you sure you want to delete facility energy?"
      />
    </>
  );
};

export default FacilityEditModal;
