import { useState } from "react";
import {
  Accordion,
  AccordionContent,
  AccordionTitle,
  Grid,
} from "semantic-ui-react";
import { AccordionTitleView, DropDown } from "../../../components";
import "./CultivarForm.scss";
import { InputNumber } from "../../../components/InputText/InputNumber";

const Fields = ({
  cultivationType,
  control,
  checkDateValidation,
  errors,
  errorStatus,
  errorMessage,
  HarvestSeasonData,
  harvestSeason,
  setHarvestSeason
}: any) => {
  const [accordionEnabled, setAccordionEnabled] = useState<boolean>(false);

  const handleClick = () => {
    setAccordionEnabled(!accordionEnabled);
  };

  return (
    <Accordion>
      <AccordionTitle active={accordionEnabled} onClick={handleClick}>
        <AccordionTitleView
          accordionEnabled={accordionEnabled}
          title={"Field"}
        />
      </AccordionTitle>
      <AccordionContent active={accordionEnabled}>
        <div>
      
        <Grid columns={2}>
                  <Grid.Row>
                    {cultivationType === "Supplier" ? (
                      <Grid.Column>
                        <InputNumber
                          transform={{
                            input: (value: any) =>
                              isNaN(value) || value === 0
                                ? ""
                                : value.toString(),
                            output: (e: any) => {
                              return e;
                            },
                          }}
                          control={control}
                          labelName={"Yield (kg/ha)"}
                          placeholder="Yield (kg/ha)"
                          name="yield"
                        />
                      </Grid.Column>
                    ) : null}
                    <Grid.Column>
                      <InputNumber
                        labelName={"Harvest year"}
                        transform={{
                          input: (value: any) =>
                            isNaN(value) || value === 0 ? "" : value.toString(),
                          output: (e: any) => {
                            return checkDateValidation(e.target.value);
                          },
                        }}
                        control={control}
                        errors={errors.harvestYear || errorStatus}
                        errorMessage={errorMessage}
                        name="harvestYear"
                      />
                    </Grid.Column>
                    {cultivationType === "Supplier" ? (
                      <Grid.Column>
                        <InputNumber
                          transform={{
                            input: (value: any) =>
                              isNaN(value) || value === 0
                                ? ""
                                : value.toString(),
                            output: (e: any) => {
                              return e;
                            },
                          }}
                          control={control}
                          labelName={"Growth Area (ha)"}
                          placeholder="Growth Area (ha)"
                          name="growthArea"
                        />
                      </Grid.Column>
                    ) : null}

                    <Grid.Column>
                      <DropDown
                        labelName={"Harvest season"}
                        placeholder="Select Season"
                        currentData={HarvestSeasonData}
                        defaultValue={harvestSeason}
                        handleChangeState={(e: any, { value }: any) => {
                          setHarvestSeason(value);
                        }}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
        </div>
      </AccordionContent>
    </Accordion>
  );
};

export default Fields;
