import { ReactElement, ReactNode } from "react";
import { PERMISSIONS } from "../config/permission-maps";
import { getUserRolesInLocal } from "../utils/cacheStorage";
import { hasPermission, getRolePermissions } from "../helpers/AuthorizationHelper";

interface PermissionsGateProps {
    children: ReactNode;
    scopes?: string[];
    feature: string;
    RenderError?: ReactElement<any>;
}

export default function PermissionsGate({
    children,
    scopes = [],
    feature,
    RenderError = <></>,
}: PermissionsGateProps): JSX.Element {
    
    const permissions = getRolePermissions();

    const permissionGranted = hasPermission({ permissions, scopes, feature });

    if (!permissionGranted) return RenderError;

    return <>{children}</>;
}