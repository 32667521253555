import { Grid } from "semantic-ui-react";

import { CustomButton } from "../button/Button";
import { CommonContainer } from "../commonContainer/commonContainer";

import "./itemAdder.scss";

export const ItemAdder = ({
  children = [<></>],
  computerMainView = 12,
  tabletMainView = 16,
  mobileMainView = 16,
  computerMain = 4,
  tabletMain = 16,
  mobileMain = 16,
  computerContent = 8,
  tabletContent = 16,
  mobileContent = 16,
  computerButton = 8,
  tabletButton = 16,
  mobileButton = 16,
  items = [{}],
  itemAddHandle = () => {},
  itemRemoveHandle = (index: number) => {},
  currentIndex = 0,
  customItemAddButtonMain,
  customItemAdderGridMain,
  customItemAdderGridWithInputs,
  customItemAddButtonMainBottom,
}: any) => {
  const checkCondition = () => {
    return (
      currentIndex !== 0 ||
      items.length - 1 !== currentIndex ||
      (items.length - 1 === currentIndex && currentIndex !== 0)
    );
  };

  const checkPlusBtnVisibility = (index: number) => {
    return items.length - 1 === index;
  };
  return (
    <CommonContainer styles="adder">
      <Grid className={`itemAdderGridMain ${customItemAdderGridMain}`}>
        <Grid.Column
          computer={computerMainView}
          tablet={tabletMainView}
          mobile={mobileMainView}
          className={`itemAdderGridWithInputs ${customItemAdderGridWithInputs}`}
        >
          {children}
        </Grid.Column>
        <Grid.Column
          computer={computerMain}
          tablet={tabletMain}
          mobile={mobileMain}
          className="itemButtonMainView"
        >
          <Grid>
            <Grid.Column
              computer={
                checkPlusBtnVisibility(currentIndex) ? computerContent : 16
              }
              tablet={tabletContent}
              mobile={mobileContent}
              className={`itemButtonAddMain paddingRemoveVertical ${customItemAddButtonMain}`}
            >
              {checkPlusBtnVisibility(currentIndex) && (
                <CustomButton
                  theme="green"
                  icon={"plus"}
                  onClick={itemAddHandle}
                  customColumnStyle='btnCustom'
                />
              )}
            </Grid.Column>
            <Grid.Column
              computer={computerButton}
              tablet={tabletButton}
              mobile={mobileButton}
              className={`itemButtonAddMain paddingRemoveVertical  ${customItemAddButtonMain}`}
            >
              {checkCondition() ? (
                <CustomButton
                  theme="green"
                  buttonOutLine={true}
                  icon={"minus"}
                  onClick={() => itemRemoveHandle(currentIndex)}
                  customColumnStyle='btnCustom'
                />
              ) : null}
            </Grid.Column>
          </Grid>
        </Grid.Column>
      </Grid>
    </CommonContainer>
  );
};
