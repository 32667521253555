import * as React from "react";

const CultivationIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <circle cx={24} cy={24} r={24} fill="var(--mappingImageBackground)" />
    <path
      fill="var(--mappingIconColor)"
      d="M32.948 14.84a.774.774 0 0 0-.218-.498.743.743 0 0 0-.487-.223c-7.137-.429-12.854 1.768-15.291 5.887a8.487 8.487 0 0 0-1.196 4.695 9.935 9.935 0 0 0 .622 3.086.385.385 0 0 0 .273.243.369.369 0 0 0 .347-.106l7.969-8.27a.748.748 0 0 1 .53-.224.738.738 0 0 1 .53.225.769.769 0 0 1 .22.542.781.781 0 0 1-.22.543l-8.708 9.034-1.33 1.36a.797.797 0 0 0-.056 1.064.752.752 0 0 0 .54.264.735.735 0 0 0 .558-.224l1.574-1.609c1.325.656 2.663 1.013 3.99 1.061a8.02 8.02 0 0 0 4.593-1.223c4.03-2.49 6.18-8.333 5.76-15.628Z"
    />
  </svg>
);
export default CultivationIcon;
