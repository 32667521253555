import * as React from "react"

const ClientIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <circle cx={24} cy={24} r={24} fill="var(--mappingImageBackground)" />
    <path
      fill="var(--mappingIconColor)"
      d="M24 15.088c1.06 0 2.078.43 2.828 1.197A4.134 4.134 0 0 1 28 19.176a4.134 4.134 0 0 1-1.172 2.89A3.957 3.957 0 0 1 24 23.265c-1.06 0-2.078-.43-2.828-1.197A4.134 4.134 0 0 1 20 19.177c0-1.085.421-2.125 1.172-2.892A3.956 3.956 0 0 1 24 15.088Zm0 10.22c4.42 0 8 1.83 8 4.088v2.044H16v-2.044c0-2.258 3.58-4.088 8-4.088Zm-5 8.176h2v2.044h-2v-2.044Zm4 0h2v2.044h-2v-2.044Zm4 0h2v2.044h-2v-2.044Z"
    />
  </svg>
);
export default ClientIcon