import React from "react";
import { Grid } from "semantic-ui-react";
import { InputText } from "../InputText/InputText";
import { DropDown } from "../selectDropDown/DropDown";
import { TitleView } from "../titleView/TitleView";

const ProcessingDetails = ({
  register,
  errors,
  processTypesWholeList,
  processingType,
  setProcessingType,
  setValue,
  facilityName,
  isValidate,
  facilityWholeList,
  setFacilityName,
}: any) => {
  return (
    <>
      <TitleView title="Processing Details" />
      <Grid>
        <Grid.Column computer={8} tablet={8} mobile={16}>
          <InputText
            register={register}
            errors={errors?.processingName}
            labelName={"Processing Name*"}
            placeholder="Processing Name"
            name={`processingName`}
            required={true}
            errorMessage={"Please enter processing Name"}
          />
        </Grid.Column>
        <Grid.Column computer={8} tablet={8} mobile={16}>
          <DropDown
            labelName={"Processing Type"}
            placeholder="Select Type"
            currentData={processTypesWholeList}
            defaultValue={processingType}
            customGridColumn={"customGridColomnTyp"}
            handleChangeState={(e: any, { value }: any) => {
              setProcessingType(value);
              setValue("processingType", value);
            }}
          />
        </Grid.Column>
        <Grid.Column
          computer={8}
          tablet={8}
          mobile={16}
          className="paddingRemoveTop"
        >
          <DropDown
            labelName={"Facility Name*"}
            placeholder="Select Facility Name"
            currentData={facilityWholeList}
            defaultValue={facilityName}
            required
            validate={isValidate}
            errorMessage={"Facility name is required"}
            customGridColumn={"customGridColomnTyp"}
            handleChangeState={(e: any, { value }: any) => {
              setFacilityName(value);
              setValue("facilityName", value);
            }}
          />
        </Grid.Column>
        <Grid.Column
          computer={8}
          tablet={8}
          mobile={16}
          className="paddingRemoveTop"
        >
          <InputText
            register={register}
            labelName={"Machine Model Number"}
            placeholder="Machine Model Number"
            name={`machineModelNo`}
          />
        </Grid.Column>
      </Grid>
    </>
  );
};

export default ProcessingDetails;
