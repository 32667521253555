import { useQuery, useMutation } from "@tanstack/react-query";
import { ApiServiceErr, axiosApi, axiosAuth } from "./apiService";
import { errorView, successMessage } from "../helpers/ErrorHandler";
import { removeTokens, saveUserIdInLocal, saveUserRoleInLocal } from "../utils/cacheStorage";

interface userData {
  userRole: any;
  tokens: any;
  cognitoidTokens: any;
  cognitoUserGroups: any;
  status: string;
  _id: string;
  firstName: string;
  email: string;
  comapanyName: string;
  userPassword: string;
}

interface UserInfo {
  result: userData[];
  page: number;
  pageSize: number;
  total: number;
}

interface UserProfileImage {
  userId: string;
  file: any;
}

interface UserProfileImageId {
  userId: string;
}

interface SupplierUserCheck {
  isSupplier: boolean;
  terms: string;
}

export const useGetUserDetails = (filterData:any) =>
  useQuery<UserInfo, ApiServiceErr>(
    [filterData, "getUserData"],
    async () => {
      const response = await axiosApi.get("/user", {
        params: filterData,
      });
      return response?.data;
    },
    {
      cacheTime: 0,
    }
  );

export const useCheckUserByEmail = (email: string) => {
  return useQuery<SupplierUserCheck, ApiServiceErr>(
    ["checkUserByEmail", email],
    async () => {
      if (!email) {
        throw new Error("Email is required");
      }
      const response = await axiosApi.post("/auth/check-user", {
        email,
      });
      return response?.data?.data;
    },
    {
      enabled: !!email,
      cacheTime: 0,
    }
  );
}
  

export const usePostUserLogin = () =>
  useMutation<any, ApiServiceErr, any>(
    async (data) => {
      const response = await axiosAuth.post(`auth/login`, data);
      if (response?.data?.data) {
        return response?.data?.data;
      } else {
        errorView(response?.data?.response?.message);
        throw new Error("Cannot divide by zero.");
      }
    },
    {
      onError: () => {
        removeTokens();
      },
    }
  );

export const usePostUserReset = (data?: any) =>
  useMutation<any, ApiServiceErr, any>(async (data) => {
    const response = await axiosAuth.post(`auth/reset-password`, data);
    if (response?.data?.data) {
      return response?.data?.data;
    } else {
      errorView(response?.data?.response?.message);
      throw new Error("Cannot divide by zero.");
    }
  }, data);

export const usePostUserForgot = (data?: any) =>
  useMutation<any, ApiServiceErr, any>(async (data) => {
    const response = await axiosAuth.post(`auth/forget-password`, data);
    if (response?.data?.data) {
      return response?.data?.data;
    } else {
      errorView(response?.data?.response?.message);
      throw new Error("Cannot divide by zero.");
    }
  }, data);

export const useGetUserById = (id?: any) =>
  useMutation<any, ApiServiceErr, any>(async (id) => {

    if (!id) {
      return new Error('User session not found');
    }
    
    const response = await axiosApi.get(`user/${id}`);
    return response?.data?.data;
  }, id);

export const usePutUpdateUserData = (data?: any) =>
  useMutation<string, ApiServiceErr, any>(async (data) => {
    if (!data.id) {
      return null;
    }
    const response = await axiosApi.put(`user/${data.id}`, data.userData);
    return response.data;
  }, data);

export const usePostRegisterUserData = (data?: any) =>
  useMutation<string, ApiServiceErr, any>(async (data) => {
    const response = await axiosApi.post(`user`, data);
    return response.data;
  }, data);

export const useDeleteUserData = (id?: any) =>
  useMutation<string, ApiServiceErr, any>(async (id) => {
    const response = await axiosApi.delete(`user/${id}`);
    return response.data;
  }, id);

export const useGetUserRole = () =>
  useQuery<UserInfo, ApiServiceErr>(
    ["getUserRoles"],
    async () => {
      const response = await axiosApi.get(`/role`);

      let filterUserData = response?.data?.data.map((e: any, index: any) => {
        return {
          key: index + 1,
          text: e.name,
          value: e.name,
        };
      });

      return filterUserData || [];
    },
    {
      cacheTime: 0,
    }
  );

export const usePostUserDetails = (id?: any) =>
  useMutation<any, ApiServiceErr, any>(async (id) => {
    const response = await axiosApi.post(`/auth/profile`);
    const user = response.data.data;
    saveUserRoleInLocal(JSON.stringify(user.userRole));
    saveUserIdInLocal(user._id);
    return user;
  }, id);

export const usePostUserLogout = () =>
  useMutation<any, ApiServiceErr, any>(async () => {
    const response = await axiosApi.post(`/auth/logout`);
    const user = response.data.data;
    return user;
  });

export const postUserFCMToken = async (fcmToken: string) => {
  try {
    await axiosApi.post(`/firebase-notification`, { fcmToken });
  } catch (error) {
    console.log("error:", error);
  }
};

export const useProfileImageUpload = () =>
  useMutation<any, ApiServiceErr, any>(
    async (data: UserProfileImage) => {
      const response = await axiosApi.post(`/user/upload`, data, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      return response.data;
    },
    {
      onSuccess(data) {
        successMessage(data);
      },
    }
  );

export const useProfileImageDelete = () =>
  useMutation<any, ApiServiceErr, any>(
    async (data: UserProfileImageId) => {
      const response = await axiosApi.delete(`/user/image/${data.userId}`);
      return response.data;
    },
    {
      onSuccess() {
        successMessage("User image delete successfully");
      },
    }
  );

