import React, { useEffect, useMemo, useState } from "react";
import { ModalHeader, ModalContent, Modal, Grid } from "semantic-ui-react";
import { CustomButton, InputText } from "../../../../../components";
import { useForm } from "react-hook-form";

interface ProcessGateFormModalProps {
  modalOpen: boolean;
  setModalOpen: (open: boolean) => void;
  newNode?: any;
  edit?: boolean,
  processGateName?: any,
  updateProcessGate: (
    processGateName: string,
    newNode: any,
    closeModal: () => void
  ) => void;
  createProcessGate: (
    processGateName: string,
    newNode: any,
    closeModal: () => void
  ) => void;
}

const ProcessGateFormModal: React.FC<ProcessGateFormModalProps> = ({
  modalOpen,
  setModalOpen,
  newNode,
  edit,
  processGateName,
  updateProcessGate,
  createProcessGate,
}) => {
  // State to manage the visibility of the modal
  const [open, setOpen] = useState(false);

  // Effect to synchronize modal state with modalOpen prop
  useEffect(() => {
    if(edit) {
      setValue("processGateName", processGateName)
    }
    setOpen(modalOpen);
  }, [modalOpen]);

  // Function to close the modal
  const closeModal = () => {
    setOpen(false);
    setModalOpen(false);
  };

  // Memoized default values for the form
  const defaultValues = useMemo(() => {
    return {
      processGateName: "",
    };
  }, []);

  // Form handling using useForm hook from react-hook-form
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    mode: "all",
    defaultValues,
  });

  // Function to handle form submission
  const onSubmit = (data: { processGateName: string }) => {
    const modifiedNode = {
      ...newNode,
      data: {
        ...newNode?.data,
        label: `Process Gate (${data.processGateName})`,
      },
    };
    if(edit) {
      updateProcessGate(data.processGateName,modifiedNode, closeModal);
    } else {
      createProcessGate(data.processGateName, modifiedNode, closeModal);
    }
  };

  return (
    <Modal closeIcon={edit ? true : false} onClose={closeModal} open={open} centered={false} dimmer="blurring">
      <ModalHeader>Process Gate</ModalHeader>
      <ModalContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid>
            <Grid.Column computer={12} tablet={12} mobile={12}>
              <InputText
                register={register}
                labelName="Process Gate Name"
                name="processGateName"
                errors={errors.processGateName}
                required
                errorMessage="Process Gate Name is required"
              />
            </Grid.Column>
            <Grid.Column computer={4} tablet={4} mobile={4}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <CustomButton theme="green" title={"Update"} type="submit" />
              </div>
            </Grid.Column>
          </Grid>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default ProcessGateFormModal;
