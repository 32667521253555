import { useMutation, useQuery } from "@tanstack/react-query";
import { ApiServiceErr, axiosApi } from "../apiService";

 const useCreateExternalStorage = (opt?: any) =>
    useMutation<any, ApiServiceErr, any>(async (data: any) => {
        const response = await axiosApi.post('/supplier-external-storage', data);
        return response.data;
    }, opt);

 const useUpdateExternalStorage = (opt?: any) =>
    useMutation<any, ApiServiceErr, any>(async (data: any) => {
        const response = await axiosApi.put(`/supplier-external-storage/${data.externalStorageId}`, data);
        return response.data;
    }, opt);

 const useGetExternalStorageById = (id: any) =>
    useQuery<any, ApiServiceErr>(
        ["getSupplierExternalStorageById", id],
        async () => {
            if (id) {
                const response = await axiosApi.get(`/supplier-external-storage/${id}`);
                return response.data;
            }
            return null;
            
        }
    );

 const useDeleteExternalStorage = (opt?: any) =>
	useMutation<any, ApiServiceErr, any>(async (externalStorageId: any) => {
		const response = await axiosApi.delete(`/supplier-external-storage/${externalStorageId}`);
		return response.data;
	}, opt);

    export {
        useCreateExternalStorage,
        useUpdateExternalStorage,
        useGetExternalStorageById,
        useDeleteExternalStorage
    }
