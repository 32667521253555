import { useMutation, useQuery } from "@tanstack/react-query";
import { ApiServiceErr, axiosApi } from "../apiService";

export const useGetSimulationProcessGateProcessDataById = (
  processGateProcessId: any
) =>
  useQuery(
    ["getSimulationFarmGateProcessDataById"],
    async () => {
      const response = await axiosApi.get(
        `/process-gate-simulation/processing/${processGateProcessId}`
      );
      return response?.data;
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!processGateProcessId,
    }
  );

export const usePutSimulationProcessGateProcessData = (data?: any) => {
  return useMutation<string, ApiServiceErr, any>(async (data) => {
    const response = await axiosApi.put(
      `/process-gate-simulation/processing/${data?.processId}`,
      data
    );
    return response.data;
  });
};

export const usePostSimulationProcessGateProcessData = (data?: any) => {
  return useMutation<string, ApiServiceErr, any>(async (data) => {
    const response = await axiosApi.post(
      `/process-gate-simulation/processing/${data?.processId}`,
      data
    );
    return response.data;
  });
};

export const useDeleteSimulationProcessGateProcessData = (data?: any) => {
  return useMutation<string, ApiServiceErr, any>(async (data) => {
    const response = await axiosApi.delete(
      `/process-gate-simulation/processing/${data?.processId}?farmGateId=${data?.processId}`
    );
    return response.data;
  });
};

export const useGetSimulationProcessRecipeByProcessId = (data: {
  recipeId: string;
  type: string;
}) =>
  useQuery(
    ["getSimulationProcessRecipeByProcessId"],
    async () => {
      const response = await axiosApi.get(
        `/${data.type}/recipe/${data.recipeId}`
      );            
      return response?.data;
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!data.recipeId,
    }
  );
