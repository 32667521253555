import React from "react";

import IngredientList from "../screens/ingredients/ingredientList/IngredientList";
import { adminAccess, userAccess } from "./useRoleAccess";

export const IngredientRoutes = [
  {
    path: "/ingredient",
    route: <IngredientList />,
    protectRoutes: true,
    roleGrantAccess: [...adminAccess, ...userAccess],
  },
];
