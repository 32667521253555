import DrawingTool from "../screens/drawing_tool/DrawingTool";
import SimulateDrawingTool from "../screens/simulate_drawing_tool/SimulateDrawingTool";
import DrawingToolForIngredient from "../screens/supplier_drawing_tool/DrawingToolForIngredient";
import { adminAccess, supplierAccess, userAccess } from "./useRoleAccess";

export const DrawingRoutes = [
  {
    path: "",
    route: <DrawingTool />,
    protectRoutes: true,
    roleGrantAccess: [...adminAccess, ...userAccess],
  },
  {
    path: "drawing-ingredient",
    route: <DrawingToolForIngredient />,
    protectRoutes: true,
    roleGrantAccess: [...supplierAccess],
  },
  {
    path: "simulate-drawing/:productId/:salesUnitId",
    route: <SimulateDrawingTool />,
    protectRoutes: true,
    roleGrantAccess: [...adminAccess, ...userAccess],
  },
];
