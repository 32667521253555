import React from "react";
import { Grid } from "semantic-ui-react";
import { CommonContainer } from "../commonContainer/commonContainer";
import "./titleBar.scss";

interface CustomButtonData {
  text: string;
  skip: boolean | undefined;
}

interface TitleBarProps {
  enableTitle?: boolean;
  titleName?: string;
  enableCustomButton?: boolean;
  customButtonAction?: (index: number) => void;
  customPageTitleMain?: string;
  customPageTitleContainer?: string;
  customPageTitle?: string;
  customPageButtonContainer?: string;
  customButtonData?: CustomButtonData[];
  selectFilterIndex?: number;
  customButtonMain?: string;
  buttonSelectView?: boolean;
}

export const TitleBar: React.FC<TitleBarProps> = ({
  enableTitle = true,
  titleName = "",
  enableCustomButton = false,
  customButtonAction = () => {},
  customPageTitleMain = "",
  customPageTitleContainer = "",
  customPageTitle = "",
  customPageButtonContainer = "",
  customButtonData = [],
  selectFilterIndex = 0,
  customButtonMain = "",
  buttonSelectView = true,
}) => {
  const isFilterDataAvailable = customButtonData.length === 0;

  if (!enableTitle) return null;

  return (
    <Grid className={`pageTitleMain ${customPageTitleMain}`}>
      <Grid.Column
        computer={isFilterDataAvailable ? 16 : 2}
        tablet={16}
        className={`pageTitleContainer ${customPageTitleContainer}`}
      >
        <CommonContainer>
          <p className={`pageTitle ${customPageTitle}`}>{titleName}</p>
        </CommonContainer>
      </Grid.Column>
      {enableCustomButton && (
        <Grid.Column
          computer={14}
          tablet={16}
          className={`pageButtonContainer ${customPageButtonContainer}`}
        >
          {buttonSelectView ? (
            <CommonContainer>
              <div className={`customButtonMain ${customButtonMain}`}>
                {customButtonData.map(
                  (data: CustomButtonData, index: number) => {
                    const isIndexZero = index === 0;
                    const isSelected = selectFilterIndex === index;

                    const mainStyles = isIndexZero
                      ? isSelected
                        ? "customButtonViewStart customButtonViewSelect"
                        : "customButtonViewStart"
                      : isSelected
                      ? "customButtonView customButtonViewSelect"
                      : "customButtonView";

                    const textStyle = isSelected
                      ? "customButtonTextSelect"
                      : "customButtonText";

                    return (
                      <div
                        key={index}
                        className={mainStyles}
                        onClick={() => customButtonAction(index)}
                      >
                        <p className={textStyle}>{data.text}</p>
                      </div>
                    );
                  }
                )}
              </div>
            </CommonContainer>
          ) : (
            <div className={`customButtonMainOther ${customButtonMain}`}>
              {customButtonData.map((data: CustomButtonData, index: number) =>
                !data.skip ? (
                  <div
                    key={index}
                    className={"customButtonViewSelect customButtonViewTitle"}
                    onClick={() => customButtonAction(index)}
                  >
                    <p className={"customButtonTextSelect"}>{data.text}</p>
                  </div>
                ) : null
              )}
            </div>
          )}
        </Grid.Column>
      )}
    </Grid>
  );
};
