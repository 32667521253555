import React from "react";
import { Grid } from "semantic-ui-react";
import { DropDown, InputText, TitleView } from "../../../components";
import { FACILITY_OWNERSHIP } from "../../../config/constants";

const FacilityInfo = ({
  register,
  errors,
  country,
  countries,
  setFacilityValue,
  setCountry,
  isCountriesDataLoading,
  FACILITY_TYPES,
  defaultFacilityType,
  setDefaultType,
  defaultFacilityOwnerShip,
  setDefaultOwnerShip,
}: any) => {
  return (
    <>
      <TitleView
        title="Facility Info"
        CustomTitleViewMain="facilityRegisterTitle"
      />
      <Grid>
        <Grid.Column computer={16} tablet={16} mobile={16}>
          <Grid>
            <Grid.Column
              computer={8}
              tablet={16}
              mobile={16}
              className="paddingRemoveBottom"
            >
              <InputText
                register={register}
                errors={errors.facilityName}
                labelName={"Facility Name*"}
                placeholder="Name"
                name="facilityName"
                required={true}
                lableName={"Facility Name"}
                errorMessage={"Please enter facility name"}
              />
            </Grid.Column>
            <Grid.Column
              computer={8}
              tablet={16}
              mobile={16}
              className="paddingRemoveBottom"
            >
              <DropDown
                labelName={"Facility Ownership*"}
                placeholder="Ownership"
                currentData={FACILITY_OWNERSHIP}
                defaultValue={defaultFacilityOwnerShip}
                customGridColumn={"customGridColomnType"}
                handleChangeState={(e: any, { value }: any) => {
                  setDefaultOwnerShip(value);
                }}
              />
            </Grid.Column>
            <Grid.Column
              computer={8}
              tablet={16}
              mobile={16}
              className="paddingRemoveBottom"
            >
              <DropDown
                labelName={"Facility Type*"}
                placeholder="Type"
                currentData={FACILITY_TYPES}
                defaultValue={defaultFacilityType}
                customGridColumn={"customGridColomnType"}
                handleChangeState={(e: any, { value }: any) => {
                  setDefaultType(value);
                }}
              />
            </Grid.Column>
            <Grid.Column
              computer={8}
              tablet={16}
              mobile={16}
              className="paddingRemoveBottom"
            >
              <InputText
                register={register}
                errors={errors.facilityAddress}
                labelName={"Address"}
                placeholder="Address"
                name="facilityAddress"
                required={true}
                errorMessage={"Please enter facility address"}
              />
            </Grid.Column>
            <Grid.Column
              computer={8}
              tablet={16}
              mobile={16}
              className="facilityEnergyRegisterTitle"
            >
              <InputText
                register={register}
                errors={errors.facilityCity}
                labelName={"City"}
                placeholder="City"
                name="facilityCity"
                required={true}
                lableName={"City"}
                errorMessage={"Please enter facility city"}
              />
            </Grid.Column>
            <Grid.Column
              computer={8}
              tablet={16}
              mobile={16}
              className="facilityEnergyRegisterTitle"
            >
              <DropDown
                labelName="Country*"
                placeholder="Select country"
                defaultValue={country}
                currentData={countries}
                handleChangeState={(e: any, { value }: any) => {
                  setFacilityValue("facilityCountry", value);
                  setCountry(value);
                }}
                loading={isCountriesDataLoading}
                search
                key={"country"}
              />
            </Grid.Column>
          </Grid>
        </Grid.Column>
      </Grid>
    </>
  );
};

export default FacilityInfo;
