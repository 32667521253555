import React from "react";

import ClientList from "../screens/clientt/clientList/ClientList";
import { adminAccess, userAccess } from "./useRoleAccess";

export const ClientListRoutes = [
  {
    path: "/client-list",
    route: <ClientList notFromDrawing={true} />,
    protectRoutes: true,
    roleGrantAccess: [...adminAccess, ...userAccess],
  },
];
