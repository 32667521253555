import { UseMutationOptions, UseQueryOptions } from "@tanstack/react-query";
import axios, { HttpStatusCode } from "axios";

import { NP_API_URL, NP_CALCULATOR_API_URL } from "../config/constants";
import {
	getTokenInForCompanyLocal,
	getTokenInLocal,
	getRefreshTokenInLocal,
	saveTokenInLocal,
	saveRefreshTokenInLocal,
	saveTokenExpiration,
	geTokenExpiration,
	saveCompanyTokenInLocal,
	getUserTenantId,
	getTenantId,
} from "../utils/cacheStorage";
import { errorView } from "../helpers/ErrorHandler";
import { removeTokens } from "../utils/cacheStorage";

type Nullable<T> = T | null;

export interface ApiServiceErr {
	msg: string;
	status: HttpStatusCode;
	errors?: string[];
}

export interface DeleteBody {
	id: string;
}

export type MutOptions<Response, TVariables = unknown> = UseMutationOptions<
	Response,
	ApiServiceErr,
	TVariables,
	unknown
>;

export type QueryOptions<Response, TVariables = unknown> = UseQueryOptions<
	Response,
	ApiServiceErr,
	TVariables,
	any[]
>;

export type QueryOpt<Response> = UseQueryOptions<
	Response,
	ApiServiceErr,
	any,
	any
>;

const getApiError = (error: any, defaultMessage?: string) => {
	if (typeof error === "string") {
		return error;
	}
	if (error?.msg) {
		return error.msg;
	}
	if (error?.response?.data) {
		if (typeof error.response.data === "string") {
			errorView(error.response.data);
			return error.response.data;
		}
		if (typeof error.response.data.message === "string") {
			errorView(error.response.data.message);
			return error.response.data.message;
		}
		if (typeof error.response.data.error === "string") {
			errorView(error.response.data.error);
			return error.response.data.error;
		}
	}
	errorView(defaultMessage);
	return defaultMessage || "Something went wrong";
};

const customConfig = {
	headers: {
		authorization: getTokenInForCompanyLocal(),
	},
};

const isAccessTokenExpired = () => {
	const expirationTimestamp = geTokenExpiration();
	return Number(expirationTimestamp) < Date.now();
};

const handleLogout = () => {
	const pathname = window.location.pathname;
	if (pathname !== "/auth") {
		window.location.href = "/auth";
	}
	removeTokens();
};

const getBearToken = (token: Nullable<string>) =>
	token ? `Bearer ${token}` : null;

const axiosApi = axios.create({ baseURL: NP_API_URL });

const calculatorApi = axios.create({ baseURL: NP_CALCULATOR_API_URL });

const axiosAuth = axios.create({ baseURL: NP_API_URL });

// In here, we handle the api error
axiosAuth.interceptors.response.use(undefined, (error) => {
	handleLogout();
	return Promise.reject({
		msg: getApiError(error),
		status: error.response.status || 500,
	});
});

// In here, we handle the api error
axiosApi.interceptors.response.use(undefined, (error) => {
	if (error.response.status === 401) {
		handleLogout();
	}
	return Promise.reject({
		errors: error?.response?.data?.data || [],
		msg: getApiError(error),
		status: error.response.status || 500,
	});
});

axiosApi.interceptors.request.use(
	async (config: any) => {
		let accessToken = getTokenInLocal();
		if (isAccessTokenExpired()) {
			const refreshToken = getRefreshTokenInLocal();
			if (refreshToken) {
				accessToken = await handleRefreshToken(refreshToken);
			}
		}
		if (accessToken) {
			config.headers.authorization = getBearToken(accessToken);
		}
		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

const handleRefreshToken = async (refreshToken: string) => {
	try {
		const isCompanyChanged = getUserTenantId() !== getTenantId();
		const response = await axiosAuth.post(
			`/auth/refresh-token`,
			{ refreshToken, tenantId: isCompanyChanged ? getTenantId() : null },
			{
				headers: {
					authorization: getBearToken(getTokenInLocal()),
				},
			}
		);
		if (response.status === 201 && response.data) {
			saveTokenInLocal(response.data.data.token);
			saveCompanyTokenInLocal(response.data.data.token);
			saveRefreshTokenInLocal(response.data.data.refreshToken);
			saveTokenExpiration(response.data.data.expiredIn);
			return response.data.data.token;
		} else {
			errorView("Token not refreshed, please login again");
			handleLogout();
		}
	} catch (error) {
		errorView("Token not refreshed, please login again");
		handleLogout();
	}
};

export { axiosApi, getApiError, axiosAuth, customConfig, calculatorApi };
