import { useState } from "react";
import {
  Accordion,
  AccordionContent,
  AccordionTitle,
  Grid,
} from "semantic-ui-react";
import { AccordionTitleView, DropDown, InputText } from "../../../components";
import "./CultivarForm.scss";
import {
  FIELD_TYPES,
  FARMING_TYPES,
  CULTIVATION_TYPES,
} from "../../../config/constants";

const CultivarField = ({
  cultivationType,
  setCultivationType,
  produceType,
  produceTypes,
  setProduceType,
  isProduceTypesLoading,
  country,
  countries,
  setCountry,
  setValue,
  isCountriesDataLoading,
  register,
  farmingType,
  setFarmingType,
  fieldType,
  setFieldType,
}: any) => {
  const [accordionEnabled, setAccordionEnabled] = useState<boolean>(false);

  const handleClick = () => {
    setAccordionEnabled(!accordionEnabled);
  };

  return (
    <Accordion>
      <AccordionTitle active={accordionEnabled} onClick={handleClick}>
        <AccordionTitleView
          accordionEnabled={accordionEnabled}
          title={"Cultivar"}
        />
      </AccordionTitle>
      <AccordionContent active={accordionEnabled}>
        <div>
          <Grid columns={2}>
            <Grid.Row>
              <Grid.Column>
                <DropDown
                  labelName="Data Type*"
                  placeholder="Select Type"
                  defaultValue={cultivationType}
                  currentData={CULTIVATION_TYPES}
                  handleChangeState={(e: any, { value }: any) => {
                    setCultivationType(value);
                  }}
                />
              </Grid.Column>
              <Grid.Column>
                <DropDown
                  labelName="Produce Type*"
                  placeholder="Select Produce Type"
                  defaultValue={produceType}
                  currentData={produceTypes}
                  handleChangeState={(e: any, { value }: any) => {
                    setProduceType(value);
                  }}
                  loading={isProduceTypesLoading}
                  search
                  key={"produceType"}
                />
              </Grid.Column>
              <Grid.Column>
                <DropDown
                  labelName="Origin Country"
                  placeholder="Select origin country"
                  defaultValue={country}
                  currentData={countries}
                  required
                  errorMessage={"Origin country required"}
                  handleChangeState={(e: any, { value }: any) => {
                    setCountry(value);
                    setValue("originCountry", value);
                  }}
                  loading={isCountriesDataLoading}
                  search
                  key={"country"}
                />
              </Grid.Column>
              <Grid.Column>
                <InputText
                  labelName={"Produce Cultivar"}
                  name="produceCultivar"
                  id="produceCultivar"
                  register={register}
                />
              </Grid.Column>
              <Grid.Column>
                <DropDown
                  labelName="Farming Type"
                  placeholder="Select Farming Type"
                  defaultValue={farmingType}
                  currentData={FARMING_TYPES}
                  handleChangeState={(e: any, { value }: any) => {
                    setFarmingType(value);
                  }}
                />
              </Grid.Column>
              <Grid.Column>
                <DropDown
                  labelName="Field Type"
                  placeholder="Select Field Type"
                  defaultValue={fieldType}
                  currentData={FIELD_TYPES}
                  handleChangeState={(e: any, { value }: any) => {
                    setFieldType(value);
                  }}
                />
              </Grid.Column>

              <InputText
                type="hidden"
                name="ingredientId"
                id="ingredientId"
                register={register}
              />
              <InputText
                type="hidden"
                name="supplierId"
                id="supplierId"
                register={register}
              />
            </Grid.Row>
          </Grid>
        </div>
      </AccordionContent>
    </Accordion>
  );
};

export default CultivarField;
