import React, { useState, useEffect, useMemo } from "react";
import { Checkbox, Grid, Dimmer, Loader } from "semantic-ui-react";
import { useForm } from "react-hook-form";

import {
  InputText,
  CustomButton,
  CommonModal,
  TitleView,
} from "../../../components";
import useWindowDimensions from "../../../hooks/windowsSize";

import "./userRegister.scss";
import {usePostRegisterUserData } from "../../../api/userDetails";
import { successMessage } from "../../../helpers/ErrorHandler";
import { customEmailValidation } from "../../../utils/utils";

const UserRegisterModal = ({ visibleModal, setVisibleModal, userRolesData }: any) => {
  const { height } = useWindowDimensions();
  const [roleData, setRoleData] = useState<any>([]);

  // reset data
  useEffect(() => {
    return () => {
      reset();
      setRoleData([]);
    };
  }, [visibleModal]);

  // register user data
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    getValues,
  } = useForm();
  const {
    data,
    isLoading,
    isSuccess = false,
    mutate,
  } = usePostRegisterUserData();

  useEffect(() => {
    if (isSuccess) {
      successMessage("User register successfully");
      setVisibleModal(false);
    }
  }, [isSuccess]);

  // submit user data
  const onSubmit = async (userData: any) => {
    userData.userRole = roleData;
    userData.status = "ACTIVE";
    await mutate(userData);
  };

  //handle loading
  if (isLoading ) {
    return (
      <Dimmer active>
        <Loader content="Loading" />
      </Dimmer>
    );
  }

  return (
    <>
      <CommonModal
        modalOpenState={visibleModal}
        modalCloseState={() => {
          setVisibleModal(false);
          reset();
        }}
        centered={true}
        title="User Register"
      >
        <TitleView title="User" CustomTitleViewMain="productRegisterTitle" />
        <Grid>
          <Grid.Column computer={16} tablet={16} mobile={16}>
            <>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div
                  style={{
                    height: height * 0.9 - 180,
                    overflowX: "hidden",
                  }}
                >
                  <Grid>
                    <Grid.Column computer={16} tablet={16} mobile={16}>
                      <Grid>
                        <Grid.Column
                          computer={8}
                          tablet={16}
                          mobile={16}
                          className="paddingRemoveBottom"
                        >
                          <InputText
                            register={register}
                            errors={errors.firstName}
                            labelName={"First Name"}
                            placeholder="First Name"
                            name="firstName"
                            required={true}
                            errorMessage={"Please enter user name"}
                          />
                        </Grid.Column>
                        <Grid.Column
                          computer={8}
                          tablet={16}
                          mobile={16}
                          className="paddingRemoveBottom"
                        >
                          <InputText
                            register={register}
                            errors={errors.firstName}
                            labelName={"Last Name"}
                            placeholder="Last Name"
                            name="lastName"
                            required={true}
                            errorMessage={"Please enter user name"}
                          />
                        </Grid.Column>

                        <Grid.Column
                          computer={8}
                          tablet={16}
                          mobile={16}
                          className="paddingRemoveTop"
                        >
                          <InputText
                            register={register}
                            errors={errors.email}
                            labelName={"Email"}
                            placeholder="Email"
                            name="email"
                            errorMessage={"Please enter email"}
                            required={true}
                            customInputValidation={true}
                            validateHandle={(value: any) => {
                              return customEmailValidation(value);
                            }}
                          />
                        </Grid.Column>
                        <Grid.Column
                          computer={8}
                          tablet={16}
                          mobile={16}
                          className="paddingRemoveTop"
                        >
                          <InputText
                            register={register}
                            errors={errors.phone}
                            labelName={"phone"}
                            placeholder="phone"
                            name="phone"
                            required={false}
                            errorMessage={"Please enter name"}
                          />
                        </Grid.Column>

                        <Grid.Column
                          computer={16}
                          tablet={16}
                          mobile={16}
                          className="paddingRemoveTop"
                        >
                          <label className={`inputTextLable`}>Role</label>
                          <Grid className="userDetailsRoles">
                            {userRolesData?.map((data: any, index: any) => {
                              return (
                                <Grid.Column
                                  computer={4}
                                  tablet={16}
                                  mobile={16}
                                >
                                  <Checkbox
                                    radio
                                    label={data.text}
                                    checked={roleData.some(
                                      (e: any) => e == data.text
                                    )}
                                    name="role"
                                    value={data.text}
                                    onChange={(e, data) => {
                                      setRoleData([data.value]);
                                    }}
                                  />
                                </Grid.Column>
                              );
                            })}
                          </Grid>
                        </Grid.Column>
                      </Grid>
                    </Grid.Column>
                  </Grid>
                </div>

                <div
                  style={{
                    height: height * 0.1,
                  }}
                  className="modalRegisterButtonMain"
                >
                  <div className="modalRegisterFlex">
                    <CustomButton
                      theme="green"
                      title={"Cancel"}
                      customColumnStyle={"cardInsertSaveOther"}
                      buttonOutLine={true}
                      onClick={() => {
                        setVisibleModal(false);
                        reset();
                      }}
                    />
                  </div>

                  <div className="modalRegisterButtonLeft">
                    <CustomButton
                      theme="green"
                      title={"Save"}
                      customColumnStyle={"cardInsertSaveOther"}
                      onClick={handleSubmit(onSubmit)}
                      type={"button"}
                    />
                  </div>
                </div>
              </form>
            </>
          </Grid.Column>
        </Grid>
      </CommonModal>
    </>
  );
};

export default UserRegisterModal;
