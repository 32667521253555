import { useMutation } from "@tanstack/react-query";
import { ApiServiceErr, axiosApi } from "../apiService";

interface processGatePackage {
  productId: string;
  salesUnitId: string;
  userCompletedStage: "RECIPE";
  status: string;
  processing: any | [];
  internalTransportation: any | [];
  internalStorage: any | [];
  packaging: any | [];
  processGateId: any
}

export const useCreateProcessGate = (opt?: any) =>
  useMutation<any, ApiServiceErr, any>(async (data: processGatePackage) => {
    const response = await axiosApi.post(`/process-gate`, data);
    return response.data;
  }, opt);

  export const useUpdateProcessGate = (opt?: any) =>
  useMutation<any, ApiServiceErr, any>(async (data: processGatePackage) => {
    const response = await axiosApi.put(`/process-gate/${data.processGateId}`, data);
    return response.data;
  }, opt);

export const useDeleteProcessGate = (opt?: any) => {
  return useMutation<string, ApiServiceErr, any>(async (processGateId) => {
    return await axiosApi.delete(`/process-gate/${processGateId}`);
  }, opt);
};

//supplier process gate
export const useCreateSupplierProcessGate = (opt?: any) =>
  useMutation<any, ApiServiceErr, any>(async (data: processGatePackage) => {
    const response = await axiosApi.post(`/supplier-process-gate`, data);
    return response.data;
  }, opt);

  export const useUpdateSupplierProcessGate = (opt?: any) =>
    useMutation<any, ApiServiceErr, any>(async (data: processGatePackage) => {
      const response = await axiosApi.put(`/supplier-process-gate/${data.processGateId}`, data);
      return response.data;
    }, opt);

  export const useDeleteSupplierProcessGate = (opt?: any) => {
    return useMutation<string, ApiServiceErr, any>(async (processGateId) => {
      return await axiosApi.delete(`/supplier-process-gate${processGateId}`);
    }, opt);
  };
