import { useState, useContext, useEffect, useMemo, useRef } from "react";
import { DropDown, InputText } from "../../../../../components";
import { Grid, Dimmer, Loader } from "semantic-ui-react";
import { useForm } from "react-hook-form";
import useWindowDimensions from "../../../../../hooks/windowsSize";
import {
  useGetProcessGateInternalStorageById,
  useDeleteProcessGateInternalStorageData,
  usePostProcessGateInternalStorageData,
  usePutProcessGateInternalStorageData,
} from "../../../../../api/process";
import { useGetFacilityWholeList } from "../../../../../api/cultivations";
import { successMessage } from "../../../../../helpers/ErrorHandler";
import isEmpty from "lodash/isEmpty";
import { DrawingContext } from "../../../DrawingTool";
import { useReactFlow } from "reactflow";
import ConfirmModal from "../../../../../components/confirmViewModal/ConfirmModal";
import { InputNumber } from "../../../../../components/InputText/InputNumber";
import {
  valueGraterThanZero,
  valueIsZero,
  isValueValid,
} from "../../../../../utils/utils";
import MainBottomButtonView from "../../../../../components/mainBottomButtonView/MainBottomButtonView";

interface StorageData {
  internalStorageName: string;
  facilityId: string;
  warehouseSize: number;
  storageTemperature: number;
  weight: number;
  electricity: number;
  utilizationOfWarehouse: number;
  storageDays: number;
}

interface InternalStorageData {
  processGateId: string;
  internalStorageId?: string;
  storageData: StorageData;
}

interface HandleValidation {
  checkCustomRequired(data?: any): void;
}

export const ProcessInternalStorageBase = ({ modalData }: any) => {
  const { processGateId, nodeItem } = modalData;
  const { deleteElements } = useReactFlow();

  const { chartEdges, chartNodes, setNodeItem, setChartNodes, saveDrawing } =
    useContext(DrawingContext);

  const { height } = useWindowDimensions();
  const [warehouse, setWarehouse] = useState();
  const [isValidate, setIsValidate] = useState(false);
  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false);
  const [customRequired, setCustomRequired] = useState(false);

  const customWareHouseNameValidation = useRef<HandleValidation>(null);
  const customWeightValidation = useRef<HandleValidation>(null);
  const customStorageDaysValidation = useRef<HandleValidation>(null);

  const {
    data: FacilityList,
    isSuccess: isFacilityDataSuccess,
    isLoading: isFacilityDataLoading,
  } = useGetFacilityWholeList("STORAGE");

  const FacilityListData = useMemo(() => {
    if (isFacilityDataSuccess) {
      return FacilityList;
    }
  }, [FacilityList, isFacilityDataSuccess]);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    control,
    getValues
  } = useForm();

  const { mutate: postInternalStorageDataMutate } =
    usePostProcessGateInternalStorageData();
  const { mutate: putInternalStorageDataMutate } =
    usePutProcessGateInternalStorageData();
  const { mutate: deleteInternalStorageData } =
    useDeleteProcessGateInternalStorageData();
  const {
    refetch: InternalStorageDataRefetch,
    isLoading: internalStorageDataLoading,
  } = useGetProcessGateInternalStorageById(
    nodeItem?.data?.reference?.internalStorageId
  );

  const getExistingInternalStorageData = async () => {
    const { data: internalStorageData } = await InternalStorageDataRefetch();
    setValue("internalStorageName", internalStorageData?.internalStorageName);
    setValue("warehouseName", internalStorageData?.facilityId);
    setWarehouse(internalStorageData?.facilityId);
    setValue("warehouseSize", internalStorageData?.warehouseSize);
    setValue("temperature", internalStorageData?.storageTemperature);
    setValue("weight", internalStorageData?.weight || 0);
    setValue("electricity", internalStorageData?.electricity);
    setValue("areaUtilization", internalStorageData?.utilizationOfWarehouse);
    setValue("storageDays", internalStorageData?.storageDays || 0);
  };

  useEffect(() => {
    if (nodeItem?.data?.reference) {
      getExistingInternalStorageData();
    }
    setValue("areaUtilization", 100);
  }, [nodeItem]);

  const onSubmit = (data: any) => {
    // Destructure form data
    const {
      internalStorageName,
      warehouseName,
      warehouseSize,
      temperature,
      weight,
      electricity,
      areaUtilization,
      storageDays,
    } = data;

    // Prepare data to submit
    const dataToSubmit: InternalStorageData = {
      processGateId,
      internalStorageId: nodeItem?.data?.reference?.internalStorageId,
      storageData: {
        internalStorageName: internalStorageName ?? "",
        facilityId: warehouseName ?? "",
        warehouseSize: warehouseSize ?? 0,
        storageTemperature: temperature ?? 0,
        weight: weight ?? 0,
        electricity: electricity ?? 0,
        utilizationOfWarehouse: areaUtilization ?? 0,
        storageDays: storageDays ?? 0,
      },
    };

    // Function to update node and chart
    const updateNodeAndChart = (
      updatedNode: any,
      successMessageText: string
    ) => {
      const updatedChartNodes = chartNodes.map((n: any) =>
        n.id === nodeItem?.id ? updatedNode : n
      );
      setChartNodes(updatedChartNodes);
      setNodeItem(updatedNode);
      saveDrawing(chartEdges, updatedChartNodes);
      successMessage(successMessageText);
    };

      // Update existing internal storage data if reference exists
      if (nodeItem?.data.reference) {
        putInternalStorageDataMutate(dataToSubmit, {
          onSuccess: () => {
            const updatedNode = {
              ...nodeItem,
              data: {
                ...nodeItem?.data,
                label: `Internal Storage (${internalStorageName})`,
              },
            };
            // Update node and chart
            updateNodeAndChart(
              updatedNode,
              "Internal Storage updated successfully"
            );
          },
        });
      } else {
        // Create new internal storage data if no reference exists
        postInternalStorageDataMutate(dataToSubmit, {
          onSuccess: (responseData: any) => {
            const updatedNode = {
              ...nodeItem,
              data: {
                ...nodeItem?.data,
                label: `Internal Storage (${internalStorageName})`,
                reference: {
                  internalStorageId: responseData?._id,
                },
              },
            };
            // Update node and chart
            updateNodeAndChart(
              updatedNode,
              "Internal Storage created successfully"
            );
          },
        });
      }
  };

  const handleDeleteSuccess = () => {
    setNodeItem(null);
    onNodesDelete();
    successMessage("Internal Storage deleted successfully");
    setVisibleDeleteModal(false);
  };

  const handleDeleteInternalStorage = () => {
    const dataToDelete = {
      processGateId: processGateId,
      internalStorageId: nodeItem?.data?.reference?.internalStorageId,
    };

    if (nodeItem?.data.reference) {
      deleteInternalStorageData(dataToDelete, {
        onSuccess: handleDeleteSuccess,
      });
      return;
    }
    handleDeleteSuccess();
  };

  const onNodesDelete = () => {
    deleteElements({ nodes: [{ id: nodeItem.id }] });
    setNodeItem(null);
  };

  if (
    (nodeItem?.data.reference && internalStorageDataLoading) ||
    isFacilityDataLoading
  ) {
    return (
      <Dimmer active>
        <Loader content="Loading" />
      </Dimmer>
    );
  }

  return (
    <Grid>
      <Grid.Column
        computer={16}
        tablet={16}
        mobile={16}
        className="userBankDetailsMain"
      >
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div
              style={{
                height: height * 0.9 - 100,
                overflowX: "hidden",
              }}
            >
              <Grid>
                <Grid.Column
                  computer={8}
                  tablet={8}
                  mobile={16}
                  className="paddingRemoveVertical-draw"
                >
                  <InputText
                    labelName={"Internal Storage Name*"}
                    errorMessage="Internal Storage name is required"
                    name="internalStorageName"
                    register={register}
                    errors={errors.internalStorageName}
                    required
                  />
                </Grid.Column>
                <Grid.Column
                  computer={8}
                  tablet={8}
                  mobile={16}
                  className="paddingRemoveVertical-draw"
                >
                  <DropDown
                    labelName={"Warehouse Name*"}
                    currentData={FacilityListData}
                    placeholder="Select Warehouse Name"
                    defaultValue={warehouse}
                    customGridColumn={"customGridColomnTyp"}
                    handleChangeState={(e: any, { value }: any) => {
                      setWarehouse(value);
                      setValue("warehouseName", value);
                    }}
                    errorMessage={"Warehouse name is required"}
                    customRequired={customRequired}
                  />
                </Grid.Column>
                <Grid.Column
                  computer={8}
                  tablet={8}
                  mobile={16}
                  className="paddingRemoveVertical-draw"
                >
                  <InputNumber
                    transform={{
                      input: (value: any) =>
                        isNaN(value) || value === 0 ? "" : value.toString(),
                      output: (e: any) => {
                        return e;
                      },
                    }}
                    control={control}
                    errors={errors.temperature}
                    labelName={"Storage Temperature (℃)"}
                    placeholder="Storage Temperature (℃)"
                    name={`temperature`}
                    enableMinus={true}
                  />
                </Grid.Column>
                <Grid.Column
                  computer={8}
                  tablet={8}
                  mobile={16}
                  className="paddingRemoveVertical-draw"
                >
                  <InputNumber
                    transform={{
                      input: (value: any) =>
                        isNaN(value) || value === 0 ? "" : value.toString(),
                      output: (e: any) => {
                        return e;
                      },
                    }}
                    control={control}
                    labelName={"Warehouse size (m3)"}
                    placeholder="Warehouse size (m3)"
                    name={`warehouseSize`}
                  />
                </Grid.Column>
                <Grid.Column
                  computer={8}
                  tablet={8}
                  mobile={16}
                  className="paddingRemoveVertical-draw"
                >
                  <InputNumber
                    ref={customWeightValidation}
                    transform={{
                      input: (value: any) =>
                        isNaN(value) || value === 0 ? "" : value.toString(),
                      output: (e: any) => {
                        return e;
                      },
                    }}
                    control={control}
                    labelName={"Weight (kg)*"}
                    placeholder="Weight (kg)"
                    name={`weight`}
                    valueAsNumber={true}
                    errors={errors?.weight}
                    errorMessage="Weight name is required"
                    customRequired={true}
                  />
                </Grid.Column>
                <Grid.Column
                  computer={8}
                  tablet={8}
                  mobile={16}
                  className="paddingRemoveVertical-draw"
                >
                  <InputNumber
                    transform={{
                      input: (value: any) =>
                        isNaN(value) || value === 0 ? "" : value.toString(),
                      output: (e: any) => {
                        return e;
                      },
                    }}
                    control={control}
                    labelName={"Electricity (kWh)"}
                    placeholder="0"
                    name={`electricity`}
                  />
                </Grid.Column>
                <Grid.Column
                  computer={8}
                  tablet={8}
                  mobile={16}
                  className="paddingRemoveVertical-draw"
                >
                  <InputNumber
                    transform={{
                      input: (value: any) =>
                        isNaN(value) || value === 0 ? "" : value.toString(),
                      output: (e: any) => {
                        return e;
                      },
                    }}
                    control={control}
                    labelName={"Utilization of warehouse %*"}
                    placeholder="Utilization of warehouse %"
                    name={`areaUtilization`}
                    errors={errors?.areaUtilization}
                    required={true}
                    errorMessage="Utilization is required"
                    otherValidation={{
                      validate: {
                        validValueCheck: (v: any) =>
                          isValueValid(v) ||
                          "Utilization minimum value should be 0.1 and maximum value should be 100",
                      },
                    }}
                  />
                </Grid.Column>
                <Grid.Column
                  computer={8}
                  tablet={8}
                  mobile={16}
                  className="paddingRemoveVertical-draw"
                >
                  <InputNumber
                    ref={customStorageDaysValidation}
                    transform={{
                      input: (value: any) =>
                        isNaN(value) || value === 0 ? "" : value.toString(),
                      output: (e: any) => {
                        return e;
                      },
                    }}
                    control={control}
                    labelName={"Storage day(s)*"}
                    placeholder="0"
                    name={`storageDays`}
                    errors={errors?.storageDays}
                    valueAsNumber={true}
                    errorMessage="Storage day(s) required"
                    customRequired={true}
                  />
                </Grid.Column>
              </Grid>
            </div>
            <MainBottomButtonView
              deleteStatus={true}
              saveButtonStatus={true}
              saveButton={() => {
                setCustomRequired(true);
                customWeightValidation.current?.checkCustomRequired(
                  getValues().weight
                );
                customStorageDaysValidation.current?.checkCustomRequired(
                  getValues().storageDays
                );
              }}
              deleteButton={() => setVisibleDeleteModal(true)}
              saveTitle={nodeItem?.data.reference ? "Update" : "Save"}
              type="submit"
            />
          </form>
        </div>
      </Grid.Column>
      <ConfirmModal
        viewModal={visibleDeleteModal}
        closeModal={() => setVisibleDeleteModal(false)}
        cancel={() => {
          setVisibleDeleteModal(false);
        }}
        approve={() => {
          handleDeleteInternalStorage();
        }}
        title="Delete Process Gate Internal Storage"
        subTitle="Are you sure you want to remove the process gate internal storage? Please consider you cannot recover these data after removed"
      />
    </Grid>
  );
};
