import React, { useEffect } from "react";
import { Route, BrowserRouter, Routes, Outlet } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import ProtectRoute from "./protectRoute";
import { MainRoutes } from "./routes";
import { AuthContextProvider } from "./context/AuthContext";
import "./index.scss";
import "./fonts.scss";
import "./styles.scss";
import "semantic-ui-css/semantic.min.css";
import "react-datepicker/dist/react-datepicker.css";
import { MainContentLayout } from "./components";
import { useThemeContext } from "./hooks/useThemeContext";
import { COMMON_PROTECT_ROUTE_PATH, THEME } from "./config/constants";
import { errorView } from "./helpers/ErrorHandler";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      cacheTime: 1,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      onError: (error: any) => {
        if (error?.status === 401) {
          window.location.href = "/auth";
          errorView(error?.msg);
        }
      },
    },
  },
});

const App: React.FC = () => {
  const { dispatch } = useThemeContext();

  useEffect(() => {
    dispatch({ type: THEME });
  }, []);

  return (
    <div className="app">
      <AuthContextProvider>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <MainContentLayout>
              <Routes>
                {MainRoutes.map((routesDetails, index) => (
                  <Route path={routesDetails.mainPath} key={index}>
                    {routesDetails.routes.map((subRoutes, index) => (
                      <Route
                        key={index}
                        path={subRoutes.path}
                        element={
                          <ProtectRoute
                            protectRoutes={subRoutes.protectRoutes}
                            roleGrantAccess={subRoutes?.roleGrantAccess}
                            redirectTo={COMMON_PROTECT_ROUTE_PATH}
                          >
                            {subRoutes.route}
                          </ProtectRoute>
                        }
                      />
                    ))}
                  </Route>
                ))}
                <Route path="*" element={<h1>Not Found</h1>} />
              </Routes>
            </MainContentLayout>
          </BrowserRouter>
        </QueryClientProvider>
      </AuthContextProvider>
    </div>
  );
};

export default App;
