import { useState, useContext, useEffect, useMemo, useRef } from "react";
import { DropDown, InputText, CustomButton } from "../../../../../components";
import { Grid, Dimmer, Loader } from "semantic-ui-react";
import { useForm } from "react-hook-form";
import useWindowDimensions from "../../../../../hooks/windowsSize";
import {
  useGetFacilityWholeList,
  usePostFarmGateInternalStorageData,
  useGetFarmGateInternalStorageById,
  usePutFarmGateInternalStorageData,
  useDeleteFarmGateInternalStorageData,
} from "../../../../../api/supplierFarmGate";
import { errorView, successMessage } from "../../../../../helpers/ErrorHandler";
import isEmpty from "lodash/isEmpty";
import { DrawingForIngredientContext } from "../../../DrawingToolForIngredient";
import ConfirmModal from "../../../../../components/confirmViewModal/ConfirmModal";
import { useReactFlow } from "reactflow";
import { InputNumber } from "../../../../../components/InputText/InputNumber";
import { valueGraterThanZero, valueIsZero, isValueValid } from "../../../../../utils/utils";
import MainBottomButtonView from "../../../../../components/mainBottomButtonView/MainBottomButtonView";

interface InternalStorageBaseProps {}

interface HandleValidation {
  checkCustomRequired(data?: any): void;
}

export const InternalStorageBase = ({ modalData }: any) => {
  const { farmGateId, nodeItem } = modalData;
  const { deleteElements } = useReactFlow();

  const { chartEdges, chartNodes, setNodeItem, setChartNodes, saveDrawing } =
    useContext(DrawingForIngredientContext);

  const { height } = useWindowDimensions();
  const [warehouse, setWarehouse] = useState();
  const [isValidate, setIsValidate] = useState(false);
  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false);

  const customWeightValidation = useRef<HandleValidation>(null);
  const customStorageDaysValidation = useRef<HandleValidation>(null);
  const customUtilizationValidation = useRef<HandleValidation>(null);

  const {
    data: FacilityList,
    isSuccess: isFacilityDataSuccess,
    isLoading: isFacilityDataLoading,
  } = useGetFacilityWholeList("STORAGE");

  const FacilityListData = useMemo(() => {
    if (isFacilityDataSuccess) {
      return FacilityList;
    }
  }, [FacilityList, isFacilityDataSuccess]);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    control,
    getValues,
  } = useForm();

  const { mutate: postInternalStorageDataMutate } =
    usePostFarmGateInternalStorageData();
  const { mutate: putInternalStorageDataMutate } =
    usePutFarmGateInternalStorageData();
  const {
    refetch: InternalStorageDataRefetch,
    isLoading: internalStorageDataLoading,
  } = useGetFarmGateInternalStorageById(
    nodeItem?.data?.reference?.internalStorageId
  );
  const { mutate: deleteFarmGateInternalStorageData } =
    useDeleteFarmGateInternalStorageData();

  const getExistingInternalStorageData = async () => {
    const { data: internalStorageData } = await InternalStorageDataRefetch();
    setValue("internalStorageName", internalStorageData?.internalStorageName);
    setValue("warehouseName", internalStorageData?.facilityId);
    setWarehouse(internalStorageData?.facilityId);
    setValue("warehouseSize", internalStorageData?.warehouseSize);
    setValue("temperature", internalStorageData?.storageTemperature);
    setValue("weight", internalStorageData?.weight);
    setValue("electricity", internalStorageData?.electricity);
    setValue("areaUtilization", internalStorageData?.utilizationOfWarehouse);
    setValue("storageDays", internalStorageData?.storageDays);
  };

  useEffect(() => {
    if (nodeItem?.data?.reference) {
      getExistingInternalStorageData();
    }
  }, [nodeItem, farmGateId]);

  // Function to update node and chart
  const updateNodeAndChart = (
    updatedNode: any,
    successMessageText: string
  ) => {
    const updatedChartNodes = chartNodes.map((n: any) =>
      n.id === nodeItem?.id ? updatedNode : n
    );
    setChartNodes(updatedChartNodes);
    setNodeItem(updatedNode);
    saveDrawing(chartEdges, updatedChartNodes);
    successMessage(successMessageText);
  };

  const onSubmit = (data: any) => {
    const dataToSubmit = {
      farmGateId: farmGateId,
      internalStorageId: nodeItem?.data?.reference?.internalStorageId,
      storageData: {
        internalStorageName: data?.internalStorageName ?? "",
        facilityId: data?.warehouseName,
        warehouseSize: data?.warehouseSize || 0,
        storageTemperature: data?.temperature || 0,
        weight: data?.weight || 0,
        electricity: data?.electricity || 0,
        utilizationOfWarehouse: data?.areaUtilization || 0,
        storageDays: data?.storageDays || 0,
      },
    };
    if (!isEmpty(data?.warehouseName)) {
      if (nodeItem?.data.reference) {
        putInternalStorageDataMutate(dataToSubmit, {
          onSuccess: () => {
            const updatedNode = {
              ...nodeItem,
              data: {
                ...nodeItem?.data,
                label: `Internal Storage (${data?.internalStorageName})`,
              },
            };
            // Update node and chart
            updateNodeAndChart(
              updatedNode,
              "Internal Storage updated successfully"
            );
          },
        });
      } else {
        postInternalStorageDataMutate(dataToSubmit, {
          onSuccess: (responseData: any) => {
            const updatedNode = {
              ...nodeItem,
              data: {
                ...nodeItem?.data,
                label: `Internal Storage (${data?.internalStorageName})`,
                // reference is mongoose mix type so you can set any type of here please set necessary reference only
                reference: {
                  internalStorageId: responseData?._id,
                },
              },
            };
            const updatedChartNodes = chartNodes.map((n: any) => {
              if (n.id === nodeItem?.id) {
                return updatedNode;
              }
              return n;
            });
            setChartNodes(updatedChartNodes);
            setNodeItem(updatedNode);
            saveDrawing(chartEdges, updatedChartNodes);
            successMessage("Internal Storage created successfully");
          },
        });
      }
    } else {
      setIsValidate(true);
    }
  };

  const onNodesDelete = () => {
    deleteElements({ nodes: [{ id: nodeItem.id }] });
    setNodeItem(null);
  };

  if (
    (nodeItem?.data.reference && internalStorageDataLoading) ||
    isFacilityDataLoading
  ) {
    return (
      <Dimmer active>
        <Loader content="Loading" />
      </Dimmer>
    );
  }

  return (
    <Grid>
      <Grid.Column
        computer={16}
        tablet={16}
        mobile={16}
        className="userBankDetailsMain"
      >
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div
              style={{
                height: height * 0.9 - 100,
                overflowX: "hidden",
              }}
            >
              <Grid>
                <Grid.Column
                  computer={8}
                  tablet={8}
                  mobile={16}
                  className="paddingRemoveVertical-draw"
                >
                  <InputText
                    labelName={"Internal Storage Name*"}
                    errorMessage="Internal Storage name is required"
                    name="internalStorageName"
                    register={register}
                    errors={errors.internalStorageName}
                    required
                  />
                </Grid.Column>
                <Grid.Column
                  computer={8}
                  tablet={8}
                  mobile={16}
                  className="paddingRemoveVertical-draw"
                >
                  <DropDown
                    labelName={"Warehouse Name*"}
                    currentData={FacilityListData}
                    required
                    validate={isValidate}
                    placeholder="Select Warehouse Name"
                    defaultValue={warehouse}
                    customGridColumn={"customGridColomnTyp"}
                    handleChangeState={(e: any, { value }: any) => {
                      setWarehouse(value);
                      setValue("warehouseName", value);
                    }}
                    errorMessage={"Warehouse name is required"}
                  />
                </Grid.Column>
                <Grid.Column
                  computer={8}
                  tablet={8}
                  mobile={16}
                  className="paddingRemoveVertical-draw"
                >
                  <InputNumber
                    transform={{
                      input: (value: any) =>
                        isNaN(value) || value === 0 ? "" : value.toString(),
                      output: (e: any) => {
                        return e;
                      },
                    }}
                    control={control}
                    errors={errors.temperature}
                    labelName={"Storage Temperature (℃)"}
                    placeholder="Storage Temperature (℃)"
                    name={`temperature`}
                    enableMinus={true}
                  />
                </Grid.Column>
                <Grid.Column
                  computer={8}
                  tablet={8}
                  mobile={16}
                  className="paddingRemoveVertical-draw"
                >
                  <InputNumber
                    transform={{
                      input: (value: any) =>
                        isNaN(value) || value === 0 ? "" : value.toString(),
                      output: (e: any) => {
                        return e;
                      },
                    }}
                    control={control}
                    labelName={"Warehouse size (m3)"}
                    placeholder="Warehouse size (m3)"
                    name={`warehouseSize`}
                  />
                </Grid.Column>
                <Grid.Column
                  computer={8}
                  tablet={8}
                  mobile={16}
                  className="paddingRemoveVertical-draw"
                >
                  <InputNumber
                    ref={customWeightValidation}
                    transform={{
                      input: (value: any) =>
                        isNaN(value) || value === 0 ? "" : value.toString(),
                      output: (e: any) => {
                        return e;
                      },
                    }}
                    control={control}
                    labelName={"Weight (kg)*"}
                    placeholder="Weight (kg)"
                    name={`weight`}
                    valueAsNumber={true}
                    errors={errors?.weight}
                    errorMessage="Weight name is required"
                    customRequired={true}
                  />
                </Grid.Column>
                <Grid.Column
                  computer={8}
                  tablet={8}
                  mobile={16}
                  className="paddingRemoveVertical-draw"
                >
                  <InputNumber
                    transform={{
                      input: (value: any) =>
                        isNaN(value) || value === 0 ? "" : value.toString(),
                      output: (e: any) => {
                        return e;
                      },
                    }}
                    control={control}
                    labelName={"Electricity (kWh)"}
                    placeholder="Electricity (kWh)"
                    name={`electricity`}
                  />
                </Grid.Column>
                <Grid.Column
                  computer={8}
                  tablet={8}
                  mobile={16}
                  className="paddingRemoveVertical-draw"
                >
                  <InputNumber
                    ref={customUtilizationValidation}
                    transform={{
                      input: (value: any) =>
                        isNaN(value) || value === 0 ? "" : value.toString(),
                      output: (e: any) => {
                        return e;
                      },
                    }}
                    control={control}
                    labelName={"Utilization of warehouse %*"}
                    placeholder="Utilization of warehouse %"
                    name={`areaUtilization`}
                    errors={errors?.areaUtilization}
                    errorMessage="Utilization is required"
                    customRequired={true}
                  />
                </Grid.Column>
                <Grid.Column
                  computer={8}
                  tablet={8}
                  mobile={16}
                  className="paddingRemoveVertical-draw"
                >
                  <InputNumber
                    ref={customStorageDaysValidation}
                    transform={{
                      input: (value: any) =>
                        isNaN(value) || value === 0 ? "" : value.toString(),
                      output: (e: any) => {
                        return e;
                      },
                    }}
                    control={control}
                    labelName={"Storage day(s)*"}
                    placeholder="0"
                    name={`storageDays`}
                    errors={errors?.storageDays}
                    valueAsNumber={true}
                    errorMessage="Storage day(s) required"
                    customRequired={true}
                  />
                </Grid.Column>
              </Grid>
            </div>
            <MainBottomButtonView
              deleteStatus={nodeItem?.data.reference}
              saveButtonStatus={true}
              saveButton={() => {
                customUtilizationValidation.current?.checkCustomRequired(
                  getValues().areaUtilization
                );
                customWeightValidation.current?.checkCustomRequired(
                  getValues().weight
                );
                customStorageDaysValidation.current?.checkCustomRequired(
                  getValues().storageDays
                );
              }}
              deleteButton={() => setVisibleDeleteModal(true)}
              saveTitle={nodeItem?.data.reference ? "Update" : "Save"}
              type="submit"
            />
          </form>
        </div>
      </Grid.Column>
      <ConfirmModal
        viewModal={visibleDeleteModal}
        closeModal={() => setVisibleDeleteModal(false)}
        cancel={() => {
          setVisibleDeleteModal(false);
        }}
        approve={() => {
          const dataToDelete = {
            farmGateId: farmGateId,
            internalStorageId: nodeItem?.data?.reference?.internalStorageId,
          };
          deleteFarmGateInternalStorageData(dataToDelete, {
            onSuccess: (data: any) => {
              setNodeItem(null);
              onNodesDelete();
              successMessage("Internal Storage deleted successfully");
            },
          });

          setVisibleDeleteModal(false);
        }}
        title="Delete Farm Gate Internal Storage"
        subTitle="Are you sure you want to remove the farm gate internal storage? Please consider you cannot recover these data after removed"
      />
    </Grid>
  );
};
