import {
  UseMutationOptions,
  useMutation,
  useQuery,
} from "@tanstack/react-query";
import { ApiServiceErr, axiosApi } from "./apiService";
import { successMessage } from "../helpers/ErrorHandler";

export const useGetSupplierDrawingRequests = (refetch: any) =>
  useQuery(
    ["getSupplierDrawingRequests", refetch],
    async () => {
      const response = await axiosApi.get(`/supplier-drawing-request`);
      return response.data;
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!refetch,
    }
  );

export const useAcceptDrawingRequest = (opt?: UseMutationOptions) =>
  useMutation<string, ApiServiceErr, any>(async (arg: any) => {
    const response = await axiosApi.patch(
      `/supplier-drawing-request/accept-request/${arg.id}`,
      arg.data
    );
    successMessage(response.data);
    return response.data;
  }, opt);

export const useCompleteDrawingRequest = (opt?: UseMutationOptions) =>
  useMutation<string, ApiServiceErr, any>(async (ingredientId: string) => {
    const response = await axiosApi.post(
      `/firebase-notification/drawing-completed/${ingredientId}`,
      null
    );
    successMessage(response.data);
    return response.data;
  }, opt);

export const useDrawingRequestUpdate = (opt?: UseMutationOptions) =>
  useMutation<string, ApiServiceErr, any>(
    async ({
      ingredientId,
      status,
    }: {
      ingredientId: string;
      status: number;
    }) => {
      const response = await axiosApi.patch(
        `/supplier-drawing-request/status/${ingredientId}`,
        {
          status,
        }
      );
      successMessage(response.data);
      return response.data;
    },
    opt
  );

export const useGetNotification = (
  isUserExist: any,
  refetch: any,
  skip: number
) =>
  useQuery(
    ["getNotificationData", refetch, skip],
    async () => {
      const response = await axiosApi.get(`/firebase-notification`, {
        params: {
          skip,
          limit: 10,
        },
      });
      return response.data;
    },
    {
      cacheTime: 10000,
      staleTime: 30000,
      refetchOnWindowFocus: true,
      enabled: !!isUserExist || refetch,
    }
  );

export const useNotificationRead = (opt?: UseMutationOptions) =>
  useMutation<string, ApiServiceErr, any>(async (notificationId: string) => {
    const response = await axiosApi.put(
      `/firebase-notification/is-read/${notificationId}`,
      null
    );
    return response.data;
  }, opt);
