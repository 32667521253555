import { useQuery, useMutation } from "@tanstack/react-query";
import { ApiServiceErr, MutOptions, axiosApi } from "./apiService";
import { successMessage } from "../helpers/ErrorHandler";

export const usePostSupplierRawMaterialsDetails = (opt?: MutOptions<string>) =>
  useMutation<string, ApiServiceErr, any>(async (data) => {
    const response = await axiosApi.post(`/supplier-raw-materials`, data);
    successMessage(response.data);
    return response.data;
  }, opt);

export const usePutSupplierRawMaterialsDetails = (opt?: MutOptions<string>) =>
  useMutation<string, ApiServiceErr, any>(async (data) => {
    const response = await axiosApi.put(
      `/supplier-raw-materials/${data._id}`,
      data
    );
    successMessage(response.data);
    return response.data;
  }, opt);

export const useGetSupplierRawMaterialsById = (id: string | any) =>
  useQuery(
    ["getSupplierRawMaterialsById", id],
    async () => {
      const response = await axiosApi.get(`/supplier-raw-materials/${id}`);
      return response.data;
    },
    {
      enabled: !!id,
    }
  );

export const useDeleteSupplierRawMaterial = (opt?: MutOptions<string>) =>
  useMutation<string, ApiServiceErr, any>(async (id) => {
    const response = await axiosApi.delete(`/supplier-raw-materials/${id}`);
    return response.data;
  }, opt);
