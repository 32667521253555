import { useEffect, useState } from "react";
import useWindowDimensions from "../../hooks/windowsSize";
import { axiosApi } from "../../api/apiService";
import { getTenantId } from "../../utils/cacheStorage";

const MetaDashboard = () => {
  const { height, width } = useWindowDimensions();
  const tenantId = getTenantId();

  const [siteUrl, setSiteUrl] = useState("");

  useEffect(() => {
    if (siteUrl !== "") return

    axiosApi.get('/user/meta/dashboard', { params: { tenantId } }).then((res) => {
      setSiteUrl(res.data)
    })
  }, [siteUrl])

  return <iframe
    src={siteUrl}
    frameBorder={0}
    width={width - 100}
    height={height}
    allowTransparency
  />
};
export default MetaDashboard;
