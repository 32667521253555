import * as React from "react";

const FarmGateIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <circle cx={24} cy={24} r={24} fill="var(--mappingImageBackground)" />
    <path
      fill="var(--mappingIconColor)"
      d="M32 32h-3v-2h3v2Zm0-4h-3v-2h3v2Zm0-4h-3v-2h3v2Zm4-5.2c-.4-3.3-3.2-5.8-6.5-5.8-1.7 0-3.4.7-4.6 1.9-.7.7-1.2 1.4-1.5 2.3l4.2 2.8H34v13h2V18.8ZM25.3 18c.6-1.8 2.3-3 4.2-3 1.9 0 3.6 1.2 4.2 3h-8.4Zm-5.8-1L12 22v11h15V22l-7.5-5ZM25 31h-3v-6h-5v6h-3v-8l5.5-3.5L25 23v8Z"
    />
  </svg>
);
export default FarmGateIcon;
