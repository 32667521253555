import React from "react";

import SettingsPage from "../screens/static-configuration/SettingsPage";
import { adminAccess } from "./useRoleAccess";

export const AdminRoutes = [
  {
    path: "static-configuration",
    route: <SettingsPage />,
    protectRoutes: true,
    roleGrantAccess: [...adminAccess],
  },
];