import { memo, useCallback, useContext } from "react";
import {
  getNodesBounds,
  NodeProps,
  useStore,
  NodeResizer,
  useReactFlow,
  NodeToolbar,
  Position,
} from "reactflow";
import { Icon, Button } from "semantic-ui-react";
import { DrawingContext } from "../DrawingTool";

const lineStyle = { borderColor: "transparent" };
const padding = 25;

function IngredientGroupNode({ id, data, type }: NodeProps) {
  const { deleteElements } = useReactFlow();
  const { chartNodes } = useContext(DrawingContext);

  const { minWidth, minHeight } = useStore((store) => {
    const childNodes = Array.from(store.nodeInternals.values()).filter(
      (n) => n.parentNode === id
    );
    const rect = getNodesBounds(childNodes);
    return {
      minWidth: rect.width + padding * 2,
      minHeight: rect.height + padding * 2,
      hasChildNodes: childNodes.length > 0,
      childNodes,
    };
  }, isEqual);

  // Delete Group
  const onDelete = useCallback(() => {
    const getNestedChart = chartNodes.filter((e: any) => e.parentNode == id);
    const checkNestedChartNode = chartNodes.filter(
      (e: any) =>
        getNestedChart.some((test) => e.parentNode == test.id) || e.id == id
    );
    const getGroupNodes = [...getNestedChart, ...checkNestedChartNode].map(
      (node) => ({ id: node.id })
    );
    deleteElements({ nodes: getGroupNodes });
  }, [type, deleteElements, chartNodes]);

  return (
    <>
      <div className="drawing_group" style={{ minWidth, minHeight }}>
        <label>{data.label}</label>
        <NodeResizer
          handleClassName="group_resizer"
          lineStyle={lineStyle}
          minWidth={minWidth}
          minHeight={minHeight}
        />
        <NodeToolbar position={Position.Bottom} align="end" offset={5}>
          &nbsp;
          <Button.Group size="tiny">
            <Button icon color="red">
              <Icon
                name="close"
                onClick={() => {
                  onDelete();
                }}
              />
            </Button>
          </Button.Group>
        </NodeToolbar>
      </div>
    </>
  );
}

type IsEqualCompareObj = {
  minWidth: number;
  minHeight: number;
  hasChildNodes: boolean;
};

function isEqual(prev: IsEqualCompareObj, next: IsEqualCompareObj): boolean {
  return (
    prev.minWidth === next.minWidth &&
    prev.minHeight === next.minHeight &&
    prev.hasChildNodes === next.hasChildNodes
  );
}

export default memo(IngredientGroupNode);
