import * as React from "react";
import {
  Dropdown,
  Message,
  Icon,
  Label,
  Loader,
  Menu,
  MenuItem,
} from "semantic-ui-react";
import "./NotificationDropdown.scss";

interface NotificationProps {
  notification: {
    list: Array<any>;
    totalCount: number;
    unReadCount: number;
  };
  updateLoadMore: (isListEmpty: boolean) => void;
  isLoadingNotification: boolean;
  onClick: (id: string) => void;
}

export const NotificationDropdown = (props: NotificationProps) => {
  const { isLoadingNotification, notification, updateLoadMore, onClick } =
    props;

  const notificationList = notification?.list || [];

  const getOptions = () => {
    const notifications = notificationList?.map((item: any) => ({
      key: item._id,
      text: item.title,
      value: item._id,
      content: (
        <Message
          color={item.isRead ? undefined : "blue"}
          header={item.title}
          content={item.body}
        />
      ),
      onClick: () => {
        onClick(item._id);
      },
    }));

    const isListEmpty = notificationList?.length === 0;
    notifications.push({
      key: "loadMore",
      text: "Load More",
      value: "loadMore",
      content: (
        <Label
          color="blue"
          as="a"
          content={isListEmpty ? "Back to first" : "Next Top Ten"}
        />
      ),
      onClick: () => {
        updateLoadMore(isListEmpty);
      },
    });

    return notifications;
  };

  return (
    <Menu compact>
      <Dropdown
        scrolling
        isLoadingNotification={isLoadingNotification}
        disabled={!notification || notification?.totalCount <= 0}
        trigger={
          <span className="webNotificationDiv">
            <MenuItem>
              <Icon size="large" name="bell" />
              {notification?.unReadCount ? (
                <Label color="red" floating>
                  {notification?.unReadCount}
                </Label>
              ) : null}
            </MenuItem>
          </span>
        }
        options={getOptions()}
        pointing="top left"
        icon={null}
      />
    </Menu>
  );
};
