import React, { useCallback, useEffect, useState } from "react";
import {
  Grid,
  Container,
  Segment,
  Modal,
  Input,
  Button,
  Dimmer,
} from "semantic-ui-react";
import Lottie from "lottie-react";

import { CommonModal } from "../../../components/commonModal/CommonModal";
import ImpactCard from "./impactCard";
import ImpactSlider from "./impactSlider";
import { CustomButton } from "../../../components";
import useWindowDimensions from "../../../hooks/windowsSize";
import Packaging from "./packaging/pakaging";
import {
  useCreateProductSimulation,
  useSimulate,
  useUpdateProductSimulation,
} from "../../../api/productSimulation";
import PackagingMachinery from "./packaging/packagingMachinery";
import { updatePackagingData } from "../utils/updateProductData";
import calculatorLoading from "../../../assets/animations/CalculatorLoading.json";
import "./compareSimulationModal.scss";

interface CompareSimulationModalProps {
  modalOpen: boolean;
  onClose: () => void;
  simulatedImpact: number;
  impactReduction: number;
  costSavings: number;
  packagingData?: PackagingData;
  productSummary?: any;
  productPayload?: any;
  gateType: string;
  packagingMaterials: PackagingMaterial[];
  packagingLevels: PackagingLevels[];
  wasteManagements: WasteManagements[];
  editedData: PackagingData;
  editSimulationData?: SimulationData;
  isView?: boolean;
}

const CompareSimulationModal: React.FC<CompareSimulationModalProps> = ({
  modalOpen,
  onClose,
  // simulatedImpact,
  // impactReduction,
  // costSavings,
  packagingData,
  productSummary,
  productPayload,
  gateType,
  packagingMaterials,
  packagingLevels,
  wasteManagements,
  editedData,
  editSimulationData,
  isView,
}) => {
  const [newPackagingData, setNewPackagingData] = useState<
    PackagingData | undefined
  >();
  const [simulationModalOpen, setSimulationModalOpen] = useState(false);
  const [simulationName, setSimulationName] = useState("");

  useEffect(() => {
    setNewPackagingData(editedData || packagingData);
    if (editSimulationData) {
      setSimulationName(editSimulationData.simulationName);
    }
  }, [packagingData, editedData]);

  const {
    mutate: onSimulate,
    isLoading: isLoadingSimulate,
    data: simulatedData,
  } = useSimulate({
    onError: () => {},
    onSuccess: () => {
      setSimulationModalOpen(true);
    },
  });

  const {
    mutate: createProductSimulation,
    isLoading: isLoadingCreateProductSimulation,
  } = useCreateProductSimulation({
    onError: () => {},
    onSuccess: () => {
      setSimulationModalOpen(false);
      onClose();
    },
  });

  const {
    mutate: updateProductSimulation,
    isLoading: isLoadingUpdateProductSimulation,
  } = useUpdateProductSimulation({
    onError: () => {},
    onSuccess: () => {
      setSimulationModalOpen(false);
      onClose();
    },
  });

  const { height } = useWindowDimensions();

  const currentImpact = productSummary?.totalImpact.find(
    (item: any) => item.key === "climateChange"
  )?.measure;

  const simulatedImpact = simulatedData?.totalImpact?.climateChange?.measure;

  const currentSimulationStatus = useCallback(() => {
    if (currentImpact > simulatedImpact) {
      return "Congratulations! You have achieved lower impact";
    }
    if (simulatedImpact > currentImpact) {
      return "Your impact has increased";
    }
    if (simulatedImpact === currentImpact) {
      return "No change in Impact";
    }
    return "Simulation impact not available, please contact administrator";
  }, [currentImpact, simulatedImpact]);

  const createProductSimulationData = () => {
    createProductSimulation({
      productId: productPayload?.productId,
      salesUnitId: productPayload?.salesUnitId,
      category: "Packaging",
      simulationName,
      originalImpact: currentImpact,
      simulatedImpact,
      impactReduction: 0,
      simulatedData,
      editedData: newPackagingData,
      gateType,
    });
  };

  const updateProductSimulationData = () => {
    updateProductSimulation({
      _id: editSimulationData?._id,
      category: "Packaging",
      simulationName,
      originalImpact: currentImpact,
      simulatedImpact,
      impactReduction: 0,
      simulatedData,
      editedData: newPackagingData,
      gateType,
    });
  };

  return (
    <CommonModal
      modalOpenState={modalOpen}
      modalCloseState={onClose}
      centered={true}
      customDimmer="simulationModal"
      title="Compare Simulations"
      size="large"
    >
      <Container
        style={{
          height: height * 0.9 - 100,
        }}
        className="modal-content"
        fluid
      >
        {isLoadingSimulate && (
          <Dimmer active>
            <div className="loading-calculation">
              <Lottie animationData={calculatorLoading} loop={true} />
            </div>
          </Dimmer>
        )}
        {!isLoadingSimulate && (
          <Segment loading={isLoadingSimulate}>
            {/*<Grid>
              <Grid.Row>
                <Grid.Column className="mt-1" width={7}>
                  <ImpactCard
                    subTitle="kg CO2 eq"
                    currentImpact={currentImpact}
                    simulatedImpact={simulatedImpact}
                    // impactReduction={impactReduction}
                    // costSavings={costSavings}
                  />
                </Grid.Column>
                <Grid.Column width={9}>
                  <ImpactSlider />
                </Grid.Column>
              </Grid.Row>
            </Grid>*/}
            <Packaging
              packagingData={packagingData}
              packagingType="base-unit"
              newPackagingData={newPackagingData}
              setNewPackagingData={setNewPackagingData}
              packagingMaterials={packagingMaterials}
              isView={isView}
            />
            <Packaging
              packagingData={packagingData}
              packagingType="sales-unit"
              newPackagingData={newPackagingData}
              setNewPackagingData={setNewPackagingData}
              packagingMaterials={packagingMaterials}
              isView={isView}
            />
            <Packaging
              packagingData={packagingData}
              packagingType="bulk-unit"
              newPackagingData={newPackagingData}
              setNewPackagingData={setNewPackagingData}
              packagingMaterials={packagingMaterials}
              isView={isView}
            />
            <Packaging
              packagingData={packagingData}
              packagingType="transport-unit"
              newPackagingData={newPackagingData}
              setNewPackagingData={setNewPackagingData}
              packagingMaterials={packagingMaterials}
              isView={isView}
            />
            <PackagingMachinery
              title="Packaging Machinery"
              packagingData={packagingData}
              newPackagingData={newPackagingData}
              setNewPackagingData={setNewPackagingData}
              packagingLevels={packagingLevels}
              wasteManagements={wasteManagements}
              isView={isView}
            />
          </Segment>
        )}
      </Container>
      {!isView && (
        <div className="modal-bottom">
          <CustomButton
            className="button-icon"
            theme="green"
            title="Cancel"
            buttonOutLine
            onClick={() => {
              onClose();
            }}
          />
          <CustomButton
            className="button-icon"
            theme="green"
            title="Run Simulation"
            disabled={isLoadingSimulate || !productPayload || !newPackagingData}
            onClick={() => {
              const updatedData = updatePackagingData(
                productPayload,
                newPackagingData,
                gateType
              );
              onSimulate(updatedData);
            }}
          />
        </div>
      )}
      <Modal
        centered={false}
        onClose={() => setSimulationModalOpen(false)}
        open={simulationModalOpen}
        size="mini"
        className="simulation-modal"
      >
        <Modal.Header className="modal-header">Simulation Results</Modal.Header>
        <Modal.Content>
          <Modal.Description className="modal-description">
            <p className="modal-content">{currentSimulationStatus()}</p>
            <p className="currentImpactTitle">Current Impact</p>
            <p className="currentImpact">
              {currentImpact === undefined
                ? "N/A"
                : currentImpact?.toLocaleString()}
            </p>
            <p className="simulatedImpactTitle">Simulated Impact</p>
            <p className="simulatedImpact">
              {simulatedData === undefined
                ? "N/A"
                : simulatedImpact?.toLocaleString()}
            </p>
          </Modal.Description>
          <Grid className="mt-1" centered>
            <Grid.Column className="m-0 p-0" width={10}>
              <Grid className="m-0 p-0">
                <Grid.Column className="m-0 p-0" width={10}>
                  <Input
                    className="simulation-input"
                    fluid
                    value={simulationName}
                    placeholder="Simulation Name"
                    onChange={(e) => {
                      setSimulationName(e.target.value);
                    }}
                  />
                </Grid.Column>
                <Grid.Column className="m-0 p-0" width={6}>
                  <Button
                    disabled={!simulationName}
                    loading={
                      isLoadingCreateProductSimulation ||
                      isLoadingUpdateProductSimulation
                    }
                    className="save-button"
                    onClick={() => {
                      if (editedData) {
                        updateProductSimulationData();
                      } else {
                        createProductSimulationData();
                      }
                    }}
                    color="green"
                  >
                    {editedData ? "Update" : "Save"}
                  </Button>
                </Grid.Column>
              </Grid>
            </Grid.Column>
          </Grid>
        </Modal.Content>
      </Modal>
    </CommonModal>
  );
};

export default CompareSimulationModal;
