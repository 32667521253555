import { getUserRolesInLocal } from "../utils/cacheStorage";
import { PERMISSIONS, SCOPES } from "../config/permission-maps";
import { HEADER_MENU_BAR } from "../config/constants";
import { isEmpty } from "lodash";

interface HasPermissionProps {
    permissions: any[];
    scopes: string[];
    feature: string;
}

export const getRolePermissions = () => {
    const role = getUserRolesInLocal()[0];
    const permissions = PERMISSIONS[role];
    return permissions;
}

export const hasPermission = ({ permissions, scopes, feature }: HasPermissionProps): boolean => {
    if (!permissions) return false;
    const scopesMap: Record<string, boolean> = {};

    scopes.forEach((scope) => {
        scopesMap[scope] = true;
    });

    const featurePermission = permissions.find((permission) => permission.name === feature);

    if (!featurePermission && !featurePermission?.scopes) return false;

    return featurePermission.scopes.some((permission: any) => scopesMap[permission]);
};

export function getHeaderMenuItems(navigate: any) {
    const permissions = getRolePermissions();
    if (!permissions) return [];
    const headerPermissions = HEADER_MENU_BAR.map((menuItem: any) => {
        if (hasPermission({ permissions, scopes: [SCOPES.canAccessInHeaderMenu], feature: menuItem.value })) {
            return {
                key: menuItem.key,
                text: menuItem.text,
                value: menuItem.value,
                onClick: () => {
                    navigate(menuItem.path);
                },
            }
        }
        return []
    });
    return headerPermissions.filter((item: any) => !isEmpty(item));
}
