import { toast } from "react-toastify";

export const toastNotification = ({ title, body }: any) => {
  // Implement your toast notification logic
  toast.info(`${title} - ${body}`)
};

export const sendNativeNotification = ({ title, body }: any) => {
  // Implement your native notification logic
  console.log(`Native Notification: ${title} - ${body}`);
};
