import { useQuery } from "@tanstack/react-query";
import { axiosApi } from "./apiService";

export const useGetClientsList = () =>
  useQuery(
    ["getClientsList"],
    async () => {
      const response = await axiosApi.get(`/clients`);
      return response.data;
    },
    {
      refetchOnWindowFocus: false,
    }
  );


