import { useQuery } from "@tanstack/react-query";
import { ApiServiceErr, axiosApi } from "./apiService";

export interface CommonValues {
  key?: string;
  indicator?: string;
  measure?: number;
  unit?: string;
}

export interface CycleStages {
  cultivationImpact?: string;
  processImpact?: string;
  transportImpact?: string;
  storageImpact?: string;
}

interface ProcessedProductSummary {
  productDetail: any;
  productName: string;
  totalImpact: CommonValues[];
  climateChange: CommonValues;
  cycleStages: CycleStages;
  errors: string[];
  warnings: string[];
}

export interface CommonValuesHistory {
  key?: string;
  indicator?: string;
  measure?: number;
  unit?: string;
  createdDate?: Date;
}

export interface ProductSummaryHistory {
  totalImpact: CommonValuesHistory[];
  climateChange: CommonValuesHistory;
  cycleStages: CycleStages;
}

interface ProcessedProductSummaryHistory {
  productDetail: any;
  productName: string;
  productSummaryHistory: ProductSummaryHistory[];
}

interface ProcessedProductSummaryOptions {
  productId?: string;
  salesUnitId?: string;
}

export const useGetProductSummary = ({
  productId,
  salesUnitId,
}: ProcessedProductSummaryOptions) =>
  useQuery<ProcessedProductSummary, ApiServiceErr>(
    [productId, salesUnitId],
    async () => {
      if (!productId || !salesUnitId) {
        return null;
      }
      const response = await axiosApi.get("/product-summary/by-id", {
        params: { productId, salesUnitId },
      });
      return response.data;
    }
  );

export const useGetProductSummaryHistory = ({
  productId,
  salesUnitId,
}: ProcessedProductSummaryOptions) =>
  useQuery<ProcessedProductSummaryHistory, ApiServiceErr>(
    [productId, salesUnitId, "useGetProductSummaryHistory"],
    async () => {
      if (!productId || !salesUnitId) {
        return null;
      }
      const response = await axiosApi.get("/product-summary/history", {
        params: { productId, salesUnitId },
      });
      return response.data;
    },
    {
      enabled: !!productId && !!salesUnitId,
    }
  );
