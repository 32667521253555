import { useEffect, useState } from "react";
import { Grid, Table, Dimmer, Loader } from "semantic-ui-react";

import {
  CustomButton,
  CommonTable,
  TitleBar,
  ImageView,
} from "../../../components";
import {
  ProductFilterData,
  UserFilterData,
  UserTableCol,
} from "../../../config/constants";
import ProductFilter from "./UserFilter";
import { TbFileSearch } from "react-icons/tb";
import { MdModeEditOutline, MdDeleteOutline } from "react-icons/md";

import ConfirmModal from "../../../components/confirmViewModal/ConfirmModal";
import UserRegisterModal from "../userRegister/UserRegisterModal";
import UserDetailsModal from "../userDetails/UserDetailsModal";
import UserEditModal from "../userEdit/UserEditModal";
import defaultUserImage from "../../../assets/images/default_profile_pic.jpeg";
import { useDeleteUserData, useGetUserDetails, useGetUserRole } from "../../../api/userDetails";
import { successMessage } from "../../../helpers/ErrorHandler";
import PaginationView from "../../../components/pagination/Pagination";

const UserList = () => {

  const [selectFilterIndex, setSelectFilter] = useState(0);
  const [visibleModal, setVisibleModal] = useState(false);
  const [visibleUserDetailsModal, setVisibleUserDetailsModal] = useState(false);
  const [visibleUserEditModal, setVisibleUserEditModal] = useState(false);
  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false);
  const [userId, setUserId] = useState("");
  const [deleteData, setDeleteData] = useState<any>({});
  const viewFilter = selectFilterIndex == 3;
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const {
    data: usersData,
    isLoading: userLoading,
    refetch,
  } = useGetUserDetails({
    status: "ACTIVE",
    pageNumber,
    pageSize,
  });

  const {
    isLoading,
    isSuccess = false,
    mutate,
    reset: dataReset,
  } = useDeleteUserData();

  const {
    data:UserRoles,
  } = useGetUserRole();

  const totalPages = Math.ceil((usersData?.total || 0) / (usersData?.pageSize || 0));

  // get user list data
  const getUserDetails = (id: any, type: any) => {
    setUserId(id);
    if (type == "edit") {
      setVisibleUserEditModal(true);
    } else {
      setVisibleUserDetailsModal(true);
    }
  };

  // handle custom hook
  useEffect(() => {
    if (isSuccess) {
      successMessage("User inactive successfully ");
      setVisibleModal(false);
      refetch();
    }
  }, [isSuccess]);

  //delete user data
  const deleteUserDetails = (data: any) => {
    mutate(data?._id);
    setDeleteData({});
  };

  //handle loading
  if (userLoading || isLoading) {
    return (
      <Dimmer active>
        <Loader content="Loading" />
      </Dimmer>
    );
  }
  
  //load user table
  const loadTableData = () => {
    return usersData?.result?.map((data: any, index: any) => {
      return (
        <Table.Row className="tbleR" key={index}>
          <Table.Cell>
            <ImageView
              avatar={true}
              imgOne={data?.profileUrl || defaultUserImage}
              imgTwo={data?.profileUrl || defaultUserImage}
              addRandomId={false}
              customImageView={"userProfileTopHeader"}
            />
          </Table.Cell>
          <Table.Cell>
            <p>{data.firstName}</p>
          </Table.Cell>
          <Table.Cell>
            <p>{data.lastName}</p>
          </Table.Cell>
          <Table.Cell>
            <p>{data.email}</p>
          </Table.Cell>
          <Table.Cell>
            <p>{data.userRole?.[0]}</p>
          </Table.Cell>
          <Table.Cell>
            <p>{data.status}</p>
          </Table.Cell>

          <Table.Cell>
            <Grid>
              <Grid.Column computer={4} tablet={5} mobile={5}>
                <TbFileSearch
                  cursor={"pointer"}
                  onClick={() => {
                    getUserDetails(data._id, "view");
                  }}
                  size={24}
                  color="var(--tableEditIcon)"
                />
              </Grid.Column>
              <Grid.Column computer={4} tablet={5} mobile={5}>
                <MdModeEditOutline
                  cursor={"pointer"}
                  onClick={() => {
                    getUserDetails(data._id, "edit");
                  }}
                  size={24}
                  color="var(--tableEditIcon)"
                />
              </Grid.Column>
              <Grid.Column computer={4} tablet={5} mobile={5}>
                <MdDeleteOutline
                  cursor={"pointer"}
                  size={24}
                  color="var(--tableEditIcon)"
                  onClick={() => {
                    setVisibleDeleteModal(true);
                    setDeleteData(data);
                  }}
                />
              </Grid.Column>
            </Grid>
          </Table.Cell>
        </Table.Row>
      );
    });
  };

  return (
    <>
      <TitleBar
        enableTitle={true}
        titleName={"Register User"}
        enableCustomButton={true}
        customButtonAction={(index: any) => {
          setSelectFilter(index);
        }}
        customButtonData={UserFilterData}
        selectFilterIndex={selectFilterIndex}
      />

      <CustomButton
        theme="green"
        title="Add New user"
        onClick={() => setVisibleModal(true)}
        customColumnStyle="topButtonView"
        iconWithText={true}
        iconName="plus"
      />
      {viewFilter ? (
        <ProductFilter
          currentData={ProductFilterData}
          defaultValue={""}
          status={viewFilter}
        />
      ) : null}

      <Grid>
        <Grid.Column computer={16} tablet={16} mobile={16}>
          <CommonTable tableHeaderData={UserTableCol}>
            {loadTableData()}
          </CommonTable>
        </Grid.Column>
      </Grid>
      <PaginationView
        currentPageNo={pageNumber}
        totalPages={totalPages}
        pageSize={pageSize}
        currentSelectPage={(page: number) => {
          setPageNumber(page);
        }}
        changePageSize={(pageSize: number) => {
          setPageNumber(1);
          setPageSize(pageSize);
        }}
      />
      <UserRegisterModal
        visibleModal={visibleModal}
        setVisibleModal={() => {
          setVisibleModal(false);
          refetch();
        }}
        userRolesData={UserRoles}
      />
      <UserDetailsModal
        visibleModal={visibleUserDetailsModal}
        setVisibleModal={() => setVisibleUserDetailsModal(false)}
        id={userId}
        userRolesData={UserRoles}
      />
      <UserEditModal
        visibleModal={visibleUserEditModal}
        setVisibleModal={() => {
          setVisibleUserEditModal(false);
          refetch();
        }}
        id={userId}
        userRolesData={UserRoles}
      />
      <ConfirmModal
        viewModal={visibleDeleteModal}
        closeModal={() => setVisibleDeleteModal(false)}
        cancel={() => {
          setVisibleDeleteModal(false);
          setDeleteData({});
        }}
        approve={() => {
          setVisibleDeleteModal(false);
          deleteUserDetails(deleteData);
        }}
        title="Delete user data"
        subTitle="Are you sure you want to delete your user?"
      />
    </>
  );
};

export default UserList;
