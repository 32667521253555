import { useQuery, useMutation, useQueries } from "@tanstack/react-query";
import { ApiServiceErr, MutOptions, axiosApi } from "./apiService";
import { PaginationResult } from "../types/Pagination";
import { successMessage } from "../helpers/ErrorHandler";

interface Products {
  _id: string;
  product_name: string;
}

interface Ingredients {
  _id: string;
  ingredient_name: string;
  productIds: string[];
}

interface SupplierIngredientDetails {
  _id: string;
  supplier_id: string;
  ingredient_id: string;
  data_type: string;
}

export interface Supplier {
  _id: string;
  supplier_name: string;
  supplier_contact_name: string;
  supplier_email: string;
  supplier_phone: string;
  products: Products[];
  ingredients: Ingredients[];
  supplierIngredientDetails: SupplierIngredientDetails[];
}

interface PaginationParams {
  pageNumber: number;
  pageSize: number;
}

export const useGetSuppliers = ({ pageNumber, pageSize }: PaginationParams) =>
  useQuery<PaginationResult<Supplier[]>, ApiServiceErr>(
    ["GetSuppliersWithPagination", pageNumber, pageSize],
    async () => {
      const response = await axiosApi.get("/supplier", {
        params: { pageNumber, pageSize },
      });
      return response.data;
    }
  );

export const useUpdateSupplierDetails = (opt?: MutOptions<string>) =>
  useMutation<string, ApiServiceErr, any>(async (data) => {
    const response = await axiosApi.put(`/supplier/${data._id}`, data);
    successMessage(response.data);
    return response.data;
  }, opt);

export const useGetSupplierById = (id: string | null) =>
  useQuery<Supplier>(
    ["getSupplierId", id],
    async () => {
      const response = await axiosApi.get(`/supplier/${id}`);
      return response.data;
    },
    {
      enabled: !!id,
    }
  );

export const useGetSuppliersByIds = (supplierIds: string[]) => {
  return useQueries({
    queries: supplierIds.map(
      (id) => {
        return {
          queryKey: ["getSupplierId", id],
          queryFn: async () => {
            const response = await axiosApi.get(`/supplier/${id}`);
            return response.data;
          },
        };
      },
      { enabled: !!supplierIds }
    ),
  });
};

export const useCreateSupplierDrawingRequest = (opt?: MutOptions<string>) =>
  useMutation<string, ApiServiceErr, any>(async (data) => {
    const response = await axiosApi.post("/supplier-drawing-request", data);
    successMessage(response.data);
    return response.data;
  }, opt);
