import React, { useEffect, useState, useRef, useCallback } from "react";
import { Grid } from "semantic-ui-react";
import { useForm, useWatch } from "react-hook-form";

import { InputText, CustomButton, SearchValue } from "../../../components";
import { usePostIngredientData } from "../../../api/ingredients";
import { successMessage } from "../../../helpers/ErrorHandler";

import "./IngredientRegister.scss";
interface HandleValidation {
  checkValidation(): void;
}

const IngredientDataRegister = ({
  ingredientListByProduct = [],
  ingredientRegisterData = () => {},
  refetchIngredient = () => {},
  currentIngredientData,
  disableEdit = false,
  showSupplier = () => {},
  setViewPackage
}: any) => {
  const [newIngredient, setNewIngredient] = useState(false);
  const [ingredientData, setIngredientData] = useState<any>({});
  const validIngredient = useRef<HandleValidation>(null);

  const { mutateAsync: mutatePostIngredient } = usePostIngredientData();

  const {
    register,
    reset,
    handleSubmit,
    setValue,
    formState: { errors },
    getValues,
    control,
  } = useForm();

  const ingredientNameChange = useWatch({
    control,
    name: "ingredient_name",
    defaultValue: getValues().ingredient_name,
  });

  //update ingredient name after add ingredient
  const checkValueISChange = useCallback(() => {
    if (ingredientData.ingredient_name == ingredientNameChange) return;
    setValue(
      "ingredient_name",
      ingredientNameChange
        ? ingredientNameChange
        : currentIngredientData?.ingredient_name
    );
    let updateObject = {
      ...currentIngredientData,
      ingredient_name: ingredientNameChange,
    };
    setIngredientData(updateObject);
    ingredientRegisterData(updateObject);
  }, [ingredientNameChange, ingredientData]);

  const setIngredientValue = useCallback(() => {
    if (disableEdit) {
      setValue("ingredient_name", ingredientData?.ingredient_name || "");
    }
  }, [disableEdit, ingredientData]);

  useEffect(() => {
    setIngredientValue();
    if (currentIngredientData) {
      setIngredientData(currentIngredientData);
    }
  }, [currentIngredientData, setIngredientValue, checkValueISChange]);

  const getSearchValue = (data: any) => {
    if (data?.result?.id === 0) {
      setValue("ingredient_name","");
      setNewIngredient(true);
      showSupplier(false);
      setViewPackage({
        processIngredient: false,
        produceIngredient: false,
      })
      ingredientRegisterData(undefined);
      setIngredientData({});
    } else {
      setNewIngredient(false);
      let ingredientData = {
        ingredient_name: data?.result?.title,
        ingredient_data:
          data?.result?.other?.ingredientData || data?.result?.ingredientDat,
      };
      ingredientRegisterData(ingredientData);
      setIngredientData(ingredientData);
      if (showSupplier) {
        showSupplier(true);
      }
    }
  };

  const handleSearchValidation = (status: any) => {
    if (status) {
      ingredientRegisterData(undefined);
    }
  };

  const onSubmit = (data: any) => {
    const dataToSubmit = {
      ingredient_name: data.ingredient_name,
      ingredient_status: "ACTIVE",
    };
    mutatePostIngredient(dataToSubmit, {
      onSuccess(data: any) {
        successMessage("Ingredient added successfully");
        let ingredientData = {
          ingredient_name: data?.ingredient_name,
          ingredient_data: data,
        };
        ingredientRegisterData(ingredientData);
        setIngredientData(ingredientData);
        setNewIngredient(false);
        refetchIngredient();
        if (showSupplier) {
          showSupplier(true);
        }
      },
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div style={{ marginBottom: 20 }}>
        <Grid>
          {newIngredient ? (
            <>
              <Grid.Column computer={10} tablet={8} mobile={16}>
                <InputText
                  register={register}
                  errors={errors.ingredient_name}
                  labelName={"Ingredient Name*"}
                  placeholder="Type your ingredient name"
                  name="ingredient_name"
                  required={true}
                  errorMessage={"Please enter ingredient name"}
                />
              </Grid.Column>
              <Grid.Column computer={3} tablet={8} mobile={16}>
                <CustomButton
                  theme="green"
                  customColumnStyle={"customIngredientUnitButton"}
                  title="Save"
                  type="submit"
                />
              </Grid.Column>
              <Grid.Column computer={3} tablet={8} mobile={16}>
                <CustomButton
                  theme="green"
                  onClick={() => {
                    setValue("ingredient_name","");
                    setIngredientData({});
                    setNewIngredient(false);
                  }}
                  customColumnStyle={"customIngredientUnitButton"}
                  title="Back"
                  type="button"
                />
              </Grid.Column>
            </>
          ) : (
            <Grid.Column computer={16} tablet={8} mobile={16}>
              {disableEdit ? (
                <InputText
                  register={register}
                  errors={errors.ingredient_name}
                  labelName={"Ingredient Name*"}
                  placeholder="Type your ingredient name"
                  name="ingredient_name"
                  required={true}
                  errorMessage={"Please enter ingredient name"}
                />
              ) : (
                <SearchValue
                  ref={validIngredient}
                  searchDataValues={ingredientListByProduct}
                  checkOther={(status: any) => {
                    handleSearchValidation(status);
                  }}
                  title={"Ingredient Name*"}
                  validationMessage="Please insert ingredient name"
                  defaultValue={ingredientData.ingredient_name}
                  selectDetails={getSearchValue}
                  disable={false}
                />
              )}
            </Grid.Column>
          )}
        </Grid>
      </div>
    </form>
  );
};

export default IngredientDataRegister;
