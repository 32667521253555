import * as React from "react";

const ProcessGateIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <circle cx={24} cy={24} r={24} fill="var(--mappingImageBackground)" />
    <path
      fill="var(--mappingIconColor)"
      d="M16.133 12.125a.76.76 0 0 0-.758.758v12.924l-1.717.86a.27.27 0 0 0-.158.24v6.578h1.508v-1.957c0-.158.127-.285.285-.285h3.172c.157 0 .285.127.285.285v1.957h1.508v-1.957c0-.158.127-.285.285-.285h3.172c.157 0 .285.127.285.285v1.957h1.508v.008h2.257v-2.858c0-.158.127-.285.285-.285h3.915c.158 0 .285.127.285.285v2.857h2.235V20.84a.847.847 0 0 0-.847-.848h-7.283a.847.847 0 0 0-.848.848v2.895l-1.882.94V12.882a.76.76 0 0 0-.758-.757h-1.485a.76.76 0 0 0-.757.758v13.29l-.72.36a.276.276 0 0 1-.405-.248v-2.093a.281.281 0 0 0-.405-.247l-.72.36V12.883a.76.76 0 0 0-.758-.758h-1.485Zm-.008 13.307v-1.314h1.5v.563l-1.5.751Zm6.75-1.314v.932l-1.5.748v-1.68h1.5Zm-6.75-8.993h1.5v1.492h-1.5v-1.492Zm1.5 4.492v1.5h-1.5v-1.5h1.5Zm3.75-4.492h1.5v1.492h-1.5v-1.492Zm1.5 4.492v1.5h-1.5v-1.5h1.5Zm4.523 3.398a.4.4 0 0 1-.398-.398v-.727a.4.4 0 0 1 .398-.398h.704a.4.4 0 0 1 .398.398v.727c0 .226-.18.398-.398.398h-.704Zm3.352-1.125v.727c0 .226-.18.398-.398.398h-.704a.4.4 0 0 1-.398-.398v-.727a.4.4 0 0 1 .398-.398h.704a.4.4 0 0 1 .398.398Zm2.25 0v.727c0 .226-.18.398-.398.398h-.704a.4.4 0 0 1-.398-.398v-.727a.4.4 0 0 1 .398-.398h.704a.4.4 0 0 1 .398.398Zm-4.5 2.242v.728a.4.4 0 0 1-.398.398h-.704A.4.4 0 0 1 27 24.86v-.727a.4.4 0 0 1 .398-.398h.704a.4.4 0 0 1 .398.398Zm2.25 0v.728a.4.4 0 0 1-.398.398h-.704a.4.4 0 0 1-.398-.398v-.727a.4.4 0 0 1 .398-.398h.704a.4.4 0 0 1 .398.398Zm2.25 0v.728a.4.4 0 0 1-.398.398h-.704a.4.4 0 0 1-.398-.398v-.727a.4.4 0 0 1 .398-.398h.704a.4.4 0 0 1 .398.398Zm-4.5 2.235v.728c0 .225-.18.398-.398.398h-.704a.4.4 0 0 1-.398-.398v-.727a.4.4 0 0 1 .398-.398h.704a.4.4 0 0 1 .398.398Zm2.25 0v.728c0 .225-.18.398-.398.398h-.704a.4.4 0 0 1-.398-.398v-.727a.4.4 0 0 1 .398-.398h.704a.4.4 0 0 1 .398.398Zm2.25 0v.728c0 .225-.18.398-.398.398h-.704a.4.4 0 0 1-.398-.398v-.727a.4.4 0 0 1 .398-.398h.704a.4.4 0 0 1 .398.398Z"
    />
  </svg>
);
export default ProcessGateIcon;
