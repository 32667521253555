import React from "react";

import ProductSummary from "../screens/productSummary/ProductSummary";
import { adminAccess, userAccess } from "./useRoleAccess";

export const ProductSummaryRoutes = [
  {
    path: "/product-summary",
    route: <ProductSummary />,
    protectRoutes: true,
    roleGrantAccess: [...adminAccess, ...userAccess],
  },
];
