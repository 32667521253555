import { useMutation, useQuery } from "@tanstack/react-query";
import { ApiServiceErr, axiosApi } from "../../apiService";
import { successMessage } from "../../../helpers/ErrorHandler";

interface farmGatePackage {
  packageName: string;
  packageMaterial: number;
  materialWeight: number;
  contentWeight: number;
  totalWeightWithPackaging: number;
}

interface farmGatePackageMethod {
  packageName: string;
  packageMaterial: number;
  materialWeight: number;
  contentWeight: number;
  totalWeightWithPackaging: number;
}

interface farmGatePackageMethodInsert {
  data: farmGatePackageMethod;
  packagingId: string;
  farmGateId: string;
}

interface farmGatePackageMethodUpdateInsert {
  data: farmGatePackageMethod;
  packagingMethodId: string;
  farmGateId: string;
}

interface farmGatePackageInsert {
  data: farmGatePackage;
  packagingType: string;
  farmGateId: string;
  packagingId: string;
}

interface farmGatePackage {
  unitSize: number;
  numberOfBaseUnits: number;
  numberOfPacks: number;
  numberOfCases: number;
  packagingType: string;
  farmGateId: string;
  packagingId: string;
}

interface farmGatePackageMethodDelete {
  packagingMethodId: string;
  farmGateId: string;
  packagingId: string;
}

interface farmGatePackageDelete {
  packagingType: string;
  farmGateId: string;
  packagingId: string;
  subPackagingId: string;
}

interface farmGateAllPackageDelete {
  farmGateId: string;
  packagingId: string;
}

export const useGetPackagingSimulationData = (packagingId?: string) =>
  useQuery<any, ApiServiceErr>(
    [packagingId, "getFarmPackagingSimulationData"],
    async () => {
      if (!packagingId) {
        return null;
      }
      const response = await axiosApi.get(
        `/farm-gate-simulation/packaging/${packagingId}`
      );
      return response?.data;
    },
    {
      cacheTime: 0,
      enabled: !!packagingId,
    }
  );

export const useCreatePackagingSimulation = (opt?: any) =>
  useMutation<any, ApiServiceErr, any>(async (data: farmGatePackageInsert) => {
    if (data.packagingId) {
      const response = await axiosApi.post(
        `/farm-gate-simulation/packaging/${data.packagingType}/${data.farmGateId}`,
        data.data,
        {
          params: { packagingId: data.packagingId },
        }
      );
      return response.data;
    } else {
      const response = await axiosApi.post(
        `/farm-gate-simulation/packaging/${data.packagingType}/${data.farmGateId}`,
        data.data
      );
      return response.data;
    }
  }, opt);

export const useUpdatePackagingSimulation = (opt?: any) =>
  useMutation<farmGatePackage, ApiServiceErr, any>(
    async (data: farmGatePackageInsert) => {
      const response = await axiosApi.put(
        `/farm-gate-simulation/packaging/${data.packagingType}/${data.packagingId}`,
        data.data,
        {
          params: { farmGateId: data.farmGateId },
        }
      );
      return response.data;
    },
    opt
  );

export const useUpdatePackagingSimulationDetails = (opt?: any) =>
  useMutation<any, ApiServiceErr, any>(async (data: farmGatePackageInsert) => {
    const response = await axiosApi.post(
      `/farm-gate-simulation/packaging/detail`,
      data.data,
      {
        params: {
          farmGateId: data.farmGateId,
          packagingId: data.packagingId,
        },
      }
    );
    return response.data;
  }, opt);

export const useGetWasteManagement = () =>
  useQuery<any, ApiServiceErr>(
    ["getFarmProcessWasteMangementData"],
    async () => {
      const response = await axiosApi.get(
        `/static-data/waste-management-types`
      );

      let wasteManagementTypes = response?.data.reduce(
        (currentType: any, data: any) => {
          let wasteType = {
            key: data?.id,
            text: data?.waste_management_type,
            value: data?.id,
          };
          return [...currentType, wasteType];
        },
        []
      );
      return [
        {
          key: "1",
          text: "   ",
          value: "",
        },
        ...wasteManagementTypes,
      ];
    },
    {
      cacheTime: 0,
    }
  );

export const useDeletePackagingSimulation = (opt?: any) =>
  useMutation<any, ApiServiceErr, any>(async (data: farmGatePackageDelete) => {
    const response = await axiosApi.delete(
      `/farm-gate-simulation/packaging/${data.packagingType}/${data.subPackagingId}`,
      {
        params: {
          packagingId: data.packagingId,
          farmGateId: data.farmGateId,
        },
      }
    );
    return response.data;
  }, opt);

export const useCreatePackagingSimulationMethod = (opt?: any) =>
  useMutation<any, ApiServiceErr, any>(
    async (data: farmGatePackageMethodInsert) => {
      if (data.packagingId) {
        const response = await axiosApi.post(
          `/farm-gate-simulation/packaging-method`,
          data.data,
          {
            params: {
              packagingId: data.packagingId,
              farmGateId: data.farmGateId,
            },
          }
        );
        return response.data;
      } else {
        const response = await axiosApi.post(
          `/farm-gate-simulation/packaging-method`,
          data.data,
          {
            params: { farmGateId: data.farmGateId },
          }
        );
        return response.data;
      }
    },
    opt
  );

export const useUpdatePackagingSimulationMethod = (opt?: any) =>
  useMutation<farmGatePackageMethod, ApiServiceErr, any>(
    async (data: farmGatePackageMethodUpdateInsert) => {
      const response = await axiosApi.put(
        `/farm-gate-simulation/packaging-method/${data.packagingMethodId}`,
        data.data,
        {
          params: { farmGateId: data.farmGateId },
        }
      );
      return response.data;
    },
    opt
  );

export const useDeletePackagingSimulationMethod = (opt?: any) =>
  useMutation<any, ApiServiceErr, any>(
    async (data: farmGatePackageMethodDelete) => {
      const response = await axiosApi.delete(
        `/farm-gate-simulation/packaging-method/${data.packagingMethodId}`,
        {
          params: {
            farmGateId: data.farmGateId,
            packagingId: data.packagingId,
          },
        }
      );
      return response.data;
    },
    opt
  );

export const useGetPackagingSimulationTypes = () =>
  useQuery<any, ApiServiceErr>(
    ["getFarmPackagingSimulationTypes"],
    async () => {
      const response = await axiosApi.get(`/static-data/packaging-types`);
      let createPackagingType = response?.data.reduce(
        (currentType: any, data: any) => {
          let packagingTypeData = {
            key: data?.id,
            text: data?.packaging_name,
            value: data?.id,
          };
          return [...currentType, packagingTypeData];
        },
        []
      );
      return createPackagingType;
    },
    {
      cacheTime: 0,
    }
  );

export const useDeleteAllPackagingSimulation = (opt?: any) =>
  useMutation<any, ApiServiceErr, any>(
    async (data: farmGateAllPackageDelete) => {
      const response = await axiosApi.delete(
        `/farm-gate-simulation/packaging/${data.packagingId}`,
        {
          params: {
            farmGateId: data.farmGateId,
          },
        }
      );
      return response.data;
    },
    opt
  );
