import { Grid } from "semantic-ui-react";
import { useGetProduceTypes } from "../../../api/static-data";
import { useMemo } from "react";

const ProduceIngredientView = ({
  produceType,
  produceCultivar,
  farmingType,
  fieldType,
  countryName,
}: any) => {
  const { data: produceTypesData, isSuccess: isProduceTypesSuccess } =
    useGetProduceTypes();

  const produceTypes = useMemo(() => {
    if (isProduceTypesSuccess) {
      return produceTypesData?.map((p: any) => ({
        text: p.produce_name,
        key: p.id,
        value: p.id,
      }));
    }
  }, [produceTypesData, isProduceTypesSuccess]);

  const produceTypeName = produceTypes?.find(
    (p) => parseInt(p.value) === parseInt(produceType)
  )?.text;

  return (
    <Grid className="dataBox">
      <Grid.Column
        computer={4}
        tablet={8}
        mobile={16}
        className="paddingRemoveTop"
      >
        <p className="mb-0">Produce Type</p>
        {produceTypeName}
      </Grid.Column>
      <Grid.Column
        computer={4}
        tablet={8}
        mobile={16}
        className="paddingRemoveTop"
      >
        <p className="mb-0">Produce Cultivar</p>
        {produceCultivar}
      </Grid.Column>
      <Grid.Column
        computer={4}
        tablet={8}
        mobile={16}
        className="paddingRemoveTop"
      >
        <p className="mb-0">Farming Type</p>
        {farmingType}
      </Grid.Column>
      <Grid.Column
        computer={4}
        tablet={8}
        mobile={16}
        className="paddingRemoveTop"
      >
        <p className="mb-0">Field Type</p>
        {fieldType}
      </Grid.Column>
      <Grid.Column
        computer={4}
        tablet={8}
        mobile={16}
        className="paddingRemoveTop"
      >
        <p className="mb-0">Country</p>
        {countryName}
      </Grid.Column>
    </Grid>
  );
};

export default ProduceIngredientView;
