import { initializeApp } from "@firebase/app";
import {
  MessagePayload,
  getMessaging,
  getToken,
  onMessage,
} from "@firebase/messaging";
import { postUserFCMToken } from "../api/userDetails";

const firebaseConfig = {
  apiKey: "AIzaSyALiJrtvdqrZ65zoi3NbEuFITaG5jaO73Y",
  authDomain: "naturepreserve-ee0b1.firebaseapp.com",
  projectId: "naturepreserve-ee0b1",
  storageBucket: "naturepreserve-ee0b1.appspot.com",
  messagingSenderId: "729895157080",
  appId: "1:729895157080:web:4c6971c31fba40001a392d",
  measurementId: "G-FE5928X4QD",
};

export const messagingKey = "R-JWOPxDRSIa87YC887jNe-oNAlAjS4UIf1WjKtdv3E";

const firebaseApp = initializeApp(firebaseConfig);
const cloudMessaging = getMessaging(firebaseApp);
const setupNotifications = async (
  user: any,
  callback: {
    (message: any): void;
    (arg0: MessagePayload): void;
  }
) => {
  try {
    // Request permission for notifications
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      // Get the FCM token
      const token = await getToken(cloudMessaging);
      if (!user.fcmTokens || user.fcmTokens?.indexOf(token) === -1) {
        await postUserFCMToken(token);
      }
    }
    // Handle foreground notifications
    onMessage(cloudMessaging, (payload) => {
      callback(payload);
      // Handle the notification or update your UI
    });
  } catch (error) {
    console.error("Error setting up notifications:", error);
  }
};
export { cloudMessaging, setupNotifications };
