import { useState } from "react";
import { Grid, Table, Dimmer, Loader, Button } from "semantic-ui-react";
import { CommonModal, CommonTable } from "../../../components";
import {
  ProductSummaryTableCol,
  ProductSummaryHistoryTableCol,
} from "../../../config/constants";
import { useGetProductSummaryHistory } from "../../../api/productSummary";

const ProductSummaryDetailHistory = ({
  visibleModal,
  setVisibleModal,
  productId,
  salesUnitId,
}: any) => {
  const [totalImpact, setTotalImpact] = useState([]);

  const {
    data: productSummaryHistory,
    isLoading: isProductSummaryHistoryLoading,
  } = useGetProductSummaryHistory({
    productId,
    salesUnitId,
  });

  const productSummaryHistoryData =
    productSummaryHistory?.productSummaryHistory || [];

  const loadHistoryTableData = () => {
    return productSummaryHistoryData.map((data: any, index: any) => {
      const { climateChange, totalImpact } = data;
      return (
        <Table.Row className="tbleR" key={index}>
          <Table.Cell>
            <p>
              {new Date(climateChange?.createdDate).toDateString()}{" "}
              {new Date(climateChange?.createdDate).toLocaleTimeString()}
            </p>
          </Table.Cell>
          <Table.Cell>
            <p>{`${climateChange.measure?.toFixed(2)} ${
              climateChange.unit
            }`}</p>
          </Table.Cell>
          <Table.Cell>
            <Grid>
              <Grid.Column
                computer={16}
                tablet={16}
                mobile={16}
                key={index}
                className="alignRight addViewDetails"
              >
                <div
                  key={index}
                  className={"customButtonViewSelect customButtonViewTitle"}
                  onClick={() => setTotalImpact(totalImpact)}
                >
                  <p className={"customButtonTextSelect"}>Details</p>
                </div>
              </Grid.Column>
            </Grid>
          </Table.Cell>
        </Table.Row>
      );
    });
  };

  const loadTableData = () => {
    return totalImpact.map((data: any, index: any) => {
      return (
        <Table.Row className="tbleR" key={index}>
          <Table.Cell>
            <p>{data.indicator}</p>
          </Table.Cell>
          <Table.Cell>
            <p>{data.measure}</p>
          </Table.Cell>
          <Table.Cell>
            <p>{data.unit}</p>
          </Table.Cell>
          <Table.Cell>
            <Grid>
              <Grid.Column
                computer={16}
                tablet={16}
                mobile={16}
                key={index}
                className="alignRight addViewDetails"
              ></Grid.Column>
            </Grid>
          </Table.Cell>
        </Table.Row>
      );
    });
  };

  // Loading state check
  if (isProductSummaryHistoryLoading) {
    return (
      <Dimmer active>
        <Loader content="Loading" />
      </Dimmer>
    );
  }

  return (
    <>
      <CommonModal
        modalOpenState={visibleModal}
        modalCloseState={() => {
          setVisibleModal(false);
          setTotalImpact([]);
        }}
        centered={true}
        
        title="Product Summary History"
        style={{ overflow: "scroll" }}
      >
        <CommonTable tableHeaderData={ProductSummaryHistoryTableCol}>
          {loadHistoryTableData()}
        </CommonTable>
        {totalImpact.length > 0 ? (
          <CommonTable tableHeaderData={ProductSummaryTableCol}>
            {loadTableData()}
          </CommonTable>
        ) : null}
      </CommonModal>
    </>
  );
};

export default ProductSummaryDetailHistory;
