export const calculateTotalWeight = (
  packaging: PackagingData
): PackagingData => {
  if (!packaging) return packaging;
  let totalWeightBaseUnit = 0;
  let totalWeightSalesOfPack = 0;
  let totalWeightBulkPack = 0;
  let totalWeightTransportUnitPack = 0;

  totalWeightBaseUnit = packaging.unitSize || 0;
  // Calculate total weight for single unit packaging
  for (const singleUnitPackaging of packaging.singleUnitPackaging || []) {
    const materialWeight = singleUnitPackaging.materialWeight || 0;
    totalWeightBaseUnit += materialWeight;
  }

  // Calculate total weight for sales unit packaging
  totalWeightSalesOfPack =
    totalWeightBaseUnit * (packaging.numberOfBaseUnits || 1);
  for (const salesUnitPackaging of packaging.salesUnitPackaging || []) {
    const materialWeight = salesUnitPackaging.materialWeight || 0;
    totalWeightSalesOfPack += materialWeight;
  }

  // Calculate total weight for bulk unit packaging
  totalWeightBulkPack = totalWeightSalesOfPack * (packaging.numberOfPacks || 1);
  for (const bulkUnitPackaging of packaging.bulkUnitPackaging || []) {
    const materialWeight = bulkUnitPackaging.materialWeight || 0;
    totalWeightBulkPack += materialWeight;
  }

  // Calculate total weight for transport unit packaging
  totalWeightTransportUnitPack =
    totalWeightBulkPack * (packaging.numberOfCases || 1);
  for (const transportUnitPackaging of packaging.transportUnitPackaging || []) {
    const materialWeight = transportUnitPackaging.materialWeight || 0;
    totalWeightTransportUnitPack += materialWeight;
  }

  return {
    ...packaging,
    totalWeightBaseUnit,
    totalWeightSalesOfPack,
    totalWeightBulkPack,
    totalWeightTransportUnitPack,
  };
};
