import { useState, useEffect, useMemo } from "react";
import { Dimmer, Grid, Loader } from "semantic-ui-react";
import { useForm } from "react-hook-form";

import {
  InputText,
  CustomButton,
  CommonModal,
  TitleView,
  DropDown,
} from "../../../components";
import useWindowDimensions from "../../../hooks/windowsSize";
import { useGetCountries } from "../../../api/static-data";

import "./companyEdit.scss";
import { InputNumber } from "../../../components/InputText/InputNumber";
import { useGetCompanyById, useUpdateCompanyData } from "../../../api/company";
import { successMessage } from "../../../helpers/ErrorHandler";
import { InputDatePicker } from "../../../components/InputDatePicker/InputDatePicker";

const CompanyEditModal = ({ visibleModal, setVisibleModal, id }: any) => {
  const { height, width } = useWindowDimensions();
  const [startDate, setStartDate] = useState(new Date());

  //custom hook for get company data by id
  const {
    data: companyDetails,
    isLoading: companyLoading,
    mutate,
    reset: getCompanyReset,
  } = useGetCompanyById();
  const [country, setCountry] = useState<any>(companyDetails?.tenantCountry);

  const {
    data,
    isLoading,
    isSuccess = false,
    mutate: updateCompanyData,
    reset: companyUpdateReset,
  } = useUpdateCompanyData();

  //company input data changes
  const values = useMemo(() => {
    setStartDate(companyDetails?.dateOfSubscription || "");
    return {
      name: companyDetails?.name || "",
      address: companyDetails?.address || "",
      companyEmail: companyDetails?.companyEmail || "",
      noOfEmployees: companyDetails?.noOfEmployees || "",
      phone: companyDetails?.phone || "",
      tenantType: companyDetails?.tenantType || "Tenant",
      tenantCountry: companyDetails?.tenantCountry || "",
      zipCode: companyDetails?.zipCode || "",
      noOfProducts: companyDetails?.noOfProducts || "",
      registrationNumber: companyDetails?.registrationNumber || "",
      website: companyDetails?.website || "",
      noOfUsers: companyDetails?.noOfUsers || 1,
      noOfSKUS: companyDetails?.noOfSKUS || 1,
    };
  }, [companyDetails]);

  //custom hook company success
  useEffect(() => {
    if (isSuccess) {
      successMessage("Company data update successfully");
      mutate(id);
      setVisibleModal(false);
    }
  }, [isSuccess]);

  useEffect(() => {
    setCountry(companyDetails?.tenantCountry);
  }, [companyDetails]);

  useEffect(() => {
    if (visibleModal) {
      mutate(id);
    }
  }, [visibleModal]);

  //custom hook company reset data
  useEffect(() => {
    return () => {
      reset();
      getCompanyReset();
      companyUpdateReset();
    };
  }, [visibleModal]);

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
    getValues,
  } = useForm({
    defaultValues: values,
    values: values,
  });

  const {
    data: countriesData,
    isSuccess: isCountriesDataSuccess,
    isLoading: isCountriesDataLoading,
  } = useGetCountries();

  //company data submit
  const onSubmit = async (data: any) => {
    data.status = "ACTIVE";
    data.isParent = false;
    data.tenantCountry = country;
    data.tenantType = "Tenant";
    data.dateOfSubscription = startDate;
    updateCompanyData({ id: companyDetails?._id, data });
  };

  const countries = useMemo(() => {
    if (isCountriesDataSuccess) {
      return countriesData?.map((c: any) => ({
        key: c.id,
        text: c.country,
        value: c.id,
      }));
    }
  }, [countriesData, isCountriesDataSuccess]);

  //handle loading
  if (isLoading || companyLoading) {
    return (
      <Dimmer active>
        <Loader content="Loading" />
      </Dimmer>
    );
  }

  return (
    <>
      <CommonModal
        modalOpenState={visibleModal}
        modalCloseState={() => {
          setVisibleModal(false);
          reset();
        }}
        centered={true}
        title="Company Details"
      >
        <TitleView
          title="Company info"
          CustomTitleViewMain="productRegisterTitle"
        />
        <Grid>
          <Grid.Column computer={16} tablet={16} mobile={16}>
            <>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div
                  style={{
                    height: height * 0.9 - 180,
                    overflowX: "hidden",
                  }}
                >
                  <Grid>
                    <Grid.Column computer={16} tablet={16} mobile={16}>
                      <Grid>
                        <Grid.Column
                          computer={8}
                          tablet={16}
                          mobile={16}
                          className="paddingRemoveBottom"
                        >
                          <InputText
                            register={register}
                            errors={errors.name}
                            labelName={"Company Name"}
                            placeholder="Company Name"
                            name="name"
                            required={true}
                            errorMessage={"Please enter user name"}
                          />
                        </Grid.Column>
                        <Grid.Column
                          computer={8}
                          tablet={16}
                          mobile={16}
                          className="paddingRemoveBottom"
                        >
                          <InputText
                            register={register}
                            errors={errors.address}
                            labelName={"Address"}
                            placeholder="Address"
                            name="address"
                            required={true}
                            errorMessage={"Please enter user name"}
                          />
                        </Grid.Column>

                        <Grid.Column
                          computer={8}
                          tablet={16}
                          mobile={16}
                          className="paddingRemoveTop"
                        >
                          <InputText
                            register={register}
                            errors={errors.companyEmail}
                            labelName={"Company Email"}
                            placeholder="Company Email"
                            name="companyEmail"
                            required={true}
                          />
                        </Grid.Column>
                        <Grid.Column
                          computer={8}
                          tablet={16}
                          mobile={16}
                          className="paddingRemoveTop"
                        >
                          <InputText
                            register={register}
                            errors={errors.phone}
                            labelName={"phone"}
                            placeholder="phone"
                            name="phone"
                            required={false}
                            errorMessage={"Please enter name"}
                          />
                        </Grid.Column>
                        <Grid.Column
                          computer={8}
                          tablet={16}
                          mobile={16}
                          className="paddingRemoveTop"
                        >
                          <InputText
                            register={register}
                            errors={errors.phone}
                            labelName={"Registration Number"}
                            placeholder="Registration Number"
                            name="registrationNumber"
                            required={false}
                            errorMessage={"Please enter name"}
                          />
                        </Grid.Column>
                        <Grid.Column
                          computer={8}
                          tablet={16}
                          mobile={16}
                          className="paddingRemoveTop"
                        >
                          <InputText
                            register={register}
                            errors={errors.phone}
                            labelName={"Website"}
                            placeholder="Website"
                            name="website"
                            required={false}
                            errorMessage={"Please enter name"}
                          />
                        </Grid.Column>

                        <Grid.Column
                          computer={8}
                          tablet={16}
                          mobile={16}
                          className="paddingRemoveTop"
                        >
                          <InputNumber
                            transform={{
                              input: (value: any) =>
                                isNaN(value) || value === 0
                                  ? ""
                                  : value.toString(),
                              output: (e: any) => {
                                return e;
                              },
                            }}
                            control={control}
                            errors={errors.noOfEmployees}
                            name="noOfEmployees"
                            labelName={"No Of Employees"}
                          />
                        </Grid.Column>
                        <Grid.Column
                          computer={8}
                          tablet={16}
                          mobile={16}
                          className="paddingRemoveTop"
                        >
                          <InputNumber
                            defaultValue={1}
                            transform={{
                              input: (value: any) =>
                                isNaN(value) || value === 0
                                  ? ""
                                  : value.toString(),
                              output: (e: any) => {
                                return e;
                              },
                            }}
                            control={control}
                            errors={errors.noOfEmployees}
                            name="noOfUsers"
                            labelName={"No Of Users"}
                            removeDecimal={true}
                          />
                        </Grid.Column>
                        <Grid.Column
                          computer={8}
                          tablet={16}
                          mobile={16}
                          className="paddingRemoveTop"
                        >
                          <InputNumber
                            transform={{
                              input: (value: any) =>
                                isNaN(value) || value === 0
                                  ? ""
                                  : value.toString(),
                              output: (e: any) => {
                                return e;
                              },
                            }}
                            control={control}
                            errors={errors.noOfProducts}
                            name="noOfProducts"
                            labelName={"No Of Products"}
                          />
                        </Grid.Column>
                        <Grid.Column
                          computer={8}
                          tablet={16}
                          mobile={16}
                          className="paddingRemoveTop"
                        >
                          <InputNumber
                            defaultValue={1}
                            transform={{
                              input: (value: any) =>
                                isNaN(value) || value === 0
                                  ? ""
                                  : value.toString(),
                              output: (e: any) => {
                                return e;
                              },
                            }}
                            control={control}
                            errors={errors.noOfProducts}
                            name="noOfSKUS"
                            labelName={"No Of SKUS"}
                            removeDecimal={true}
                          />
                        </Grid.Column>
                        <Grid.Column
                          computer={8}
                          tablet={16}
                          mobile={16}
                          className="paddingRemoveTop"
                        >
                          <InputText
                            register={register}
                            errors={errors.phone}
                            labelName={"Industry"}
                            placeholder="Industry"
                            name="industry"
                          />
                        </Grid.Column>
                        <Grid.Column
                          computer={8}
                          tablet={16}
                          mobile={16}
                          className="paddingRemoveTop"
                        >
                          <DropDown
                            labelName="Country"
                            placeholder="Select country"
                            defaultValue={country}
                            currentData={countries}
                            handleChangeState={(e: any, { value }: any) => {
                              setCountry(value);
                            }}
                            loading={isCountriesDataLoading}
                            search
                            key={"tenantCountry"}
                          />
                        </Grid.Column>
                        <Grid.Column
                          computer={8}
                          tablet={16}
                          mobile={16}
                          className="paddingRemoveTop"
                        >
                          <InputText
                            register={register}
                            errors={errors.zipCode}
                            labelName={"zipCode"}
                            placeholder="zipCode"
                            name="zipCode"
                          />
                        </Grid.Column>
                        <Grid.Column
                          computer={8}
                          tablet={16}
                          mobile={16}
                          className="paddingRemoveTop"
                        >
                          <InputDatePicker
                            labelName="Date of Subscription"
                            changeDate={setStartDate}
                            currentSelectedData={startDate}
                          />
                        </Grid.Column>
                      </Grid>
                    </Grid.Column>
                  </Grid>
                </div>

                <div
                  style={{
                    height: height * 0.1,
                  }}
                  className="modalRegisterButtonMain"
                >
                  <div className="modalRegisterFlex">
                    <CustomButton
                      theme="green"
                      title={"Cancel"}
                      customColumnStyle={"cardInsertSaveOther"}
                      buttonOutLine={true}
                      onClick={() => {
                        setVisibleModal(false);
                        reset();
                      }}
                    />
                  </div>

                  <div className="modalRegisterButtonLeft">
                    <CustomButton
                      theme="green"
                      title={"Save"}
                      customColumnStyle={"cardInsertSaveOther"}
                      onClick={handleSubmit(onSubmit)}
                      type={"button"}
                    />
                  </div>
                </div>
              </form>
            </>
          </Grid.Column>
        </Grid>
      </CommonModal>
    </>
  );
};

export default CompanyEditModal;
