import { useMutation, useQuery } from "@tanstack/react-query";
import { ApiServiceErr, MutOptions, axiosApi } from "./apiService";

export const usePostWasteManagementData = (opt?: MutOptions<string>) => {
  return useMutation<any, ApiServiceErr, any>(async (data) => {
    if(data.supplierDataHandle){
      if (data.type == "farm") {
        return await axiosApi.post(
          `/supplier-farm-gate/processing-waste-management?processingId=${data?.processingId}`,
          data.wasteData
        );
      } else {
        return await axiosApi.post(
          `/supplier-process-gate/processing-waste-management?processingId=${data?.processingId}`,
          data.wasteData
        );
      }
    }else{
      if (data.type == "farm") {
        return await axiosApi.post(
          `/farm-gate/processing-waste-management?processingId=${data?.processingId}`,
          data.wasteData
        );
      } else {
        return await axiosApi.post(
          `/process-gate/processing-waste-management?processingId=${data?.processingId}`,
          data.wasteData
        );
      }
    }
  }, opt);
};

export const usePutWasteManagementData = (opt?: MutOptions<string>) => {
  return useMutation<any, ApiServiceErr, any>(async (data) => {
    if(data.supplierDataHandle){
      if (data.type == "farm") {
        return await axiosApi.put(
          `/supplier-farm-gate/processing-waste-management/${data.wasteManagementId}`,
          data.wasteData
        );
      } else {
        return await axiosApi.put(
          `/supplier-process-gate/processing-waste-management/${data.wasteManagementId}`,
          data.wasteData
        );
      }
    }else{
      if (data.type == "farm") {
        return await axiosApi.put(
          `/farm-gate/processing-waste-management/${data.wasteManagementId}`,
          data.wasteData
        );
      } else {
        return await axiosApi.put(
          `/process-gate/processing-waste-management/${data.wasteManagementId}`,
          data.wasteData
        );
      }
    }
  }, opt);
};

export const useDeleteWasteManagementData = (opt?: MutOptions<string>) => {
  return useMutation<any, ApiServiceErr, any>(async (data) => {
    if(data.supplierDataHandle){
      if (data.type == "farm") {
        return await axiosApi.delete(
          `/supplier-farm-gate/processing-waste-management/${data.wasteData.wasteManagementId}?processingId=${data?.wasteData.processingId}`
        );
      } else {
        return await axiosApi.delete(
          `/supplier-process-gate/processing-waste-management/${data.wasteData.wasteManagementId}?processingId=${data?.wasteData.processingId}`
        );
      }
    }else{
      if (data.type == "farm") {
        return await axiosApi.delete(
          `/farm-gate/processing-waste-management/${data.wasteData.wasteManagementId}?processingId=${data?.wasteData.processingId}`
        );
      } else {
        return await axiosApi.delete(
          `/process-gate/processing-waste-management/${data.wasteData.wasteManagementId}?processingId=${data?.wasteData.processingId}`
        );
      }
    }
   
  }, opt);
};