import PackagingSimulation from "../screens/packagingSimulation/PackagingSimulation";
import ProductSimulation from "../screens/productSimulation/ProductSimulation";
import { adminAccess, userAccess } from "./useRoleAccess";

export const SimulationRoutes = [
  {
    path: "packaging-simulation",
    route: <PackagingSimulation />,
    protectRoutes: true,
    roleGrantAccess: [...adminAccess, ...userAccess],
  },
  {
    path: "product-simulation",
    route: <ProductSimulation />,
    protectRoutes: true,
    roleGrantAccess: [...adminAccess, ...userAccess],
  },
];
