export enum userRoles {
  rootAdmin = "Root Admin",
  groupAdmin = "Group Admin",
  user = "User",
  supplier = "Supplier",
  admin = "Admin",
}

export enum userInitialRout {
  rootAdmin = "/",
  groupAdmin = "/",
  admin = "/",
  user = "/",
  supplier = "/facility",
}

export const adminAccess = [
  userRoles.admin,
  userRoles.groupAdmin,
  userRoles.rootAdmin,
];

export const userAccess = [userRoles.user];

export const supplierAccess = [userRoles.supplier];
