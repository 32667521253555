import { useMutation, useQuery } from "@tanstack/react-query";
import { ApiServiceErr, axiosApi, MutOptions } from "../apiService";

export const usePostSimulationProcessGateInternalStorageData = (data?: any) => {
  return useMutation<string, ApiServiceErr, any>(async (data) => {
    const response = await axiosApi.post(
      `/process-gate-simulation/internal-storage?processGateId=${data?.processGateId}`,
      data?.storageData
    );
    return response.data;
  });
};

export const usePutSimulationProcessGateInternalStorageData = (data?: any) => {
  return useMutation<string, ApiServiceErr, any>(async (data) => {
    const response = await axiosApi.put(
      `/process-gate-simulation/internal-storage/${data?.internalStorageId}?processGateId=${data?.processGateId}`,
      data?.storageData
    );
    return response.data;
  });
};

export const useDeleteSimulationProcessGateInternalStorageData = (
  data?: any
) => {
  return useMutation<string, ApiServiceErr, any>(async (data) => {
    const response = await axiosApi.delete(
      `/process-gate-simulation/internal-storage/${data?.internalStorageId}?ProcessGate=${data?.processGateId}`
    );
    return response.data;
  });
};

export const useGetSimulationProcessGateInternalStorageById = (
  processGateInternalStorageId: string
) =>
  useQuery(
    ["getSimulationProcessGateInternalStorageById"],
    async () => {
      const response = await axiosApi.get(
        `/process-gate-simulation/internal-storage/${processGateInternalStorageId}`
      );
      return response?.data;
    },
    {
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );
