import { useMutation, useQuery } from "@tanstack/react-query";
import { ApiServiceErr, axiosApi } from "../apiService";

export const useCreateExternalStorageSimulation = (opt?: any) =>
  useMutation<any, ApiServiceErr, any>(async (data: any) => {
    const response = await axiosApi.post("/external-storage-simulation", data);
    return response.data;
  }, opt);

export const useUpdateExternalStorageSimulation = (opt?: any) =>
  useMutation<any, ApiServiceErr, any>(async (data: any) => {
    const response = await axiosApi.put(
      `/external-storage-simulation/${data.externalStorageId}`,
      data
    );
    return response.data;
  }, opt);

export const useGetExternalStorageSimulationById = (id: string) =>
  useQuery<any, ApiServiceErr>(
    ["getExternalStorageSimulationById", id],
    async () => {
      if (!id) return null;
      const response = await axiosApi.get(`/external-storage-simulation/${id}`);
      return response.data;
    },
    {
      enabled: !!id,
    }
  );

export const useDeleteExternalStorageSimulation = (opt?: any) =>
  useMutation<any, ApiServiceErr, any>(async (externalStorageId: string) => {
    const response = await axiosApi.delete(
      `/external-storage-simulation/${externalStorageId}`
    );
    return response.data;
  }, opt);
