import { useContext, useMemo, useState } from "react";
import { useReactFlow } from "reactflow";
import { useForm } from "react-hook-form";

import { DrawingForIngredientContext } from "../DrawingToolForIngredient";
import { InputText } from "../../../components";
import useWindowDimensions from "../../../hooks/windowsSize";
import { Dimmer, Grid, Loader } from "semantic-ui-react";
import MainBottomButtonView from "../../../components/mainBottomButtonView/MainBottomButtonView";
import { successMessage } from "../../../helpers/ErrorHandler";
import {
  useDeleteSupplierRawMaterial,
  useGetSupplierRawMaterialsById,
  usePostSupplierRawMaterialsDetails,
  usePutSupplierRawMaterialsDetails,
} from "../../../api/supplierRawMaterials";
import ConfirmModal from "../../../components/confirmViewModal/ConfirmModal";

export const SupplierRawMaterial = () => {
  const {
    nodeItemId,
    chartEdges,
    chartNodes,
    setNodeItem,
    setChartNodes,
    saveDrawing,
  } = useContext(DrawingForIngredientContext);
  // get chart node item from node id
  const reactFlow = useReactFlow();
  const nodeItem: any = useMemo(
    () => reactFlow.getNode(nodeItemId || ""),
    [nodeItemId]
  );
  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false);
  const { deleteElements } = useReactFlow();

  const supplierRawMaterialId =
    nodeItem?.data?.reference?.supplierRawMaterialId || null;

  const { data: supplierRawMaterialsData } = useGetSupplierRawMaterialsById(
    supplierRawMaterialId
  );

  const { isLoading: loadSupplierData, mutate: createSupplierRawMaterialData } =
    usePostSupplierRawMaterialsDetails(supplierRawMaterialId);

  const {
    isLoading: loadSupplierUpdateData,
    mutate: updateSupplierRawMaterialData,
  } = usePutSupplierRawMaterialsDetails(supplierRawMaterialId);

  const {
    isLoading: loadDeleteSupplierRawMaterialData,
    mutate: deleteSupplierRawMaterialData,
  } = useDeleteSupplierRawMaterial(supplierRawMaterialId);

  const onNodesDelete = () => {
    deleteElements({ nodes: [{ id: nodeItem?.id }] });
    setNodeItem(null);
  };

  const values = useMemo(() => {
    return {
      rawMaterialName: supplierRawMaterialsData?.rawMaterialName || "",
      supplierName: supplierRawMaterialsData?.supplierName || "",
      climateImpact: supplierRawMaterialsData?.climateImpact || "",
      calculatedBy: supplierRawMaterialsData?.calculatedBy || "",
    };
  }, [supplierRawMaterialsData]);

  const { height } = useWindowDimensions();

  const onSubmit = (data: any) => {
    if (nodeItem?.data?.reference) {
      data._id = supplierRawMaterialId;
      updateSupplierRawMaterialData(data, {
        onSuccess(data: any) {
          const updatedNode = {
            ...nodeItem,
            data: {
              ...nodeItem?.data,
              label: data?.rawMaterialName,
              reference: {
                supplierRawMaterialId: data?._id,
              },
            },
          };
          const updatedChartNodes = chartNodes.map((n: any) => {
            if (n.id === nodeItem?.id) {
              return updatedNode;
            }
            return n;
          });
          setChartNodes(updatedChartNodes);
          setNodeItem(updatedNode);
          saveDrawing(chartEdges, updatedChartNodes);
          successMessage("Process updated successfully");
        },
      });
    } else {
      createSupplierRawMaterialData(data, {
        onSuccess: (data: any) => {
          const updatedNode = {
            ...nodeItem,
            data: {
              ...nodeItem?.data,
              label: data?.rawMaterialName,
              reference: {
                supplierRawMaterialId: data?._id,
              },
            },
          };
          const updatedChartNodes = chartNodes.map((n: any) => {
            if (n.id === nodeItem?.id) {
              return updatedNode;
            }
            return n;
          });
          setChartNodes(updatedChartNodes);
          setNodeItem(updatedNode);
          saveDrawing(chartEdges, updatedChartNodes);
          successMessage("Process created successfully");
        },
      });
    }
  };

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    values: values,
  });

  // Loading state check
  if (
    loadSupplierData ||
    loadSupplierUpdateData ||
    loadDeleteSupplierRawMaterialData
  ) {
    return (
      <Dimmer active>
        <Loader content="Loading" />
      </Dimmer>
    );
  }

  return (
    <>
      <div
        style={{
          height: height * 0.9 - 100,
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid>
            <Grid.Column computer={16}>
              <InputText
                register={register}
                errors={errors.rawMaterialName}
                labelName={"Raw Material Name"}
                placeholder=""
                name="rawMaterialName"
                required={true}
                errorMessage="Raw Material Name is required"
              />
            </Grid.Column>
            <Grid.Column computer={16} className="paddingRemoveTop">
              <InputText
                register={register}
                errors={errors.supplierName}
                labelName={"Supplier Name"}
                placeholder=""
                name="supplierName"
              />
            </Grid.Column>
            <Grid.Column computer={16} className="paddingRemoveTop">
              <InputText
                register={register}
                errors={errors.climateImpact}
                labelName={"Climate Impact"}
                placeholder=""
                name="climateImpact"
              />
            </Grid.Column>
            <Grid.Column computer={16} className="paddingRemoveTop">
              <InputText
                register={register}
                errors={errors.calculatedBy}
                labelName={"Calculated By"}
                placeholder=""
                name="calculatedBy"
              />
            </Grid.Column>
          </Grid>
        </form>
      </div>
      <MainBottomButtonView
        saveButtonStatus={true}
        deleteStatus={nodeItem?.data.reference}
        deleteButton={() => setVisibleDeleteModal(true)}
        saveTitle={nodeItem?.data.reference ? "Update" : "Save"}
        saveButton={handleSubmit(onSubmit)}
        type="submit"
      />
      <ConfirmModal
        viewModal={visibleDeleteModal}
        closeModal={() => setVisibleDeleteModal(false)}
        cancel={() => {
          setVisibleDeleteModal(false);
        }}
        approve={() => {
          deleteSupplierRawMaterialData(supplierRawMaterialId, {
            onSuccess: (data: any) => {
              setNodeItem(null);
              onNodesDelete();
              successMessage("Supplier Raw Materials deleted successfully");
            },
          });

          setVisibleDeleteModal(false);
        }}
        title="Delete Supplier Raw Material"
        subTitle="Are you sure you want to remove the Supplier Raw Material? Please consider you cannot recover these data after removed"
      />
    </>
  );
};
