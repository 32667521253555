import React, { useEffect, useState } from "react";
import { Grid, Table, Loader, Dimmer } from "semantic-ui-react";
import { CustomButton, CommonTable, TitleBar } from "../../../components";
import { ClientTableCol, ClientListTableCol } from "../../../config/constants";
import { useGetClientsList } from "../../../api/clients";

interface ClientListProps {
  notFromDrawing: boolean;
}

const ClientList = (props: ClientListProps) => {
  const { notFromDrawing } = props;
  const { data: clientList, isLoading: isClientListLoading } =
    useGetClientsList();

  const loadTableData = () => {
    return clientList?.map((data: any, index: any) => {
      return (
        <Table.Row className="tbleR" key={index}>
          <Table.Cell>
            <p>{data?.clientName}</p>
          </Table.Cell>
          <>
            <Table.Cell>
              <p>{data?.clientEmail}</p>
            </Table.Cell>
            <Table.Cell>
              <p>{data?.clientPhone}</p>
            </Table.Cell>
          </>
        </Table.Row>
      );
    });
  };

  // Loading state check
  if (isClientListLoading) {
    return (
      <Dimmer active>
        <Loader content="Loading" />
      </Dimmer>
    );
  }

  return (
    <>
      <TitleBar titleName={"Clients"} />
      <Grid>
        <Grid.Column
          computer={16}
          tablet={16}
          mobile={16}
          className="userBankDetailsMain"
        >
          <CommonTable
            tableHeaderData={
              notFromDrawing ? ClientListTableCol : ClientTableCol
            }
          >
            {loadTableData()}
          </CommonTable>
        </Grid.Column>
      </Grid>
    </>
  );
};

export default ClientList;
