import * as React from "react";

const InternalStorage = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <circle cx={24} cy={24} r={24} fill="var(--mappingImageBackground)" />
    <path
      fill="var(--mappingIconColor)"
      fillRule="evenodd"
      d="m24 11.44 11.24 6.266a.517.517 0 0 1 .199.693.494.494 0 0 1-.678.204l-.53-.296v16.71H13.769v-16.71l-.53.296a.494.494 0 0 1-.678-.204.517.517 0 0 1 .2-.694L24 11.44Zm-9.23 6.31v16.245h2.384V21.731c0-.847.672-1.533 1.5-1.533h10.27c.828 0 1.5.686 1.5 1.533v12.264h2.807V17.75L24 12.604l-9.232 5.146Zm14.653 16.245V21.731a.506.506 0 0 0-.5-.51h-10.27c-.275 0-.5.228-.5.51v12.264h11.27Zm-8.038-17.374c0-.282.224-.51.5-.51h3.807c.276 0 .5.228.5.51a.506.506 0 0 1-.5.511h-3.807a.506.506 0 0 1-.5-.51Z"
      clipRule="evenodd"
    />
    <path
      fill="var(--mappingIconColor)"
      fillRule="evenodd"
      d="M22.5 29.907h-3v3.066h3v-3.066Zm-3-.51c-.276 0-.5.228-.5.51v3.066c0 .282.224.511.5.511h3c.276 0 .5-.229.5-.51v-3.067a.506.506 0 0 0-.5-.51h-3ZM22.5 24.797h-3v3.066h3v-3.066Zm-3-.51c-.276 0-.5.228-.5.51v3.066c0 .282.224.511.5.511h3c.276 0 .5-.229.5-.51v-3.067a.506.506 0 0 0-.5-.51h-3ZM27.5 29.907h-3v3.066h3v-3.066Zm-3-.51c-.276 0-.5.228-.5.51v3.066c0 .282.224.511.5.511h3c.276 0 .5-.229.5-.51v-3.067a.506.506 0 0 0-.5-.51h-3Z"
      clipRule="evenodd"
    />
    <path
      fill="var(--mappingIconColor)"
      fillRule="evenodd"
      d="M21 31.44v-2.044h1v2.044h-1ZM21 26.33v-2.044h1v2.044h-1ZM26 31.44v-2.044h1v2.044h-1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default InternalStorage;
