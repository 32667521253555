import React, { memo, useContext } from "react";
import { Handle, Position, useStore } from "reactflow";
import "./CustomNode.scss";
import {
  generalNodes,
  farmGateNodes,
  processGateNodes,
} from "../../../config/drawingConstants";
import { DrawingForIngredientContext } from "../DrawingToolForIngredient";
import { iconMap } from "../../../utils/mapping";
import IngredientIcon from "../../../assets/mappingIcon/IngredientIcon";

const connectionNodeIdSelector = (state: any) => state.connectionNodeId;
interface CustomNodeProps {
  id: string;
  data: any;
  isConnectable: boolean;
  selected: boolean;
}

const sourceStyle = { zIndex: 1, backgroundColor: "#3FBE5A" };

export default memo((props: CustomNodeProps) => {
  const drawingContext = useContext(DrawingForIngredientContext);
  const { data } = props;
  const nodeTypes = [...generalNodes, ...farmGateNodes, ...processGateNodes];
  const connectionNodeId = useStore(connectionNodeIdSelector);
  const isConnecting = !!connectionNodeId;
  const imageName = nodeTypes.find((n) => n.id === data.icon)?.image || "";

  const loadMappingIcon = (iconName: string): JSX.Element => {
    const SelectedIcon = iconMap[iconName] || IngredientIcon;
    return <SelectedIcon />;
  };

  return (
    <>
      <div className="node-item">
        <div className="node-header">
          <span>{data.label}</span>
        </div>

        {!isConnecting && (
          <Handle
            className="custom-handle"
            position={Position.Left}
            type="source"
            style={sourceStyle}
          />
        )}
        <Handle
          className="custom-handle"
          position={Position.Left}
          type="target"
          style={{ backgroundColor: "#3FBE5A" }}
        />
        <div className="node-item-image">{loadMappingIcon(imageName)}</div>
        <div
          className="node-footer"
          onClick={() => {
            drawingContext.setNodeItem(props.id);
          }}
        >
          <span>{data.description || "Add Details"}</span>
        </div>
      </div>
    </>
  );
});
