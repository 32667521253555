import { Button, Modal } from "semantic-ui-react";
import "./confirmModal.scss";
import { CustomButton } from "../button/Button";

const ConfirmModal = ({
  viewModal = false,
  closeModal = () => {},
  cancel = () => {},
  approve = () => {},
  size = "mini",
  title = "",
  subTitle = "",
  otherConditionCheck = false,
  otherConditionBottomButton = () => {},
}: any) => {
  const handleBottomButton = () => {
    if (otherConditionCheck) {
      return otherConditionBottomButton;
    }
    return (
      <>
        <Button negative onClick={() => cancel()}>
          No
        </Button>
        <Button positive onClick={() => approve()}>
          Yes
        </Button>
      </>
    );
  };
  return (
    <Modal
      centered={false}
      size={size}
      open={viewModal}
      onClose={() => closeModal()}
      dimmer="blurring"
    >
      <Modal.Header>{title}</Modal.Header>
      <Modal.Content>
        <p className="subTitleModalView">{subTitle}</p>
      </Modal.Content>
      <Modal.Actions>{handleBottomButton()}</Modal.Actions>
    </Modal>
  );
};

export default ConfirmModal;
