import { useMutation, useQuery } from "@tanstack/react-query";
import { ApiServiceErr, axiosApi } from "./apiService";
import { successMessage } from "../helpers/ErrorHandler";

interface Facility {
  EnergyType: string;
  kWh: number;
  Percentage: number;
}
interface Facility {
  _id: string;
  facilityName: string;
  facilityAddress: string;
  facilityCity: any;
  facilityCountry: string;
  facilityType: string;
  status: string;
  tenantId: string;
  createdAt: string;
  createdBy: string;
  energy: Facility[];
}

export const useGetFacilityList = () =>
  useQuery(
    ["getFacilityWholeList"],
    async () => {
      const response = await axiosApi.get(`/facility/list`);
      return response.data;
    },
    {
      refetchOnWindowFocus: false,
    }
  );

export const useGetFacilityPagesList = (
  pageNumber: number,
  pageLimit: number
) =>
  useQuery(
    ["GetFacilityPagesList",pageNumber, pageLimit],
    async () => {
      const response = await axiosApi.get(
        `/facility?pageNumber=${pageNumber}&pageSize=${pageLimit}`
      );
      return response.data;
    }
  );

export const useCreateFacility = (opt?: any) =>
  useMutation<Facility, ApiServiceErr, any>(async (data: Facility) => {
    const response = await axiosApi.post("/facility", data);
    return response.data;
  }, opt);

export const useEditFacility = () =>
  useMutation<Facility, ApiServiceErr, any>(
    async (data: Facility) => {
      const response = await axiosApi.put(`/facility/${data._id}`, data);
      return response.data;
    },
    {
      onSuccess() {
        successMessage("Facility updated successfully");
      },
    }
  );

export const useGetFacilityById = (id: string) =>
  useQuery<Facility, ApiServiceErr>(
    [id, "getFacilityDataById"],
    async () => {
      const response = await axiosApi.get(`/facility/${id}`);
      return response?.data;
    },
    {
      cacheTime: 0,
    }
  );

export const useDeleteFacility = () =>
  useMutation<Facility, ApiServiceErr, any>(async (data: Facility) => {
    data.status = "INACTIVE";
    const response = await axiosApi.put(`/facility/${data._id}`, data);
    return response.data;
  });


