export enum IngredientType {
  RAW = "RAW",
  PROCESSED = "PROCESSED",
}

export enum IngredientDataType {
  SUPPLIER = "SUPPLIER",
  GENERIC = "GENERIC",
  USER = "USER",
}
