import { useEffect, useState } from "react";
import { Grid, Table, Loader, Dimmer, Icon } from "semantic-ui-react";
import { CustomButton, CommonTable, TitleBar } from "../../components";
import { NotificationCol } from "../../config/constants";
import ConfirmModal from "../../components/confirmViewModal/ConfirmModal";
import PaginationView from "../../components/pagination/Pagination";
import {
  useGetSupplierDrawingRequests,
  useAcceptDrawingRequest,
} from "../../api/notification";
import { useAuthContext } from "../../hooks/useAuthContext";

const SupplierDrawingRequests = () => {
  const [notification, setNotificationAction] = useState(null);
  const [pageNumber, setPageNumber] = useState<any>(1);
  const [pageLimit, setPageLimit] = useState<any>(10);
  const { newNotification, dispatch }: any = useAuthContext();
  const {
    data: drawingRequests,
    isLoading: isNotificationLoading,
    refetch,
  } = useGetSupplierDrawingRequests(newNotification);

  const { mutate } = useAcceptDrawingRequest();

  useEffect(() => {
    refetch();
  }, []);
  const handlePagination = (currentPage: any) => {
    setPageNumber(currentPage);
  };

  const handlePageSize = (pageSize: any) => {
    setPageLimit(pageSize);
  };

  const onApproveRequest = () => {
    mutate(
      {
        id: notification,
        data: {
          isAccepted: true,
        },
      },
      {
        onSuccess: () => {
          dispatch({
            type: "NEW_NOTIFICATION",
          });
          setNotificationAction(null);
        },
        onError: () => {
          setNotificationAction(null);
        },
      }
    );
  };

  const loadTableData = () => {
    return drawingRequests?.map((data: any, index: any) => {
      return (
        <Table.Row className="tbleR" key={index}>
          <Table.Cell>
            <p>{data?.ingredients?.ingredient_name}</p>
          </Table.Cell>
          <Table.Cell>
            <p>{data?.tenants?.name}</p>
          </Table.Cell>
          <Table.Cell>
            <Grid>
              <Grid.Column
                className="action_column"
                computer={16}
                tablet={16}
                mobile={16}
                key={index}
              >
                {data.isAccepted ? (
                  <Icon color="green" name="check circle" size="large" />
                ) : (
                  <CustomButton
                    theme="green"
                    title="Accept Request"
                    onClick={() => {
                      setNotificationAction(data._id);
                    }}
                  />
                )}
              </Grid.Column>
            </Grid>
          </Table.Cell>
        </Table.Row>
      );
    });
  };

  // Loading state check
  if (isNotificationLoading) {
    return (
      <Dimmer active>
        <Loader content="Loading" />
      </Dimmer>
    );
  }

  return (
    <>
      <TitleBar titleName={"Notification"} />
      <Grid>
        <Grid.Column computer={16} tablet={16} mobile={16}>
          <CommonTable tableHeaderData={NotificationCol}>
            {loadTableData()}
          </CommonTable>
          <PaginationView
            changePageSize={handlePageSize}
            pageSize={pageLimit}
            totalPages={Math.ceil(drawingRequests?.total / pageLimit)}
            currentPageNo={pageNumber}
            currentSelectPage={handlePagination}
          />
        </Grid.Column>
      </Grid>
      <ConfirmModal
        viewModal={!!notification}
        closeModal={() => setNotificationAction(null)}
        cancel={() => {
          setNotificationAction(null);
        }}
        approve={() => {
          onApproveRequest();
        }}
        title={`Submit notification request`}
        subTitle={`Are you sure you want to submit request?`}
      />
    </>
  );
};

export default SupplierDrawingRequests;
