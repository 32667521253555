import { useMutation, useQuery } from "@tanstack/react-query";
import { ApiServiceErr, axiosApi } from "../apiService";

export const useCreateExternalStorage = (opt?: any) =>
    useMutation<any, ApiServiceErr, any>(async (data: any) => {
        const response = await axiosApi.post('/external-storage', data);
        return response.data;
    }, opt);

export const useUpdateExternalStorage = (opt?: any) =>
    useMutation<any, ApiServiceErr, any>(async (data: any) => {
        const response = await axiosApi.put(`/external-storage/${data.externalStorageId}`, data);
        return response.data;
    }, opt);

export const useGetExternalStorageById = (id: any) =>
    useQuery<any, ApiServiceErr>(
        ["getExternalStorageById", id],
        async () => {
            if (id) {
                const response = await axiosApi.get(`/external-storage/${id}`);
                return response.data;
            }
            return null;
            
        }
    );

export const useDeleteExternalStorage = (opt?: any) =>
	useMutation<any, ApiServiceErr, any>(async (externalStorageId: any) => {
		const response = await axiosApi.delete(`/external-storage/${externalStorageId}`);
		return response.data;
	}, opt);
