import { useMutation } from "@tanstack/react-query";
import { ApiServiceErr, axiosApi } from "../apiService";

interface processGatePackage {
  productId: string;
  salesUnitId: string;
  userCompletedStage: "RECIPE";
  status: string;
  processing: any | [];
  internalTransportation: any | [];
  internalStorage: any | [];
  packaging: any | [];
  processGateId: any
}

export const useCreateSimulationProcessGate = (opt?: any) =>
  useMutation<any, ApiServiceErr, any>(async (data: processGatePackage) => {
    const response = await axiosApi.post(`/process-gate-simulation`, data);
    return response.data;
  }, opt);

  export const useUpdateSimulationProcessGate = (opt?: any) =>
  useMutation<any, ApiServiceErr, any>(async (data: processGatePackage) => {
    const response = await axiosApi.put(
      `/process-gate-simulation/${data.processGateId}`,
      data
    );
    return response.data;
  }, opt);

export const useDeleteSimulationProcessGate = (opt?: any) => {
  return useMutation<string, ApiServiceErr, any>(async (processGateId) => {
    return await axiosApi.delete(`/process-gate-simulation/${processGateId}`);
  }, opt);
};

