import * as React from "react";
import { getPathName } from "../../utils/utils";

const MappingIcon = (props: any) => {
  const pathName = getPathName();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={36}
      height={36}
      fill="none"
      {...props}
    >
      <path
        fill={
          pathName == "drawing"
            ? "var(--menuBarIconActive)"
            : "var(--menuBarIconDeactive)"
        }
        d="M23.304 26.379a3.184 3.184 0 0 0-4.393 0 2.929 2.929 0 0 0 0 4.242 3.185 3.185 0 0 0 4.393 0c-1.207 1.167 1.214-1.171 0 0l2.19-2.115 2.19-2.128"
      />
      <path
        stroke={
          pathName == "drawing"
            ? "var(--menuBarIconActive)"
            : "var(--menuBarIconDeactive)"
        }
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M23.304 26.379a3.184 3.184 0 0 0-4.393 0 2.929 2.929 0 0 0 0 4.242 3.185 3.185 0 0 0 4.393 0m0 0c-1.207 1.167 1.214-1.171 0 0Zm0 0 2.19-2.115 2.19-2.128"
      />
      <path
        stroke={
          pathName == "drawing"
            ? "var(--menuBarIconActive)"
            : "var(--menuBarIconDeactive)"
        }
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="m23.31 26.373 2.19 2.13 2.19 2.115m0 0c-1.207-1.168 1.213 1.17 0 0Zm0 0c1.207 1.169 3.18 1.172 4.393 0a2.927 2.927 0 0 0 0-4.242 3.184 3.184 0 0 0-4.393 0M21 4.5v6a1.5 1.5 0 0 0 1.5 1.5h6"
      />
      <path
        stroke={
          pathName == "drawing"
            ? "var(--menuBarIconActive)"
            : "var(--menuBarIconDeactive)"
        }
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M14.25 31.5H10.5a3 3 0 0 1-3-3v-21a3 3 0 0 1 3-3H21l7.5 7.5v9"
      />
    </svg>
  );
};
export default MappingIcon;
