import { useMutation, useQuery } from "@tanstack/react-query";
import { ApiServiceErr, MutOptions, axiosApi } from "../apiService";
import { successMessage } from "../../helpers/ErrorHandler";

export const usePostGeneralClientData = (data?: any) => {
  return useMutation<string, ApiServiceErr, any>(async (data) => {
    const response = await axiosApi.post(`/clients`, data);
    return response.data;
  });
};

export const usePutGeneralClientData = (data?: any) => {
  return useMutation<string, ApiServiceErr, any>(async (data) => {
    const response = await axiosApi.put(`/clients`, data);
    return response.data;
  });
};

export const useGetGeneralClientDataById = (clientId: any) =>
  useQuery(
    ["getGeneralClientDataById"],
    async () => {
      const response = await axiosApi.get(`/clients/${clientId}`);
      return response?.data;
    },
    {
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );

export const useGetClientWholeList = () =>
  useQuery(
    ["getClientWholeList"],
    async () => {
      const response = await axiosApi.get(`/clients`);

      const newData = response?.data?.map((value: any, index: any) => {
        return {
          key: `client_${index}`,
          text: value?.clientName,
          value: {
            clientName: value?.clientName,
            clientId: value?._id,
          },
        };
      });
      return [
        ...newData,
        {
          key: `new`,
          text: "New",
          value: "new",
        },
      ];
    },
    {
      refetchOnWindowFocus: false,
    }
  );

export const usePostClientTransportMethodData = (data?: any) => {
  return useMutation<string, ApiServiceErr, any>(async (data) => {
    const response = await axiosApi.post(
      `/clients/transport-method/${data?.clientId}?productId=${data?.productId}&salesUnitId=${data?.salesUnitId}`,
      data?.transportMethodData
    );
    return response.data;
  });
};

export const usePutClientTransportMethodData = (data?: any) => {
  return useMutation<string, ApiServiceErr, any>(async (data) => {
    const response = await axiosApi.put(
      `/clients/transport-method/${data?.clientId}?transportMethodId=${data?.transportMethodId}`,
      data?.transportMethodData
    );
    return response.data;
  });
};

export const useDeleteClientTransportMethodData = (data?: any) => {
  return useMutation<string, ApiServiceErr, any>(async (data) => {
    const response = await axiosApi.delete(
      `/clients/transport-method/${data?.clientId}?productId=${data?.productId}&salesUnitId=${data?.salesUnitId}&transportMethodId=${data?.transportMethodId}`
    );
    return response.data;
  });
};

export const useDeleteClientData = (data?: any) => {
  return useMutation<string, ApiServiceErr, any>(async (data) => {
    const response = await axiosApi.delete(`/clients/${data?.clientId}`);
    return response.data;
  });
};

export const useGetClientTransportMethods = (clientId: any) =>
  useQuery(
    ["getClientTransportMethods"],
    async () => {
      const response = await axiosApi.get(`/clients/${clientId}`);
      return response?.data?.product;
    },
    {
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );

export const usePostClientProductData = (data?: any) => {
  return useMutation<string, ApiServiceErr, any>(async (data) => {
    const response = await axiosApi.post(
      `/clients/client-product/${data?.clientId}`,
      data?.product
    );
    return response.data;
  });
};
