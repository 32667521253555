import { useMutation, useQuery } from "@tanstack/react-query";
import {
  ApiServiceErr,
  MutOptions,
  axiosApi,
  calculatorApi,
} from "./apiService";

interface ImpactCategoryOptions {
  categoryName?: string;
  salesUnitId?: string;
}

interface ProductPayloadOptions {
  salesUnitId?: string;
}

interface GetProductSimulationsOptions {
  pageNumber: number;
  pageSize: number;
  productId?: string;
  salesUnitId?: string;
}

export const useGetImpactCategory = ({
  categoryName,
  salesUnitId,
}: ImpactCategoryOptions) =>
  useQuery<ImpactCategory[], ApiServiceErr>(
    [categoryName, salesUnitId, "useGetImpactCategory"],
    async () => {
      if (!categoryName || !salesUnitId) {
        return null;
      }
      const response = await axiosApi.get(
        `/drawing-tool/impact-category/${categoryName}`,
        {
          params: { salesUnitId },
        }
      );
      return response.data;
    },
    {
      enabled: !!categoryName && !!salesUnitId,
    }
  );

export const useGetProductPayload = ({ salesUnitId }: ProductPayloadOptions) =>
  useQuery<ImpactCategory, ApiServiceErr>(
    [salesUnitId, "useGetProductPayload"],
    async () => {
      if (!salesUnitId) {
        return null;
      }
      const response = await axiosApi.get(
        `/drawing-tool/queue-data/${salesUnitId}`
      );
      return response.data;
    },
    {
      enabled: !!salesUnitId,
    }
  );

export const useSimulate = (opt?: MutOptions<string>) =>
  useMutation<any, ApiServiceErr, any>(async (data) => {
    const response = await calculatorApi.post("/simulation", data);
    return response.data;
  }, opt);

export const useCreateProductSimulation = (opt?: MutOptions<string>) =>
  useMutation<any, ApiServiceErr, any>(async (data) => {
    const response = await axiosApi.post("/product-simulation", data);
    return response.data;
  }, opt);

export const useUpdateProductSimulation = (opt?: MutOptions<string>) =>
  useMutation<any, ApiServiceErr, any>(async (data) => {
    const response = await axiosApi.put(
      `/product-simulation/${data._id}`,
      data
    );
    return response.data;
  }, opt);

export const useGetProductSimulations = (
  params: GetProductSimulationsOptions
) => {
  return useQuery<any, ApiServiceErr>(
    ["product-simulation", params],
    async () => {
      const response = await axiosApi.get("/product-simulation", { params });
      return response.data;
    }
  );
};

export const useDeleteProductSimulation = (opt?: MutOptions<string>) =>
  useMutation<any, ApiServiceErr, any>(async (data) => {
    const response = await axiosApi.delete(`/product-simulation/${data._id}`);
    return response.data;
  }, opt);
