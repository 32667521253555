import { memo } from "react";
import { Grid, Dropdown, Icon, Label, Menu, MenuItem } from "semantic-ui-react";

import "./Header.scss";
import { SearchValue } from "../SearchValue/SearchValue";
import { COMPANY_LOGO } from "../../config/constants";
import { hideSearchPath } from "../../utils/utils";
import PermissionsGate from "../PermissionGate";
import { FEATURES, SCOPES } from "../../config/permission-maps";
import { NotificationDropdown } from "../notification/NotificationDropdown";

export const HeaderWebView = memo(
  ({
    options,
    userImage,
    navigate,
    company,
    getSearchValue,
    loadCompanyData,
    loadCompanyToken,
    searchCompany,
    notificationData,
    updateLoadMore,
    isLoadingNotification,
    onClickNotification
  }: any) => {
    const pathname = window.location.pathname;
    return (
      <div className="mainContent">
        <Grid verticalAlign="middle" className="headerDefaultView" centered>
          <Grid.Column computer={3} className="rightContent" only="computer">
            <div onClick={() => navigate("/")}>
              <img src={COMPANY_LOGO} alt="logo" className="logo" />
            </div>
          </Grid.Column>

          <Grid.Column computer={13} only="computer" className="mainCenterView">
            <div className="headerRightView">
              <PermissionsGate
                scopes={[SCOPES.canAccessInHeaderMenu]}
                feature={FEATURES.companySearch}
              >
                {hideSearchPath(pathname) ? (
                  <>
                    <NotificationDropdown
                      notification={notificationData}
                      updateLoadMore={updateLoadMore}
                      isLoadingNotification={isLoadingNotification}
                      onClick={onClickNotification}
                    />
                    <div className="webSerachDiv">
                      <SearchValue
                        searchDataValues={company}
                        checkOther={() => {}}
                        selectDetails={getSearchValue}
                        searchByApi={true}
                        defaultValue={localStorage.getItem("companyName")}
                        searchValueData={(key: any) => searchCompany(key)}
                        disable={loadCompanyData || loadCompanyToken}
                      />
                    </div>
                  </>
                ) : null}
              </PermissionsGate>
              <Dropdown
                trigger={userImage}
                options={options}
                pointing="top left"
                icon={null}
              />
            </div>
          </Grid.Column>
        </Grid>
      </div>
    );
  }
);
