import { images } from "../assets/images";

const generalNodes = [
  {
    id: "1",
    image: "ingredient",
    cardHeader: "Ingredients",
    cardExtra: "Ingredients types",
  },
  {
    id: "2",
    image: "externalTransport",
    cardHeader: "External Transportation",
    cardExtra: "Transportation by Flight",
  },
  {
    id: "3",
    image: "externalWarehouse",
    cardHeader: "External Warehouse",
    cardExtra: "Ingredients types",
  },
  {
    id: "4",
    image: "client",
    cardHeader: "Client",
    cardExtra: "Ingredients types",
    exclude: true
  },
];

const farmGateNodes = [
  {
    id: "5",
    image: "cultivation",
    cardHeader: "Cultivation",
    cardExtra: "Cultivar types",
  },
  {
    id: "6",
    image: "process",
    cardHeader: "Process",
    cardExtra: "Precessed Ingredients",
  },
  {
    id: "7",
    image: "internalTransport",
    cardHeader: "Internal Transportation",
    cardExtra: "Internal Transportation",
  },
  {
    id: "8",
    image: "packing",
    cardHeader: "Packing",
    cardExtra: "Packing",
  },
  {
    id: "9",
    image: "internalStorage",
    cardHeader: "Internal Storage",
    cardExtra: "Internal Storage",
  },
];

const processGateNodes = [
  {
    id: "10",
    image: "process",
    cardHeader: "Processing",
    cardExtra: "Precessed Ingredients",
  },
  {
    id: "11",
    image: "packing",
    cardHeader: "Packing",
    cardExtra: "Packing",
  },
  {
    id: "12",
    image: "internalTransport",
    cardHeader: "Internal Transportation",
    cardExtra: "Internal Transportation",
  },
  {
    id: "13",
    image: "internalStorage",
    cardHeader: "Internal Storage",
    cardExtra: "Internal Storage",
  },
];

const nodeType = {
  Ingredients: "1",
  Transportation: "2",
  Warehouse: "3",
  Client: "4",
  Cultivar: "5",
  FarmGateProcess: "6",
  FarmGateInternalTransport: "7",
  FarmGatePacking: "8",
  FarmGateInternalStorage: "9",
  ProcessGateProcessing: "10",
  ProcessGatePacking: "11",
  ProcessGateInternalTransport: "12",
  ProcessGateInternalStorage: "13",
  Packing: "11",
};

const nodeCatagories = {
  special: "special",
  farmGate: "farmGate",
  processGate: "processGate",
  ingredientGate: "ingredientGate",
};

export {
  nodeType,
  generalNodes,
  farmGateNodes,
  processGateNodes,
  nodeCatagories,
};
