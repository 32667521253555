import {
  Button,
  Modal,
  Segment,
  Input,
  GridColumn,
  Form,
  TextArea,
} from "semantic-ui-react";
import { useState } from "react";

const SimulationDrawingSaveModal = ({
  title,
  note,
  isCalculation,
  closeModal = () => {},
  onSave = () => {},
}: any) => {
  const [drawingName, setDrawingName] = useState(title || "");
  const [drawingNote, setDrawingNote] = useState<any>(note || "");

  return (
    <Modal
      centered={false}
      size={"small"}
      open={true}
      onClose={() => closeModal()}
      dimmer="blurring"
    >
      <Modal.Header>Save Mapping!</Modal.Header>
      <Modal.Content as={Segment}>
        <GridColumn>
          <label>Mapping Save Name</label>
        </GridColumn>
        <GridColumn>
          <Input
            fluid
            disabled={isCalculation}
            value={drawingName}
            onChange={(e, { value }) => {
              setDrawingName(value);
            }}
          />
        </GridColumn>
        <GridColumn>
          <label>Notes</label>
        </GridColumn>
        <GridColumn>
          <Form>
            <TextArea
              fluid
              value={drawingNote}
              onChange={(e, { value }) => {
                setDrawingNote(value);
              }}
            />
          </Form>
        </GridColumn>
      </Modal.Content>
      <Modal.Actions>
        <Button negative onClick={() => closeModal()}>
          Cancel
        </Button>
        <Button
          positive
          onClick={() => {
            onSave(drawingName, drawingNote);
          }}
        >
          Save
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default SimulationDrawingSaveModal;
