import React, { ReactChildren, ReactChild, useState } from "react";
import { Modal, Loader } from "semantic-ui-react";

import MenuIcon from "../../assets/images/menuView.png";
import { CommonContainer } from "../../components/commonContainer/commonContainer";
import MenuBar from "../../components/menuBar/Menubar";
import useWindowDimensions from "../../hooks/windowsSize";
import ModalMenuBar from "../../components/menuBar/ModalMenu";
import { Header, ImageView } from "../../components/index";
import { drawerRoute } from "../../config/constants";

import "../mainContanier.scss";

interface MainContentViewLayoutProps {
  children: ReactChild | ReactChildren;
  currentPath: string;
}

export const MainContentViewLayout = ({
  children,
  currentPath,
}: MainContentViewLayoutProps) => {
  const [visible, setVisible] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);
  const { width } = useWindowDimensions();

  return (
    <div className="viewMain" style={{ width: width }}>
      <div id="left" className="columns">
        <div
          className="topIconView"
          onClick={() => setVisibleModal(!visibleModal)}
        >
          <ImageView src={MenuIcon} customImageView={"headerLeftMenuIcon"} />
        </div>
        <div className="bottom">
          <MenuBar />
        </div>
      </div>

      <div id="right" className="columns">
        <div className="top-right">
          <Header setVisible={setVisible} />
        </div>
        <div
          className={
            drawerRoute.includes(currentPath)
              ? "rightBottomViewForDrawer"
              : "rightBottomView"
          }
        >
          <CommonContainer
            currentPath={currentPath}
            styles={`customMainBottomContainer`}
          >
            {children}
          </CommonContainer>
        </div>
      </div>
      <ModalMenuBar
        visibleModal={visibleModal}
        setVisibleModal={() => setVisibleModal(false)}
      />
      <Modal open={false}>
        <Loader inverted content="Loading" />
      </Modal>
    </div>
  );
};
