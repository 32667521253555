import { useState, useEffect, useMemo } from "react";
import { Grid, Dimmer, Loader } from "semantic-ui-react";
import { useForm } from "react-hook-form";

import { CustomButton, CommonModal } from "../../../components";
import useWindowDimensions from "../../../hooks/windowsSize";
import { isEmpty } from "lodash";
import { errorView, successMessage } from "../../../helpers/ErrorHandler";

import "./facilityRegister.scss";
import { useCreateFacility } from "../../../api/facility";
import {
  FACILITY_OWNERSHIP,
  FACILITY_TYPES,
  UNIT_TYPES,
} from "../../../config/constants";
import { useGetEnergyType } from "../../../api/common";
import {
  useGetCountries,
  useGetHeatSourceType,
  useGetRefrigerantSourceType,
  useGetWaterSourceType,
} from "../../../api/static-data";
import FacilityInfo from "../facilityForms/FacilityInfo";
import FacilityEnergy from "../facilityForms/FacilityEnergy";
import FacilityHeatSource from "../facilityForms/FacilityHeatSource";
import FacilityWaterSource from "../facilityForms/FacilityWaterSource";
import FacilityRefrigerant from "../facilityForms/FacilityRefrigerant";
import ConfirmModal from "../../../components/confirmViewModal/ConfirmModal";

const FacilityRegisterModal = ({ visibleModal, setVisibleModal }: any) => {
  const [addFacilityEnergy, setFacilityEnergy] = useState<any>([]);
  const [addFacilityHeat, setFacilityHeat] = useState<any>([]);
  const [addFacilityRefrigerant, setFacilityRefrigerant] = useState<any>([]);

  const [addFacilityWater, setFacilityWater] = useState<any>([]);
  const [defaultEnergyType, setDefaultEnergyType] = useState<any>(0);
  const [defaultHeatType, setDefaultHeatType] = useState<any>(0);
  const [defaultWaterType, setDefaultWaterType] = useState<any>(0);
  const [defaultFacilityType, setDefaultType] = useState<any>(
    FACILITY_TYPES[0].value
  );
  const [defaultFacilityOwnerShip, setDefaultOwnerShip] = useState<any>(
    FACILITY_OWNERSHIP[0].value
  );
  const [getDefaultUnit, setUnit] = useState(UNIT_TYPES[0].value);
  const [defaultRefrigerant, setDefaultRefrigerantType] = useState<any>(0);
  const [getDefaultRefrigerantUnit, setRefrigerantUnit] = useState(
    UNIT_TYPES[0].value
  );
  const [country, setCountry] = useState<any>();
  const [addDataObject, setAddDataObject] = useState({
    EnergyType: 0,
    Percentage: 0,
    Price: 0,
    Year: "",
    kWhConsumed: 0,
  });
  const [addHeatDataObject, setAddHeatDataObject] = useState({
    HeatSource: 0,
    Volumes: 0,
    Price: 0,
    Year: 0,
    UnitType: UNIT_TYPES[0].value,
  });
  const [deleteRefrigerantData, setDeleteRefrigerantData] = useState<any>({});
  const [visibleHeatDeleteModal, setVisibleHeatDeleteModal] = useState(false);
  const [visibleWaterDeleteModal, setVisibleWaterDeleteModal] = useState(false);
  const [visibleRefrigerantDeleteModal, setVisibleRefrigerantDeleteModal] =
    useState(false);
  const [addRefrigerantDataObject, setAddRefrigerantDataObject] = useState({
    RefrigerantSource: 0,
    Volumes: 0,
    Price: 0,
    Year: 0,
    UnitType: UNIT_TYPES[0].value,
  });
  const [addWaterDataObject, setAddWaterDataObject] = useState({
    WaterSource: 0,
    Volumes: 0,
    Price: 0,
    Year: 0,
  });
  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false);
  const [deleteData, setDeleteData] = useState<any>({});
  const [deleteHeatData, setDeleteHeatData] = useState<any>({});
  const [deleteWaterData, setDeleteWaterData] = useState<any>({});

  const { height } = useWindowDimensions();

  //get energy type data
  const { data: energyType, status, fetchStatus, refetch } = useGetEnergyType();
    const {
      data: refrigerantSource,
      status:refrigerantStatus,
      fetchStatus:refrigerantFetchStatus,
      refetch:refrigerantFetch,
    } = useGetRefrigerantSourceType();

  const {
    data: heatSource,
    status: heatSourceStatus,
    fetchStatus: fetchHeatSource,
    refetch: refetchHeatSource,
  } = useGetHeatSourceType();
  const {
    data: waterSource,
    status: waterSourceStatus,
    fetchStatus: fetchWaterSource,
    refetch: refetchWaterSource,
  } = useGetWaterSourceType();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    getValues,
    setValue,
  } = useForm();

  const {
    formState: { errors: errorsFacilityEnergy },
    handleSubmit: handleSubmitFacilityEnergy,
    setValue: setEnergyValue,
    reset: resetEnergy,
    control,
    setError
  } = useForm();

  const {
    formState: { errors: errorsFacilityHeat },
    handleSubmit: handleSubmitFacilityHeat,
    setValue: setHeatValue,
    reset: resetHeat,
    control: controlHeat,
  } = useForm();

  const {
    formState: { errors: errorsFacilityWater },
    handleSubmit: handleSubmitFacilityWater,
    setValue: setWaterValue,
    reset: resetWater,
    control: controlWater,
  } = useForm();

  const {
    formState: { errors: errorRefrigerant },
    handleSubmit: handleSubmitFacilityRefrigerant,
    setValue: setRefrigerantValue,
    reset: resetRefrigerant,
    control: controlRefrigerant,
  } = useForm();

  const {
    data: countriesData,
    isSuccess: isCountriesDataSuccess,
    isLoading: isCountriesDataLoading,
  } = useGetCountries();

  //custom hook create facility
  const { isLoading, mutate, data, reset: resetData } = useCreateFacility();

  useEffect(() => {
    return () => {
      setFacilityEnergy([]);
      setFacilityHeat([]);
      reset();
      resetFacilityEnergys();
      resetEnergy();
      resetHeat();
      resetWater();
      setDefaultOwnerShip(FACILITY_OWNERSHIP[0].value);
    };
  }, [visibleModal]);

  const countries = useMemo(() => {
    if (isCountriesDataSuccess) {
      return countriesData?.map((c: any) => ({
        key: c.id,
        text: c.country,
        value: c.id,
      }));
    }
  }, [countriesData, isCountriesDataSuccess]);

  //Handle close modal status
  const closeModalStatus = () => {
    let value = getValues();
    setVisibleModal(true, value);
  };

  // product facility energy handler
  const removeDataHandler = (index: number) => {
    const newData = addFacilityEnergy;
    newData.splice(index, 1);
    setFacilityEnergy([...newData]);
  };

  // product facility energy handler
  const removeWaterDataHandler = (index: number) => {
    const newData = addFacilityWater;
    newData.splice(index, 1);
    setFacilityWater([...newData]);
  };

  // product facility heat handler
  const removeHeatDataHandler = (index: number) => {
    const newData = addFacilityHeat;
    newData.splice(index, 1);
    setFacilityHeat([...newData]);
  };

  //check product error view
  const checkFacilitySaveCondition = (data: any) => {
    if (isEmpty(data.facilityName.trim())) {
      errorView("Facility name is required");
      return true;
    } else if (!country) {
      errorView("Facility country is required");
      return true;
    } else if (addFacilityEnergy.length <= 0) {
      errorView("Please add facility energy");
      return true;
    } else {
      return false;
    }
  };

  //Reset facility energys
  const resetFacilityEnergys = () => {
    setDefaultEnergyType(0);
    setEnergyValue("Percentage", null);
    setEnergyValue("Price", 0.0);
    setEnergyValue("Year", null);
    setEnergyValue("kWhConsumed", null);
  };

  //Reset facility heat
  const resetFacilityHeat = () => {
    setDefaultHeatType(0);
    setHeatValue("Percentage", null);
    setHeatValue("Price", 0.0);
    setHeatValue("Volumes", null);
    setHeatValue("Year", null);
    setUnit(UNIT_TYPES[0].value);
  };

  //Reset facility water
  const resetFacilityWater = () => {
    setDefaultWaterType(0);
    setWaterValue("Price", 0.0);
    setWaterValue("Volumes", null);
    setWaterValue("Year", null);
  };

  //add product Energy data
  const addEnergyData = (data: any) => {
    setAddDataObject({
      ...addDataObject,
      EnergyType: energyType?.[defaultEnergyType]?.text || "",
      Percentage: Number(data?.Percentage) ?? 0,
      Price: Number(data?.Price) ?? 0,
      Year: data?.Year,
      kWhConsumed: Number(data?.kWhConsumed) ?? 0,
    });
    setFacilityEnergy([
      ...addFacilityEnergy,
      {
        ...addDataObject,
        EnergyType: energyType?.[defaultEnergyType]?.text || "",
        Percentage: Number(data?.Percentage) ?? 0,
        Price: Number(data?.Price) ?? 0,
        Year: data?.Year,
        kWhConsumed: Number(data?.kWhConsumed) ?? 0,
      },
    ]);
    resetFacilityEnergys();
  };

  //add product Heat data
  const addHeatData = (data: any) => {
    setAddHeatDataObject({
      ...addHeatDataObject,
      HeatSource: heatSource?.[defaultHeatType]?.text || "",
      Price: Number(data?.Price) ?? 0,
      Volumes: Number(data?.Volumes) ?? 0,
      UnitType: getDefaultUnit,
      Year:data?.Year,
    });
    setFacilityHeat([
      ...addFacilityHeat,
      {
        ...addHeatDataObject,
        HeatSource: heatSource?.[defaultHeatType]?.text || "",
        Price: Number(data?.Price) ?? 0,
        Volumes: Number(data?.Volumes) ?? 0,
        UnitType: getDefaultUnit,
        Year:data?.Year,
      },
    ]);
    resetFacilityHeat();
  };

  //add product Heat data
  const addHWaterData = (data: any) => {
    setAddWaterDataObject({
      ...addHeatDataObject,
      WaterSource: waterSource?.[defaultHeatType]?.text || "",
      Price: Number(data?.Price) ?? 0,
      Volumes: Number(data?.Volumes) ?? 0,
      Year: data?.Year,
    });
    setFacilityWater([
      ...addFacilityWater,
      {
        ...addWaterDataObject,
        WaterSource: waterSource?.[defaultHeatType]?.text || "",
        Price: Number(data?.Price) ?? 0,
        Volumes: Number(data?.Volumes) ?? 0,
        Year: data?.Year,
      },
    ]);
    resetFacilityWater();
  };

  //add product Heat data
  const addRefrigerant = (data: any) => {
    setAddRefrigerantDataObject({
      ...addRefrigerantDataObject,
      RefrigerantSource: refrigerantSource?.[defaultRefrigerant]?.text || "",
      Price: Number(data?.Price) ?? 0,
      Volumes: Number(data?.Volumes) ?? 0,
      UnitType: getDefaultRefrigerantUnit,
      Year: data?.Year,
    });
    setFacilityRefrigerant([
      ...addFacilityRefrigerant,
      {
        ...addRefrigerantDataObject,
        RefrigerantSource: refrigerantSource?.[defaultRefrigerant]?.text || "",
        Price: Number(data?.Price) ?? 0,
        Volumes: Number(data?.Volumes) ?? 0,
        UnitType: getDefaultRefrigerantUnit,
        Year: data?.Year,
      },
    ]);
    resetRefrigerant();
  };

  //save product data
  const onSubmit = async (data: any) => {
    if (!checkFacilitySaveCondition(data)) {
      let {
        facilityName,
        facilityAddress,
        facilityCity,
        facilityType = defaultFacilityType,
        facilityOwnership = defaultFacilityOwnerShip,
      } = data;
      let facilityData = {
        facilityName,
        facilityAddress,
        facilityCity,
        facilityCountry: country,
        facilityType,
        facilityOwnership,
        energy: addFacilityEnergy,
        heatSource: addFacilityHeat,
        waterSource: addFacilityWater,
        refrigerantSource: addFacilityRefrigerant,
      };

      mutate(facilityData, {
        onSuccess: () => {
          successMessage("Facility created successfully");
          closeModalStatus();
        },
      });
    }
  };

  //loading view
  if (
    isLoading ||
    (status == "loading" && fetchStatus == "fetching") ||
    (heatSourceStatus == "loading" && fetchHeatSource == "fetching") ||
    (waterSourceStatus == "loading" && fetchWaterSource == "fetching") ||
    (refrigerantStatus == "loading" && refrigerantFetchStatus == "fetching")
  ) {
    return (
      <Dimmer active>
        <Loader content="Loading" />
      </Dimmer>
    );
  }

  const deleteFacilityEnergy = () => {
    if (visibleDeleteModal) {
      const newData = addFacilityEnergy;
      newData.splice(deleteData.index, 1);
      setFacilityEnergy([...newData]);
    } else if (visibleHeatDeleteModal) {
      const newData = addFacilityHeat;
      newData.splice(deleteHeatData.index, 1);
      setFacilityHeat([...newData]);
    } else if (visibleRefrigerantDeleteModal) {
      const newData = addFacilityRefrigerant;
      newData.splice(deleteRefrigerantData.index, 1);
      setFacilityRefrigerant([...newData]);
    } else {
      const newData = addFacilityWater;
      newData.splice(deleteWaterData.index, 1);
      setFacilityWater([...newData]);
    }
  };

  return (
    <>
      <CommonModal
        modalOpenState={visibleModal}
        modalCloseState={() => {
          closeModalStatus();
          reset();
        }}
        centered={true}
        title="Add New Facility"
      >
        <Grid>
          <Grid.Column computer={16} tablet={16} mobile={16}>
            <>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div
                  style={{
                    height: height * 0.9 - 180,
                    overflowX: "hidden",
                  }}
                >
                  <FacilityInfo
                    register={register}
                    errors={errors}
                    country={country}
                    countries={countries}
                    setFacilityValue={setValue}
                    setCountry={setCountry}
                    isCountriesDataLoading={isCountriesDataLoading}
                    FACILITY_TYPES={FACILITY_TYPES}
                    defaultFacilityType={defaultFacilityType}
                    defaultFacilityOwnerShip={defaultFacilityOwnerShip}
                    setDefaultOwnerShip={setDefaultOwnerShip}
                    setDefaultType={setDefaultType}
                  />
                  <FacilityEnergy
                    energyType={energyType}
                    defaultEnergyType={defaultEnergyType}
                    setDefaultEnergyType={setDefaultEnergyType}
                    errorsFacilityEnergy={errorsFacilityEnergy}
                    handleSubmitFacilityEnergy={handleSubmitFacilityEnergy}
                    addFacilityEnergyData={addEnergyData}
                    addFacilityEnergy={addFacilityEnergy}
                    currentFacilityData={addFacilityEnergy || []}
                    setDeleteData={setDeleteData}
                    setVisibleDeleteModal={setVisibleDeleteModal}
                    control={control}
                    setError={setError}
                  />
                  <FacilityHeatSource
                    heatType={heatSource}
                    defaultHeatType={defaultHeatType}
                    setDefaultHeatType={setDefaultHeatType}
                    errorsFacilityHeat={errorsFacilityHeat}
                    handleSubmitFacilityHeat={handleSubmitFacilityHeat}
                    addFacilityHeatData={addHeatData}
                    addFacilityHeat={addFacilityHeat}
                    currentFacilityHeatData={addFacilityHeat || []}
                    setDeleteHeatData={setDeleteHeatData}
                    setVisibleDeleteHeatModal={setVisibleHeatDeleteModal}
                    control={controlHeat}
                    setUnit={setUnit}
                    getDefaultUnit={getDefaultUnit}
                  />
                  <FacilityWaterSource
                    waterType={waterSource}
                    defaultWaterType={defaultWaterType}
                    setDefaultWaterType={setDefaultWaterType}
                    errorsFacilityWater={errorsFacilityWater}
                    handleSubmitFacilityWater={handleSubmitFacilityWater}
                    addFacilityWaterData={addHWaterData}
                    addFacilityWater={addFacilityWater}
                    currentFacilityWaterData={addFacilityWater || []}
                    setDeleteWaterData={setDeleteWaterData}
                    setVisibleDeleteWaterModal={setVisibleWaterDeleteModal}
                    control={controlWater}
                  />
                  <FacilityRefrigerant
                    refrigerantType={refrigerantSource}
                    defaultRefrigerantType={defaultRefrigerant}
                    setDefaultRefrigerantType={setDefaultRefrigerantType}
                    errorsFacilityRefrigerant={errorRefrigerant}
                    handleSubmitFacilityRefrigerant={
                      handleSubmitFacilityRefrigerant
                    }
                    addFacilityRefrigerantData={addRefrigerant}
                    addFacilityRefrigerant={addFacilityRefrigerant}
                    currentFacilityRefrigerantData={
                      addFacilityRefrigerant || []
                    }
                    setDeleteRefrigerantData={setDeleteRefrigerantData}
                    setVisibleDeleteRefrigerantModal={
                      setVisibleRefrigerantDeleteModal
                    }
                    control={controlRefrigerant}
                    setUnit={setRefrigerantUnit}
                    getDefaultUnit={getDefaultRefrigerantUnit}
                  />
                </div>

                <div
                  style={{
                    height: height * 0.1,
                  }}
                  className="modalRegisterButtonMain"
                >
                  <div className="modalRegisterFlex">
                    <CustomButton
                      theme="green"
                      title={"Cancel"}
                      customColumnStyle={"cardInsertSaveOther"}
                      buttonOutLine={true}
                      onClick={() => {
                        closeModalStatus();
                        setCountry("");
                        reset();
                      }}
                    />
                  </div>
                  <div className="modalRegisterButtonLeft">
                    <CustomButton
                      theme="green"
                      title={"Save Facility"}
                      customColumnStyle={"cardInsertSaveOther"}
                      buttonOutLine={true}
                      onClick={() => {
                        const values = getValues();
                        setValue("enableNavigate", false);
                        onSubmit(values);
                      }}
                    />
                  </div>
                </div>
              </form>
            </>
          </Grid.Column>
        </Grid>
      </CommonModal>
      <ConfirmModal
        viewModal={
          visibleDeleteModal ||
          visibleHeatDeleteModal ||
          visibleWaterDeleteModal ||
          visibleRefrigerantDeleteModal
        }
        closeModal={() => setVisibleDeleteModal(false)}
        cancel={() => {
          setVisibleDeleteModal(false);
          setDeleteData({});
          setVisibleHeatDeleteModal(false);
          setDeleteHeatData({});
          setVisibleWaterDeleteModal(false);
          setDeleteWaterData({});
          setVisibleRefrigerantDeleteModal(false);
          setDeleteRefrigerantData({});
        }}
        approve={() => {
          setVisibleDeleteModal(false);
          setVisibleHeatDeleteModal(false);
          setVisibleWaterDeleteModal(false);
          setVisibleRefrigerantDeleteModal(false);
          deleteFacilityEnergy();
        }}
        title="Delete Facility Energy"
        subTitle="Are you sure you want to delete facility energy?"
      />
    </>
  );
};

export default FacilityRegisterModal;
