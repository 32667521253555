import React from "react";
import { Grid } from "semantic-ui-react";
import { InputText } from "../InputText/InputText";
import { DropDown } from "../selectDropDown/DropDown";
import { TitleView } from "../titleView/TitleView";
import { isEmpty } from "lodash";

const SimulationProcessingDetails = ({
  register,
  processTypesWholeList,
  processingType,
  setProcessingType,
  setValue,
  facilityName,
  isValidate,
  facilityWholeList,
  setFacilityName,
  originalProcessData,
}: any) => {
  const disableProcess = isEmpty(originalProcessData?.processingName)
    ? false
    : true;
  return (
    <>
      <TitleView title="Processing Details" />
      <Grid>
        <Grid.Row divided>
          <Grid.Column computer={8} tablet={8} mobile={8}>
            <Grid.Column computer={8} tablet={8} mobile={8}>
              <p>Processing Name</p>
              <p>{originalProcessData?.processingName}</p>
            </Grid.Column>
            <Grid.Column computer={8} tablet={8} mobile={8} className="mt-1">
              <p>Processing Type</p>
              <p>
                {
                  processTypesWholeList?.find(
                    (types: any) =>
                      originalProcessData?.processingType == types?.value
                  )?.text
                }
              </p>
            </Grid.Column>
            <Grid.Column computer={8} tablet={8} mobile={8} className="mt-1">
              <p>Facility Name</p>
              <p>
                {
                  facilityWholeList?.find(
                    (types: any) =>
                      originalProcessData?.facilityId == types?.value
                  )?.text
                }
              </p>
            </Grid.Column>
            <Grid.Column
              computer={8}
              tablet={8}
              mobile={8}
              className="mt-1 mb-2"
            >
              <p>Machine Model Number</p>
              <p>{originalProcessData?.machineModelNo}</p>
            </Grid.Column>
          </Grid.Column>
          <Grid.Column computer={8} tablet={8} mobile={8}>
            <Grid.Column computer={8} tablet={8} mobile={16}>
              <Grid.Column
                computer={8}
                tablet={8}
                mobile={16}
                className="paddingRemoveTop"
              >
                <InputText
                  register={register}
                  labelName={"Process Name"}
                  placeholder="Process Name"
                  name={`processingName`}
                  disabled={disableProcess}
                  required={true}
                  errorMessage={"Process name is required"}
                />
              </Grid.Column>
              <DropDown
                labelName={"Processing Type"}
                placeholder="Select Type"
                currentData={processTypesWholeList}
                defaultValue={processingType}
                customGridColumn={"customGridColomnTyp"}
                handleChangeState={(e: any, { value }: any) => {
                  setProcessingType(value);
                  setValue("processingType", value);
                }}
              />
            </Grid.Column>
            <Grid.Column
              computer={8}
              tablet={8}
              mobile={16}
              className="paddingRemoveTop"
            >
              <DropDown
                labelName={"Facility Name*"}
                placeholder="Select Facility Name"
                currentData={facilityWholeList}
                defaultValue={facilityName}
                required
                validate={isValidate}
                errorMessage={"Facility name is required"}
                customGridColumn={"customGridColomnTyp"}
                handleChangeState={(e: any, { value }: any) => {
                  setFacilityName(value);
                  setValue("facilityName", value);
                }}
              />
            </Grid.Column>
            <Grid.Column
              computer={8}
              tablet={8}
              mobile={16}
              className="paddingRemoveTop"
            >
              <InputText
                register={register}
                labelName={"Machine Model Number"}
                placeholder="Machine Model Number"
                name={`machineModelNo`}
              />
            </Grid.Column>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};

export default SimulationProcessingDetails;
