import { Modal, Icon } from "semantic-ui-react";
import "./commonModal.scss";
import { ImageView } from "../ImageView/ImageView";
import { images } from "../../assets/images";
import { useCallback, useLayoutEffect, useState } from "react";

interface CommonModalProps {
  modalOpenState: boolean;
  modalCloseState: (state: boolean) => void;
  children: any;
  title?: string;
  size?: "mini" | "tiny" | "small" | "large" | "fullscreen";
  centered?: boolean;
  customModalTitleMain?: string;
  customModalTitle?: string;
  customDimmer?: string;
  style?: any;
}

export const CommonModal: React.FC<CommonModalProps> = ({
  modalOpenState,
  modalCloseState,
  children,
  title,
  size = "small",
  centered = false,
  customModalTitleMain,
  customModalTitle,
  customDimmer,
  style,
}: any) => {
  const [sizeStatus, setSizeStatus] = useState("large");

  useLayoutEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1500) {
        setSizeStatus("middle");
      }
      if (window.innerWidth > 1500) {
        setSizeStatus("large");
      }
      if (window.innerWidth < 991) {
        setSizeStatus("small");
      }
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const getMainContentView = useCallback(() => {
    switch (sizeStatus) {
      case "large":
        return "customDimmerLayoutLarge";
      case "middle":
        return "customDimmerLayoutMiddle";
      case "small":
        return "customDimmerLayoutSmall";
      default:
        return "customDimmerLayoutLarge";
    }
  }, [sizeStatus]);

  return (
    <Modal
      centered={centered}
      size={size}
      onClose={() => {
        modalCloseState(false);
      }}
      open={modalOpenState}
      className={`${customDimmer} ${getMainContentView()}`}
      style={{
        ...style,
      }}
    >
      <Modal.Content className="full-height">
        <Modal.Description className="modal-description">
          {title ? (
            <div className={`commonModalMain ${customModalTitleMain}`}>
              <h3 className={`commonModalTitle ${customModalTitle}`}>
                {title}
              </h3>
              <Icon
                name={"close"}
                size="large"
                className={"commonModalCloseIcon"}
                onClick={() => modalCloseState(false)}
              />
            </div>
          ) : null}
          {children}
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
};
