import { useMutation, useQuery } from "@tanstack/react-query";
import { ApiServiceErr, axiosApi } from "../apiService";
import { successMessage } from "../../helpers/ErrorHandler";

interface externalTransport {
  productId: string;
  salesUnitId: string;
  ingredientId: string;
  supplierId: string;
  transportationType: number;
  from: string;
  to: string;
  weight: number;
  distance: number;
  status: string;
}

interface externalTransportUpdateInput {
    id:string
    data:externalTransport
}

  export const useGetExternalTransportData = () =>
    useQuery<any, ApiServiceErr>(
      ["getExternalTransport"],
      async () => {
        const response = await axiosApi.get(
          `/external-transport`
        );
        return response?.data;
      },
      {
        cacheTime: 0,
      }
    );
  
  
  export const useCreateExternalTransport = (opt?: any) =>
    useMutation<any, ApiServiceErr, any>(async (data:externalTransport) => {
      const response = await axiosApi.post(`/external-transport`,data);
      return response.data;
    }, opt);
  
    export const useUpdateExternalTransport = (opt?: any) =>
      useMutation<any, ApiServiceErr, externalTransportUpdateInput>(
        async (data: externalTransportUpdateInput) => {
          const response = await axiosApi.put(
            `/external-transport/${data.id}`,
            data.data
          );
          return response.data;
        },
        opt
      );
  
  export const useGetExternalTransportById = (id: any) =>
    useQuery<any, ApiServiceErr>(
      ["getExternalTransportById"],
      async () => {
        const response = await axiosApi.get(`/external-transport/${id}`);
        return response?.data;
      },
      {
        cacheTime: 0,
        enabled:!!id
      }
    );
 

