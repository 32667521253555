import {
  useMutation,
  useQuery,
  UseMutationOptions,
} from "@tanstack/react-query";
import { successMessage } from "../helpers/ErrorHandler";
import { ApiServiceErr, axiosApi, customConfig } from "./apiService";

interface IngredientRequestParams {
  searchKey: string;
}

interface IngredientInfo {
  _id: string;
  tenantId: string;
  ingredient_name: string;
  supplierId: string;
}

interface SupplierInfo {
  _id: string;
  tenantId: string;
  supplier_name: string;
}

interface DrawingTool {
  _id: string;
  tenantId: string;
  salesUnitId: string;
  chartNodes: [
    {
      parentNode: string | undefined;
      id: string;
      type: string;
      data: any;
      position: any;
      width: number;
      height: number;
    }
  ];
  chartEdges: [
    {
      id: string;
      type: string;
      data: any;
      position: any;
      width: number;
      height: number;
      style: any;
      markerEnd: any;
      source: string;
      sourceHandle: string;
      target: string;
    }
  ];
  updatedAt: Date;
}

export const useGetSupplierDetailsForUser = (userId: string) =>
  useQuery<SupplierInfo, ApiServiceErr>(
    [userId],
    async () => {
      const response = await axiosApi.get(`/supplier/user/${userId}`);
      return response.data;
    },
    {
      cacheTime: 0,
      enabled: !!userId,
    }
  );

export const useGetIngredientForSupplier = ({
  searchKey,
}: IngredientRequestParams) =>
  useQuery<IngredientInfo[], ApiServiceErr>(
    [searchKey, "GetIngredientForSupplier"],
    async () => {
      const response = await axiosApi.get(`/ingredient/supplier`, {
        params: { searchKey },
      });
      return response.data;
    },
    {
      cacheTime: 0,
    }
  );

export const useGetSupplierDrawingToolData = (
  ingredientId: string | undefined,
  supplierId: string | undefined
) =>
  useQuery<DrawingTool, ApiServiceErr>(
    [ingredientId, supplierId],
    async () => {
      const response = await axiosApi.get(
        `/supplier-drawing-tool/${ingredientId}/${supplierId}`,
        customConfig
      );
      return response?.data || null;
    },
    {
      cacheTime: 0,
      enabled: !!ingredientId && !!supplierId,
    }
  );

export const useCreateSupplierDrawing = (opt?: UseMutationOptions) =>
  useMutation<string, ApiServiceErr, any>(async (data) => {
    const response = await axiosApi.post(`/supplier-drawing-tool`, data);
    successMessage(response.data);
    return response.data;
  }, opt);

export const useUpdateSupplierDrawing = (opt?: UseMutationOptions) =>
  useMutation<string, ApiServiceErr, any>(async (data) => {
    const response = await axiosApi.put(
      `/supplier-drawing-tool/${data._id}`,
      data
    );
    successMessage(response.data);
    return response.data;
  }, opt);

export const useGetSupplierDrawingToolById = (
  supplierDrawingId: string | undefined
) =>
  useQuery<DrawingTool, ApiServiceErr>(
    [supplierDrawingId],
    async () => {
      const response = await axiosApi.get(
        `/supplier-drawing-tool/${supplierDrawingId}`
      );
      return response?.data || null;
    },
    {
      cacheTime: 0,
      enabled: !!supplierDrawingId,
    }
  );
