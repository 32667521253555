import React from "react";

import CompanyList from "../screens/company/companyList/CompanyList";
import { adminAccess, userAccess } from "./useRoleAccess";

export const CompanyRoutes = [
  {
    path: "/company",
    route: <CompanyList />,
    protectRoutes: true,
    roleGrantAccess: [...adminAccess, ...userAccess],
  },
];
