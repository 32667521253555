import { DragEvent } from "react";
import { Header, Sidebar, Segment, Tab, Icon } from "semantic-ui-react";
import "./AddingItemsPanel.scss";
import { CreateNodeCard } from "../../../components/drawing-tool/CreateNodeCard";
import {
  farmGateNodes,
  generalNodes,
  nodeType,
  processGateNodes,
} from "../../../config/drawingConstants";
import { CreateGroupCard } from "../../../components/drawing-tool/CreateGroupCard";
import { images } from "../../../assets/images";

interface AddingItemsPanelProps {
  isVisible: boolean;
  onClose: () => void;
}

const AddingItemsPanel = (props: AddingItemsPanelProps) => {
  const { isVisible, onClose } = props;
  const panes = [
    {
      menuItem: "General",
      render: () => (
        <Tab.Pane as={Segment} className="tab-segment" basic attached={false}>
          {generalNodes.map((item) =>
            item?.exclude ? null : (
              <CreateNodeCard
                key={item.id}
                item={item}
                image={item.image}
                cardHeader={item.cardHeader}
                cardExtra={item.cardExtra}
              />
            )
          )}
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Farm Gate",
      render: () => (
        <Tab.Pane as={Segment} basic attached={false}>
          <CreateGroupCard
            key={"farm_gate_group"}
            item={{
              id: "farmGate",
              cardHeader: "Farm Gate",
            }}
            image={"farmGate"}
            cardHeader={"Add New Farm Gate"}
            cardExtra={"just grouping"}
          />
          {farmGateNodes.map((item) =>
            item.id !== nodeType.Cultivar ? (
              <CreateNodeCard
                key={item.id}
                item={item}
                image={item.image}
                cardHeader={item.cardHeader}
                cardExtra={item.cardExtra}
              />
            ) : null
          )}
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Process Gate",
      render: () => (
        <Tab.Pane as={Segment} basic attached={false}>
          <CreateGroupCard
            key={"process_gate_group"}
            item={{
              id: "processGate",
              cardHeader: "Process Gate",
            }}
            image={images.process_gate}
            cardHeader={"Add New Process Gate"}
            cardExtra={"just grouping"}
          />
          {processGateNodes.map((item) => (
            <CreateNodeCard
              key={item.id}
              item={item}
              image={item.image}
              cardHeader={item.cardHeader}
              cardExtra={item.cardExtra}
            />
          ))}
        </Tab.Pane>
      ),
    },
  ];
  return (
    <Sidebar
      as={Segment}
      width="wide"
      size="tiny"
      className="tab-side-menu"
      animation="push"
      direction={"left"}
      visible={isVisible}
    >
      <Segment basic compact className="side-menu-header">
        <Header as="h2">Supply Chain Mapping</Header>
        <Icon
          name="close"
          onClick={() => {
            onClose();
          }}
        />
      </Segment>
      <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
    </Sidebar>
  );
};

export default AddingItemsPanel;
