import React from "react";

import ClientList from "../screens/clientt/clientList/ClientList";
import { adminAccess, userAccess } from "./useRoleAccess";

export const ClientRoutes = [
  {
    path: "/client",
    route: <ClientList notFromDrawing={false} />,
    protectRoutes: true,
    roleGrantAccess: [...adminAccess, ...userAccess],
  },
];
