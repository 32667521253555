import React, { useEffect } from "react";
import { Dimmer, Loader } from "semantic-ui-react";

import { MainContentLoginLayout } from "../components/index";

import "./mainContanier.scss";
import { useLocation } from "react-router-dom";
import { MainContentViewLayout } from "./mainLayout/MainContentViewLayout";
import { useAuthContext } from "../hooks/useAuthContext";
import { usePostUserDetails } from "../api/userDetails";
import { setupNotifications } from "../config/firebase";
import useVisibilityChange from "../hooks/useVisibilityChange";
import {
  sendNativeNotification,
  toastNotification,
} from "../hooks/notificationHelpers";

const publicRoutes = [
  "/auth",
  "/password-reset",
  "/password-forgot",
  "/unauthorized",
];

export const MainContentLayout = ({ children }: any) => {
  const location = useLocation();
  const isForeground = useVisibilityChange();
  var currentPath = location.pathname;
  const { dispatch }: any = useAuthContext();

  //custom hook
  const {
    data: userProfileData,
    isLoading,
    mutate: loadUserData,
    isSuccess: isSuccessLoadData,
  } = usePostUserDetails();

  //load user details
  useEffect(() => {
    if (localStorage.getItem("IdToken")) {
      loadUserData("");
    }
  }, []);

  //save user data in context
  useEffect(() => {
    if (isSuccessLoadData) {
      dispatch({ type: "LOGIN", payload: userProfileData });
      requestPermission(userProfileData);
    }
  }, [isSuccessLoadData]);

  const checkUserAuth = () => {
    return publicRoutes.includes(currentPath);
  };

  const requestPermission = async (userProfileData: any) => {
    setupNotifications(userProfileData, (message: any) => {
      if (isForeground) {
        // App is in the foreground, show toast notification
        toastNotification({
          title: message.notification.title,
          body: message.notification.body,
        });
      } else {
        // App is in the background, show native notification
        sendNativeNotification({
          title: message.notification.title,
          description: message.notification.body,
        });
      }
      dispatch({ type: "NEW_NOTIFICATION" });
    });
  };

  //handle loading
  if (isLoading) {
    return (
      <Dimmer active>
        <Loader content="Loading" />
      </Dimmer>
    );
  }

  return checkUserAuth() ? (
    <MainContentLoginLayout children={children} />
  ) : (
    <MainContentViewLayout currentPath={currentPath} children={children} />
  );
};
