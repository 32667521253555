import { useMutation, useQuery } from "@tanstack/react-query";
import { ApiServiceErr, axiosApi } from "../apiService";
import { successMessage } from "../../helpers/ErrorHandler";

interface processGatePackage {
  packageName: string;
  packageMaterial: number;
  materialWeight: number;
  contentWeight: number;
  totalWeightWithPackaging: number;
}

interface processGatePackageMethod {
  packageName: string;
  packageMaterial: number;
  materialWeight: number;
  contentWeight: number;
  totalWeightWithPackaging: number;
}

interface processGatePackageMethodInsert {
  data: processGatePackageMethod;
  packagingId: string;
  processGateId: string;
}

interface processGatePackageMethodUpdateInsert {
  data: processGatePackageMethod;
  packagingMethodId: string;
  processGateId: string;
}

interface processGatePackageInsert {
  data: processGatePackage;
  packagingType: string;
  processGateId: string;
  packagingId: string;
}

interface processGatePackage {
  unitSize: number;
  numberOfBaseUnits: number;
  numberOfPacks: number;
  numberOfCases: number;
  packagingType: string;
  processGateId: string;
  packagingId: string;
}

interface processGatePackageMethodDelete {
  packagingMethodId: string;
  processGateId: string;
  packagingId: string;
}

interface processGatePackageDelete {
  packagingType: string;
  processGateId: string;
  packagingId: string;
  subPackagingId: string;
}

interface processGateAllPackageDelete {
  processGateId: string;
  packagingId: string;
}

export const useGetPackagingData = (packagingId: string) =>
  useQuery<any, ApiServiceErr>(
    [packagingId, "getProcessGateData"],
    async () => {
      const response = await axiosApi.get(
        `/supplier-process-gate/packaging/${packagingId}`
      );
      return response?.data;
    },
    {
      cacheTime: 0,
      enabled: !!packagingId,
    }
  );

export const useCreatePackaging = (opt?: any) =>
  useMutation<any, ApiServiceErr, any>(
    async (data: processGatePackageInsert) => {
      if (data.packagingId) {
        const response = await axiosApi.post(
          `/supplier-process-gate/packaging/${data.packagingType}/${data.processGateId}`,
          data.data,
          {
            params: { packagingId: data.packagingId },
          }
        );
        return response.data;
      } else {
        const response = await axiosApi.post(
          `/supplier-process-gate/packaging/${data.packagingType}/${data.processGateId}`,
          data.data
        );
        return response.data;
      }
    },
    opt
  );

  export const useUpdatePackaging = (opt?: any) =>
  useMutation<processGatePackage, ApiServiceErr, any>(
    async (data: processGatePackageInsert) => {
      const response = await axiosApi.put(
        `/supplier-process-gate/packaging/${data.packagingType}/${data.packagingId}`,
        data.data,
        {
          params: { processGateId: data.processGateId },
        }
      );
      return response.data;
    },
    opt
  );

export const useUpdatePackagingDetails = (opt?: any) =>
  useMutation<any, ApiServiceErr, any>(
    async (data: processGatePackageInsert) => {
      const response = await axiosApi.post(
        `/supplier-process-gate/packaging/detail`,
        data.data,
        {
          params: {
            processGateId: data.processGateId,
            packagingId: data.packagingId,
          },
        }
      );
      return response.data;
    },
    opt
  );

  export const useGetWasteManagement = () =>
  useQuery<any, ApiServiceErr>(
    ["getProcessWasteMangementData"],
    async () => {
      const response = await axiosApi.get(`/static-data/waste-management-types`);
      
      let wasteManagementTypes = response?.data.reduce((currentType:any,data:any)=>{
        let wasteType =  {
          key: data?.id,
          text: data?.waste_management_type,
          value: data?.id
      };
      return [...currentType,wasteType]
      },[])
      return [{
        key: "1",
        text: "   ",
        value: ""
    },...wasteManagementTypes]
    },
    {
      cacheTime: 0,
    }
  );

export const useDeletePackaging = (opt?: any) =>
  useMutation<any, ApiServiceErr, any>(
    async (data: processGatePackageDelete) => {
      const response = await axiosApi.delete(
        `/supplier-process-gate/packaging/${data.packagingType}/${data.subPackagingId}`,
        {
          params: {
            packagingId: data.packagingId,
            processGateId: data.processGateId,
          },
        }
      );
      return response.data;
    },
    opt
  );

export const useCreatePackagingMethod = (opt?: any) =>
  useMutation<any, ApiServiceErr, any>(
    async (data: processGatePackageMethodInsert) => {
      if (data.packagingId) {
        const response = await axiosApi.post(
          `/supplier-process-gate/packaging-method`,
          data.data,
          {
            params: {
              packagingId: data.packagingId,
              processGateId: data.processGateId,
            },
          }
        );
        return response.data;
      } else {
        const response = await axiosApi.post(
          `/supplier-process-gate/packaging-method`,
          data.data,
          {
            params: { processGateId: data.processGateId },
          }
        );
        return response.data;
      }
    },
    opt
  );

export const useUpdatePackagingMethod = (opt?: any) =>
  useMutation<processGatePackageMethod, ApiServiceErr, any>(
    async (data: processGatePackageMethodUpdateInsert) => {
      const response = await axiosApi.put(
        `/supplier-process-gate/packaging-method/${data.packagingMethodId}`,
        data.data,
        {
          params: { processGateId: data.processGateId },
        }
      );
      return response.data;
    },
    opt
  );

export const useDeletePackagingMethod = (opt?: any) =>
  useMutation<any, ApiServiceErr, any>(
    async (data: processGatePackageMethodDelete) => {
      const response = await axiosApi.delete(
        `/supplier-process-gate/packaging-method/${data.packagingMethodId}`,
        {
          params: {
            processGateId: data.processGateId,
            packagingId: data.packagingId,
          },
        }
      );
      return response.data;
    },
    opt
  );

export const useGetPackagingTypes = () =>
  useQuery<any, ApiServiceErr>(
    ["getPackagingType"],
    async () => {
      const response = await axiosApi.get(`/static-data/packaging-types`);
      let createPackagingType = response?.data.reduce(
        (currentType: any, data: any) => {
          let packagingTypeData = {
            key: data?.id,
            text: data?.packaging_name,
            value: data?.id,
          };
          return [...currentType, packagingTypeData];
        },
        []
      );
      return createPackagingType;
    },
    {
      cacheTime: 0,
    }
  );

export const useDeleteAllPackaging = (opt?: any) =>
  useMutation<any, ApiServiceErr, any>(
    async (data: processGateAllPackageDelete) => {
      const response = await axiosApi.delete(
        `/supplier-process-gate/packaging/${data.packagingId}`,
        {
          params: {
            processGateId: data.processGateId,
          },
        }
      );
      return response.data;
    },
    opt
  );
