import * as React from "react";
import { getPathName } from "../../utils/utils";
const ClientIcon = (props: any) => {
  const pathName = getPathName();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={36}
      height={36}
      fill="none"
      {...props}
    >
      <path
        fill={
          pathName == "client-list"
            ? "var(--menuBarIconActive)"
            : "var(--menuBarIconDeactive)"
        }
        d="M22.5 6a6 6 0 1 1 0 12 6 6 0 0 1 0-12Zm0 2.85a3.15 3.15 0 1 0 0 6.3 3.15 3.15 0 0 0 0-6.3Zm0 10.65c4.005 0 12 1.995 12 6V30h-24v-4.5c0-4.005 7.995-6 12-6Zm0 2.85c-4.5 0-9.15 2.19-9.15 3.15v1.65h18.3V25.5c0-.96-4.695-3.15-9.15-3.15Zm-15-2.43-3.75 2.235 1.02-4.215-3.27-2.82 4.305-.375 1.695-3.96 1.665 3.96 4.335.375-3.3 2.82.975 4.215L7.5 19.92Z"
      />
    </svg>
  );
};
export default ClientIcon;
