import React, { useState } from "react";
import {
  Dimmer,
  Grid,
  Loader,
  Table,
  AccordionTitle,
  AccordionContent,
  Accordion,
} from "semantic-ui-react";
import { useForm } from "react-hook-form";

import {
  InputText,
  CustomButton,
  CommonTable,
  DropDown,
  AccordionTitleView,
} from "../../../../../components";
import { MdDeleteOutline, MdModeEditOutline } from "react-icons/md";
import { CultivationPackagingMethodView } from "../../../../../config/constants";
import ConfirmModal from "../../../../../components/confirmViewModal/ConfirmModal";
import { InputNumber } from "../../../../../components/InputText/InputNumber";
import {
  useCreatePackagingMethod,
  useDeletePackagingMethod,
  useUpdatePackagingMethod,
  useGetWasteManagement,
} from "../../../../../api/farmGate/packaging";
import { successMessage } from "../../../../../helpers/ErrorHandler";
import { PACKAGING_LEVELS } from "../../../../../config/constants";

import "./packaging.scss";
import { valueGraterThanZero } from "../../../../../utils/utils";

const PackagingMethod = ({
  packagingData,
  refetchData,
  farmGateId,
  packagingId,
  savePackingNodeData,
  packagingName,
}: any) => {
  const [enableSizeEditStatus, setEnableSizeEditStatus] = useState<any>(false);
  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false);
  const [deleteData, setDeleteData] = useState<any>({});
  const [packagingMethodId, setPackagingMethodId] = useState<any>(null);
  const [defaultPackagingLevels, setDefaultLevel] = useState<any>(
    PACKAGING_LEVELS[0].value
  );
  const [defaultWasteType, setDefaultWasteType] = useState<any>();
  const [accordionEnabled, setAccordionEnabled] = useState<boolean>(false);

  const {
    register: packageMethodRegister,
    formState: { errors: errorsUnit },
    handleSubmit: handleSubmitPackageMethod,
    reset,
    setValue,
    setError,
    clearErrors,
    control,
  } = useForm();

  //custom hook create packaging method
  const { isLoading, mutate } = useCreatePackagingMethod();

  //custom hook update packaging method
  const { isLoading: isLoadingUpdate, mutate: updatePackaging } =
    useUpdatePackagingMethod();

  //custom hook delete packaging method
  const { isLoading: isDeletePackaging, mutate: deletePackage } =
    useDeletePackagingMethod();

  const { data: wasteManagementTypes } = useGetWasteManagement();

  const setUnitSizeUpdate = (data: any) => {
    setValue("packagingMethodName", data.packagingMethodName);
    setValue("packagingMachine", data.packagingMachine);
    setValue("energy", data.energy);
    setValue("waste", data.waste);
    setValue("capacity", data.capacity);
    setPackagingMethodId(data._id);
    setDefaultLevel(data.packagingLevel);
    let getIndex = wasteManagementTypes?.findIndex(
      (e: any) => e?.value == data.wasteManagementMethod
    );
    setDefaultWasteType(getIndex);
  };

  const commentResetValue = (statusMessage: any) => {
    reset();
    setEnableSizeEditStatus(false);
    refetchData();
    successMessage(statusMessage);
    setPackagingMethodId(null);
    setDefaultLevel(PACKAGING_LEVELS[0].value);
    setDefaultWasteType(0);
  };

  const loadTableData = () => {
    return packagingData.map((data: any, index: number) => {
      return (
        <Table.Row className="tbleR" key={index}>
          <Table.Cell>
            <p>{data?.packagingMethodName ?? ""}</p>
          </Table.Cell>
          <Table.Cell>
            <p>{data?.packagingMachine ?? ""}</p>
          </Table.Cell>
          <Table.Cell>
            <p>{data?.packagingLevel ?? ""}</p>
          </Table.Cell>
          <Table.Cell>
            <p>{data?.energy ?? ""}</p>
          </Table.Cell>
          <Table.Cell>
            <p>{data?.capacity ?? ""}</p>
          </Table.Cell>
          <Table.Cell>
            <p>{data?.waste ?? ""}</p>
          </Table.Cell>
          <Table.Cell>
            <Grid>
              <Grid.Column computer={5} tablet={16} mobile={16}>
                <MdModeEditOutline
                  cursor={"pointer"}
                  size={24}
                  color="var(--tableEditIcon)"
                  onClick={() => {
                    setUnitSizeUpdate(data);
                    setEnableSizeEditStatus(true);
                  }}
                />
              </Grid.Column>
              <Grid.Column computer={4} tablet={16} mobile={16}>
                <MdDeleteOutline
                  cursor={"pointer"}
                  size={24}
                  color="var(--tableEditIcon)"
                  onClick={() => {
                    setDeleteData(data);
                    setVisibleDeleteModal(true);
                  }}
                />
              </Grid.Column>
            </Grid>
          </Table.Cell>
        </Table.Row>
      );
    });
  };

  //api call add to package data
  const addPackageMethodData = (data: any) => {
    let packageMethodData = {
      data,
      packagingId,
      farmGateId,
    };
    mutate(packageMethodData, {
      onSuccess: (data) => {
        commentResetValue("Package method created successfully");
        if (packagingId) {
          refetchData(packagingId);
          return;
        }
        savePackingNodeData(data.packagingId);
      },
    });
  };

  //api call edit to package data
  const editPackageMethodData = (data: any) => {
    let packageMethodData = {
      data,
      packagingMethodId: packagingMethodId,
      farmGateId,
    };
    updatePackaging(packageMethodData, {
      onSuccess: () => {
        commentResetValue("Package method updated successfully");
      },
    });
  };

  //handle package data view
  const packageMethodInsert = (data: any, status: any) => {
    data.energy = Number(data.energy);
    data.waste = Number(data.waste);
    data.capacity = Number(data.capacity);
    data.wasteManagementMethod = defaultWasteType
      ? Number(wasteManagementTypes?.[defaultWasteType]?.value)
      : null;
    data.packagingLevel = defaultPackagingLevels;

    if (status == "add") {
      addPackageMethodData(data);
    } else {
      editPackageMethodData(data);
    }
  };

  //delete package method data
  const deletePackageMethodData = (data: any) => {
    const deletePackagingMethodData = {
      farmGateId,
      packagingId,
      packagingMethodId: data._id,
    };
    deletePackage(deletePackagingMethodData, {
      onSuccess() {
        refetchData();
        successMessage("Package deleted successfully");
      },
    });
  };

  const handleClick = () => {
    if (!packagingName) return;
    setAccordionEnabled(!accordionEnabled);
  };

  if (isLoading || isLoadingUpdate || isDeletePackaging) {
    return (
      <Dimmer active>
        <Loader content="Loading" />
      </Dimmer>
    );
  }

  return (
    <Accordion>
      <AccordionTitle active={accordionEnabled} onClick={handleClick}>
        <AccordionTitleView
          isDisabled={!packagingName}
          accordionEnabled={accordionEnabled}
          title={"Add Packaging Machinery"}
        />
      </AccordionTitle>
      <AccordionContent active={accordionEnabled}>
        <Grid>
          <Grid.Row columns={2}>
            <Grid.Column
              computer={13}
              tablet={8}
              mobile={16}
              className="borderForPackingMethod"
            >
              <Grid>
                <Grid.Row columns={3}>
                  <Grid.Column computer={5}>
                    <InputText
                      register={packageMethodRegister}
                      errors={errorsUnit.packagingMethodName}
                      labelName={"Packaging Step Name"}
                      placeholder=""
                      name="packagingMethodName"
                      required={true}
                      errorMessage="Packaging step name is required"
                    />
                  </Grid.Column>
                  <Grid.Column computer={5}>
                    <InputText
                      register={packageMethodRegister}
                      errors={errorsUnit.packagingMachine}
                      labelName={"Packaging Machinery Name"}
                      placeholder=""
                      name="packagingMachine"
                      required={false}
                      errorMessage="Packaging Machine is required"
                    />
                  </Grid.Column>
                  <Grid.Column computer={5}>
                    <DropDown
                      labelName={"Packaging Level"}
                      placeholder="Select Level"
                      currentData={PACKAGING_LEVELS}
                      defaultValue={defaultPackagingLevels}
                      customGridColumn={"customGridColomnType"}
                      handleChangeState={(e: any, { value }: any) => {
                        setDefaultLevel(value);
                      }}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <Grid>
                <Grid.Row>
                  <Grid.Column
                    computer={4}
                    tablet={8}
                    mobile={16}
                    className="paddingRemoveTop"
                  >
                    <InputNumber
                      control={control}
                      errors={errorsUnit.energy}
                      labelName={"Energy (kWh)"}
                      placeholder="Energy"
                      name="energy"
                      required={false}
                      errorMessage="Energy is required"
                    />
                  </Grid.Column>
                  <Grid.Column
                    computer={4}
                    tablet={8}
                    mobile={16}
                    className="paddingRemoveTop"
                  >
                    <InputNumber
                      control={control}
                      errors={errorsUnit.capacity}
                      labelName={"Capacity (pk/hr)"}
                      placeholder="Capacity"
                      name="capacity"
                      required={false}
                      errorMessage="Capacity is required"
                    />
                  </Grid.Column>
                  <Grid.Column
                    computer={4}
                    tablet={8}
                    mobile={16}
                    className="paddingRemoveTop"
                  >
                    <InputNumber
                      control={control}
                      errors={errorsUnit.waste}
                      labelName={"Waste (pk/hr)"}
                      placeholder="Waste"
                      name="waste"
                      required={false}
                      errorMessage="Waste is required"
                    />
                  </Grid.Column>
                  <Grid.Column
                    computer={4}
                    tablet={8}
                    mobile={16}
                    className="paddingRemoveTop"
                  >
                    <DropDown
                      labelName={"Waste Management"}
                      placeholder=""
                      currentData={wasteManagementTypes}
                      defaultValue={
                        wasteManagementTypes?.[defaultWasteType]?.value || null
                      }
                      customGridColumn={"customGridColomnType"}
                      handleChangeState={(e: any, { value }: any) => {
                        let getIndex = wasteManagementTypes?.findIndex(
                          (e: any) => e?.value === value
                        );
                        setDefaultWasteType(getIndex);
                      }}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>

            <Grid.Column
              computer={2}
              tablet={8}
              mobile={16}
              style={{ alignSelf: "center" }}
            >
              {enableSizeEditStatus ? (
                <CustomButton
                  theme="green"
                  title="Edit"
                  onClick={handleSubmitPackageMethod((data) => {
                    packageMethodInsert(data, "edit");
                  })}
                />
              ) : (
                <CustomButton
                  theme="green"
                  icon="plus"
                  onClick={handleSubmitPackageMethod((data) => {
                    packageMethodInsert(data, "add");
                  })}
                />
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {packagingData?.length > 0 && (
          <Grid.Column
            computer={16}
            tablet={16}
            mobile={16}
            className="packagingMethodDetailsView"
          >
            <CommonTable tableHeaderData={CultivationPackagingMethodView}>
              {loadTableData()}
            </CommonTable>
          </Grid.Column>
        )}
      </AccordionContent>
      <ConfirmModal
        viewModal={visibleDeleteModal}
        closeModal={() => setVisibleDeleteModal(false)}
        cancel={() => {
          setVisibleDeleteModal(false);
          setDeleteData({});
        }}
        approve={() => {
          setVisibleDeleteModal(false);
          deletePackageMethodData(deleteData);
        }}
        title="Delete single unit packaging"
        subTitle="Are you sure you want to delete single unit packaging?"
      />
    </Accordion>
  );
};

export default PackagingMethod;
