import IngredientIcon from "../assets/mappingIcon/IngredientIcon";
import ExternalTransport from "../assets/mappingIcon/ExternalTransport";
import ExternalStorage from "../assets/mappingIcon/ExternalStorage";
import ClientIcon from "../assets/mappingIcon/ClientIcon";
import CultivationIcon from "../assets/mappingIcon/CultivationIcon";
import ProcessIcon from "../assets/mappingIcon/ProcessIcon";
import InternalTransport from "../assets/mappingIcon/InternalTransportIcon";
import PackIcon from "../assets/mappingIcon/PackIcon";
import InternalStorage from "../assets/mappingIcon/InternalStorageIcon";
import FarmGateIcon from "../assets/mappingIcon/FarmgateIcon";
import ProcessGateIcon from "../assets/mappingIcon/ProcessGateIcon";

export const iconMap: { [key: string]: React.FC } = {
  ingredient: IngredientIcon,
  externalTransport: ExternalTransport,
  externalWarehouse: ExternalStorage,
  client: ClientIcon,
  cultivation: CultivationIcon,
  process: ProcessIcon,
  internalTransport: InternalTransport,
  packing: PackIcon,
  internalStorage: InternalStorage,
  farmGate:FarmGateIcon,
  processGate:ProcessGateIcon
};
