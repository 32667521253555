import React from "react";
import { Grid } from "semantic-ui-react";
import DatePicker from "react-datepicker";
import "./inputDate.scss";

export const InputDatePicker = ({
  computer = undefined,
  tablet = undefined,
  mobile = undefined,
  labelName,
  customLabelText,
  customGridMain,
  customGridColumn,
  changeDate,
  currentSelectedData = new Date()
}: any) => {
  return (
    <>
      <label className={`inputTextLable ${customLabelText}`}>{labelName}</label>
      <Grid className={`${customGridMain}`}>
        <Grid.Column
          computer={computer}
          tablet={tablet}
          mobile={mobile}
          className={`inputTextContanier ${customGridColumn}`}
        >
          <DatePicker selected={currentSelectedData} onChange={(date: any) => {changeDate(date)}} />
        </Grid.Column>
      </Grid>
    </>
  );
};
