import React from "react";

import UserList from "../screens/user/userList/UserList";
import { adminAccess, supplierAccess, userAccess } from "./useRoleAccess";

export const UserRoutes = [
  {
    path: "/users",
    route: <UserList />,
    protectRoutes: true,
    roleGrantAccess: [...adminAccess, ...userAccess, ...supplierAccess],
  },
];
