import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  Grid,
  AccordionTitle,
  Accordion,
  AccordionContent,
  Table,
} from "semantic-ui-react";
import {
  CustomButton,
  CommonTable,
  InputText,
  TitleView,
  AccordionTitleView,
} from "../../../components";
import { usePostProcessGateRecipeData } from "../../../api/process";
import { errorView, successMessage } from "../../../helpers/ErrorHandler";
import { useForm } from "react-hook-form";
import { useGetSupplierRawMaterialsById } from "../../../api/supplierRawMaterials";
import { findIncomingIngredientsAndProcess } from "../../../helpers/DrawingHelper";
import { DrawingContext } from "../../drawing_tool/DrawingTool";
import { useGetProcessRecipeByProcessId } from "../../../api/processGate/supplierProcess";
import { isEmpty } from "lodash";

const SupplierRecipeContent = ({
  processId,
  recipeId,
  nodeItem,
  type,
  checkRecipeValidation = () => {},
}: any) => {
  const { chartEdges, chartNodes } = useContext(DrawingContext);

  const [accordionEnabled, setAccordionEnabled] = useState<boolean>(false);
  const [recipeData, setRecipeData] = useState<any>({
    ingredientList: [],
    processList: [],
    processId: "",
  });

  const values = useMemo(() => {
    return {
      recipeData: recipeData,
      nodeItem,
      processId,
      recipeId,
      type,
    };
  }, [accordionEnabled, recipeData]);

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
    control,
  } = useForm({
    mode: "all",
    defaultValues: values,
    values: values,
  });

  const [supplierIds, setSupplierIds] = useState<string[]>([]);
  const [validationError, setValidationError] = useState<{
    id: string;
    error: string;
  }>({ id: "", error: "" });

  const {
    mutate: postProcessGateRecipeData,
    isSuccess: isSuccessPostProcessGateRecipeData,
  } = usePostProcessGateRecipeData();
  const suppliers = useGetSupplierRawMaterialsById(supplierIds);

  const onSubmitHandler = (data: any) => {
    const formData: any = getValues();
    let processList = [];
    let ingredientList = [];

    for (const key in formData) {
      if (key.includes("process") && key.split("-")[1]) {
        processList.push({
          id: key.split("-")[1],
          value: formData[key].value ? formData[key].value : 0,
        });
      } else if (key.includes("ingredient") && key.split("-")[1]) {
        ingredientList.push({
          id: key.split("-")[1],
          value: formData[key].value ? formData[key].value : 0,
        });
      }
    }
    const recipeData = {
      processId,
      processList,
      ingredientList,
      type,
    };

    postProcessGateRecipeData(recipeData);
  };

  const { data: processRecipeData, refetch: refetchProcessRecipe } =
    useGetProcessRecipeByProcessId({
      recipeId: recipeId,
      type: "supplier-process-gate",
    });

  const loadRecipeData = () => {
    let processList: any[] = [];
    let ingredientList: any[] = [];
    try {
      const incomings = findIncomingIngredientsAndProcess(
        chartEdges,
        chartNodes,
        nodeItem.id,
        ingredientList,
        processList
      );
      if (
        incomings.ingredientList.length > 0 ||
        incomings.processList.length > 0
      ) {
        processList = incomings?.processList?.map((item: any) => {
          const processMap = processRecipeData?.processList?.find(
            (process: any) => item?.data?.reference?.processId === process.id
          );
          if (processMap) {
            return {
              ...item,
              ...processMap,
            };
          }
          return item;
        });

        ingredientList = incomings?.ingredientList?.map((item: any) => {
          const processMap = processRecipeData?.ingredientList?.find(
            (process: any) =>
              item?.data?.reference?.supplierRawMaterialId === process.id
          );
          if (processMap) {
            return {
              ...item,
              ...processMap,
            };
          }
          return item;
        });
        setRecipeData({
          ingredientList,
          processList,
          processId,
        });
        return true;
      } else {
        errorView("Please create ingredient or process");
        return false;
      }
    } catch (error) {
      errorView("Something went wrong");
      return false;
    }
  };

  useEffect(() => {
    if (accordionEnabled) {
      loadRecipeData();
    }
  }, [accordionEnabled]);

  useEffect(() => {
    if (isSuccessPostProcessGateRecipeData) {
      successMessage("Recipe Added Successfully");
      setAccordionEnabled(false);
    }
  }, [isSuccessPostProcessGateRecipeData]);

  useEffect(() => {
    if (values.recipeData?.ingredientList?.length > 0) {
      values.recipeData?.ingredientList?.map((ingredient: any) => {
        setSupplierIds((ids) => {
          if (ids) {
            return [ingredient?.data?.reference?.supplierRawMaterialId];
          }
          return [ingredient?.data?.reference?.supplierRawMaterialId];
        });
      });
    }
  }, [values]);

  const validateTotalValue = (id: string, value: string) => {
    setValidationError({ id: "", error: "" });
    if (isNaN(parseFloat(value))) {
      setValidationError({
        id,
        error: "Please enter a valid number",
      });
      return;
    } else {
      const formData: any = getValues();
      let totalValue = 0;
      for (const key in formData) {
        if (key.includes("process") && key.split("-")[1]) {
          totalValue += parseFloat(formData[key].value);
        } else if (key.includes("ingredient") && key.split("-")[1]) {
          totalValue += parseFloat(formData[key].value);
        }
      }

      totalValue += parseFloat(value);
      if (totalValue > 101) {
        setValidationError({
          id,
          error: "Sum of recipe values less than or equal to 100%",
        });
      }
    }
  };

  const renderProcessListTable = (data: any) => {
    return values?.recipeData?.processList?.map((process: any) => {
      const processId = process.data?.reference?.processId;
      return (
        <Table.Row className="tbleR" key={processId}>
          <Table.Cell>
            <p>{process?.data?.label}</p>
          </Table.Cell>
          <Table.Cell>
            <InputText
              register={register}
              name={`process-${processId}.value`}
              control={control}
              defaultValues={process?.value}
              onChangeFunction={(e: any) => {
                validateTotalValue(processId, e.target.value);
              }}
              valueAsNumber={true}
            />
            {validationError.id === processId && (
              <span style={{ color: "red" }}>{validationError.error}</span>
            )}
          </Table.Cell>
        </Table.Row>
      );
    });
  };

  const handleClick = () => {
    if (!loadRecipeData() || isEmpty(processId)) {
      checkRecipeValidation();
      return;
    }
    setAccordionEnabled(!accordionEnabled);
  };

  return (
    <div>
      <Accordion>
        <AccordionTitle active={accordionEnabled} onClick={handleClick}>
          <AccordionTitleView
            accordionEnabled={accordionEnabled}
            title={"Recipe"}
          />
        </AccordionTitle>
        <AccordionContent active={accordionEnabled}>
          <form onSubmit={handleSubmit(onSubmitHandler)}>
            <div>
              <Grid>
                {values.recipeData?.processList?.length > 0 && (
                  <>
                    <Grid.Row>
                      <Grid.Column width={16}>
                        <TitleView
                          CustomTextTitle="customT"
                          CustomTitleViewMain="recipeTitle"
                          title="Process (s)"
                        />
                      </Grid.Column>
                      <Grid.Column width={16}>
                        <CommonTable
                          tableHeaderData={[
                            { name: "Name", col: 12 },
                            { name: "Value %", col: 4 },
                          ]}
                        >
                          {renderProcessListTable(values.recipeData)}
                        </CommonTable>
                      </Grid.Column>
                    </Grid.Row>
                  </>
                )}
              </Grid>
              <Grid>
                {values.recipeData?.ingredientList?.length > 0 && (
                  <>
                    <Grid.Row>
                      <Grid.Column width={16}>
                        <TitleView
                          CustomTextTitle="customT"
                          CustomTitleViewMain="recipeTitle"
                          title="Ingredient (s)"
                        />
                      </Grid.Column>
                      <Grid.Column width={16}>
                        <CommonTable
                          tableHeaderData={[
                            { name: "Name", col: 6 },
                            { name: "Supplier", col: 6 },
                            { name: "Value %", col: 4 },
                          ]}
                        >
                          {values.recipeData?.ingredientList?.map(
                            (ingredient: any, index: any) => {
                              return (
                                <Table.Row
                                  key={
                                    ingredient?.data?.reference
                                      ?.supplierRawMaterialId
                                  }
                                >
                                  <Table.Cell>
                                    <p>{ingredient?.data?.label}</p>
                                  </Table.Cell>
                                  <Table.Cell>
                                    <p>{suppliers?.data?.supplierName}</p>
                                  </Table.Cell>
                                  <Table.Cell>
                                    <InputText
                                      register={register}
                                      name={`ingredient-${ingredient?.data?.reference?.supplierRawMaterialId}.value`}
                                      control={control}
                                      defaultValues={ingredient?.value}
                                      onChangeFunction={(e: any) => {
                                        validateTotalValue(
                                          ingredient?.data?.reference
                                            ?.supplierRawMaterialId,
                                          e.target.value
                                        );
                                      }}
                                      valueAsNumber={false}
                                    />
                                    {validationError.id ===
                                      ingredient?.data?.reference
                                        ?.ingredientId && (
                                      <span style={{ color: "red" }}>
                                        {validationError.error}
                                      </span>
                                    )}
                                  </Table.Cell>
                                </Table.Row>
                              );
                            }
                          )}
                        </CommonTable>
                      </Grid.Column>
                    </Grid.Row>
                  </>
                )}
              </Grid>
            </div>

            <div className="buttonGroup">
              <div className="sipCancel">
                <CustomButton
                  onClick={() => {
                    setAccordionEnabled(false);
                  }}
                  title="Cancel"
                  theme="green"
                  buttonOutLine={true}
                  disabled={Boolean(validationError.error)}
                />
              </div>
              <div className="sipAdd">
                <CustomButton type="submit" title="Save" theme="green" />
              </div>
            </div>
          </form>
        </AccordionContent>
      </Accordion>
    </div>
  );
};

export default SupplierRecipeContent;
