import React from "react";
import { Grid, GridRow, Icon } from "semantic-ui-react";

import "./AccordionTitleView.scss";
interface AccordionTitleViewProps {
  title: string | undefined;
  accordionEnabled: boolean;
  plusIcon?: boolean;
  isDisabled?: boolean;
}

export const AccordionTitleView: React.FC<AccordionTitleViewProps> = ({
  title,
  accordionEnabled,
  plusIcon = true,
}: any) => {
  return (
    <div className={`titleViewMain ${accordionEnabled ? "active" : ""}`}>
      <Grid>
        <GridRow>
          <Grid.Column floated="left" computer={15} tablet={8} mobile={16}>
            <p className={`textTitle ${accordionEnabled ? "active" : ""}`}>
              {title}
            </p>
          </Grid.Column>
          {plusIcon && (
            <Grid.Column floated="left" computer={1} tablet={8} mobile={16}>
              <Icon
                className={`plusIcon ${accordionEnabled ? "active" : ""}`}
                name={accordionEnabled ? "minus" : "plus"}
                size="small"
              />
            </Grid.Column>
          )}
        </GridRow>
      </Grid>
    </div>
  );
};
