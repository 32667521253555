import { Grid } from "semantic-ui-react";
import "./IngredientDetails.scss";
import { DATA_TYPES } from "../../../config/constants";

const IngredientDetailsView = ({
  ingredient_type,
  data_type,
  unit_size,
  unit_type,
  ean_bar_code,
}: any) => {
  
  const dataType = DATA_TYPES.find((type) => type.value === data_type)?.text;

  return (
    <Grid className="dataBox">
      <Grid.Column
        computer={8}
        tablet={8}
        mobile={16}
        className="paddingRemoveTop"
      >
        <p className="mb-0">Ingredient Type</p>
        {ingredient_type}
      </Grid.Column>
      <Grid.Column
        computer={8}
        tablet={8}
        mobile={16}
        className="paddingRemoveTop"
      >
        <p className="mb-0">Data Type</p>
        {dataType}
      </Grid.Column>
      <Grid.Column
        computer={4}
        tablet={8}
        mobile={16}
        className="paddingRemoveTop"
      >
        <p className="mb-0">Unit Size</p>
        {unit_size}
      </Grid.Column>
      <Grid.Column
        computer={4}
        tablet={8}
        mobile={16}
        className="paddingRemoveTop"
      >
        <p className="mb-0">Unit Type</p>
        {unit_type}
      </Grid.Column>
      <Grid.Column
        computer={4}
        tablet={8}
        mobile={16}
        className="paddingRemoveTop"
      >
        <p className="mb-0">EAN Barcode</p>
        {ean_bar_code}
      </Grid.Column>
    </Grid>
  );
};

export default IngredientDetailsView;
