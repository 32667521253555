import React from "react";
import { Progress } from "semantic-ui-react";
import { MdModeEditOutline } from "react-icons/md";

import { ImageView } from "../ImageView/ImageView";
import Dashboard from "../../assets/images/dashboard.png";

import "./progressBar.scss";
import { IconType } from "react-icons/lib";

interface ProgressBarProps {
  imageView?: boolean;
  iconView?: boolean;
  IconName?: IconType;
  title?: string;
  count?: string;
  customProgressBarViewMain?: string;
  customProgressBarTitle?: string;
  customProgressImageViewMain?: string;
  customProgressImageView?: string;
  customProgressCount?: string;
  iconColor?: string;
  image?: string;
  percentage: number;
}

const ProgressBar: React.FC<ProgressBarProps> = ({
  imageView = false,
  iconView = false,
  IconName = MdModeEditOutline,
  title = "Title",
  count = "50%",
  customProgressBarViewMain = "",
  customProgressBarTitle = "",
  customProgressImageViewMain = "",
  customProgressImageView = "",
  customProgressCount = "",
  iconColor = "#fff",
  image = Dashboard,
  percentage = 0
}) => {
  return (
    <div className={`progressBarViewMain ${customProgressBarViewMain}`}>
      <p className={`progressBarTitle ${customProgressBarTitle}`}>{title}</p>
      <div className={`progressImageViewMain ${customProgressImageViewMain}`}>
        {imageView ? (
          <ImageView
            addRandomId={false}
            src={image}
            customImageView={`progressImageView ${customProgressImageView}`}
          />
        ) : iconView ? (
          <IconName size={24} color={iconColor} />
        ) : null}
        <p
          className={
            iconView
              ? `progressCount ${customProgressCount}`
              : `progressCountView ${customProgressCount}`
          }
        >
          {count}
        </p>
      </div>
      <Progress percent={percentage} />
    </div>
  );
};

export default ProgressBar;
