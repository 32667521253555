import * as React from "react";

const InternalTransport = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <circle cx={24} cy={24} r={24} fill="var(--mappingImageBackground)" />
    <path
      fill="var(--mappingIconColor)"
      d="m34.44 23.732-2.25-5.366a.763.763 0 0 0-.276-.34.739.739 0 0 0-.414-.127h-2.25v-1.533a.775.775 0 0 0-.22-.542.742.742 0 0 0-.53-.225H14.25c-.199 0-.39.08-.53.225a.775.775 0 0 0-.22.542v13.03c0 .204.079.399.22.542.14.144.331.225.53.225h1.605a3.07 3.07 0 0 0 1.073 1.631c.523.41 1.163.63 1.822.63s1.299-.22 1.822-.63a3.07 3.07 0 0 0 1.073-1.631h4.71a3.07 3.07 0 0 0 1.073 1.631c.523.41 1.163.63 1.822.63s1.299-.22 1.822-.63a3.07 3.07 0 0 0 1.073-1.631h1.605c.199 0 .39-.08.53-.225a.775.775 0 0 0 .22-.542v-5.365a.781.781 0 0 0-.06-.3Zm-5.19-4.3h1.755l1.605 3.832h-3.36v-3.832Zm-10.5 11.497c-.297 0-.587-.09-.833-.258a1.527 1.527 0 0 1-.553-.688 1.564 1.564 0 0 1 .325-1.67 1.47 1.47 0 0 1 1.635-.332c.274.115.508.311.673.564a1.557 1.557 0 0 1-.186 1.935 1.484 1.484 0 0 1-1.061.45Zm7.605-2.3h-4.71A3.07 3.07 0 0 0 20.572 27a2.958 2.958 0 0 0-1.822-.631c-.659 0-1.299.221-1.822.63a3.07 3.07 0 0 0-1.073 1.632H15V17.132h12.75v9.627c-.341.203-.64.473-.88.793-.24.322-.414.688-.515 1.078Zm2.895 2.3c-.297 0-.587-.09-.833-.258a1.527 1.527 0 0 1-.553-.688 1.564 1.564 0 0 1 .325-1.67 1.47 1.47 0 0 1 1.635-.332c.274.115.508.311.673.564a1.557 1.557 0 0 1-.186 1.935 1.484 1.484 0 0 1-1.061.45Zm3.75-2.3h-.855a3.072 3.072 0 0 0-1.064-1.654 2.959 2.959 0 0 0-1.831-.645v-1.533H33v3.833Z"
    />
  </svg>
);
export default InternalTransport;
