import { useMutation, useQuery } from "@tanstack/react-query";
import { ApiServiceErr, MutOptions, axiosApi } from "./apiService";
import { successMessage } from "../helpers/ErrorHandler";

export const usePostChemicalData = (opt?: MutOptions<string>) => {
  return useMutation<any, ApiServiceErr, any>(async (data) => {
    if(data.supplierDataHandle){
      if (data.type == "farm") {
        return await axiosApi.post(
          `/supplier-farm-gate/processing-chemical?processingId=${data?.processingId}`,
          data.chemicalData
        );
      } else {
        return await axiosApi.post(
          `/supplier-process-gate/processing-chemical?processingId=${data?.processingId}`,
          data.chemicalData
        );
      }
    }else{
      if (data.type == "farm") {
        return await axiosApi.post(
          `/farm-gate/processing-chemical?processingId=${data?.processingId}`,
          data.chemicalData
        );
      } else {
        return await axiosApi.post(
          `/process-gate/processing-chemical?processingId=${data?.processingId}`,
          data.chemicalData
        );
      }
    }
 
  }, opt);
};

export const usePutChemicalData = (opt?: MutOptions<string>) => {
  return useMutation<any, ApiServiceErr, any>(async (data) => {
    if(data.supplierDataHandle){
      if (data.type == "farm") {
        return await axiosApi.put(
          `/supplier-farm-gate/processing-chemical/${data.chemicalId}`,
          data.chemicalData
        );
      } else {
        return await axiosApi.put(
          `/supplier-process-gate/processing-chemical/${data.chemicalId}`,
          data.chemicalData
        );
      }
    }else{
      if (data.type == "farm") {
        return await axiosApi.put(
          `/farm-gate/processing-chemical/${data.chemicalId}`,
          data.chemicalData
        );
      } else {
        return await axiosApi.put(
          `/process-gate/processing-chemical/${data.chemicalId}`,
          data.chemicalData
        );
      }
    }
  
  }, opt);
};

export const useDeleteChemicalData = (opt?: MutOptions<string>) => {
  return useMutation<any, ApiServiceErr, any>(async (data) => {
    if(data.supplierDataHandle){
      if (data.type == "farm") {
        return await axiosApi.delete(
          `/supplier-farm-gate/processing-chemical/${data.chemicalData.chemicalId}?processingId=${data?.chemicalData.processingId}`
        );
      } else {
        return await axiosApi.delete(
          `/supplier-process-gate/processing-chemical/${data.chemicalData.chemicalId}?processingId=${data?.chemicalData.processingId}`
        );
      }
    }else{
      if (data.type == "farm") {
        return await axiosApi.delete(
          `/farm-gate/processing-chemical/${data.chemicalData.chemicalId}?processingId=${data?.chemicalData.processingId}`
        );
      } else {
        return await axiosApi.delete(
          `/process-gate/processing-chemical/${data.chemicalData.chemicalId}?processingId=${data?.chemicalData.processingId}`
        );
      }
    }
 
  }, opt);
};

export const usePostChemicalTransportData = (opt?: MutOptions<string>) => {
  return useMutation<any, ApiServiceErr, any>(async (data) => {
    if(data.supplierDataHandle){
      if (data.type == "farm") {
        return await axiosApi.post(
          `/supplier-farm-gate/processing-chemical-transport?chemicalId=${data?.chemicalId}`,
          data.transportData
        );
      } else {
        return await axiosApi.post(
          `/supplier-process-gate/processing-chemical-transport?chemicalId=${data?.chemicalId}`,
          data.transportData
        );
      }
    }else{
      if (data.type == "farm") {
        return await axiosApi.post(
          `/farm-gate/processing-chemical-transport?chemicalId=${data?.chemicalId}`,
          data.transportData
        );
      } else {
        return await axiosApi.post(
          `/process-gate/processing-chemical-transport?chemicalId=${data?.chemicalId}`,
          data.transportData
        );
      }
    }

  }, opt);
};

export const usePutChemicalTransportData = (opt?: MutOptions<string>) => {
  return useMutation<any, ApiServiceErr, any>(async (data) => {
    if(data.supplierDataHandle){
      if (data.type == "farm") {
        return await axiosApi.put(
          `/supplier-farm-gate/processing-chemical-transport/${data.chemicalTransportId}`,
          data.transportData
        );
      } else {
        return await axiosApi.put(
          `/supplier-process-gate/processing-chemical-transport/${data.chemicalTransportId}`,
          data.transportData
        );
      }
    }else{
      if (data.type == "farm") {
        return await axiosApi.put(
          `/farm-gate/processing-chemical-transport/${data.chemicalTransportId}`,
          data.transportData
        );
      } else {
        return await axiosApi.put(
          `/process-gate/processing-chemical-transport/${data.chemicalTransportId}`,
          data.transportData
        );
      }
    }

  }, opt);
};

export const useDeleteChemicalTransportData = (opt?: MutOptions<string>) => {
  return useMutation<any, ApiServiceErr, any>(async (data) => {
    if(data.supplierDataHandle){
      if (data.type == "farm") {
        return await axiosApi.delete(
          `/supplier-farm-gate/processing-chemical-transport/${data.chemicalTransportId}?chemicalId=${data?.chemicalId}`
        );
      }else{
        return await axiosApi.delete(
          `/supplier-process-gate/processing-chemical-transport/${data.chemicalTransportId}?chemicalId=${data?.chemicalId}`
        );
      }
    }else{
      if (data.type == "farm") {
        return await axiosApi.delete(
          `/farm-gate/processing-chemical-transport/${data.chemicalTransportId}?chemicalId=${data?.chemicalId}`
        );
      }else{
        return await axiosApi.delete(
          `/process-gate/processing-chemical-transport/${data.chemicalTransportId}?chemicalId=${data?.chemicalId}`
        );
      }
    }
 
  }, opt);
};
