import React, { useCallback } from "react";
import { Grid, Table } from "semantic-ui-react";
import { MdModeEditOutline, MdDeleteOutline } from "react-icons/md";
import { isArray } from "lodash";

export const LoadWasteManagementTableData = ({
  wasteManagement,
  wasteManagementType,
  updateWasteData,
  deleteDataAction,
}: any) => {
  const getManagementType = useCallback(
    (typeId: any) => {
      let findManagementType = wasteManagementType?.find(
        (e: any) => e.value === typeId.toString()
      );
      return findManagementType?.text || "";
    },
    [wasteManagementType]
  );

  return (
    <>
      {isArray(wasteManagement)
        ? wasteManagement?.map((data: any, index: any) => {
            return (
              <Table.Row className="tbleR" key={index}>
                <Table.Cell>
                  <p>{data?.managementName}</p>
                </Table.Cell>
                <Table.Cell>
                  <p>{getManagementType(data?.managementTypeId || 0)}</p>
                </Table.Cell>
                <Table.Cell>
                  <p>{data?.sideStreamsDistribution}</p>
                </Table.Cell>
                <Table.Cell>
                  <Grid>
                    <Grid.Column
                      computer={8}
                      tablet={16}
                      mobile={16}
                      key={index}
                      className="alignTableIcon"
                    >
                      <MdModeEditOutline
                        cursor={"pointer"}
                        onClick={() => {
                          updateWasteData(data);
                        }}
                        size={24}
                        color="var(--tableEditIcon)"
                      />
                    </Grid.Column>
                    <Grid.Column
                      computer={8}
                      tablet={16}
                      mobile={16}
                      key={index}
                      className="alignTableIcon"
                    >
                      <MdDeleteOutline
                        cursor={"pointer"}
                        size={24}
                        color="var(--tableEditIcon)"
                        onClick={() => {
                          deleteDataAction(data);
                        }}
                      />
                    </Grid.Column>
                  </Grid>
                </Table.Cell>
              </Table.Row>
            );
          })
        : null}
    </>
  );
};
