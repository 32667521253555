import { CKEditor } from '@ckeditor/ckeditor5-react';
import 'ckeditor5/ckeditor5.css';
import DOMPurify from 'dompurify';
import {
    ClassicEditor,
    Autoformat,
    Bold,
    Italic,
    Underline,
    BlockQuote,
    Base64UploadAdapter,
    CKFinder,
    CKFinderUploadAdapter,
    CloudServices,
    CKBox,
    Essentials,
    Heading,
    Image,
    ImageCaption,
    ImageResize,
    ImageStyle,
    ImageToolbar,
    ImageUpload,
    PictureEditing,
    Indent,
    IndentBlock,
    Link,
    List,
    MediaEmbed,
    Mention,
    Paragraph,
    PasteFromOffice,
    Table,
    TableColumnResize,
    TableToolbar,
    TextTransformation,
    FontColor,
    FontFamily,
    FontBackgroundColor,
    FontSize,
    HtmlEmbed,
    SourceEditing,
    GeneralHtmlSupport
} from 'ckeditor5';

import 'ckeditor5/ckeditor5.css';
import { useEffect, useState } from 'react';
import { Button } from 'semantic-ui-react';

function CustomEditor({ onSave, initialData }: { onSave: (content: string) => void, initialData?: string }) {

    const [preview, setPreview] = useState(initialData || '');

    const onSaveHandler = () => {
        if (preview) {
            onSave(preview);  
        }
    }

    return (
        <div>
            <CKEditor
                editor={ClassicEditor}
                config={{
                    toolbar: {
                        items: [
                            'undo',
                            'redo',
                            '|',
                            'heading',
                            '|',
                            'bold',
                            'italic',
                            'underline',
                            '|',
                            'link',
                            'uploadImage',
                            'ckbox',
                            'insertTable',
                            'blockQuote',
                            'mediaEmbed',
                            '|',
                            'bulletedList',
                            'numberedList',
                            '|',
                            'outdent',
                            'indent',
                            '|',
                            'fontColor',
                            'fontFamily',
                            'fontBackgroundColor',
                            'fontSize',
                            '|',
                            'htmlEmbed',
                            'sourceEditing',
                        ],
                    },
                    heading: {
                        options: [
                            {
                                model: 'paragraph',
                                title: 'Paragraph',
                                class: 'ck-heading_paragraph',
                            },
                            {
                                model: 'heading1',
                                view: 'h1',
                                title: 'Heading 1',
                                class: 'ck-heading_heading1',
                            },
                            {
                                model: 'heading2',
                                view: 'h2',
                                title: 'Heading 2',
                                class: 'ck-heading_heading2',
                            },
                            {
                                model: 'heading3',
                                view: 'h3',
                                title: 'Heading 3',
                                class: 'ck-heading_heading3',
                            },
                            {
                                model: 'heading4',
                                view: 'h4',
                                title: 'Heading 4',
                                class: 'ck-heading_heading4',
                            },
                        ],
                    },
                    image: {
                        resizeOptions: [
                            {
                                name: 'resizeImage:original',
                                label: 'Default image width',
                                value: null,
                            },
                            {
                                name: 'resizeImage:50',
                                label: '50% page width',
                                value: '50',
                            },
                            {
                                name: 'resizeImage:75',
                                label: '75% page width',
                                value: '75',
                            },
                        ],
                        toolbar: [
                            'imageTextAlternative',
                            'toggleImageCaption',
                            '|',
                            'imageStyle:inline',
                            'imageStyle:wrapText',
                            'imageStyle:breakText',
                            '|',
                            'resizeImage',
                        ],
                    },
                    link: {
                        addTargetToExternalLinks: true,
                        defaultProtocol: 'https://',
                    },
                    table: {
                        contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells'],
                    },
                    htmlSupport: {
                        allow: [
                            {
                                name: /.*/,
                                attributes: true,
                                classes: true,
                                styles: true
                            },
                            {
                                name: 'style',
                                attributes: true,
                                classes: true,
                                styles: true
                            }
                        ]
                    },
                    plugins: [
                        Autoformat,
                        Bold,
                        Italic,
                        Underline,
                        BlockQuote,
                        Base64UploadAdapter,
                        CKFinder,
                        CKFinderUploadAdapter,
                        CloudServices,
                        CKBox,
                        Essentials,
                        Heading,
                        Image,
                        ImageCaption,
                        ImageResize,
                        ImageStyle,
                        ImageToolbar,
                        ImageUpload,
                        PictureEditing,
                        Indent,
                        IndentBlock,
                        Link,
                        List,
                        MediaEmbed,
                        Mention,
                        Paragraph,
                        PasteFromOffice,
                        Table,
                        TableColumnResize,
                        TableToolbar,
                        TextTransformation,
                        FontColor,
                        FontSize,
                        FontFamily,
                        FontBackgroundColor,
                        HtmlEmbed,
                        SourceEditing,
                        GeneralHtmlSupport
                    ],
                    initialData: initialData,


                }}
                onChange={(event, editor) => {
                    const data = editor.getData();
                    const sanitizedContent = DOMPurify.sanitize(data);
                    setPreview(sanitizedContent);
                }}
            />

            <div style={{ marginTop: '1rem', border: '1px solid' }}>
                <div style={{ display: 'flex', flexDirection: 'row', gap: '4rem', alignItems: 'center' }}>
                    <h1>Preview</h1>
                    <div>
                        <Button color="teal" onClick={onSaveHandler} disabled={preview === ''}>Save</Button>
                    </div>
                </div>

                <hr />
                <div dangerouslySetInnerHTML={{ __html: preview }} />
            </div>

        </div>
    );
}

export default CustomEditor;