import { useState } from "react";
import {
  Accordion,
  AccordionContent,
  AccordionTitle,
  Grid,
  Table,
} from "semantic-ui-react";
import {
  AccordionTitleView,
  CommonTable,
  CustomButton,
  DropDown,
} from "../../../components";
import "./CultivarForm.scss";
import { InputNumber } from "../../../components/InputText/InputNumber";
import { MdDeleteOutline, MdModeEditOutline } from "react-icons/md";

const Fertilizer = ({
  fertilizerTableList,
  FertilizerTableHeader,
  onEditHandler,
  control,
  setVisibleDeleteModal,
  setDeleteValue,
  fertilizer,
  fertilizerDropdownList,
  setFertilizer,
  onAddClickFertilizerHandler,
  cultivationType,
}: any) => {
  const [accordionEnabled, setAccordionEnabled] = useState<boolean>(false);

  const handleClick = () => {
    setAccordionEnabled(!accordionEnabled);
  };
  return cultivationType === "Supplier" ? (
    <Accordion>
      <AccordionTitle active={accordionEnabled} onClick={handleClick}>
        <AccordionTitleView
          accordionEnabled={accordionEnabled}
          title={"Fertilizer"}
        />
      </AccordionTitle>
      <AccordionContent active={accordionEnabled}>
        <div>
          <Grid>
            <Grid.Column computer={6} tablet={8} mobile={16}>
              <DropDown
                labelName={"Fertilizer Name"}
                placeholder="Select Fertilizer Name"
                defaultValue={fertilizer}
                currentData={fertilizerDropdownList}
                handleChangeState={(e: any, { value }: any) => {
                  setFertilizer(value);
                }}
                search
                key={"fertilizer_name"}
              />
            </Grid.Column>
            <Grid.Column computer={4} tablet={6} mobile={16}>
              <InputNumber
                transform={{
                  input: (value: any) =>
                    isNaN(value) || value === 0 ? "" : value.toString(),
                  output: (e: any) => {
                    return e;
                  },
                }}
                control={control}
                labelName={"kg/ha"}
                placeholder="kg/ha"
                name="fertilizerQuantity"
              />
            </Grid.Column>
            <Grid.Column computer={2} tablet={2} mobile={16}>
              <CustomButton
                theme="green"
                icon="plus"
                disabled={!fertilizer}
                customColumnStyle={"plusButton"}
                onClick={onAddClickFertilizerHandler}
              />
            </Grid.Column>
          </Grid>
          {fertilizerTableList.length > 0 && (
            <Grid.Column
              computer={16}
              tablet={16}
              mobile={16}
              className="pesticideTable"
            >
              <CommonTable
                tableHeaderData={FertilizerTableHeader}
                className="pesticideTable"
              >
                {fertilizerTableList.map((item: any) => {
                  return (
                    <Table.Row key={`fertilizer_${item.key}`}>
                      <Table.Cell>
                        <p>{item?.fertilizer_name ?? ""}</p>
                      </Table.Cell>
                      <Table.Cell>
                        <p>{item?.fertilizerQuantity ?? ""}</p>
                      </Table.Cell>
                      <Table.Cell>
                        <Grid>
                          <Grid.Column computer={8} tablet={16} mobile={16}>
                            <MdModeEditOutline
                              cursor={"pointer"}
                              size={24}
                              color="var(--tableEditIcon)"
                              onClick={() => onEditHandler(item, "Fertilizer")}
                            />
                          </Grid.Column>
                          <Grid.Column computer={8} tablet={16} mobile={16}>
                            <MdDeleteOutline
                              cursor={"pointer"}
                              size={24}
                              color="var(--tableEditIcon)"
                              onClick={() => {
                                setVisibleDeleteModal(true);
                                setDeleteValue({
                                  type: "Fertilizer",
                                  item: item ?? "",
                                });
                              }}
                            />
                          </Grid.Column>
                        </Grid>
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </CommonTable>
            </Grid.Column>
          )}
        </div>
      </AccordionContent>
    </Accordion>
  ) : null;
};

export default Fertilizer;
