import { Checkbox, Grid, Table } from "semantic-ui-react";
import { useForm } from "react-hook-form";
import {
  InputText,
  CustomButton,
  InputTextArea,
  CommonModal,
  TitleView,
  CommonTable,
  DropDown,
} from "../../../components";
import useWindowDimensions from "../../../hooks/windowsSize";
import { SupplierIngredientTable } from "../../../config/constants";
import "./supplierDetails.scss";
import { useGetCountries } from "../../../api/static-data";
import { useMemo } from "react";

const SupplierDetailsModal = ({
  visibleModal,
  setVisibleModal,
  supplier,
}: any) => {
  const { register } = useForm({
    values: supplier,
  });
  const { height } = useWindowDimensions();

  const {
    data: countriesData,
    isSuccess: isCountriesDataSuccess,
    isLoading: isCountriesDataLoading,
  } = useGetCountries();

  const countries = useMemo(() => {
    if (isCountriesDataSuccess) {
      return countriesData?.map((c: any) => ({
        key: c.id,
        text: c.country,
        value: c.id,
      }));
    }
  }, [countriesData, isCountriesDataSuccess]);

  const ingredients = supplier?.ingredients || [];
  const ingredientDetails = supplier?.supplierIngredientDetails || [];
  const supplierAsUser = supplier?.is_supplier_as_user || false;
  const country = supplier?.supplier_country || null;

  const loadTableData = () => {
    return ingredients.map((data: any, index: number) => {
      const ingredientDetail = ingredientDetails.find(
        (ingredient: any) => ingredient.ingredient_id === data._id
      );
      return (
        <Table.Row className="tbleR" key={index}>
          <Table.Cell>
            <p>{data?.ingredient_name ?? ""}</p>
          </Table.Cell>
          <Table.Cell>
            <p>{ingredientDetail?.unit_size ?? ""}</p>
          </Table.Cell>
          <Table.Cell>
            <p>{ingredientDetail?.unit_type ?? ""}</p>
          </Table.Cell>
          <Table.Cell>
            <p>{ingredientDetail?.ean_bar_code ?? ""}</p>
          </Table.Cell>
          <Table.Cell>
            <Grid></Grid>
          </Table.Cell>
        </Table.Row>
      );
    });
  };

  return (
    <CommonModal
      modalOpenState={visibleModal}
      modalCloseState={() => setVisibleModal(false)}
      centered={true}
      title="Supplier Details"
    >
      <Grid>
        <Grid.Column computer={16} tablet={16} mobile={16}>
          <div>
            <form>
              <div
                style={{
                  height: height * 0.9 - 180,
                  overflowX: "hidden",
                }}
              >
                <Grid>
                  <Grid.Column
                    computer={8}
                    tablet={16}
                    mobile={16}
                    className="paddingRemoveBottom"
                  >
                    <InputText
                      register={register}
                      labelName={"Supplier Name"}
                      placeholder="Supplier Name"
                      name="supplier_name"
                      disabled={true}
                    />
                  </Grid.Column>
                  <Grid.Column
                    computer={8}
                    tablet={16}
                    mobile={16}
                    className="paddingRemoveBottom"
                  >
                    <InputText
                      register={register}
                      labelName={"Email"}
                      placeholder="Email"
                      name="supplier_email"
                      disabled={true}
                    />
                  </Grid.Column>
                  <Grid.Column
                    computer={8}
                    tablet={16}
                    mobile={16}
                    className="paddingRemoveBottom"
                  >
                    <InputText
                      register={register}
                      labelName={"Contact Number"}
                      placeholder="Contact Number"
                      name="supplier_phone"
                      disabled={true}
                    />
                  </Grid.Column>
                  <Grid.Column
                    computer={8}
                    tablet={16}
                    mobile={16}
                    className="paddingRemoveBottom"
                  >
                    <InputText
                      register={register}
                      labelName={"Address"}
                      placeholder="Address"
                      name="supplier_address"
                      disabled={true}
                    />
                  </Grid.Column>
                  <Grid.Column
                    computer={8}
                    tablet={16}
                    mobile={16}
                    className="paddingRemoveBottom"
                  >
                    <InputText
                      register={register}
                      labelName={"Province"}
                      placeholder="Province"
                      name="supplier_province"
                      disabled={true}
                    />
                  </Grid.Column>
                  <Grid.Column
                    computer={8}
                    tablet={16}
                    mobile={16}
                    className="paddingRemoveBottom"
                  >
                    <DropDown
                      labelName="Country"
                      placeholder="Country"
                      defaultValue={country}
                      currentData={countries}
                      loading={isCountriesDataLoading}
                      key={"supplier_country"}
                      disabled={true}
                    />
                  </Grid.Column>
                  <Grid.Column
                    computer={16}
                    tablet={16}
                    mobile={16}
                    className="paddingRemoveBottom"
                  >
                    <InputText
                      register={register}
                      labelName={"Zip Code"}
                      placeholder="Zip Code"
                      name="supplier_postal_code"
                      disabled={true}
                    />
                  </Grid.Column>
                  <Grid.Column
                    computer={16}
                    tablet={16}
                    mobile={16}
                    className="paddingRemoveBottom"
                  >
                    <InputTextArea
                      register={register}
                      labelName={"Other Information"}
                      placeholder="Other Information"
                      name="supplier_other_info"
                      disabled={true}
                    />
                  </Grid.Column>
                  <Grid.Column
                    computer={8}
                    tablet={16}
                    mobile={16}
                    className="customCheckpointSupplierUser"
                  >
                    <Checkbox
                      label={"Register supplier as user"}
                      checked={supplierAsUser}
                      disabled
                    />
                  </Grid.Column>
                </Grid>
                {ingredients.length > 0 ? (
                  <TitleView
                    title="Ingredient List"
                    CustomTitleViewMain="supplierAddCustomIngredientTitle"
                  />
                ) : null}

                <Grid>
                  <Grid.Column
                    computer={16}
                    tablet={16}
                    mobile={16}
                    className="userBankDetailsMain "
                  >
                    <div>
                      <Grid.Column
                        computer={16}
                        tablet={16}
                        mobile={16}
                        className="productRegisterTable"
                      >
                        {ingredients.length > 0 && (
                          <CommonTable
                            tableHeaderData={SupplierIngredientTable}
                          >
                            {loadTableData()}
                          </CommonTable>
                        )}
                      </Grid.Column>
                    </div>
                  </Grid.Column>
                </Grid>
              </div>

              <div
                style={{
                  height: height * 0.1,
                }}
                className="modalRegisterButtonMain"
              >
                <div className="modalRegisterButtonLeft">
                  <CustomButton
                    theme="green"
                    title={"Close"}
                    customColumnStyle={"cardInsertSaveOther"}
                    onClick={() => setVisibleModal(false)}
                  />
                </div>
              </div>
            </form>
          </div>
        </Grid.Column>
      </Grid>
    </CommonModal>
  );
};

export default SupplierDetailsModal;
