import { ProductRoutes } from "./productRoutes";
import { IngredientRoutes } from "./ingredientRoutes";
import { SupplierRoutes } from "./supplierRoutes";
import { CommonRoutes } from "./commonRoutes";
import { CultivationRoutes } from "./cultivationRoutes";
import { TransportationRoutes } from "./transportationRoutes";
import { ClientRoutes } from "./clientRoutes";
import { ProductSummaryRoutes } from "./productSummary";
import { UserRoutes } from "./userRoutes";
import { CompanyRoutes } from "./companyRoutes";
import { ClientListRoutes } from "./clientListRoutes";
import { DrawingRoutes } from "./drawerRoutes";
import { FacilityRoutes } from "./facilityRoutes";
import { AdminRoutes } from "./adminRoutes";
import { SimulationRoutes } from "./simulationRoutes";

export const MainRoutes = [
  {
    mainPath: "/",
    routes: CommonRoutes,
  },
  {
    mainPath: "product",
    routes: ProductRoutes,
  },
  {
    mainPath: "ingredient",
    routes: IngredientRoutes,
  },
  {
    mainPath: "supplier",
    routes: SupplierRoutes,
  },
  {
    mainPath: "drawing",
    routes: DrawingRoutes,
  },
  {
    mainPath: "cultivation",
    routes: CultivationRoutes,
  },
  {
    mainPath: "transportation",
    routes: TransportationRoutes,
  },
  {
    mainPath: "client",
    routes: ClientRoutes,
  },
  {
    mainPath: "product-summary",
    routes: ProductSummaryRoutes,
  },
  {
    mainPath: "facility",
    routes: FacilityRoutes,
  },
  {
    mainPath: "users",
    routes: UserRoutes,
  },
  {
    mainPath: "company",
    routes: CompanyRoutes,
  },
  {
    mainPath: "client-list",
    routes: ClientListRoutes,
  },
  {
    mainPath: "settings",
    routes: AdminRoutes,
  },
  {
    mainPath: "simulations",
    routes: SimulationRoutes,
  },
];
