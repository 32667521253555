import * as React from "react";

const PackIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <circle cx={24} cy={24} r={24} fill="var(--mappingImageBackground)" />
    <path
      stroke="var(--mappingIconColor)"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m16 19.176 8 4.088m-8-4.088v8.176l8 4.088m-8-12.264 4-2.044m4 6.132 4-2.044m-4 2.044v8.176m0 0 8-4.088v-8.176m-12-2.044 4-2.044 8 4.088m-12-2.044 8 4.088m0 0 4-2.044"
    />
  </svg>
);
export default PackIcon;
