import { useContext, useState, useMemo, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { Grid, Dimmer, Loader } from "semantic-ui-react";
import { useReactFlow } from "reactflow";
import { DrawingForIngredientContext } from "../../../DrawingToolForIngredient";
import useWindowDimensions from "../../../../../hooks/windowsSize";
import { ChemicalContent } from "../../../../../components";
import { SipContents } from "../../../../../config/constants";
import AddIngredientModal from "../../../../cultivation/processing/IngredientContentModal";
import SipContentContent from "../../../../cultivation/processing/SipContentModal";
import {
  useGetProcessGateProcessDataById,
  usePostProcessGateProcessData,
  usePutProcessGateProcessData,
  useDeleteProcessGateProcessData,
  useGetProcessRecipeByProcessId,
} from "../../../../../api/processGate/supplierProcess";
import { useGetSupplierById } from "../../../../../api/supplier";
import {
  useGetFacilityWholeList,
  useGetProcessTypesWholeList,
  useGetTransportTypesList,
} from "../../../../../api/cultivations";
import { errorView, successMessage } from "../../../../../helpers/ErrorHandler";
import ConfirmModal from "../../../../../components/confirmViewModal/ConfirmModal";
import MainBottomButtonView from "../../../../../components/mainBottomButtonView/MainBottomButtonView";
import {
  useGetChemicalTypes,
  useGetWasteManagementWasteTypes,
} from "../../../../../api/static-data";
import { WasteManagementContent } from "../../../../../components/wasteManagment/WasteManagementModal";
import { AuthContext } from "../../../../../context/AuthContext";
import { isArray, isEmpty } from "lodash";
import SupplierRecipeContent from "../../../../cultivation/processing/SupplierRecipeModal";
import {
  removeNewDrawingItemStatus,
  saveNewDrawingItem,
} from "../../../../../utils/cacheStorage";
import ProcessingDetails from "../../../../../components/processingDetails/ProcessingDetails";
import MeasurementDetails from "../../../../../components/measurementDetails/MeasurementDetails";

interface HandleValidation {
  checkCustomRequired(data?: any): void;
}

export const ProcessGateProcessingBase = ({ modalData }: any) => {
  const { processGateId, nodeItem, rawMaterialId } = modalData;
  const { deleteElements } = useReactFlow();

  const {
    chartEdges,
    chartNodes,
    supplierIngredientId,
    setNodeItem,
    setChartNodes,
    saveDrawing,
  } = useContext(DrawingForIngredientContext);

  const { height } = useWindowDimensions();
  const processId = nodeItem?.data?.reference?.processId;

  const [viewIngredient, setViewIngredient] = useState(false);
  const [viewCipSip, setViewCipSip] = useState(false);
  const [viewRecipe, setViewRecipe] = useState(false);
  const [viewChemical, setViewChemical] = useState(false);
  const [facilityName, setFacilityName] = useState("");
  const [processingType, setProcessingType] = useState("");
  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false);
  const [recipeData, setRecipeData] = useState({});
  const [viewWasteManagement, setViewWasteManagement] = useState(false);
  const [isValidate, setIsValidate] = useState(false);
  const [customRequired, setCustomRequired] = useState(false);
  const [bounceEnable, setBounceEnable] = useState(false);

  const { data: facilityWholeList, isSuccess: isFacilitySuccess } =
    useGetFacilityWholeList("FACTORY");
  const customCapacityValidation = useRef<HandleValidation>(null);
  const customInputValidation = useRef<HandleValidation>(null);
  const customOutputValidation = useRef<HandleValidation>(null);
  const { data: processTypesWholeList } = useGetProcessTypesWholeList();
  const { mutate: postFarmGateProcessData } = usePostProcessGateProcessData();
  const { mutate: putFarmGateProcessData } = usePutProcessGateProcessData();
  const { mutate: deleteProcessGateProcessData } =
    useDeleteProcessGateProcessData();

  const { data, status, fetchStatus, refetch } =
    useGetProcessGateProcessDataById(nodeItem?.data?.reference?.processId);
  const { data: processRecipeData, refetch: refetchProcessRecipe } =
    useGetProcessRecipeByProcessId({
      recipeId: data?.recipeId,
      type: "supplier-process-gate",
    });
  const [ingredientSupplierId, setIngredientSupplierId] = useState<
    string | null
  >(null);
  const { data: supplierData } = useGetSupplierById(ingredientSupplierId);

  const [cipSipData, setCipSipData] = useState<any>([]);
  const [addIngredientActive, setAddIngredientActive] = useState(false);

  const authState = useContext(AuthContext);
  const supplierUser = authState?.user || { _id: null, tenantId: null };

  const handleChemical = () => {
    if (processId) {
      return true;
    } else {
      setBounceEnable(true);
      errorView("Please save the process to add chemical details");
      return false;
    }
  };

  const handleWasteManagement = () => {
    if (processId) {
      return true;
    } else {
      setBounceEnable(true);
      errorView("Please save the process to add waste management details");
      return false;
    }
  };

  const values = useMemo(() => {
    setCipSipData(data?.cipSip);
    setProcessingType(`${data?.processingType}`);
    setFacilityName(data?.facilityId);
    return {
      processingName: data?.processingName,
      processingType: data?.processingType,
      facilityName: data?.facilityId,
      machineModelNo: data?.machineModelNo,
      capacity: data?.capacity,
      input: data?.input,
      output: data?.output,
      sideStream: data?.sideStream,
      heat: data?.heat || 0,
      electricity: data?.electricity || 0,
      water: data?.water || 0,
      citricAcid: data?.citricAcid,
      recipeId: data?.recipeId,
    };
  }, [nodeItem, data]);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    control,
    getValues,
  } = useForm({
    mode: "all",
    defaultValues: values,
    values: values,
  });
  const chemicalData = data?.chemical || [];
  const wasteManagement = data?.wasteManagements || [];

  const { data: chemicalDataType, isSuccess: chemicalDataLoad } =
    useGetChemicalTypes();

  const { data: wasteManagementType, isSuccess: wasteManagementTypeLoad } =
    useGetWasteManagementWasteTypes();

  const { data: TransportTypeList } = useGetTransportTypesList();

  const onSubmit = (data: any, cipSip?: any) => {
    let checkIsValidCipSip = isArray(cipSip);
    const dataToCreate = {
      processingName: data?.processingName,
      productId: supplierIngredientId,
      salesUnitId: supplierUser?.tenantId,
      ingredientId: rawMaterialId,
      supplierId: supplierUser?._id,
      processNumber: 1,
      facilityId: data?.facilityName,
      processingIngredient: [],
      cipSip: checkIsValidCipSip ? cipSip : cipSipData,
      processingType: data?.processingType,
      machineModelNo: data?.machineModelNo,
      capacity: data?.capacity || 0,
      input: data?.input || 0,
      output: data?.output || 0,
      sideStream: data?.sideStream || 0,
      heat: data?.heat || 0,
      electricity: data?.electricity || 0,
      water: data?.water || 0,
      citricAcid: data?.citricAcid || 0,
      processingStatus: "ACTIVE",
      processGateId: processGateId,
      processId: nodeItem?.data?.reference?.processId,
    };
    if (isEmpty(data?.facilityName)) {
      setIsValidate(true);
      errorView("Please select a facility");
      return;
    }
    if (nodeItem?.data?.reference?.processId) {
      putFarmGateProcessData(dataToCreate, {
        onSuccess: (data: any) => {
          removeNewDrawingItemStatus();
          const updatedNode = {
            ...nodeItem,
            data: {
              ...nodeItem?.data,
              label: data?.processingName,
              // reference is mongoose mix type so you can set any type of here please set necessary reference only
              reference: {
                processId: data?._id,
              },
            },
          };
          const updatedChartNodes = chartNodes.map((n: any) => {
            if (n.id === nodeItem?.id) {
              return updatedNode;
            }
            return n;
          });
          if (checkIsValidCipSip) {
            successMessage("Process updated CIP / SIP successfully");
            return refetch();
          }
          setChartNodes(updatedChartNodes);
          setNodeItem(updatedNode);
          saveDrawing(chartEdges, updatedChartNodes);
          successMessage("Process updated successfully");
        },
      });
    } else {
      postFarmGateProcessData(dataToCreate, {
        onSuccess: (data: any) => {
          saveNewDrawingItem("created");
          const updatedNode = {
            ...nodeItem,
            data: {
              ...nodeItem?.data,
              label: data?.processingName,
              // reference is mongoose mix type so you can set any type of here please set necessary reference only
              reference: {
                processId: data?._id,
              },
            },
          };
          const updatedChartNodes = chartNodes.map((n: any) => {
            if (n.id === nodeItem?.id) {
              return updatedNode;
            }
            return n;
          });
          setChartNodes(updatedChartNodes);
          setNodeItem(updatedNode);
          saveDrawing(chartEdges, updatedChartNodes);
          successMessage("Process created successfully");
        },
      });
    }
  };

  const loadCIPData = () => {
    if (nodeItem?.data.reference && cipSipData?.length > 0) {
      const responseSipData = Object.keys(cipSipData?.[0]);
      const newSipContent: any = SipContents?.map((sip) => {
        const sipContent = responseSipData?.map((resSip) => {
          return (
            sip?.typeName === resSip && {
              ...sip,
              value: cipSipData?.[0][resSip],
            }
          );
        });
        return sipContent.filter((value) => value !== false);
      });
      return newSipContent.flat(Infinity);
    } else {
      return SipContents;
    }
  };

  const onNodesDelete = () => {
    deleteElements({ nodes: [{ id: nodeItem.id }] });
    setNodeItem(null);
  };

  const checkRecipeValidation = () => {
    if (processId) return;
    setBounceEnable(true);
    errorView("Please save the process to add recipe");
    return;
  };

  useEffect(() => {
    if (isFacilitySuccess) {
      setFacilityName(data?.facilityId || facilityWholeList?.[0]?.value);
      setValue(
        "facilityName",
        data?.facilityId || facilityWholeList?.[0]?.value
      );
    }
  }, [isFacilitySuccess, data]);

  if (
    nodeItem?.data.reference &&
    status == "loading" &&
    fetchStatus == "fetching"
  ) {
    return (
      <Dimmer active>
        <Loader content="Loading" />
      </Dimmer>
    );
  }

  const updateCipSipData = (data: any) => {
    setCipSipData(data);
    const currentData = getValues();
    onSubmit(currentData, data);
  };

  return (
    <Grid>
      <Grid.Column
        computer={16}
        tablet={16}
        mobile={16}
        className="userBankDetailsMain"
      >
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div
              style={{
                height: height * 0.9 - 80,
                overflowX: "hidden",
              }}
            >
              <ProcessingDetails
                register={register}
                errors={errors}
                processTypesWholeList={processTypesWholeList}
                processingType={processingType}
                setProcessingType={setProcessingType}
                setValue={setValue}
                facilityName={facilityName}
                isValidate={isValidate}
                facilityWholeList={facilityWholeList}
                setFacilityName={setFacilityName}
              />
              <MeasurementDetails
                control={control}
                errors={errors}
                customCapacityValidation={customCapacityValidation}
                customInputValidation={customInputValidation}
                customOutputValidation={customOutputValidation}
                addIngredientActive={addIngredientActive}
                setViewIngredient={setViewIngredient}
              />
              <Grid>
                <Grid.Column computer={16}>
                  <SipContentContent
                    getSipData={(data: any) => {
                      updateCipSipData(data);
                    }}
                    processCIPData={loadCIPData()}
                    currentCipSipData={data?.cipSip}
                  />
                </Grid.Column>
                <Grid.Column computer={16} className="pt-0">
                  <SupplierRecipeContent
                    data={recipeData}
                    processId={nodeItem?.data?.reference?.processId}
                    nodeItem={nodeItem}
                    recipeId={data?.recipeId}
                    refetchProcessRecipe={refetchProcessRecipe}
                    checkRecipeValidation={checkRecipeValidation}
                    type="supplier-process-gate"
                  />
                </Grid.Column>
                <Grid.Column computer={16} className="pt-0">
                  <ChemicalContent
                    chemicalDataType={chemicalDataType}
                    processCIPData={() => {}}
                    chemicalData={chemicalData}
                    processId={processId}
                    TransportTypeList={TransportTypeList}
                    refetchChemical={refetch}
                    handleOpenValidation={handleChemical}
                    supplierDataHandle={true}
                    type="process"
                  />
                </Grid.Column>
                <Grid.Column computer={16} className="pt-0">
                  <WasteManagementContent
                    wasteManagementType={wasteManagementType}
                    wasteManagement={wasteManagement}
                    processId={processId}
                    sideStreamValue={values?.sideStream}
                    refetchWasteData={refetch}
                    handleOpenValidation={handleWasteManagement}
                    supplierDataHandle={true}
                    type="process"
                  />
                </Grid.Column>
              </Grid>
            </div>
            <MainBottomButtonView
              deleteStatus={nodeItem?.data.reference}
              saveButtonStatus={true}
              saveButton={() => {
                setBounceEnable(false);
                setCustomRequired(true);
                customCapacityValidation.current?.checkCustomRequired(
                  getValues().capacity
                );
                customInputValidation.current?.checkCustomRequired(
                  getValues().input
                );
                customOutputValidation.current?.checkCustomRequired(
                  getValues().output
                );
              }}
              deleteButton={() => setVisibleDeleteModal(true)}
              saveTitle={nodeItem?.data.reference ? "Update" : "Save"}
              bounceEnable={bounceEnable}
              type="submit"
            />
          </form>
        </div>
      </Grid.Column>
      <AddIngredientModal
        visibleModal={viewIngredient}
        setVisibleModal={() => {
          setViewIngredient(false);
        }}
      />
      <ConfirmModal
        viewModal={visibleDeleteModal}
        closeModal={() => setVisibleDeleteModal(false)}
        cancel={() => {
          setVisibleDeleteModal(false);
        }}
        approve={() => {
          const dataToDelete = {
            processGateId: processGateId,
            processId: nodeItem?.data?.reference?.processId,
          };
          deleteProcessGateProcessData(dataToDelete, {
            onSuccess: (data: any) => {
              onNodesDelete();
              successMessage("Process deleted successfully");
            },
          });
          setVisibleDeleteModal(false);
        }}
        title={`Delete ${data?.processingName} Process`}
        subTitle={`Are you sure you want to remove the ${data?.processingName} process gate process? Please consider you cannot recover these data after remove`}
      />
    </Grid>
  );
};
