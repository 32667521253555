import React, { useState } from "react";
import { Grid, Table } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import { TbFileSearch } from "react-icons/tb";
import { MdModeEditOutline, MdDeleteOutline } from "react-icons/md";
import { CustomButton, CommonTable, TitleBar } from "../../../components";
import {
  ProductTableContent,
  TransportTableCol,
} from "../../../config/constants";
import TransportRegisterModal from "../transportRegister/TransportRegisterModal";

const TransportList = () => {
  const [visibleModal, setVisibleModal] = useState(false);

  const loadTableData = () => {
    return ProductTableContent.map((data, index) => {
      return (
        <Table.Row className="tbleR" key={index}>
          <Table.Cell>
            <p>Lovi juice</p>
          </Table.Cell>
          <Table.Cell>
            <p>Cinnamon</p>
          </Table.Cell>
          <Table.Cell>
            <p>Cinnamon</p>
          </Table.Cell>
          <Table.Cell>
            <p>Nature Preserve</p>
          </Table.Cell>
          <Table.Cell>
            <Grid>
              <Grid.Column computer={5} tablet={16} mobile={16} key={index}>
                <TbFileSearch cursor={"pointer"} size={24} color="var(--tableEditIcon)" />
              </Grid.Column>
              <Grid.Column computer={5} tablet={16} mobile={16} key={index}>
                <MdModeEditOutline
                  cursor={"pointer"}
                  size={24}
                  color="var(--tableEditIcon)"
                />
              </Grid.Column>
              <Grid.Column computer={5} tablet={16} mobile={16} key={index}>
                <MdDeleteOutline cursor={"pointer"} size={24} color="var(--tableEditIcon)" />
              </Grid.Column>
            </Grid>
          </Table.Cell>
        </Table.Row>
      );
    });
  };

  return (
    <>
      <TitleBar titleName={"Product Transportation"} />
      <CustomButton
        theme="green"
        title="New Transportation"
        onClick={() => setVisibleModal(true)}
        customColumnStyle="topButtonView"
        iconWithText={true}
        iconName="plus"
      />
      <Grid>
        <Grid.Column
          computer={16}
          tablet={16}
          mobile={16}
          className="userBankDetailsMain"
        >
          <CommonTable tableHeaderData={TransportTableCol}>
            {loadTableData()}
          </CommonTable>
        </Grid.Column>
      </Grid>
      <TransportRegisterModal
        visibleModal={visibleModal}
        setVisibleModal={() => setVisibleModal(false)}
      />
    </>
  );
};

export default TransportList;
