import React from "react";
import { Grid } from "semantic-ui-react";
import CustomEditor from "../../components/custom-editor";
import { TitleBar } from "../../components";
import { useGetSupplierStaticCOntent, useUpdateSupplierStaticCOntent } from "../../api/common";
import { successMessage } from "../../helpers/ErrorHandler";

const SettingsPage = () => {

    const {
        data: supplierStaticContent,
        isLoading: loadSupplierStaticContent,
    } = useGetSupplierStaticCOntent();

    const {
        mutate: updateSupplierStaticContent,
        isSuccess: successUpdateSupplierStaticContent,
    } = useUpdateSupplierStaticCOntent("");

    const onSaveHandler = (content: string) => {
        updateSupplierStaticContent(content);
    }

    if (successUpdateSupplierStaticContent) {
        successMessage("Supplier Terms & Condition updated successfully");
    }

    return (
        <Grid>
            <Grid.Column computer={16} tablet={16} mobile={16}>
                <TitleBar enableTitle={true} titleName={"Supplier Terms & Condition editor"} />
            </Grid.Column>
            <Grid.Column computer={16} tablet={16} mobile={16}>
                {supplierStaticContent && <CustomEditor onSave={onSaveHandler} initialData={supplierStaticContent.data || ''} />}
            </Grid.Column>
        </Grid>
    );
};

export default SettingsPage;