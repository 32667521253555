export const updatePackagingData = (
  productData: any,
  updatedData: any,
  gateType: string
) => {
  if (!productData || !updatedData) return;

  const updatePackaging = (packagingArray: any[]) => {
    for (const packaging of packagingArray) {
      Object.assign(packaging, updatedData);
    }
  };

  if (gateType === "farmGate") {
    for (const farmGate of productData.farmGate || []) {
      updatePackaging(farmGate.packaging || []);
    }
  } else if (gateType === "processGate") {
    for (const processGate of productData.processGate || []) {
      updatePackaging(processGate.packaging || []);
    }
  }

  return productData;
};
