import { nodeCatagories, nodeType } from "../config/drawingConstants";

let rootNodeId = '';

export const findIncomingIngredientsAndProcess = (
    edges: any[],
    nodes: any[],
    selectedNodeId: string,
    ingredientList: any[],
    processList: any[],
    previousSourceNodeId = null,
): any => {
    if (previousSourceNodeId === null) {
        rootNodeId = selectedNodeId;
    }
    if (edges.length <= 0) return { ingredientList, processList };
    const allIncomingEdges = edges.filter((edge) => edge.target === selectedNodeId);
    if (!allIncomingEdges) return { ingredientList, processList };
    allIncomingEdges.forEach((edge: any) => {
        return checkIngredientOrProcessNodeFromNodes(nodes, edges, edge, ingredientList, processList, selectedNodeId, rootNodeId);
    });
    return { ingredientList, processList };
};


const checkIngredientOrProcessNodeFromNodes = (
    nodes: any[],
    edges: any[],
    selectedEdge: any,
    ingredientList: any[],
    processList: any[],
    previousSourceNodeId: string,
    rootNodeId: string,
) => {
    const sourceNode = nodes.find((node) => node.id === selectedEdge.source);
    if (sourceNode.data.icon === nodeType.Ingredients) {
        let previousComponent = findPreviousComponent(nodes, edges, sourceNode, rootNodeId, null);
        ingredientList.push({...sourceNode, ...previousComponent});
    } else if (sourceNode.data.icon === nodeType.ProcessGateProcessing || sourceNode.data.icon === nodeType.FarmGateProcess) {
        const parentNode = nodes.find((node) => node.id === sourceNode?.parentNode);
        let previousComponent = findPreviousComponent(nodes, edges, sourceNode, rootNodeId, null);
        if (parentNode.type === nodeCatagories.processGate) {
            processList.push({ ...sourceNode, 'parentNodeDetails': { processGateId: parentNode.data?.reference?.processGateId, type: parentNode?.type }, ...previousComponent });
        } else if (parentNode.type === nodeCatagories.farmGate) {
            processList.push({ ...sourceNode, 'parentNodeDetails': { farmGateId: parentNode.data?.reference?.farmGateId, type: parentNode?.type }, ...previousComponent });
        } else {
            processList.push(sourceNode);
        }
        return { ingredientList, processList };
    } else if (previousSourceNodeId !== sourceNode.id) {
        return findIncomingIngredientsAndProcess(edges, nodes, sourceNode.id, ingredientList, processList, sourceNode.id);
    } else {
        return { ingredientList, processList };
    }
}

const findPreviousComponent = (
    nodes: any[],
    edges: any[],
    sourceNode: any,
    targetNodeId: string,
    previousComponent: any,
) => {
    const allOutgoingEdges = edges.filter((edge) => edge.source === sourceNode.id && edge.target === targetNodeId);
    if (allOutgoingEdges.length === 1) {
        previousComponent = {
            'sourceNodeId': sourceNode.id,
            'previousComponentId': filterDataReferenceIds(sourceNode.data.icon, sourceNode.data.reference),
            'previousComponentType': findNodeLabelById(sourceNode.data.icon)
        }
        return previousComponent;
    } else if (allOutgoingEdges.length === 0) {
        const allOutgoingEdges = edges.filter((edge) => edge.source === sourceNode.id);
        allOutgoingEdges.forEach((edge) => {
            const sourceNode = nodes.find((node) => node.id === edge.target);
            if (sourceNode === undefined) return previousComponent;
            const withoutSourceNode = nodes.filter((node) => node?.id !== sourceNode?.id);
            previousComponent = findPreviousComponent(withoutSourceNode, edges, sourceNode, targetNodeId, previousComponent);
        });
    }
    return previousComponent;
}

const findNodeLabelById = (nodeTypeId: string) => {
    switch (nodeTypeId) {
        case nodeType.Ingredients:
            return 'ingredient';
        case nodeType.Transportation:
            return 'transportation';
        case nodeType.Warehouse:
            return 'warehouse';
        case nodeType.Client:
            return 'client';
        case nodeType.Cultivar:
            return 'cultivar';
        case nodeType.FarmGateProcess:
            return 'farmGateProcess';
        case nodeType.FarmGateInternalTransport:
            return 'farmGateInternalTransport';
        case nodeType.FarmGatePacking:
            return 'farmGatePacking';
        case nodeType.FarmGateInternalStorage:
            return 'farmGateInternalStorage';
        case nodeType.ProcessGateProcessing:
            return 'processGateProcessing';
        case nodeType.ProcessGatePacking:
            return 'processGatePacking';
        case nodeType.ProcessGateInternalTransport:
            return 'processGateInternalTransport';
        case nodeType.ProcessGateInternalStorage:
            return 'processGateInternalStorage';
        case nodeType.Packing:
            return 'packing';
        default:
            return '';
    }
}

const filterDataReferenceIds = (nodeTypeId: string, dataReference: any) => {
    if (nodeTypeId === nodeType.Cultivar) {
        return { ingredientId: dataReference?.ingredientId };
    }
    return dataReference;
}