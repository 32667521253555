import { CommonModal } from "../../../components";
import { useCallback, useContext, useMemo } from "react";
import "./UpdateNodeModal.scss";
import { DrawingContext } from "../DrawingTool";
import { nodeType } from "../../../config/drawingConstants";
import { IngredientBase } from "./IngredientBase";
import { InternalTransportBase } from "./FarmGate/Transport/InternalTransportBase";
import { TransportBase } from "./TransportBase";
import { InternalStorageBase } from "./FarmGate/Storage/InternalStorageBase";
import { FarmGateProcessBase } from "./FarmGate/Processing/FarmGateProcessBase";
import { FarmGatePackagingBase } from "./FarmGate/Packaging/FarmGatePackagingBase";
import { WarehouseBase } from "./WarehouseBase";
import { ClientBase } from "./ClientBase";
import { CultivarBase } from "./CultivarBase";
import { useReactFlow } from "reactflow";
import useWindowDimensions from "../../../hooks/windowsSize";
import { ProcessInternalTransportBase } from "./ProcessGate/Transport/InternalTransportBase";
import { ProcessInternalStorageBase } from "./ProcessGate/Storage/InternalStorageBase";
import { ProcessGateProcessingBase } from "./ProcessGate/Processing/ProcessGateProcessBase";
import { ProcessGatePackagingBase } from "./ProcessGate/Packaging/ProcessGatePackagingBase";
import { checkNodeModalValidation } from "../utils";
import { errorView } from "../../../helpers/ErrorHandler";
import { getNewDaringItemStatus } from "../../../utils/cacheStorage";
import { DISABLE_CLOSE_MODEL_ID } from "../../../config/constants";
import { isEmpty } from "lodash";

interface UpdateNodeModalProps {}

const UpdateNodeModal = (props: UpdateNodeModalProps) => {
  const {
    nodeItemId,
    chartNodes,
    chartEdges,
    setNodeItem,
    salesUnitId,
    productId,
  } = useContext(DrawingContext);
  // get chart node item from node id
  const reactFlow = useReactFlow();

  const nodeItem = useMemo(() => {
    const getIconId = nodeItemId?.data?.icon || 0;
    const checkDrawingIsNew = getNewDaringItemStatus();
    if (
      (nodeItemId?.id &&
        DISABLE_CLOSE_MODEL_ID.some((e) => e === getIconId) &&
        !isEmpty(checkDrawingIsNew)) ||
      false
    ) {
      return reactFlow.getNode(nodeItemId?.id || "");
    } else {
      return reactFlow.getNode(nodeItemId || "");
    }
  }, [nodeItemId, reactFlow]);
    
  const height = useWindowDimensions();  

  // Filter the Modal content base on node type
  const nodeTypeContent = useCallback(() => {
    let { status, data, errorMessage } = checkNodeModalValidation(
      nodeItem,
      chartNodes,
      chartEdges,
      salesUnitId,
      productId
    );
    if (!status) {
      setNodeItem(null);
      errorView(errorMessage);
      return;
    }
    switch (nodeItem?.data.icon) {
      case nodeType.Ingredients:
        return <IngredientBase />;
      case nodeType.Client:
        return <ClientBase modalData={data} />;
      case nodeType.FarmGateInternalTransport:
        return <InternalTransportBase modalData={data} />;
      case nodeType.Transportation:
        return <TransportBase modalData={data} />;
      case nodeType.FarmGateInternalStorage:
        return <InternalStorageBase modalData={data} />;
      case nodeType.FarmGateProcess:
        return <FarmGateProcessBase modalData={data} />;
      case nodeType.FarmGatePacking:
        return <FarmGatePackagingBase modalData={data} />;
      case nodeType.Warehouse:
        return <WarehouseBase />;
      case nodeType.Cultivar:
        return <CultivarBase modalData={data} />;
      case nodeType.ProcessGateInternalTransport:
        return <ProcessInternalTransportBase modalData={data} />;
      case nodeType.ProcessGateInternalStorage:
        return <ProcessInternalStorageBase modalData={data} />;
      case nodeType.ProcessGatePacking:
        return <ProcessGatePackagingBase modalData={data} />;
      case nodeType.ProcessGateProcessing:
        return <ProcessGateProcessingBase modalData={data} />;
      default:
        return null;
    }
  }, [nodeItem]);

  // Filter the Modal header
  const nodeTypeHeader = useMemo(() => {
    switch (nodeItem?.data.icon) {
      case nodeType.Ingredients:
        return "Ingredients";
      case nodeType.FarmGateInternalTransport:
        return "Internal Transportation";
      case nodeType.Transportation:
        return "External Transportation";
      case nodeType.FarmGateInternalStorage:
        return "Internal Storage";
      case nodeType.FarmGateProcess:
        return "Processing Information";
      case nodeType.Warehouse:
        return "External Warehouse";
      case nodeType.Client:
        return "Client";
      case nodeType.FarmGatePacking:
        return "Packaging Information";
      case nodeType.Cultivar:
        return "Cultivar";
      case nodeType.ProcessGateInternalTransport:
        return "Process Internal Transportation";
      case nodeType.ProcessGateInternalStorage:
        return "Process Internal Storage";
      case nodeType.ProcessGatePacking:
        return "Packaging Information";
      case nodeType.ProcessGateProcessing:
        return "Processing Information";

      default:
        return "";
    }
  }, [nodeItem]);

  return (
    <CommonModal
      modalOpenState={!!nodeItem}
      modalCloseState={() => {
        setNodeItem(null);
      }}
      centered={true}
      title={`Update ${nodeTypeHeader}`}
      style={height}
    >
      {nodeTypeContent()}
    </CommonModal>
  );
};

export default UpdateNodeModal;
