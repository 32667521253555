import { ReactNode, useMemo } from "react";
import { drawerRoute } from "../../config/constants";
import "./container.scss";

type CommonContainerProps = {
  children?: any;
  left?: boolean;
  right?: boolean;
  styles?: string;
  currentPath?: string;
};

export const CommonContainer: React.FC<CommonContainerProps> = ({
  children,
  left = false,
  right = false,
  styles,
  currentPath,
}: any) => {
  const containerStyle = useMemo(() => {
    if (left) return `topLeftContainer ${styles}`;
    if (right) return `topRightContainer ${styles}`;
    if (drawerRoute.includes(currentPath)) return `drawerContainer ${styles}`;
    return `defaultContainer ${styles}`;
  }, [left, right, currentPath]);

  return <div className={containerStyle}>{children}</div>;
};
